@import 'assets/theme';

.container {
  height: max-content;
  min-height: 100vh;
  background: linear-gradient(0deg, #fff, #4b6184, #2a4670 40%) no-repeat;
  position: relative;
  z-index: 1;

  .headerWrapper {
    display: flex;
    padding: 0 16px 24px;
  }

  .contentWrapper {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    position: relative;
    z-index: 3;
  }
}

.container::after {
  position: absolute;
  content: ' ';
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 100%;
  min-height: 100vh;
  opacity: 0.8;
  background-color: #2a4670;
}
