@import 'helm/assets/theme';

.container {
  display: flex;
  border: 1px dashed #f15944;
  border-radius: 8px;
  width: 90px;
  min-width: 90px;
  height: 100px;
  background-color: $helm-light-yellow;
}

.bookContainer {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 8px;
  padding: 4px 8px 8px;
  min-height: 100px;
  min-width: 106px;

  .closeBtn {
    position: relative;
    left: 90px;
    top: -14px;
    height: 0;

    .closeIcon {
      background: $white;
      border-radius: 50%;
      font-size: 18px;
      padding: 2px;
      color: $helm-red;
      cursor: pointer;
      user-select: none;
    }
  }

  .timeSlot {
    font-family: $primary-font-stack-bold !important;
    font-size: 10px;
    line-height: 13px;
    background: $helm-blue;
    color: $white;
    border-radius: 24px;
    margin-top: 8px;
    padding: 4px 12px;
    text-align: center;
  }
}

.blockContainer {
  @extend .bookContainer;
  background-color: $helm-lighter-yellow;
  border: 2px solid $helm-blue;
}
