@import 'assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #2a4670;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: #2a4670;
  flex: 1 0 auto;

  .headerContent {
    display: flex;
    width: 100%;
    flex: initial;
  }

  .headerWrapper {
    display: flex;
  }
}

.content {
  padding-top: 24px;
  width: 100%;
  position: relative;

  @media (max-width: $medium-screen-min-width) {
    padding: 0;
  }
}
