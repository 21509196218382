@import 'helm/assets/theme';

.container {
  background-color: $green50;
  min-height: 100vh;
  overflow: hidden;
  font-family: 'Nunito', normal;

  .content {
    max-width: 720px;
    margin: $standard-padding * 8 auto $standard-padding * 2;

    .header {
      font-weight: 700;
      font-size: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: -0.04em;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 28px;
      }

      .logo {
        width: 80px;
        min-width: 80px;
        height: 80px;
        border-radius: 80px;
        background-color: #211f20;
        margin-right: $standard-padding * 6;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: $tablet-screen-max-content) {
          margin-right: $standard-padding * 2;
        }

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .card {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      background-color: white;
      padding: $standard-padding * 5 $standard-padding * 8;
      max-width: 700px;
      margin-top: $standard-padding * 6;

      @media (max-width: $tablet-screen-max-content) {
        padding: $standard-padding * 3 $standard-padding * 4;
      }

      .cardTitle {
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;

        @media (max-width: $tablet-screen-max-content) {
          font-size: 24px;
          line-height: 36px;
        }
      }

      .cardText {
        margin-top: $standard-padding * 4;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;

        @media (max-width: $tablet-screen-max-content) {
          font-size: 16px;
        }
      }

      .cardButton {
        width: 100%;
        display: flex;
        margin-top: $standard-padding * 5;
      }

      .proceedBtn {
        margin: auto;
        margin-top: $standard-padding * 7;
        margin-bottom: $standard-padding;
        box-shadow: none;
        width: 100%;
        max-width: 360px;
        font-weight: 600;
        font-size: 16px;
        height: 47px;

        @media (max-width: $tablet-screen-max-content) {
          margin-top: $standard-padding * 2;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
