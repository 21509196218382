@import 'assets/theme';

.container {
  &:not(:last-of-type) {
    margin-bottom: $standard-padding * 3;
  }
}

.rangeTitle {
  font-size: 13px;
  font-weight: bold;
}

.chartContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 4px 16px;

  .arrowWrapper {
    display: flex;

    .arrow {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 2px;
      position: relative;

      .label {
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        color: #1941da;
      }

      .icon {
        font-size: 40px;
        line-height: 10px;
        padding-bottom: 8px;
        color: #1941da;
      }
    }
  }

  .chart {
    display: flex;
    width: 100%;
    height: 16px;
    border: 1px solid #343434;

    .part {
      &:not(:last-child) {
        border-right: 1px solid #343434;
      }
    }
  }

  .descWrapper {
    display: flex;

    .labelPart {
      display: flex;
      font-size: 11px;
      justify-content: space-between;

      .value {
        position: relative;
        right: 3px;
      }

      .noAdjustValue {
        position: relative;
      }

      .lastValue {
        position: relative;
        left: 4px;
      }
    }
  }
}
