@import 'assets/theme';

.tableContainer {
  padding: 12px;
  table {
    width: 100%;
  }
  table tr {
    margin: auto 0;
    &:last-child {
      border-bottom: 1px solid #d9d9d9;
    }
  }
  table th {
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    color: $colorGrey700;
    height: 54px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
  }
  table td {
    padding: 20px;
    vertical-align: initial;
  }
  .noBorder {
    border-bottom: none !important;
  }
  .noPadding {
    width: 44px;
    padding-left: 0;
    padding-right: 0;
  }
  .width110 {
    width: 110px;
  }
  .dashedBorderTop {
    border-top: 1px dashed #2a4670;
  }
  .totalLabel {
    color: #22348c;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
  .totalPrice {
    color: $colorGrey700;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
  }
  .title {
    color: $colorGrey700;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}
