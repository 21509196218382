@import 'Portobello/assets/theme';

.container {
  z-index: 1;
  min-height: calc(100vh - 82px);
  background-size: cover;
  background-image: url('~Portobello/assets/images/background/background.png');
  background-repeat: no-repeat;
  font-family: $Playfair-Display;

  .content {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 100%;
    margin: 24px 0;
  }
}
