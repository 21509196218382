@import 'CaW/assets/theme';

.container {
  display: flex;
  border: 1px dashed #f15944;
  border-radius: 8px;
  width: 110px;
  height: 109px;
  background-color: $white;

  @media (max-width: $medium-small-screen-min-width) {
    width: 100%;
    max-width: 107px;
  }
}

.bookContainer {
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 8px;
  justify-content: center;
  width: 110px;
  height: 109px;
  position: relative;

  @media (max-width: $medium-small-screen-min-width) {
    width: 100%;
    max-width: 107px;
  }

  .closeBtn {
    position: absolute;
    right: -11px;
    top: -11px;

    .closeIcon {
      background: $white;
      border-radius: 50%;
      font-size: 18px;
      padding: 2px;
      color: $caw-dark-red;
      cursor: pointer;
      user-select: none;
    }
  }

  .timeSlot {
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    letter-spacing: -0.04em;

    background: $white;
    color: $caw-blue2;
    border-radius: 24px;
    margin-top: 16px;
    text-align: center;
  }
}

.blockContainer {
  @extend .bookContainer;
  background-color: $caw-light-red;
  border: 2px solid $caw-blue;
}
