@import 'assets/theme';
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  .header {
    display: flex;
    align-items: center;
    .logo {
      width: 113px;
      height: 42px;
    }
  }
  .backBtnWrapper {
    display: flex;
    align-items: center;
    color: gray;
    font-size: 12px;
    cursor: pointer;
    .icon {
      display: flex;
      width: 10px;
      font-size: 30px;
      position: relative;
      right: 14px;
    }
  }
}
