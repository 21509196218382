@import 'SomeoneHealth/assets/theme';

.footerContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-image: url(~SomeoneHealth/assets/images/background/footer.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 280px;
  transition: background-position 0.3s;

  @media (max-width: 1024px) {
    padding-top: 330px;
    background-position: center center;
  }
}

.container {
  max-width: 1140px;
  padding: 10px;
  display: flex;
  justify-content: flex-end;

  .content {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    #aboutUsWrapper {
      padding: 10px 16px 10px 10px;
    }

    .textContainer {
      width: 25%;
      padding: 10px 10px 10px 10px;

      @media (max-width: $small-screen-max-width) {
        width: 100%;
      }

      #aboutUsContent {
        line-height: 32px;
      }

      #quickLinksHeader {
        margin: 0px 0px 18px 0px;
      }

      #getInTouchContent,
      #getInTouchContentLast {
        position: relative;
        margin-bottom: 12px;
        padding-left: 29px;

        .icon {
          position: absolute;
          left: 0;
          top: 6.5px;
          z-index: 1;
          width: 15px;
          text-align: center;
          line-height: inherit;
          color: #f46386;
        }

        .text {
          display: flex;
          align-items: flex-start;
        }
      }

      #getInTouchContentLast {
        margin-bottom: 0;
      }

      .header {
        color: #ffffff;
        font-size: 17px;
        font-weight: 600;
        line-height: 35px;
        margin: 0px 0px 25px 0px;
      }

      .list {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      .text {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;
        line-height: 28px;
        letter-spacing: 0;
      }

      .textBold {
        font-weight: 900;
      }

      .textPink {
        color: #f8e0ed;
      }

      .textBlue {
        color: #c7c2e4;
      }

      .logo {
        margin-top: 30px;
        max-width: 100%;
        cursor: pointer;
      }
    }

    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }
  }

  .staySafe {
    padding: 10px;

    .lineWrapper {
      margin: 18px 0;

      .line {
        border-top: 1px solid #9f4191;
      }
    }

    .text {
      letter-spacing: 0px;
      text-align: center;
      color: #f8e0ed;
      font-size: 15px;
      font-weight: 500;
      line-height: 30px;
      margin: 0px;
    }
  }

  @media (max-width: 1024px) {
    padding: 0 10px;
  }
}
