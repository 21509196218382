@import 'assets/theme';

.contentWrapper {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 0 16px;
  background-color: #2a4670;

  .content {
    display: flex;
    flex-direction: column;
    color: #ffffff;

    .heading {
      font-size: 24px;
      line-height: 28px;
      padding: 24px 0;
    }

    .text {
      font-size: 18px;
      line-height: 28px;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    .suggestionList {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 16px;
      color: #ffffff;

      &:first-child {
        margin-top: 16px;
      }

      .suggestionIcon {
        border: 1px solid #fff;
        border-radius: 50%;
        padding: 4px;
        margin-left: 16px;
      }
    }
  }
}
