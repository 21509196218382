@import 'SomeoneHealth/assets/theme';

.someone-health-theme {
  .mbsc-font {
    font-family: $font;
  }

  .mbsc-calendar-grid-item {
    padding-top: 3.5em;
    background-color: $white;
  }

  .mbsc-calendar-grid {
    padding: 0;
  }

  .mbsc-calendar-header.mbsc-ios {
    border-bottom: solid 1px $someone-health-blue;
    margin-bottom: 20px;
    border-radius: 8px 8px 0 0;
  }

  .mbsc-ios.mbsc-calendar-button.mbsc-button {
    font-size: 18px !important;
  }

  .mbsc-datepicker {
    border-radius: 7px;
  }

  .mbsc-ios.mbsc-datepicker-inline {
    border: none !important;
  }

  .mbsc-ios.mbsc-eventcalendar .mbsc-calendar-wrapper {
    border-bottom: none;
  }

  .mbsc-ios.mbsc-calendar {
    border-radius: 8px;
  }

  .mbsc-ios.mbsc-calendar-month-title {
    color: $someone-health-blue;
    font-weight: normal;
    font-size: 28px;
    line-height: 36px;
    margin: -2em 36px 0 !important;

    @media (max-width: calc($small-desktop-screen-max-content + 100px)) {
      margin: -2em 32px 0 !important;
    }
  }

  .mbsc-ios.mbsc-calendar-day-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px !important;
    line-height: 32px !important;
    padding-top: 2px !important;

    @media (max-width: $tablet-screen-max-content) {
      font-size: 20px !important;
      line-height: 27px !important;
      padding-top: 0 !important;
    }
  }

  .mbsc-ios.mbsc-calendar-week-day {
    color: $someone-health-blue;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  [aria-label='Saturday'] {
    color: $someone-health-pink !important;
  }

  [aria-label='Sunday'] {
    color: $someone-health-pink !important;
  }

  .mbsc-calendar-cell-text {
    color: $someone-health-blue;
  }

  .mbsc-segmented {
    max-width: 300px;
    margin: 0 auto;
  }

  .cal-header-picker {
    flex: 1 0 auto;
  }

  .mbsc-ios.mbsc-calendar-header {
    background: $white !important;
    padding: 13px 16px 11px;
  }

  .mbsc-disabled {
    background: none !important;
  }

  .mbsc-disabled .mbsc-calendar-cell-text {
    color: $text-disable-grey;
    opacity: 1;
    font-weight: 500;
  }

  .mbsc-calendar-day-inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mbsc-ios.mbsc-calendar-grid .mbsc-calendar-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
  }

  .mbsc-ios.mbsc-calendar-marks {
    margin: 24px auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: auto;

    @media (max-width: $mobile-screen-max-content) {
      margin: 16px auto 0;
      max-width: 30px;
    }
  }

  .mbsc-ios.mbsc-calendar-mark {
    margin: 0.0625em !important;

    @media (max-width: $mobile-screen-max-content) {
      margin: 0.0325em !important;
    }
  }

  .mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
    color: $white;
    background: $someone-health-blue !important;
    border-radius: 12px;
    border: 4px solid #d3d6df;
  }

  .mbsc-ios.mbsc-hover .mbsc-calendar-cell-text {
    background-color: #24306220;
    border-radius: 12px;
  }

  .mbsc-calendar-grid .mbsc-calendar-table {
    margin: 0 16px;
    width: calc(100% - 32px);
  }

  .mbsc-ios.mbsc-calendar-grid-view .mbsc-calendar-title {
    font-size: 28px;
    line-height: 36px;
    color: $someone-health-blue;
  }

  .mbsc-calendar-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: $someone-health-blue;
    @media (max-width: $mobile-screen-max-content) {
      font-size: 18px !important;
    }
  }

  .mbsc-ios.mbsc-calendar-button.mbsc-button {
    color: $someone-health-blue !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }

  .mbsc-ios.mbsc-calendar-today {
    color: $someone-health-blue;
  }

  .mbsc-calendar-grid-row {
    background-color: $white;
  }

  .mbsc-ios-dark.mbsc-calendar-mark {
    background: #cccccc !important;
  }
}

.s1h-client-portal-theme {
  .mbsc-font {
    font-family: $Poppins;
  }

  .mbsc-ios.mbsc-calendar-header {
    background: #f7f7f7 !important;
  }

  .mbsc-calendar-header.mbsc-ios {
    border-bottom: none;
  }

  .mbsc-ios.mbsc-calendar-button.mbsc-button {
    color: $someone-health-maroon !important;
  }

  .mbsc-calendar-title {
    color: $someone-health-maroon;
  }

  .mbsc-ios.mbsc-calendar-week-day {
    color: $someone-health-maroon;
  }

  [aria-label='Saturday'] {
    color: $someone-health-maroon !important;
  }

  [aria-label='Sunday'] {
    color: $someone-health-maroon !important;
  }

  .mbsc-ios.mbsc-datepicker .mbsc-calendar,
  .mbsc-ios.mbsc-datepicker .mbsc-calendar-slide,
  .mbsc-ios.mbsc-datepicker .mbsc-calendar-cell {
    background: #f7f7f7;
  }

  .mbsc-disabled .mbsc-calendar-cell-text {
    color: #e5e5e5;
  }

  .mbsc-calendar-cell-text {
    color: $someone-health-maroon;
    width: 47px !important;
    height: 47px !important;
  }

  .mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
    color: $someone-health-maroon;
    background: $someone-health-light-maroon !important;
    border: none;
    border-radius: 8.69px;
  }

  .mbsc-calendar-cell-text {
    border-radius: 8.69px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mbsc-ios.mbsc-calendar-day-text {
    border: none;
    font-weight: 500;
  }
}
