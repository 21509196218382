@import 'assets/theme';

.container {
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.row-reverse {
  flex-direction: row-reverse;
}

.column-reverse {
  flex-direction: column-reverse;
}

.alignItems-center {
  align-items: center;
}

.alignItems-flex-start {
  align-items: flex-start;
}

.alignItems-flex-end {
  align-items: flex-end;
}

.justifyContent-center {
  justify-content: center;
}

.justifyContent-flex-start {
  justify-content: flex-start;
}

.justifyContent-flex-end {
  justify-content: flex-end;
}

.justifyContent-space-between {
  justify-content: space-between;
}

.justifyContent-space-around {
  justify-content: space-around;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-initial {
  flex: initial;
}

.spacing-4 {
  gap: 4px;
}

.spacing-8 {
  gap: 8px;
}

.spacing-12 {
  gap: 12px;
}

.spacing-16 {
  gap: 16px;
}

.spacing-20 {
  gap: 20px;
}

.spacing-40 {
  gap: 40px;
}
