@import 'MumsMatter/assets/theme';

.container {
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 82px);
  background-color: $white;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('~MumsMatter/assets/images/background/background.png');
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.8;
    z-index: -1;
  }

  .content {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 100%;
    margin: 24px 0;
  }
}
