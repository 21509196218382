@import 'Recharge/assets/theme';

:global(.recharge-theme) {
  .editButton {
    &:hover {
      background-color: $red500 !important;
    }
  }
}

:global(.s1h-client-portal-theme) {
  .editButton {
    border-radius: 28px;
  }
}
