@import 'Recharge/assets/theme';

.container {
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 360px;
  background-image: url(~Recharge/assets/images/background/quote.png);

  @media (max-width: $mobile-screen-max-content) {
    min-height: 220px;
  }

  .quoteContent {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 360px;

    @media (max-width: $mobile-screen-max-content) {
      min-height: 220px;
    }

    .quote {
      white-space: pre-line;
      padding: 16px 0;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      letter-spacing: -0.02em;
      color: $recharge-purple;
      max-width: 540px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 24px;
        line-height: 32px;
      }

      @media (max-width: $mobile-screen-max-content) {
        font-size: 16px;
        line-height: 24px;
        max-width: 60%;
      }
    }

    .commaContainer {
      display: flex;
      position: relative;
      left: -60px;

      @media screen and (max-width: $tablet-screen-max-content) {
        left: -10px;
      }

      .comma {
        width: 20px;
        height: 30px;

        @media screen and (max-width: $tablet-screen-max-content) {
          width: 16px;
        }

        @media screen and (max-width: $mobile-screen-max-content) {
          width: 10px;
        }
      }
    }

    .endCommaContainer {
      @extend .commaContainer;
      justify-content: flex-end;
      left: 60px;

      @media screen and (max-width: $tablet-screen-max-content) {
        left: 10px;
      }
    }
  }
}
