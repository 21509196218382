@import '../../assets/theme/index';

.container {
  display: flex;
  padding: $standard-padding * 3 $standard-padding * 5;
  min-height: 290px;
  flex-direction: column;
  page-break-inside: avoid;

  .label {
    font-size: 1.6rem;
    line-height: 1.1;
    font-weight: bold;
    margin-bottom: $standard-padding * 6;
  }

  .content {
    flex-grow: 1;
    white-space: pre-wrap;
  }
}
