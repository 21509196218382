@import 'SomeoneHealth/assets/theme';
@import 'assets/theme';

.container {
  padding: 0 calc((100% - 1120px) / 2);
  margin-top: 8px;

  @media screen and (max-width: ($medium-large_screen-min-width + 1)) {
    padding: 0;
    display: flex;
  }

  .content {
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media screen and (max-width: ($medium-large_screen-min-width + 1)) {
      max-width: 540px;
      gap: 35px;
    }
  }

  .fieldContainer {
    margin-bottom: 8px;
  }

  .desc {
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    color: #202225;
  }

  .dateFieldContainer {
    margin-top: -20px;
  }

  .gpDateFieldContainer {
    margin-top: -20px;
  }

  .referralDocuments,
  .treatmentPlans {
    .label {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: $colorGrey700;
    }
  }

  .file {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .fileName {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $colorGrey700;

      i {
        font-size: 15px;
        color: $blue500;
      }
    }
  }

  .uploadArea {
    background: $someone-health-light-pink;
    padding: 40px 60px 40px 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (max-width: $medium-large_screen-min-width) {
      padding: 30px;
    }
  }

  .uploadButtonContainer {
    width: fit-content;

    .uploadInput {
      display: none;
    }

    .uploadButton {
      display: flex;
      padding: 14px;
      gap: 14px;
      border: solid 1px $someone-health-pink;
      border-radius: 4px;
      color: $someone-health-pink;
      cursor: pointer;
    }
  }
}
