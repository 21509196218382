@import 'MumsMatter/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 10px;
}

.subContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: $colorGrey700;
}

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 14px;
}

.icon {
  padding: 0;
  font-size: 22px;
}

.desc {
  color: $colorGrey700;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.processingPolicyTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;

  @media (max-width: $medium-large_screen-min-width) {
    padding-left: 0;
  }
}

:global(.mums-matter-theme) {
  .title {
    font-weight: 700;
    color: $mums-matter-dark-blue;
  }

  .desc {
    color: $mums-matter-dark-blue;
    font-size: 16px;
    font-weight: 500;
  }
}
