@import 'Recharge/assets/theme';

$scrollingSlotView: $mobile-screen-max-content + 100px;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 40px;
    line-height: 54px;
    font-weight: 600;
    text-align: center;
    color: $recharge-black;

    @media (max-width: $scrollingSlotView) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .desc {
    display: flex;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: $recharge-black;
    max-width: 700px;
    padding-top: $standard-padding;

    @media (max-width: $scrollingSlotView) {
      font-size: 16px;
      line-height: 22px;
    }

    a {
      color: $recharge-purple;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .buttonWrapper {
    padding-top: $standard-padding * 6;
  }
}
