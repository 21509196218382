@import 'helm/assets/theme';

.contentLayout {
  display: flex;
  flex-direction: column;
  max-width: $desktop-screen-max-content;
  margin: 0 auto;
  width: 100%;
  padding: 0 16px;

  @media (max-width: $tablet-screen-max-content) {
    padding: 0 16px;
  }

  @media (max-width: $mobile-screen-max-content) {
    padding: 0 16px;
  }
}
