@import 'helm/assets/theme';

.container {
  display: flex;

  &:not(:last-child) {
    .line {
      border-right: 1px solid #c4c4c4;
    }
  }

  .pointWrapper {
    display: flex;
    position: relative;
    left: 10px;

    .bubbleWrapper {
      width: 36px;
      height: 36px;

      .bubbleDot {
        border-radius: 50%;
        border: 2px solid #2a4670;
        background-color: #2a4670;
        color: $white;
        font-size: 16px;
        padding: 5px;
        position: absolute;
        left: -14px;
      }
    }
  }

  .detailsWrapper {
    display: flex;
    flex-direction: column;
    padding-left: 8px;

    .title {
      display: flex;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: $helm-blue;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 16px;
        line-height: 21px;
      }
    }

    .desc {
      color: $helm-blue;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      padding: 20px 0 60px 0;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 14px;
        line-height: 18px;
        padding: 8px 0 38px 0;
      }

      a {
        color: $helm-blue;
        text-decoration: underline;
      }
    }
  }
}
