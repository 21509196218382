@import 'assets/theme';
@import 'SomeoneHealth/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';

.modalContainer {
  width: 760px !important;

  @media (max-width: $medium-small-screen-min-width) {
    width: unset !important;
  }
}

.container {
  padding: 22px 20px 28px 22px;
  display: flex;
  gap: 20px;

  .infoIcon {
    color: #263054;
  }

  .alertContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: $colorGrey700;
  }

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
  }

  .link {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $blue500;
    cursor: pointer;

    .backIcon {
      font-size: 18px;
      margin-top: 1px;
    }
  }
}

:global(.recharge-theme) {
  .container {
    .infoIcon,
    .link {
      color: $recharge-purple;
    }

    .alertContent {
      color: $recharge-black;
    }
  }
}

:global(.select-theme) {
  .container {
    .infoIcon {
      color: $select-black;
    }

    .link {
      color: $select-black;

      &:hover {
        .text {
          text-decoration: underline;
        }
      }
    }

    .alertContent {
      color: $select-black;
    }
  }
}
