@import 'CORDS/assets/theme';
@import 'assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  color: $cords-dark-blue;
  text-align: center;
  background: linear-gradient(180deg, #fdeabd, #fab40c);

  @media (max-width: $mobile-screen-max-content) {
    padding: 0 16px;
  }

  .thankYouBigText {
    font-weight: 700;
    font-size: 32px;
    margin-top: $standard-padding * 6;

    @media (max-width: $mobile-screen-max-content) {
      margin-top: $standard-padding * 3;
      font-size: 28px;
    }
  }

  .thankYouText {
    font-weight: 600;
    font-size: 24px;
    margin-top: $standard-padding * 3;

    @media (max-width: $mobile-screen-max-content) {
      margin-top: $standard-padding * 2;
      font-size: 16px;
    }
  }

  .checkIcon {
    align-self: center;
    width: 100%;
    max-width: 280px;

    @media (max-width: $mobile-screen-max-content) {
      max-width: 180px;
      height: 180px;
    }
  }
}
