@import 'helm/assets/theme';

.container {
  display: flex;
  background: $helm-lighter-yellow;

  .content {
    display: flex;
    flex-direction: column;
    padding: $standard-padding * 2 0 $standard-padding * 2;
  }

  .logoWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      max-width: 270px;

      @media (max-width: $tablet-screen-max-content) {
        max-width: 160px;
      }
    }
  }

  .headerTitleWrapper {
    display: flex;
    flex: 1;
    margin: 0 20px 0 60px;

    @media (max-width: $small-desktop-screen-max-content) {
      margin: 0;
      display: none;
    }

    @media (max-width: $tablet-screen-max-content) {
      padding-bottom: $standard-padding * 2;
    }

    .headerTitle {
      font-family: $title-font-stack;
      color: $helm-blue;
      font-size: 72px;
      line-height: 79px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 48px;
        line-height: 53px;
      }
    }
  }

  .mobileHeaderTitleWrapper {
    @extend .headerTitleWrapper;
    display: none;
    margin: 0 16px;

    @media (max-width: $small-desktop-screen-max-content) {
      display: flex;
    }
  }

  .filterContainer {
    display: flex;
    align-items: center;
    margin: 20px 0;

    @media (max-width: $small-desktop-screen-max-content) {
      flex-direction: column;
      align-items: flex-start;
    }

    .titleWrapper {
      display: flex;
      flex: 1 30%;
      margin: 0 28px;

      @media (max-width: $small-desktop-screen-max-content) {
        margin: 0;
      }

      @media (max-width: $tablet-screen-max-content) {
        padding-bottom: $standard-padding * 2;
      }
    }

    .title {
      font-family: $title-font-stack;
      color: $helm-blue;
      font-size: 72px;
      line-height: 79px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 48px;
        line-height: 53px;
      }
    }

    .filterWrapper {
      display: flex;
      flex-direction: column;
      flex: 1 75%;
      @include rowGap(16px);

      .filter {
        display: flex;
        align-items: center;
        color: $white;
        letter-spacing: -0.02em;
        font-weight: 500;
        font-size: 36px;
        line-height: 47px;
        flex-wrap: wrap;

        @media (max-width: $tablet-screen-max-content) {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }

  .timezoneContainer {
    display: flex;
    justify-content: flex-end;
  }
}
