@import 'Portobello/assets/theme';

.container {
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 82px);
  background-size: cover;
  background-image: url('~Portobello/assets/images/background/background.png');
  background-repeat: no-repeat;
  font-family: $Playfair-Display;

  .fixedLeftBtn {
    position: fixed;
    bottom: 40px;
    box-shadow: 0 4px 10px rgba(52, 52, 52, 0.35);
    border-radius: 4px !important;
    height: 55px;
    z-index: 4;
    font-weight: 600;
    font-size: 13px;
    color: $white;
    min-height: 48px;
    min-width: 175px;

    @media (max-height: 740px) {
      bottom: 13px;
      left: 2%;
    }

    .newJournalBtn {
      display: flex;
      align-items: center;

      .icon {
        padding-right: 8px;
      }
    }

    .btnLoading {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn:after {
        border-color: #fff transparent #fff transparent;
      }
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 3;
  }

  .content {
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 80px 16px 120px;
    align-items: center;

    @media (max-width: $tablet-screen-max-content) {
      padding: 24px 0 0;
    }

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 150px 0 0;
    }

    .contentBox {
      display: flex;
      flex-direction: column;
      row-gap: $standard-padding * 3;
      width: 730px;
      margin: 0 auto;

      @media (max-width: $tablet-screen-max-content) {
        width: 100%;
      }

      .welcomeMessage {
        color: #ffffff;
      }

      .noTaskMassage {
        .label {
          font-size: 16px;
          line-height: 24px;
        }

        .taskLabel {
          font-size: 18px;
          line-height: 27px;
          margin: 32px 0;

          .number {
            color: $grey700;
            font-weight: 600;
          }
        }
      }

      .taskListContainer {
        display: flex;
        flex-direction: column;
        max-width: 600px;

        .taskListTitle {
          font-family: $Playfair-Display;
          font-size: 18px;
          line-height: 28px;
          color: #ffffff;
          padding-bottom: 16px;

          @media (max-height: $tablet-screen-max-content) {
            font-size: 16px;
          }

          .title {
            font-weight: 700;
            font-size: 28px;
            line-height: 42px;
            letter-spacing: -0.02em;
            margin-bottom: $standard-padding * 6;
          }
        }

        .taskListBox {
          display: flex;
          flex-direction: column;
          row-gap: 24px;

          @media (max-height: $tablet-screen-max-content) {
            row-gap: 16px;
          }

          .taskBoxClassName {
            border-radius: 4px;
            background-color: $portobello-light-green;
            font-family: $Montserrat;

            .arrowIcon {
              border-radius: 4px !important;
            }
          }
        }
      }
    }
  }

  .footer {
    z-index: 3;
  }
}
