@import 'Ease/assets/theme';

.container {
  height: 100%;
  overflow: auto;
  position: relative;
  z-index: 1;

  .content {
    text-align: center;
    max-width: 1014px;
    background-color: white;
    border-radius: 8px;
    padding: $standard-padding * 14 $standard-padding * 16;
    margin: $standard-padding * 6 auto;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    @media (max-width: $tablet-screen-max-content) {
      padding: $standard-padding * 8 $standard-padding * 6;
      margin: $standard-padding * 28 $standard-padding * 4;
    }

    .skipWrapper {
      display: flex;
      align-items: center;
      margin: -32px -24px 24px 0;
      justify-content: flex-end;
      font-size: 16px;

      @media (max-width: $tablet-screen-max-content) {
        margin: -20px -12px 16px 0;
        font-size: 14px;
      }

      .skipBtn {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: $ease-dark-grey;
      }

      .arrowIcon {
        margin-left: 4px;

        @media (max-width: $tablet-screen-max-content) {
          font-size: 16px;
        }
      }
    }

    .input {
      padding-left: $standard-padding * 4 + 2;
      margin-left: -$standard-padding * 4;
      width: calc(100% + 32px);
    }

    .inputBox {
      max-width: 507px;
      margin: auto;
      width: 80%;
    }

    .submitBtn {
      margin: $standard-padding * 4 auto;
      width: 275px;
      box-shadow: none;

      @media only screen and (max-width: $tablet-screen-max-content) {
        width: 100%;
      }

      &:active {
        opacity: 0.8;
      }
    }
  }
}
