@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  color: $someone-health-blue;
  background-color: $calendarPill;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  min-width: 120px;
}

.darkYellowContainer {
  @extend .container;
  background-color: $someone-health-light-green;
}

:global(.s1h-client-portal-theme) {
  .container {
    border-radius: 4px;
    color: $someone-health-maroon;
    background-color: $white;
    border: 1px solid $someone-health-maroon2;
    font-weight: $font-weight-bolder;
    font-size: 14px;
    padding: 8px 16px;
    width: 160px;

    @media (max-width: $medium-small-screen-min-width) {
      padding: 8px 4px;
      width: 130px;
    }
  }
}
