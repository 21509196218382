@import 'CaW/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  font-family: $Gotham-Book;
  min-height: 100vh;
  letter-spacing: -0.04em;

  input,
  textarea,
  button {
    font-family: $Gotham-Book;
    letter-spacing: -0.04em;
  }
}
