@import 'helm/assets/theme/index';

.container {
  max-width: 540px;
}

.inputLabel {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.customInput {
  color: #343434;
  margin-bottom: $standard-padding !important;
}

.customSelect {
  padding-bottom: $standard-padding * 2;
}

.selectInputLabel {
  @extend .inputLabel;
  margin-bottom: 0;
  font-size: 12px;

  &:focus {
    color: #d3d8dd !important;
  }
}
