@import 'SomeoneHealth/assets/theme';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .header {
    color: $colorGrey700;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
  }

  .inputLabel {
    color: $colorGrey800;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }

  .customInput {
    transform: translateX(-$standard-padding);
    padding: 0 $standard-padding;
    color: #343434;
    margin-bottom: $standard-padding !important;
  }

  .customSelect {
    padding: $standard-padding * 2 0;
  }

  .selectInputLabel {
    @extend .inputLabel;
    margin-bottom: 0;
    font-size: 12px;

    &:focus {
      color: $someone-health-pink !important;
    }
  }
}
