@import 'MumsMatter/assets/theme';

.summary {
  display: flex;
}

.index {
  min-width: 30px;
  height: 30px;
  background: rgba(218, 60, 144, 0.2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.details {
  margin: 15px 0;
  padding-top: 20px;

  &.topSeparation {
    border-top: 1px dashed $colorGrey700;
  }

  &.bottomSeparation {
    border-bottom: 1px dashed $colorGrey700;
    padding-bottom: 24px;
  }
}

.item {
  display: flex;
  gap: 33px;
  padding: 10px 0;

  .label {
    flex: 1 1;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: $grey500;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;

    @media screen and (max-width: $medium-large_screen-min-width) {
      font-weight: 600;
      font-size: 12px;
    }
  }

  .value {
    flex: 3 1;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: $colorGrey700;
    min-width: 337px;

    @media screen and (max-width: $medium-large_screen-min-width) {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    @media screen and (max-width: $medium-small-screen-min-width) {
      min-width: unset;
    }
  }
}

:global(.mums-matter-theme) {
  .index {
    background: $mums-matter-light-green;
    font-size: 18px;
    font-weight: 700;
  }
  .details {
    &.topSeparation {
      border-top: 1px dashed $mums-matter-dark-blue;
    }

    &.bottomSeparation {
      border-bottom: 1px dashed $mums-matter-dark-blue;
    }
  }
  .label {
    color: $mums-matter-dark-blue;
  }
  .value {
    color: $mums-matter-dark-blue;
    font-weight: bold;
  }
}
