@import 'assets/theme';

.container {
  display: flex;
  flex-wrap: wrap;
  @include rowGap($standard-padding * 2);
  max-width: 768px;

  .column {
    width: 20%;
    display: flex;
    justify-content: center;
    @media (min-width: $medium-screen-min-width) {
      width: 10%;
    }

    .number {
      background-color: transparent;
      border: 1px solid #3f52ff;
      width: 40px;
      height: 40px;

      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 16px;
      font-weight: 500;

      &.active {
        background-color: #3f52ff;
        color: #ffffff;
      }
    }
  }
}
