@import 'CaW/assets/theme';

.container {
  display: flex;
  width: 90%;
  align-items: center;
  column-gap: 16px;

  @media (max-width: $medium-desktop-screen-max-content) {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    @include rowGap(32px);
  }

  .mentalHealthConcern {
    max-width: 460px;
    width: 100%;

    @media (max-width: $medium-desktop-screen-max-content) {
      max-width: 100%;
    }
  }
}
