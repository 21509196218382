@import 'helm/assets/theme';

.container {
  background-position: 'center';
  background-repeat: 'no-repeat';
  background-size: 'cover';
  background: $helm-blue;
  height: max-content;
  min-height: 100vh;
  padding-bottom: $standard-padding * 10;
  position: relative;
  z-index: 1;
}
