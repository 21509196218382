@import 'SomeoneHealth/assets/theme';

.container {
  margin-top: $standard-padding * 17;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  position: relative;
  width: 100%;
  padding: $standard-padding * 8 $standard-padding * 7;

  .shape2 {
    position: absolute;
    top: -217px;
    right: -236px;
    background-size: cover;
    width: 398px;
    height: 352px;
    transform: rotate(156.52deg);
    mask-image: url(~helm/assets/images/shape/2.png);
    mask-size: 100% 100%;
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: $someone-health-yellow;
  }

  .shape4 {
    position: absolute;
    left: -266px;
    bottom: -256px;
    background-size: cover;
    width: 488px;
    height: 392px;
    transform: rotate(-169.16deg);
    mask-image: url(~helm/assets/images/shape/4.png);
    mask-size: 100% 100%;
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: $someone-health-yellow;
  }

  .cardBody {
    border-radius: 8px;
    border: solid 2px $someone-health-yellow;
    padding-bottom: 2rem;
  }

  .cardTitle {
    padding: $standard-padding * 10 $standard-padding * 6;
    padding-bottom: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: left;

    &::after {
      content: '';
      display: block;
      margin-top: $standard-padding * 2;
      height: 4px;
      width: 40px;
      background-color: $someone-health-purple;
    }
  }

  .cardContent {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: left;
    margin: $standard-padding * 8 auto $standard-padding * 4 auto;
    padding: 0 $standard-padding * 13;
  }

  @media screen and (max-width: $small-desktop-screen-max-content) {
    padding: $standard-padding * 5 $standard-padding * 4;
    padding-bottom: $standard-padding * 7;
    .cardTitle {
      font-size: 16px;
      &::after {
        transform: scale(0.84);
      }
    }

    .cardContent {
      font-size: 14px;
      padding: 0 $standard-padding * 10;
    }

    .testimonialClientAvatar {
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}
