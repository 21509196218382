@import 'CORDS/assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  z-index: 999;
  max-height: 90px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .logoBox {
    cursor: pointer;
  }

  .logo {
    max-width: 330px;

    @media (max-width: $tablet-screen-max-content) {
      max-width: 160px;
    }
  }
}
