@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;

  .title {
    font-weight: 600;
    font-size: 13px;
    line-height: 23px;
    color: $colorTextualBlack;
  }

  .filter {
    font-size: 12px;
    line-height: 18px;
    color: #6a707e;

    .headerFilterDropdown {
      font-size: 12px;
      color: $colorBlue1;
      cursor: pointer;
    }
  }
}
