@import 'Recharge/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  font-family: $font, sans-serif;
  min-height: 100vh;
  // specially for Recharge's font, Montserrat
  font-variant: normal;

  input,
  textarea,
  button {
    font-family: $font, -apple-system, BlinkMacSystemFont, sans-serif;
  }
}
