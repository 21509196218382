@import 'SomeoneHealth/assets/theme';

.container {
  background-color: $white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 32px 24px;
  color: #414449;

  .label {
    font-size: 16px;
    line-height: 24px;
    color: #414449;
  }

  .taskLabel {
    font-size: 18px;
    line-height: 27px;
    color: #22348c;
    margin: 32px 0;

    .number {
      color: #414449;
      font-weight: 600;
    }
  }

  .suggestionWrapper {
    display: flex;
    flex: 1;
    margin-top: 24px;
    @include columnGap(24px);

    @media (max-width: $tablet-screen-max-content) {
      flex-direction: column;
      @include columnGap(0);
      @include rowGap(24px);
    }
  }
}
