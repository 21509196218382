.title {
  padding-top: 16px;
  font-size: 18px;
}
.phoneNumberContainer {
  margin-bottom: 24px;
  margin-top: 16px;

  .titlePhone {
    font-size: 11px;
    color: #818e9b;
  }
  .phoneNumberInput {
    width: calc(100% - 11px) !important;
    height: 30px !important;
    padding-left: 52px !important;
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid #d3d8dd !important;
    border-radius: 0px !important;
    outline: none !important;
    color: #343434 !important;
  }
  .countrySelect {
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid #d3d8dd !important;
    border-radius: 0px !important;

    div,
    div:hover {
      background-color: transparent !important;
    }
  }

  .countryDropdown {
    position: fixed;

    li {
      margin-top: 0px !important;
    }
  }
}

.button {
  display: flex;
  justify-content: center;
  background: #3f52fb;
  color: #fff;
  border-radius: 16px;
  padding: 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;

  &.loading {
    background-color: #3f52fb80;
  }

  .loadingContainer {
    margin-right: 8px;
  }
}
