@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  &.vertical {
    flex-direction: column;
  }

  @media (max-width: $tablet-screen-max-content) {
    flex-direction: column;
    align-items: flex-start;
  }

  .label {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: $white;
  }

  .inputContainer {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 664px;

    .icon {
      position: absolute;
      left: 15px;
      color: $someone-health-pink;
      font-size: 24px;
    }

    .input {
      width: 100%;
      border: none;
      border-radius: 75.0203px;
      display: flex;
      flex: 1;
      height: 48px;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      padding: 16px 60px 16px 60px;
      color: $someone-health-black;
    }
  }

  .clearButton {
    border: none;
    background: none;
    position: absolute;
    right: 24px;
    height: 18px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    .clearIcon {
      color: $someone-health-pink;
    }
  }
}
