@import 'Select/assets/theme';

.loadingContainer {
  display: flex;
  justify-content: center;
  padding: 60px 0;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $select-black;

  @media screen and (min-width: ($medium-large_screen-min-width + 1)) {
    padding: 13px 23px;
  }

  .header {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
  }

  .paymentContainer {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .paymentDetails {
      display: flex;
      gap: 33px;
    }

    .label {
      flex: 1 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
    }

    .value {
      flex: 3 1;
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -0.02em;
    }
  }
}
