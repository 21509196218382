@import 'Select/assets/theme';

.container {
  background-color: $select-light-green;
  position: fixed;
  height: 100vh;
  width: 100vw;
  margin-bottom: 0;
  overflow: auto;

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 2;
  }

  .content {
    padding: 50px 0 30px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 118px);
    max-width: 760px;
    position: relative;

    @media (max-width: $medium-large_screen-min-width) {
      margin: 0 auto;
    }

    @media (max-width: $small-desktop-screen-max-content) {
      position: static;
    }

    .avatar {
      position: absolute;
      right: -10%;
      z-index: 1;
      transform: translateX(100%);

      @media (max-width: $small-desktop-screen-max-content) {
        right: 2%;
        transform: none;
      }

      @media (max-width: $medium-large_screen-min-width) {
        display: none;
      }

      img {
        height: 220px;
        min-width: 220px;
        border-radius: 50%;
      }
    }

    .title {
      display: flex;
      align-items: flex-start;
      font-size: 48px;
      font-weight: 700;
      line-height: 62px;
      color: $select-black;

      @media (max-width: $medium-large_screen-min-width) {
        padding-left: 0;
      }
    }

    .welcome {
      display: flex;
      margin-top: 10px;
      background: #ffffff;
      border-radius: 4px;
      padding: 24px 36px;
      position: relative;
      color: $select-black;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);

      @media (max-width: $medium-large_screen-min-width) {
        padding: 20px 16px;
      }

      .messageHeading {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        margin: 12px 0 24px;

        @media (max-width: $medium-large_screen-min-width) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .message {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        margin: 12px 0 24px;

        @media (max-width: $medium-large_screen-min-width) {
          font-size: 14px;
          line-height: 19px;
        }
      }

      .messageEnding {
        @extend .messageHeading;

        margin-top: $standard-padding * 6;
      }
    }

    .whatNext {
      padding: 24px 36px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 60px;
      display: flex;
      flex-direction: column;

      @media (max-width: $medium-large_screen-min-width) {
        margin-top: 28px;
        padding: 20px 16px;
      }

      .heading {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 30px;
      }

      .tasks {
        ul {
          display: flex;
          flex-direction: column;
          gap: $standard-padding * 2;
          font-weight: 600;
          font-size: 18px;
          line-height: 23px;
        }
      }

      .goToMyAccountBtn {
        width: 100%;
      }
    }
  }
}
