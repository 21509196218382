@import 'SomeoneHealth/assets/theme';

.container {
  @include s1hLoginBackground;

  .headerContent {
    display: flex;
    width: 100%;
    flex: initial;
  }

  .headerWrapper {
    display: flex;
    padding: 0 16px 24px;

    .shHeader {
      padding: 8px 0 !important;

      @media (max-width: $medium-large_screen-min-width) {
        padding: 8px 16px !important;
      }
    }
  }

  .contentWrapper {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    position: relative;
    z-index: 3;
    height: calc(100vh - 100px);
    padding: 0;
  }
}
