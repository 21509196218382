@import 'helm/assets/theme';

.container {
  display: flex;
  align-items: center;
  @include columnGap($standard-padding * 3);

  .calendarBadge {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(~helm/assets/images/calendar/calendarShape.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 58px;
    padding: 21px 0 6px 0;

    .date {
      font-size: 24px;
      font-family: $primary-font-stack-bold !important;
      line-height: 24px;
      letter-spacing: -0.02em;
    }

    .month {
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      text-align: center;
    }
  }

  .dayWrapper {
    display: flex;
    flex-direction: column;

    .day {
      font-family: $primary-font-stack-bold !important;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: -0.02em;
    }

    .remainingDay {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      padding-top: $standard-padding;
    }
  }
}

.containerCenter {
  @extend .container;
  justify-content: center;
}
