.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .card {
    background: #f8f9fd;
    padding: 16px;
    box-sizing: border-box;

    .headerContainer {
      display: flex;
      color: #192A3E;
      align-items: center;
      padding-bottom: 12px;

      .title {
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        padding-right: 12px;
      }

      .date {
        font-style: italic;
        font-weight: 600;
        font-size: 13px;
        line-height: 23px;
      }
    }
  }
}
