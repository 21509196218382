@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: 46px 80px;
  @include rowGap(30px);

  @media screen and (max-width: $tablet-screen-max-content) {
    padding: 24px;
  }

  .title {
    font-weight: 600;
    font-size: 48px;
  }

  .descriptionContainer {
    @include rowGap(24px);
  }

  .button {
    max-width: max-content;
    box-shadow: none;
  }
}
