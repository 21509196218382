@import 'assets/theme/index';
@import 'SomeoneHealth/assets/theme';

.container {
  margin-top: 48px;
  margin-left: 24px;
  margin-right: 12px;

  @media (max-width: $medium-small-screen-min-width) {
    margin-top: 32px;
    margin-left: 12px;
  }

  .title {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    color: #5666a6;
  }

  .content {
    margin-left: 8px;

    @media (max-width: $medium-small-screen-min-width) {
      margin-left: 0;
    }
  }

  .headingContainer {
    display: flex;

    .heading {
      font-size: 12px;
      line-height: 16px;
      color: #34343480;

      &:first-child {
        width: 5%;
      }

      &:nth-child(2) {
        width: calc(50% - 40px);
      }

      &:nth-child(3) {
        width: 15%;
      }

      &:nth-child(4) {
        width: 15%;
      }

      &:last-child {
        width: 15%;
        margin-right: 4px;
      }
    }
  }

  .itemContainer {
    display: flex;
    margin-top: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #343434;

    .index {
      width: 5%;
      font-size: 14px;
      font-weight: bold;
      line-height: 22px;
      color: #fc8468;
    }

    .label {
      width: calc(50% - 40px);

      .tags {
        display: flex;
        flex-wrap: wrap;
        margin-top: $standard-padding * 0.5;
        gap: $standard-padding * 0.5;

        .tag {
          display: flex;
          align-items: center;
          padding: $standard-padding * 0.5 $standard-padding;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: $white;
          background: $colorBlue3;
          border-radius: 43px;
        }
      }
    }

    .cost {
      width: 15%;
    }

    .tax {
      width: 15%;
    }

    .total {
      width: 15%;
      margin-right: 4px;
    }
  }

  .discountContainer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #343434;

    .label {
      width: calc(50% - 40px);
      margin-left: calc(5% + 4px);
    }

    .discount {
      width: 15%;
    }
  }
}

:global(.s1h-client-portal-theme) {
  .container {
    margin-top: 20px;
    margin-left: 22px;
    margin-right: 12px;

    @media (max-width: $medium-small-screen-min-width) {
      margin-top: 20px;
      margin-left: 12px;
    }

    .title {
      font-weight: 600;
    }

    .headingContainer {
      .heading {
        color: $colorGrey700;
        font-weight: $font-weight-bolder;
        font-size: 14px;
        line-height: 21px;
      }
    }

    .itemContainer {
      color: $colorGrey700;
      font-weight: 600;

      .index {
        color: $colorGrey700;
      }

      .label .tags .tag {
        background-color: $colorGrey700;
      }
    }

    .discountContainer {
      color: $colorGrey700;
    }
  }
}
