.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 730px;
  padding: 24px 16px 0;
  margin: auto;

  .infoWrapper {
    display: flex;
    align-items: center;
    width: 100%;

    .info {
      display: flex;
      flex-direction: column;
      .text {
        margin-left: 20px;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: #343434;
        margin-top: 7px;
      }

      .desc {
        margin-left: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #343434;
        margin-top: 12px;
      }
    }

    .profile {
      width: 100px;
    }
  }

  .count {
    margin-top: 36px;
    margin-bottom: 16px;
    display: flex;
    .number {
      font-size: 24px;
      color: #3f52ff;
    }
    .content {
      font-size: 18px;
      color: black;
    }
    text-transform: none;
    line-height: 25px;
    font-weight: 700;
  }
}
