@import 'Portobello/assets/theme';

.rowWrapper {
  display: flex;
  gap: 16px;

  @media (max-width: $tablet-screen-max-content) {
    flex-direction: column;
  }

  .fieldWrapper {
    width: 100%;
    max-width: 400px;
    color: #343434;

    .selectInputLabel {
      font-size: 12px;
      color: #343434;
      font-family: $Montserrat;
    }
  }
}
