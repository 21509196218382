@import 'CaW/assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .filterContainer {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    max-width: 68%;

    @media (max-width: $mobile-screen-max-content) {
      align-items: flex-start;
      @include rowGap(8px);
      @include columnGap(0);
      flex-direction: column;
    }
    .timeFilterContainer {
      display: flex;
      align-items: center;
      @include columnGap(12px);

      .filterLabel {
        @include playfairDisplayFont;
      }

      .filterList {
        display: flex;
        align-items: center;
        @include columnGap(12px);
      }

      .timeRange {
        min-width: 210px;

        @media (max-width: $mobile-screen-max-content) {
          min-width: 180px;
        }
      }
    }
  }

  .calendarWrapper {
    display: flex;
    z-index: 0;
    @include columnGap(16px);

    @media (max-width: $small-desktop-screen-max-content + 100px) {
      flex-direction: column;
      @include columnGap(0px);
      @include rowGap(16px);
    }

    .calendarCard {
      display: flex;
      flex-direction: column;
      flex: 1 68%;
      background-color: $white;
      border-radius: 8px;
      overflow: hidden;
      height: max-content;
    }

    .slotPickerCard {
      display: flex;
      flex: 1 32%;
    }
  }

  &.isAdvisorySession {
    color: $caw-blue;

    .calendarWrapper {
      @include columnGap(26px);

      .calendarCard {
        border: solid 2px $caw-blue;
        flex: 1 50%;

        @media (max-width: $small-desktop-screen-max-content + 100px) {
          margin-right: 0;
        }
      }

      .slotPickerCard {
        flex: 1 50%;
      }
    }
  }
}
