@import 'Portobello/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 82px);
  background-size: cover;
  background-image: url('~Portobello/assets/images/background/background.png');
  background-repeat: no-repeat;
  font-family: $Playfair-Display;
}
