@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: 46px 80px;
  @include rowGap(48px);

  @media screen and (max-width: $tablet-screen-max-content) {
    padding: 32px 20px;
    @include rowGap(24px);
  }

  .chartContainer {
    display: flex;
    flex-direction: column;
    @include rowGap(8px);

    .title {
      font-weight: 600;
      font-size: 18px;
    }

    .graphContainer {
      background-color: $white;
      padding: 20px;
      border-radius: 8px;
    }
  }

  .buttonContainer {
    display: flex;
    @include columnGap(30px);

    .secondaryButton {
      background-color: $white;
    }

    @media screen and (max-width: $tablet-screen-max-content) {
      flex-direction: column;
      max-width: max-content;
      @include columnGap(0px);
      @include rowGap(16px);
    }
  }
}
