.container {
  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #343434;
    margin-bottom: 16px;
  }

  .referralForm {
    display: flex;
    flex-direction: column;

    .subTitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #343434;
    }

    .content {
      margin-top: 36px;
      margin-left: 16px;

      .radioWrapperClass {
        flex-direction: column;
        align-items: flex-start;

        .radioLabelClass {
          font-size: 15px;
          line-height: 23px;
          letter-spacing: 0.1px;
          color: #343434;
        }
      }

      .referralContactContainer {
        margin-top: 8px;
        margin-bottom: 11px;
        padding-left: 0;

        .radioTitle {
          font-size: 15px;
          line-height: 23px;
          color: #192a3e;
        }

        .contactFormContainer {
          padding-top: 0;
        }
      }
    }
  }

  .radioWrapperClass {
    flex-direction: column;
    align-items: flex-start;

    .radioLabelClass {
      font-size: 15px;
      line-height: 23px;
      letter-spacing: 0.1px;
      color: #343434;
    }
  }

  .referralContactContainer {
    margin-top: 8px;
    margin-bottom: 11px;
    padding-left: 0;

    .radioTitle {
      font-size: 15px;
      line-height: 23px;
      color: #192a3e;
    }

    .contactFormContainer {
      padding-top: 16px;
    }
  }
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 32px;
  max-width: 380px;

  &.fieldError,
  .fieldError {
    font-size: 12px;
    color: #ff4d4f;

    input {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }
}

.fieldWithTooltip {
  display: flex;
  flex-direction: row;
  align-items: center;

  .leftContainer {
    flex: 1;
  }
}

.selectContainer {
  @extend .fieldContainer;
  margin: -12px 0 32px;
}

.phoneContainer {
  @extend .fieldContainer;
  margin: -10px 0 32px;
}

:global(.someone-health-theme) {
  .fieldContainer {
    max-width: 540px;
  }
}
