@import 'SomeoneHealth/assets/theme';

.headingContainer {
  text-align: center;

  .heading {
    font-weight: 700;
    font-size: 48px;
    margin: 80px auto 96px auto;
    color: #333333;
    line-height: 70px;
    letter-spacing: -0.01em;
    font-feature-settings: 'kern' off;

    @media (max-width: $small-screen-max-width) {
      margin: 80px auto 24px auto;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.02em;
    }
  }
}

.contentContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
  align-items: flex-start;

  @media (max-width: $medium-desktop-screen-max-content) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: $small-screen-max-width) {
    display: flex;
    flex-wrap: wrap;
    gap: 44px;
  }

  .linkContainer {
    width: 282px;
    padding: 10px 16px;
    letter-spacing: -0.02em;

    .heading {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 24px;
      color: $someone-health-grey700;
    }

    .linkList {
      list-style-type: none;
      margin: 0;
      padding: 0;

      .link {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $someone-health-grey500;
        margin: 8px 0;
        transition: color 0.2s;

        &:hover {
          color: $someone-health-blue500;
        }
      }
    }
  }
}
