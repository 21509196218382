@import 'helm/assets/theme';

.container {
  background-color: $green50;
  min-height: 100vh;
  overflow: hidden;
  font-family: 'Nunito', normal;

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 2;
  }

  .signUpResult {
    display: flex;
    margin-top: 24px;

    .leftContent {
      width: 63%;
      color: $grey700;

      .leftContentBody {
        max-width: 810px;
        padding: 0 $standard-padding * 5;
        margin: 0 0 24px auto;

        .header {
          font-weight: 700;
          font-size: 48px;
        }

        .subHeader {
          font-weight: 600;
          font-size: 28px;
        }

        .card {
          box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
          border-radius: 8px;
          background-color: white;
          padding: $standard-padding * 5 $standard-padding * 8;
          max-width: 700px;
          margin-top: $standard-padding * 6;

          @media (max-width: $tablet-screen-max-content) {
            padding: $standard-padding * 3 $standard-padding * 4;
          }

          .welcomeTitle {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 12px;

            @media (max-width: $tablet-screen-max-content) {
              font-size: 24px;
              line-height: 36px;
            }
          }

          .welcomeNote {
            margin-bottom: 16px;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;

            @media (max-width: $tablet-screen-max-content) {
              font-size: 16px;
            }
          }

          .welcomeText {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            padding-top: $standard-padding;

            @media (max-width: $tablet-screen-max-content) {
              font-size: 20px;
              line-height: 26px;
            }
          }

          .messageEnding {
            margin-top: $standard-padding * 6;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;

            @media (max-width: $tablet-screen-max-content) {
              font-size: 18px;
            }
          }

          .cardText {
            margin-top: $standard-padding * 4;
            padding-right: 10%;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;

            @media (max-width: $tablet-screen-max-content) {
              font-size: 16px;
            }
          }

          .tasks {
            margin-top: $standard-padding * 5;

            ul {
              padding: 0;

              li {
                font-weight: 400;
                font-size: 14px;
                display: flex;
                align-items: flex-start;
                list-style: none;
                list-style-position: inside;
                margin: $standard-padding * 2 0;

                .circle {
                  vertical-align: top;
                  display: inline-block;
                  width: 20px;
                  height: 20px;
                  border: solid 2px;
                  border-radius: 10px;
                  margin-top: 1px;
                  margin-right: $standard-padding * 3;
                }

                .liText {
                  flex: 1;
                  font-size: 16px;
                  line-height: 24px;
                }
              }
            }
          }

          .proceedBtn {
            margin-top: $standard-padding * 8;
            margin-bottom: $standard-padding;
            box-shadow: none;
            width: 100%;
            max-width: 350px;
            font-weight: 600;
            font-size: 16px;

            @media (max-width: $tablet-screen-max-content) {
              margin-top: $standard-padding * 2;
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }

    .rightContent {
      width: 36%;

      @media (max-width: $tablet-screen-max-content) {
        display: none;
      }

      .image {
        margin-top: $standard-padding * 2;
        position: relative;
        height: fit-content;
        max-width: 390px;
        padding: $standard-padding * 5;

        .shape3 {
          position: absolute;
          top: 0;
          right: 26px;
          transform: rotate(96.6deg);
          mask-size: 100% 100%;
          width: 291px;
          height: 290px;
          mask-image: url(~helm/assets/images/shape/3.png);
          mask-position: center;
          mask-repeat: no-repeat;
          background-color: $helm-lighter-yellow;
        }

        .shape4 {
          position: absolute;
          top: 34px;
          right: 0;
          transform: rotate(218deg);
          mask-size: 100% 100%;
          width: 301px;
          height: 270px;
          mask-image: url(~helm/assets/images/shape/4.png);
          mask-position: center;
          mask-repeat: no-repeat;
          background-color: $helm-lighter-yellow;

          img {
            transform: rotate(-218deg) translate(-10px, 10px);
            width: 102%;
            height: 102%;
            object-fit: cover;
          }
        }
      }
    }

    @media (max-width: $tablet-screen-max-content) {
      .leftContent {
        width: 100%;
        color: $grey700;
        margin-top: $standard-padding * 5;

        .leftContentBody {
          max-width: 740px;
          padding: 0;
          margin: 0 0 24px auto;

          .header {
            margin-top: $standard-padding * 4;
            font-weight: 700;
            font-size: 32px;
          }

          .welcomeMessage {
            margin-top: $standard-padding * 3;

            .welcomeTitle {
              font-weight: 400;
              font-size: 18px;
            }

            .welcomeText {
              margin-top: $standard-padding * 4;
              font-weight: 600;
              font-size: 16px;
            }
          }

          .messageEnding {
            margin-top: $standard-padding * 4;
            margin-bottom: $standard-padding * 5;
            font-weight: 600;
            font-size: 18px;

            .name {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
