@import 'assets/theme/index';

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  max-width: 320px;
  margin: 0 auto;
  padding-bottom: 24px;

  .phone {
    padding-bottom: 16px;
  }

  .title {
    font-size: 16px;
    font-weight: bold;
  }
}
