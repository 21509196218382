@import 'Recharge/assets/theme';

.container {
  display: flex;
  flex-wrap: wrap;
  color: $recharge-black;

  .calendarContent {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .calendarListContent {
    @media (max-width: $tablet-screen-max-content) {
      margin: 0 -16px;
      padding: 0 16px;
      display: flex;
      flex: 1;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @media (max-width: $mobile-screen-max-content) {
      margin: 4px -16px 0 -16px;
      padding: 0;
    }
  }

  .calendarCardWrapper {
    display: flex;
    flex-direction: column;

    @media (max-width: $medium-desktop-screen-max-content) {
      flex-direction: row;
      @include columnGap(16px);
    }

    @media (min-width: $tablet-screen-max-content) {
      flex-wrap: wrap;
    }
  }

  .calendarCardOdd {
    display: flex;
    justify-content: center;
    min-width: 230px;
    max-width: 900px;
    background: $white;
    border-radius: 4px;
    padding: $standard-padding * 4 $standard-padding * 5;
    margin-bottom: 16px;

    @media (max-width: $medium-desktop-screen-max-content) {
      margin-bottom: 0;
    }

    @media (max-width: $mobile-screen-max-content) {
      &:first-child {
        margin-left: 16px;
      }
    }

    .cardContent {
      display: flex;
      flex: 1;

      @media (max-width: $medium-desktop-screen-max-content) {
        flex-direction: column;
      }

      .calendarInfo {
        display: flex;
        width: 100%;
        max-width: 190px;
        align-items: center;
        @include columnGap(12px);

        .greenDot {
          border-radius: 50%;
          background-color: #62cb7f;
          min-width: 13px;
          width: 13px;
          min-height: 13px;
          height: 13px;
          margin-right: 16px;
        }

        .calendarDateInfo {
          display: flex;
          flex-direction: column;
          color: $recharge-black;

          .date {
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
          }

          .month {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
          }

          .day {
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
          }

          .openAppointment {
            font-weight: 600;
            font-size: 10px;
            line-height: 13px;
            color: $recharge-black;
            opacity: 0.5;
          }
        }
      }
    }

    .timeslotWrapper {
      display: flex;
      align-items: center;
      margin: 0 30px;
      @include columnGap($standard-padding * 3);

      @media (max-width: $medium-desktop-screen-max-content) {
        flex-direction: column;
        margin: 16px 0 0 0;
        @include columnGap(0);
        @include rowGap($standard-padding * 3);

        a {
          width: 100%;
        }
      }

      .timeslot {
        line-height: 18px;
      }

      .seeMoreSlot {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 18px;
        text-decoration-line: underline;
        cursor: pointer;
        color: $blue500;
      }
    }
  }

  .calendarCardEven {
    @extend .calendarCardOdd;
    background: $recharge-lighter-blue;
  }

  .noAvailableWrapperEven {
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: 12px;
    margin-right: 8px;
    padding: 24px;

    @media (max-width: $medium-small-screen-min-width) {
      padding: 16px;
    }

    .noAvailableCard {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 20px;
      justify-content: space-between;

      .messageWrapper {
        display: flex;
        max-width: 500px;

        .redDotWrapper {
          padding-top: 10px;
        }
      }

      .redDot {
        border-radius: 50%;
        background-color: #eb001b;
        width: 13px;
        height: 13px;
        margin-right: 16px;
      }

      .message {
        display: flex;
        flex: 1;
        flex-direction: column;

        .desc {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: $recharge-black;

          @media (max-width: $medium-small-screen-min-width) {
            font-size: 20px;
          }
        }

        .noAvailDesc {
          display: flex;
          flex-wrap: wrap;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          color: $recharge-black;
          margin-right: 16px;
          white-space: break-spaces;

          @media (max-width: $medium-small-screen-min-width) {
            font-size: 14px;
          }
        }
      }

      .buttonWrapper {
        white-space: break-spaces;

        @media (max-width: $mobile-screen-max-content) {
          display: flex;
          width: 100%;
          justify-content: center;
        }
      }

      .label {
        @media (max-width: $medium-small-screen-min-width) {
          font-size: 14px;
        }
      }
    }
  }

  .noAvailableWrapperOdd {
    @extend .noAvailableWrapperEven;
    background: $recharge-lighter-blue;

    .noAvailableCard .buttonWrapper {
      background: $white;
    }
  }
}
