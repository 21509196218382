@import 'assets/theme';

.assessmentTitle {
  font-family: 'Monoton' !important;
  font-size: 24px;
  margin-bottom: 16px;
}
.assessmentSubtitle {
  font-size: 16px;
  margin-bottom: $standard-padding * 5;
}

.assessmentContainer {
  border: 1px solid #0019fe;
  display: flex;
  flex-direction: column;

  .progressBarContainer {
    display: flex;

    .progressBar {
      background: #ff9d73;
      height: 6px;
    }

    .remainingProgressBar {
      background: #fad2bf;
      height: 6px;
    }
  }

  .body {
    padding: $standard-padding * 4;
    display: flex;
    flex-direction: column;

    color: #3f52ff;

    .description {
      font-weight: 400;
      font-size: 20px;
    }

    .radio {
      padding: 20px 0px;
      * {
        font-weight: 600;
        font-size: 16px;
      }
      label {
        padding: 5px 0;
        margin: 15px 0;
      }
    }

    .navButtons {
      display: flex;
      justify-content: space-between;

      .button {
        border: 1px solid #3f52ff;
        color: #3f52ff;
        background: none;
        font-size: 16px;
        font-weight: 600;
        padding: 16px 50px;
        display: flex;
        justify-content: center;

        &:not(:disabled):hover {
          background-color: #3f52ff;
          color: white;
        }

        &:disabled {
          border-color: #949aa2 !important;
          color: #949aa2 !important;
        }
      }
    }
  }
}

.completedText {
  font-size: 16px;
}
