@import 'SomeoneHealth/assets/theme';

.headingContainer {
  text-align: center;

  .heading {
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;

    @media (max-width: $small-screen-max-width) {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.02em;
    }
  }

  .subheading {
    color: #4f4f4f;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    margin: 12px auto 24px auto;

    @media (max-width: $small-screen-max-width) {
      font-size: 16px;
      line-height: 21px;
      margin: 4px auto 32px auto;
    }
  }
}

.cardWrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
}

.arrow {
  .upArrow {
    position: absolute;
    left: 19.4%;
    top: 210px;
    max-width: 250px;
    width: 17.3%;
    height: 31px;
    transform: rotate(-2.06deg);
  }

  .downArrow {
    position: absolute;
    width: 13.5%;
    height: 31px;
    top: 340px;
    left: 57.4%;
    transform: matrix(0.98, -0.2, -0.2, -0.98, 0, 0);
  }

  @media (max-width: 1100px) {
    display: none;
  }
}
