@import 'assets/theme';

.container {
  padding: 24px;

  @media (max-width: $medium-small-screen-min-width) {
    padding: 16px;
  }
}

.slider {
  margin-top: 25px;
}

:global {
  .ant-slider-mark-text {
    max-width: 105px;
  }
}
