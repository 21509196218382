@import 'Portobello/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;

  .profileWrapper {
    display: flex;
    align-items: center;

    .profileCard {
      display: flex;
      align-items: center;
    }

    .profile {
      height: 50px;
      width: 50px;

      @media (max-width: $tablet-screen-max-content) {
        height: 40px;
        width: 40px;
      }
    }

    .name {
      font-size: 20px;
      line-height: 26px;
      color: $portobello-blue;
      margin-left: 16px;
      padding-top: 4px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 16px;
        line-height: 26px;
      }

      .menuIcon {
        font-size: 30px;
        user-select: none;
      }
    }

    .nameWhite {
      @extend .name;
      color: $white;
    }
  }

  .loginWrapper {
    display: flex;

    .login {
      font-size: 16px;
      line-height: 26px;
      text-decoration-line: underline;
      color: $portobello-blue;
      cursor: pointer;
    }

    .loginWhite {
      @extend .login;
      color: $white;
    }
  }
}
