@import '../../assets/theme/index';
@import 'SomeoneHealth/assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';

.fieldLabel {
  font-size: 11px;
  font-weight: 600;
  color: #818e9b;
}

.phoneInputWrapper {
  display: flex;

  .loadingWrapper {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    .loadingBox {
      position: absolute;
      left: -26px;
    }
  }
}

.mobileNumberInput {
  width: 100% !important;
  height: 31px !important;
  padding-left: 52px !important;
  border: 1px solid #ffffff !important;
  border-bottom: 1px solid #818e9b !important;
  border-radius: 0 !important;
  outline: none !important;
  color: #343434 !important;
}

.countrySelect {
  z-index: 3 !important;
  background-color: transparent !important;
  border: 1px solid #ffffff !important;
  border-bottom: 1px solid #818e9b !important;
  border-right: none !important;
  border-radius: 8px 0 0 0 !important;

  div,
  div:hover {
    background-color: transparent !important;
  }
}

.countryDropdown {
  max-height: 100px;

  li {
    margin-top: 0 !important;
  }
}

.redBorderBottom {
  border-bottom: 1px solid #ff4d4f !important;
}

:global(.someone-health-theme) {
  .fieldLabel {
    color: $grey600;
    font-size: 12px;
    font-weight: 400;
  }

  .fieldLabelFocus {
    color: $someone-health-pink;
  }
}

:global(.ease-theme) {
  .fieldLabel {
    color: $grey600;
    font-size: 12px;
    font-weight: 400;
  }

  .fieldLabelFocus {
    color: $ease-dark-grey;
  }
}

:global(.recharge-theme) {
  .fieldLabel {
    color: $grey600;
    font-size: 12px;
    font-weight: 400;
  }

  .fieldLabelFocus {
    color: $recharge-black;
  }
}

:global(.select-theme) {
  .fieldLabel {
    color: $grey600;
    font-size: 12px;
    font-weight: 400;
  }

  .fieldLabelFocus {
    color: $select-black;
  }
}

:global(.s1h-client-portal-theme) {
  .mobileNumberInput {
    color: $someone-health-maroon !important;
  }
  .fieldLabel {
    color: $someone-health-maroon;
  }

  .fieldLabelFocus {
    color: $someone-health-maroon;
  }
}
