@import 'CORDS/assets/theme';

.container {
  background-color: $cords-dark-blue;

  .content {
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
  }
}
