@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  background: linear-gradient(91.74deg, #db3c98 30.48%, #30208b 99.77%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 28px 34px;
  margin: 0 -16px;

  @media (max-width: $tablet-screen-max-content) {
    margin: 0 -32px;
    padding: 28px 16px;
    border-radius: 0;
  }

  .title {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: $someone-health-light-blue;
    flex-wrap: wrap;

    @media (max-width: $tablet-screen-max-content) {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }
  }

  .highLightTitle {
    display: inline-flex;
    width: 0;
    white-space: nowrap;
    overflow: hidden;
    animation: cursor 1s step-start forwards, text 1s steps(13) alternate forwards;
    animation-delay: 1s;
    background: linear-gradient(90deg, #fff 50%, rgba(255, 255, 255, 0) 50%);
    background-size: 200% 100%;
    background-position: 100% 0;
    border-radius: 4px;
    height: 44px;
  }

  @keyframes cursor {
    99% {
      border-right: 4px solid $someone-health-blue;
    }
    100% {
      border-right: none;
      background-position: 0 0;
      color: $someone-health-blue;
      padding: 4px;
    }
  }

  @keyframes text {
    0% {
      width: 0;
    }
    99% {
      width: 10.2ch;
    }
    100% {
      width: max-content;
    }
  }

  .filterWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    margin-top: 38px;
    color: $white;

    @media (max-width: $medium-desktop-screen-max-content) {
      align-items: flex-end;
    }

    @media (max-width: $tablet-screen-max-content) {
      flex-direction: column;
    }

    .searchSection {
      display: flex;
      margin-left: 16px;

      @media (max-width: $tablet-screen-max-content) {
        display: unset;
        width: 100%;
        margin-left: 0;
        margin-top: 24px;
      }

      .searchButton {
        width: max-content;

        @media (max-width: $tablet-screen-max-content) {
          width: 100%;
        }
      }
    }
  }
}
