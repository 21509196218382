@import 'SomeoneHealth/assets/theme';

.container {
  @include s1hLoginBackground;
}

.contentLayoutWrapper {
  margin-top: 60px;
  padding-bottom: 110px;
  max-width: 1180px;
  gap: 20px;
  color: $someone-health-maroon;

  .content {
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: 8px;

    .gpInfoWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid #d9d9d9;
      padding: 64px 8px 68px;

      @media (max-width: $small-screen-max-width) {
        padding: 60px 8px 33px;
      }

      .gpTitle {
        font-weight: $font-weight-bolder;
        font-size: 28px;
        line-height: 36px;
        text-align: center;

        @media (max-width: $small-screen-max-width) {
          text-align: center;
          font-size: 24px;
          line-height: 32px;
        }
      }

      .gpTitleLoading {
        height: 30px;
        width: 100%;
        max-width: 80%;
        display: flex;
        justify-content: center;
      }

      .gpImageWrapper {
        display: flex;
        height: 150px;
        width: 150px;
        margin-bottom: 48px;

        @media (max-width: $small-screen-max-width) {
          margin-bottom: 33px;
        }

        .gpImageLoading {
          width: 150px;
          height: 100%;
          border-radius: 50%;
        }

        .gpImage {
          width: 150px;
          border-radius: 50%;
        }
      }
    }

    .nextInfoWrapper {
      display: flex;
      flex-direction: column;
      padding: 48px 8px 52px;
      max-width: 980px;
      margin: 0 auto;

      @media (max-width: $small-screen-max-width) {
        padding: 30px 8px 33px;
      }

      .nextTitle {
        font-weight: $font-weight-bolder;
        font-size: 28px;
        line-height: 36px;

        @media (max-width: $small-screen-max-width) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      .nextInfo {
        margin-top: 12px;
        font-size: 18px;
        line-height: 23px;
      }

      .link {
        text-decoration: underline;
        color: $someone-health-maroon;
        font-weight: 500;
      }
    }

    .buttonWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 48px;

      .button {
        min-width: 270px;
      }
    }
  }
}

.welcomeMessage {
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: $someone-health-maroon;

  @media (max-width: $small-screen-max-width) {
    font-size: 24px;
    line-height: 36px;
  }
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 99;
}
