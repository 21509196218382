@import 'helm/assets/theme';

@font-face {
  font-family: $title-font-stack;
  src: url(~helm/assets/theme/typography/national2compressed-extrabold.woff2) format('woff2'),
    url(~helm/assets/theme/typography/national2compressed-extrabold.woff) format('woff2');
  font-weight: normal;
  font-style: normal;
}

.container {
  display: flex;
  flex-direction: column;
  font-family: 'Nunito', normal;
  min-height: 100vh;

  input,
  textarea,
  button {
    font-family: 'Nunito', -apple-system, BlinkMacSystemFont, sans-serif;
  }
}
