.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 560px;
  margin: auto;
  border-radius: 20px;
}
.appointmentList {
  margin-top: 16px;
}
.customLink {
  color: #343434;
}
.appointmentContainer {
  display: flex;
  background: #fcf7f7;
  border-radius: 16px;
  margin: 16px 8px;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.18);
  justify-content: space-between;
  .contentContainer {
    margin: 10px 10px;
    .title {
      font-weight: bold;
      font-size: 18px;
    }
    .money_time {
      margin-top: 16px;
      margin-bottom: 16px;
      display: flex;
      .icon {
        align-items: center;
        height: 20px;
        width: 20px;
        display: flex;
        margin-inline-end: 14px;
        color: #2a4670;
      }
      .text {
        align-items: center;
        display: flex;
        font-size: 18px;
        margin-inline-end: 10%;
      }
    }
    .availableText {
      text-transform: uppercase;
      font-size: 11px;
      color: #818e9b;
    }
  }
  .button {
    align-items: center;
    display: flex;
  }
  .availableDate {
    display: flex;
    font-weight: bold;
    .isAvail {
      color: #27ae60;
      margin-inline-end: 5%;
    }
    .isUnavail {
      color: #c4c4c4;
      margin-inline-end: 5%;
    }
  }
  .viaText {
    text-transform: uppercase;
    font-size: 11px;
    color: #818e9b;
  }
  .deliveryOptions {
    display: flex;
    gap: 8px;

    .icon {
      color: #27ae60;
      margin-inline-end: 10px;
    }
  }
  .button {
    margin: 15px;
  }
}
