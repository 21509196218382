@import 'SomeoneHealth/assets/theme';

.toggleSwitchWrapper {
  margin: 32px 0 28px;

  .fieldLabel {
    font-size: 11px;
    color: #818e9b;
    font-weight: 600;
    padding-bottom: 4px;
  }
}

.referralForm {
  display: flex;
  flex-direction: column;

  .subTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #343434;
    margin-bottom: 8px;
  }

  .content {
    margin-left: 0;

    .radioWrapperClass {
      flex-direction: column;
      align-items: flex-start;

      .radioLabelClass {
        font-size: 15px;
        line-height: 23px;
        letter-spacing: 0.1px;
        color: #343434;
      }
    }

    .referralContactContainer {
      margin-top: 8px;
      margin-bottom: 11px;
      padding-left: 0;

      .radioTitle {
        font-size: 15px;
        line-height: 23px;
        color: #192a3e;
      }

      .contactFormContainer {
        padding-top: 16px;
      }
    }

    .phoneInput {
      margin-bottom: 0;
    }

    .selectContainer {
      @extend .fieldContainer;
      margin: 12px 0 32px;
    }

    .phoneContainer {
      @extend .fieldContainer;
      margin: -10px 0 32px;
    }
  }
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 32px;
  max-width: 380px;

  &.fieldError,
  .fieldError {
    font-size: 12px;
    color: #ff4d4f;

    input {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }
}

.selectContainer {
  @extend .fieldContainer;
  margin: -12px 0 32px;

  .selectContainerChild {
    z-index: 4;
  }
}

.fieldWithTooltip {
  display: flex;
  flex-direction: row;
  align-items: center;

  .leftContainer {
    flex: 1;
  }
}

:global(.someone-health-theme) {
  .fieldLabel {
    color: $grey600;
    font-size: 12px;
    font-weight: 400;
  }

  .fieldContainer {
    max-width: 540px;
  }

  .selectFocusLabelClass {
    color: $someone-health-pink;
  }
}
