.button {
  width: 44px;
  height: 44px;
  border: none;
  padding: 0;
  background: none;
  border-radius: 50%;

  &:hover {
    background-color: #d9dcff;
  }
}
