// Ease main color
$ease-dark-grey: #333333;
$ease-green: #adea5c;
$ease-lighter-orange: #bac33033;
$ease-green-orange: #83bb38;
$ease-dark-purple: #263054;
$ease-light-yellow: #eaedbc;

$colorGrey50: #f9f9fa;
$colorGrey100: #ecedef;
$colorGrey500: #94969d;
$colorGrey600: #61666e;
$colorGrey700: #414449;
$colorGrey800: #202225;

$text-disable-grey: #aaaaaa;

$badge-background-color: rgba(44, 52, 96, 0.2);

$black: #000000;
$white: #ffffff;

$darkblue50: #eaedfa;
$darkblue200: #6e80dc;
$darkblue300: #314bcb;
$darkblue400: #22348c;
$darkblue500: #2a4670;
$darkblue400: #426eb1;

$lightpink: #fcf7f7;
$blue50: #f5f6ff;
$blue500: #3f52ff;

$red500: #eb4545;
$red400: #ef6a6a;
$orange500: #ff7d45;
$green500: #4bc27d;

// grey
$grey700: #414449;
$grey600: #61666e;
$grey500: #949aa2;
$grey100: #ecedef;
$green50: #edf9f2;
$calendarPill: #cfebdb;
