@import 'Select/assets/theme';

.summary {
  display: flex;
}

.index {
  min-width: 30px;
  height: 30px;
  background: rgba(218, 60, 144, 0.2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.details {
  margin: 15px 0 15px 30px;

  &.topSeparation {
    margin-left: 0;
    padding-top: 20px;
    border-top: 1px dashed $colorGrey700;
  }

  &.bottomSeparation {
    border-bottom: 1px dashed $colorGrey700;
    padding-bottom: 24px;
  }
}

.item {
  display: flex;
  gap: 33px;
  padding: 10px 0;

  .label {
    flex: 1 1;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: $select-black;
    opacity: 0.5;

    @media screen and (max-width: $medium-large_screen-min-width) {
      font-size: 12px;
    }
  }

  .value {
    flex: 3 1;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: $select-black;
    min-width: 337px;

    @media screen and (max-width: $medium-small-screen-min-width) {
      min-width: unset;
    }
  }
}
