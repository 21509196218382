.container {
  border-top: solid;
  border-color: #2a4670;
  border-width: 12px;

  .footer {
    display: flex;
    flex-direction: column;
    bottom: 0;
    max-width: 1136px;
    border-top: solid;
    border-width: 1px;
    border-color: #c4c4c4;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 8px;
    margin: auto;

    .poweredBy {
      color: #343434;
      font-size: 11px;
      line-height: 20px;
      font-weight: 600;
      font-kerning: 0.1px;
    }

    .logo {
      width: 114px;
      filter: grayscale(100%);
    }
  }
}
