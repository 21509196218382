@import 'helm/assets/theme';

.container {
  background-color: $helm-blue;
  line-height: normal;
}

.wrapper {
  align-items: center;
  gap: 44px;
  padding: 40px 20px;
  color: $white;

  @media (min-width: $medium-small-screen-min-width) {
    padding: 60px;
  }

  @media (min-width: $tablet-screen-max-content) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 80px 100px;
  }

  @media (min-width: $medium-desktop-screen-max-content) {
    padding: 80px 120px;
  }

  .goals {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    @media (min-width: $tablet-screen-max-content) {
      gap: 40px;
      width: auto;
      flex: 1 0 380px;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: $title-font-stack;
      font-size: 32px;

      @media (min-width: $medium-small-screen-min-width) {
        font-size: 44px;
      }

      @media (min-width: $tablet-screen-max-content) {
        font-size: 48px;
      }

      @media (min-width: $medium-desktop-screen-max-content) {
        font-size: 52px;
      }

      .titleSmpButton {
        padding: 12px 18px;
        font-family: $title-font-stack;
        font-weight: normal;
        color: $black;
        background: $helm-yellow;
        border-radius: 20px;

        &:active {
          color: $white;
        }

        @media (min-width: 1124px) {
          display: none;
        }
      }
    }

    .goalItem {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 20px 24px;
      border-radius: 0px 12px 12px 12px;
      background-color: $white;
      box-shadow: 0px 4px 8px 0px #0000001a;

      .icon {
        padding: 12px;
        font-size: 32px;
        border-radius: 50%;
        background-color: #f15944;
      }

      .goalIndex {
        margin-bottom: 4px;
        font-family: $title-font-stack;
        font-size: 12px;
        color: $helm-purple;
      }

      .goal {
        font-family: $primary-font-stack-light;
        font-size: 16px;
        font-weight: 500;
        color: $helm-blue;
        word-break: break-word;
      }

      @media (min-width: $medium-small-screen-min-width) {
        padding: 24px;

        .icon {
          padding: 16px;
        }

        .goalIndex {
          margin-bottom: 8px;
        }
      }

      @media (min-width: $tablet-screen-max-content) {
        gap: 20px;
        padding: 28px;

        .icon {
          padding: 20px;
          font-size: 50px;
        }

        .goalIndex {
          font-size: 14px;
        }
      }

      @media (min-width: $medium-desktop-screen-max-content) {
        gap: 24px;
        padding: 32px;

        .icon {
          padding: 24px;
        }

        .goalIndex {
          font-size: 16px;
        }

        .goal {
          font-size: 20px;
        }
      }
    }

    .smpButton {
      width: 100%;
      padding: 12px 18px;
      font-family: $title-font-stack;
      font-weight: normal;
      color: $black;
      background: $helm-yellow;
      border-radius: 20px;

      &:active {
        color: $white;
      }
    }

    .smpButtonContainer {
      display: none;

      @media (min-width: 1124px) {
        display: block;
      }
    }
  }
}
