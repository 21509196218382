@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: $standard-padding * 2;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    z-index: 1;

    @media (max-width: $medium-screen-min-width) {
      padding: 2px 0;
    }

    .top {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      .titleContainer {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        font-size: 11px;
        font-weight: bold;
        color: #ffffff;

        .title {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }

      .profilePic {
        margin-left: $standard-padding * 0.5;
        height: 48px;
        width: 48px;
        border-radius: 50%;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      margin-top: $standard-padding * 6;
      padding: 0 $standard-padding * 4;

      .complete {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: bold;
        font-size: 11px;
        color: $cta;
      }

      @media (max-width: $medium-screen-min-width) {
        padding: 0 $standard-padding * 2;
        margin-top: 12px;
      }
    }
  }

  .heading {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.1px;
    line-height: 20px;
    color: #343434;
  }

  .description {
    margin-top: 8px;
    font-size: 12px;
    letter-spacing: 0.1px;
    line-height: 20px;
    color: #676767;
  }

  .clinicalAssessmentResult {
    margin-top: 16px;
    margin-bottom: 32px;

    .clinicalAssessmentTitle {
      margin-bottom: 32px;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0.1px;
      line-height: 20px;
      color: #343434;
    }
  }

  .button {
    align-self: center;
    max-width: 200px;
  }
}
