@import 'Ease/assets/theme';

$slotChangeViewTablet: $small-desktop-screen-max-content + 220px;
$scrollingSlotView: $mobile-screen-max-content + 100px;

.contentLayout {
  padding-top: 40px;
  padding-bottom: 40px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;

  .background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('~Ease/assets/images/background/booking-session-background.png');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    opacity: 0.3;
    z-index: -1;
  }
}

.bookingContainer {
  padding: 24px 16px;

  @media (min-width: ($small-desktop-screen-max-content + 1px)) {
    height: 200px;
    margin-bottom: 100px;
    padding: 0 16px 20px 16px;
  }

  &.isAdvisorySession {
    padding: 24px;
  }

  .bookingContent {
    display: flex;

    @media (max-width: $small-desktop-screen-max-content) {
      display: block;
    }

    @media (max-width: $scrollingSlotView) {
      display: block;
    }

    .slotWrapper {
      width: 100%;
      @include columnGap(30px);

      .importantInfoContainer {
        margin-top: 8px;

        .importantInfoContent {
          display: flex;
          align-items: center;
          padding-left: 20px;
          font-size: 14px;

          .cancellationPolicyButton {
            height: max-content;
            padding: 0;
            font-size: 14px;
            color: $ease-dark-grey;
            text-decoration: underline;

            &:hover {
              background: transparent;
            }
          }
        }
      }
    }

    .middleContent {
      display: flex;
      width: 100%;

      @media (max-width: $small-desktop-screen-max-content) {
        gap: 30px;
        margin-top: 30px;
        padding: 0;
      }
    }

    .bookingDesc {
      display: flex;
      flex-direction: column;
      min-width: 280px;
      margin-right: 10px;
      color: $ease-dark-grey;

      .bookingTitle {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
      }

      .bookingDescSummary {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        margin-top: 10px;
      }

      .bookingOnLabel {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        font-weight: 700;
      }
    }

    .slotContainer {
      display: flex;
      flex-direction: column;

      @media (max-width: $scrollingSlotView) {
        width: auto;
        align-items: initial;
      }

      .blocked {
        background: $ease-green;
        border: 2px solid $ease-lighter-orange;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: $ease-dark-grey;
        padding: $standard-padding;
        border-radius: 4px;
        max-width: max-content;
        margin-bottom: 16px;
      }

      .timeslotWrapper {
        display: flex;
        max-width: 100%;
        align-items: center;
        row-gap: 16px;
        @include columnGap(16px);
      }

      .slotLabelMobile {
        color: $white;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        padding-left: 40px;
        padding-top: 4px;
        display: none;

        @media (max-width: $slotChangeViewTablet) {
          display: block;
          padding-left: 0;
          font-size: 10px;
          line-height: 13px;
        }
      }
    }

    .continueBtnWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .continueBtn {
        min-width: 300px;
        font-weight: 400;
      }

      @media (max-width: $small-desktop-screen-max-content) {
        align-items: center;
        margin-top: 30px;
      }

      .continueLabel {
        color: $ease-dark-grey;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        padding-top: 4px;
      }
    }
  }

  .consultPreferenceWrapper {
    margin: 0 10px;
    min-width: 160px;

    @media (max-width: $small-desktop-screen-max-content) {
      display: flex;
      margin: unset;
    }
  }

  .consultPreference {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 150px;
    color: $ease-dark-grey;
    justify-content: center;
    text-align: center;

    .consultPreferenceLabel {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }

    .consultPreferenceButton {
      width: fit-content;
      padding: 0 5px;
      background: $white;
      border: 1.1383px solid #d9d9d9;
      margin-top: 10px;

      .indicator {
        box-shadow: unset;
        border: 1.87719px solid $ease-green;
        background-color: $ease-green;
      }

      .label {
        padding: 0 !important;
        color: $ease-dark-grey !important;
      }

      .labelActive {
        padding: 0 !important;
        color: $ease-dark-grey !important;
      }
    }

    .optionButton1 {
      min-width: 50px;
    }

    .optionButton2 {
      min-width: 100px;
    }

    .optionButton3 {
      min-width: 150px;
    }

    .selectedPreference {
      margin-top: 15px;
    }
  }
}
