@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'assets/theme';
@import 'SomeoneHealth/assets/theme';

.container {
  flex: 1 0 21%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 6px 2px;
  touch-action: manipulation;
}

.emojiButton {
  display: flex;
  cursor: pointer !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  user-select: none;
  transition: all 0.15s ease-in-out;
  touch-action: manipulation;

  @media only screen and (max-width: $small-screen-min-width) {
    height: 60px;
    width: 60px;
  }

  .emoji {
    font-size: 20px;
  }

  .text {
    padding: 0 4px;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;

    @media only screen and (max-width: $small-screen-min-width) {
      font-size: 10px;
    }
  }
}

.buttonValue0 {
  @extend .emojiButton;
  background-color: #cfe8f2;
  color: #00000080;
}

.buttonValue1 {
  @extend .emojiButton;
  height: 80px;
  width: 80px;
  background-color: #5ab6e2;
  border: 0.5px solid #3f52ff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.17);
  font-weight: 800;
  color: #ffffff;
}

.buttonValue2 {
  @extend .emojiButton;
  height: 90px;
  width: 90px;
  background-color: #fc8367;
  border: 1px solid #3f52fb;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  font-weight: 800;
  color: #ffffff;
}

:global(.ease-theme) {
  .buttonValue1 {
    color: #00000080;
    background-color: $ease-green;
    border-color: $ease-green-orange;
  }

  .buttonValue2 {
    border-color: $ease-green-orange;
  }
}

:global(.recharge-theme) {
  .buttonValue1 {
    color: #00000080;
    background-color: $recharge-light-purple;
    border-color: $recharge-purple;
  }

  .buttonValue2 {
    border-color: $recharge-purple;
  }
}

:global(.select-theme) {
  .buttonValue1 {
    color: #00000080;
    background-color: $select-light-red;
    border-color: $select-red;
  }

  .buttonValue2 {
    border-color: $select-red;
  }
}

:global(.s1h-client-portal-theme) {
  .buttonValue0 {
    color: $someone-health-maroon;
    background-color: $someone-health-light-maroon2;
  }

  .buttonValue1 {
    color: $someone-health-maroon;
    background-color: $someone-health-light-maroon;
    border-color: $someone-health-maroon;
  }

  .buttonValue2 {
    border-color: $someone-health-maroon;
    background-color: $someone-health-maroon;
  }
}
