@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  color: $helm-blue;

  .label {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  .deliveredDetails {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    @include rowGap($standard-padding * 2);

    .details {
      display: flex;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 14px;
        line-height: 18px;
      }

      .icon {
        font-size: 20px;
        padding: $standard-padding;
        background-color: $badge-background-color;
        border-radius: 50%;
        margin-right: 8px;

        @media (max-width: $tablet-screen-max-content) {
          font-size: 18px;
        }
      }

      .descLabel {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
      }

      .mapWrapper {
        border-radius: 10px;
      }

      .locationDetails {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
