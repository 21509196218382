@import 'MumsMatter/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: $tablet-screen-max-content) {
    gap: 8px;
  }

  .dateWrapper {
    margin-bottom: 4px;
  }
}

.rowWrapper {
  display: flex;
  gap: 16px;

  @media (max-width: $tablet-screen-max-content) {
    flex-direction: column;
    gap: 8px;
  }

  .fieldWrapper {
    width: 100%;
    max-width: 400px;
    color: #343434;

    .selectInputLabel {
      font-size: 12px;
      color: #343434;
    }
  }
}

.referralFileWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.referralCheckWrapper {
  margin-top: 14px;
}

.uploadButtonContainer {
  width: fit-content;

  .uploadInput {
    display: none;
  }

  .uploadButton {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;
    border: solid 1px $mums-matter-dark-blue;
    border-radius: 50px;
    color: $mums-matter-dark-blue;
    cursor: pointer;
  }
}

.file {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 4px;

  .fileName {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $colorGrey700;

    .icon {
      font-size: 16px;
      margin-right: 4px;
      color: $mums-matter-dark-blue;
    }
  }
}
