@import 'Portobello/assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 #0000001a;
  border: 1px solid #c0c0c0;
  border-radius: 8px;
  padding: 16px;

  @media (max-width: $medium-large_screen-min-width) {
    margin-top: 12px;
  }

  .basicFormContainer {
    max-width: 800px;
  }

  .nameWrapper {
    display: flex;
    gap: 16px;

    @media (max-width: $tablet-screen-max-content) {
      flex-direction: column;
    }

    .nameInput {
      width: 100%;
      max-width: 400px;
      color: #343434;
    }
  }

  .dobInputWrapper {
    margin: 12px 0 0;
  }

  .phoneInputWrapper {
    margin: 16px 0;
    max-width: 400px;
  }

  .emailInput {
    max-width: 400px;
  }

  .inputLabel {
    color: $portobello-black;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }

  .customInput {
    transform: translateX(-$standard-padding);
    padding: 0 $standard-padding;
    color: #343434;
    margin-bottom: $standard-padding !important;

    input {
      width: 100%;
    }
  }

  .customSelect {
    margin-bottom: $standard-padding !important;
  }

  .selectInputLabel {
    font-weight: 600;
  }

  .createAccountContainer {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border: 1px solid #c0c0c0;
    border-radius: 4px;
    margin-top: 16px;

    .createAccountHeader {
      display: flex;
      margin-bottom: 16px;

      .createAccountTitle {
        font-size: 20px;
        font-weight: 700;
        font-family: $Playfair-Display;

        @media (max-width: $medium-large_screen-min-width) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    .passwordInput {
      max-width: 500px;
    }
  }

  .showPasswordButtonClass {
    color: $select-red !important;
    min-width: max-content !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  .customLabel {
    padding-left: 16px;
    color: #202225;
  }

  .subHeader {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 6px;
  }

  .passwordHint {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    .hints {
      padding-top: $standard-padding * 3;

      ul {
        padding: 0;

        li {
          list-style: none;
          padding: $standard-padding * 1 0;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: $portobello-black;

          &::before {
            content: '';
            display: inline-block;
            vertical-align: text-top;
            height: 20px;
            margin-right: $standard-padding * 4;
            width: 20px;
            border: solid 3px $portobello-black;
            border-radius: 10px;
          }
        }
      }
    }
  }

  .passwordHintWithError {
    margin-top: 0;
  }

  .checkBoxes {
    display: flex;
    flex-direction: column;
  }

  .checkBoxText {
    height: fit-content;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-top: 16px;

    .checkBox {
      vertical-align: top;

      input {
        outline: none;
      }
      margin-right: $standard-padding * 3;
    }

    a {
      text-decoration: underline;
      color: $portobello-light-blue;
    }
  }

  .communicationPreferences {
    margin-top: -16px;

    .title {
      font-size: 18px;
      font-weight: 700;
      font-family: $Playfair-Display;

      @media (max-width: $medium-large_screen-min-width) {
        font-size: 16px;
        line-height: 22px;
      }
    }

    .desc {
      font-size: 14px;
      line-height: 20px;
      margin-top: 8px;
    }
  }

  .privacyPolicyCheckboxContainer {
    @extend .communicationPreferences;
    margin-top: 28px;
  }

  .buttonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    @media (max-width: $mobile-screen-max-content) {
      flex-direction: column;
      gap: 20px;
    }

    .submitButtonWrapper {
      @media (max-width: $mobile-screen-max-content) {
        width: 100%;
      }
    }

    .submitButton {
      width: 100%;

      .rightArrow {
        font-size: 12px;
      }
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.link {
  display: inline-flex;
  align-items: center;
  color: $blue500;
  font-weight: 600;

  .linkIcon {
    font-size: 20px;
    padding-left: 4px;
  }
}
