@import 'assets/theme';

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: $standard-padding * 0.5;
  gap: $standard-padding * 0.5;

  .tag {
    display: flex;
    align-items: center;
    padding: $standard-padding * 0.5 $standard-padding;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: $colorGrey700;
    background: #d9dcff;
    border-radius: 43px;
  }
}
