@import 'assets/theme/index';

.container {
  padding: 16px 24px 24px;
  border-radius: 30px;
  margin: 6px 8px 0;
  background-color: $white;
  background-image: url(~assets/images/background-circles.png);
  background-size: cover;
  background-repeat: no-repeat;

  .titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .iconWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      background: #464b5f;
      opacity: 0.4;
      border-radius: 8px;
      position: relative;
      left: -10px;
      cursor: pointer;

      .icon {
        color: $white;
      }
    }

    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      flex: 1;
    }
  }

  .detail {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 24px 0 8px;

    .patientWrapper {
      display: flex;
      flex-direction: column;

      .patientName {
        margin-top: 8px;
        font-style: italic;
        font-weight: 700;
        text-align: center;
        width: 72px;
        word-break: break-all;
      }
    }

    .lineWrapper {
      display: flex;
      position: relative;
      top: 35px;

      .line {
        height: 3px;
        width: 90px;
        background: #0086be;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

        &.lineLoading {
          background: none;
        }
      }
    }

    .profileImg {
      width: 72px;
      min-width: 72px;
      min-height: 72px;
      border: 3px solid #0086be;
      padding: 2px;
      border-radius: 50px;
    }

    .loadingProfileImg {
      border-radius: 50px;
    }
  }

  .appDetails {
    padding-top: 16px;
    max-width: 420px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .date {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }

    .timeWrapper {
      display: flex;
      justify-content: center;
      padding: 16px 0;

      .timeBox {
        &:not(:last-child) {
          padding-right: 60px;
        }

        .timeText {
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
        }

        .time {
          font-weight: bold;
          font-size: 24px;
          line-height: 33px;
          color: #fc8468;
        }
      }
    }

    .deliveryMethod {
      display: flex;
      justify-content: center;

      .deliveryMethodContent {
        color: #fff;
        background-color: #3f52fbc0;
        border-radius: 8px;
        font-weight: bold;
        padding: 8px 16px;
      }
    }
  }
}
