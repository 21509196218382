@import 'CaW/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 12px;

  .selectButton {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;

    @media (max-width: $medium-desktop-screen-max-content) {
      flex-direction: row;
    }

    .selectedValue {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $caw-blue;
    }

    .selectedValueWhite {
      @extend .selectedValue;
      color: $white;
    }

    .changeButton {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-decoration: underline;
      color: $caw-blue;
      margin-left: $standard-padding * 2;
      user-select: none;
    }

    .changeButtonWhite {
      @extend .changeButton;
      color: $white;
    }
  }

  .menuWrapper {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    z-index: 1;

    .menuBoxHide {
      position: absolute;
      width: 100%;
      min-width: 300px;
      background: #fff;
      border-radius: 12px;
      border: 2px solid $caw-blue;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
      transform: scaleY(0);
      transform-origin: top;

      .listBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 12px 40px;
        color: $caw-blue;
        transition: all 0.2s ease;
        cursor: pointer;
        position: relative;

        &:not(:last-child) {
          border-bottom: 1px dashed $caw-blue;
        }

        &:hover {
          color: #3f52ff;
        }

        .title {
          display: flex;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
        }
      }
    }

    .menuBoxShow {
      @extend .menuBoxHide;
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    }
  }
}
