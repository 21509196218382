@import 'SomeoneHealth/assets/theme';

.container {
  background-color: #fafafa;
  border-radius: 30px;
  width: 326px;
  min-height: 370px;
  padding: 80px 38px 42px 38px;
  margin: 12px auto 0 auto;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 110px;
    background: #f6f3ff;
    border-radius: 20px;
  }

  @media (max-width: 1100px) {
    width: 100%;
    margin: 40px auto;
  }

  @media (max-width: $tablet-screen-max-content) {
    width: 100%;
    padding: 80px 22px 40px 44px;
    margin: 20px 8px;
  }

  .heading {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    margin: 12px auto 24px auto;
  }

  .bolded {
    font-weight: 700;
  }

  .subheading {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4f4f4f;
  }
}
