@import 'SomeoneHealth/assets/theme';

.pageContainer {
  background-color: $someone-health-light-pink;
  min-height: 100vh;
}

.header {
  flex: unset;
}

.container {
  color: $someone-health-maroon;
  align-items: center;
  row-gap: 20px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-align: center;
}

.content {
  padding: 24px 36px;
  background: $white;
  max-width: 955px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 36px;

  @media screen and (max-width: $small-screen-max-width) {
    font-size: 16px;
    line-height: 24px;
  }

  .description {
    display: flex;

    .iconContainer {
      width: 24px;

      .icon {
        color: $someone-health-maroon;
        font-size: 16px;
      }
    }
  }

  .link {
    color: $someone-health-maroon;
    text-decoration: underline;
  }
}

.button {
  margin-top: 32px;
  min-width: 358px;

  @media screen and (max-width: $medium-small-screen-min-width) {
    min-width: 100%;
  }
}

.footer {
  flex: unset;
  position: sticky;
  top: 100vh;
  background-color: $blue50;
}
