@import 'assets/theme';

.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 135px;
  padding: $smp-standard-padding * 2 $smp-standard-padding * 10 0;

  .img {
    height: 135px;
  }

  .text {
    font-size: 0.8rem;
    font-weight: bold;
  }
}
