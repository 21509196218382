@import 'assets/theme';

.question-form-container {
  display: flex;
  flex-direction: column;

  .divider {
    height: 0;
    width: 100%;
    border: 1px solid #f3f3f3;
    margin-top: $standard-padding * 2;
    margin-bottom: $standard-padding * 2;
  }
}
