@import 'Portobello/assets/theme/index';

.contentLayout {
  display: flex;
  flex-direction: column;
  max-width: $desktop-screen-max-content;
  margin: 0 auto;
  width: 100%;
  padding: 0 16px;
}

.colorBg {
  background-color: $portobello-light-green;
}
