@import 'assets/theme';

$base-duration: 1s;

// Colors
$color-1: #2c3e50;
$color-2: $colorBlue1;
$color-3: #2ecc71;

// Breakpoints
$sm: new-breakpoint(min-width 320px);
$med: new-breakpoint(min-width 768px);
$lg: new-breakpoint(min-width 1024px);

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

.hide {
  display: none;
}

.container {
  display: flex;
  min-width: 150px;
  max-height: 52px;
  height: 52px;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: $color-2;
  border: none;
  border-radius: 0.3125rem;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);

  color: white;
  font-weight: 600;
  //transition: $base-duration ease;
  overflow: hidden;

  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: lighten($color-3, 10%);
  }

  .submit {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include columnGap(8px);

    &.reverse {
      flex-direction: row-reverse;
    }
  }

  span {
    &:nth-of-type(2) {
      display: none;
    }

    &:nth-of-type(3) {
      display: none;
    }
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.active {
  display: flex;
  background-color: $color-3;
  max-height: 52px;
  height: 52px;

  &:before {
    width: 100%;
    transition: width 3s linear;
  }

  .submit {
    visibility: hidden;
    height: 52px;
  }

  span {
    &:nth-of-type(1) {
      top: -100%;
      transform: translateY(-50%);
    }

    &:nth-of-type(2) {
      top: 100%;
      transform: translateY(0%);
      font-size: 20px;
      display: block;

      svg {
        animation: loading $base-duration linear infinite;
      }
    }

    &:nth-of-type(3) {
      display: none;
    }
  }
}

.finished {
  display: flex;
  background-color: lighten($color-3, 10%);
  max-height: 52px;
  height: 52px;

  .submit {
    display: none;
  }

  .loading {
    display: none;
  }

  .check {
    display: block !important;
    font-size: 20px;
    animation: scale 0.5s linear;

    svg {
      transform-origin: center center;
    }
  }
}

@keyframes loading {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale {
  0% {
    transform: scale(10);
  }
  50% {
    transform: scale(0.2);
  }
  70% {
    transform: scale(1.2);
  }
  90% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

.secondary {
  box-shadow: none;
  background-color: transparent;
  border: 1px solid #3f52ff;
  color: #3f52ff;
  font-weight: 400;

  &:active {
    background-color: #f8f9fd;
  }
}

.link {
  border: none;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  height: auto;
  min-height: 0;
  min-width: 0;

  display: flex;
  justify-content: center;
  color: $colorBlue1;
}
