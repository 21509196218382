@import 'Recharge/assets/theme';

.container {
  padding: $standard-padding * 6;
  color: $recharge-black;

  .infoSection {
    @include rowGap(16px);
    margin-bottom: $standard-padding * 10;
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }

  .confirmationSection {
    display: flex;
    flex-direction: row;
    .profile {
      width: 30%;
      margin-right: $standard-padding * 6;
      @media (max-width: $tablet-screen-max-content) {
        visibility: hidden;
        margin-right: 0;
        width: 0;
      }

      a {
        height: fit-content;
      }

      .profileFrame {
        padding: $standard-padding * 2;
      }
    }

    .confirmInfo {
      @include rowGap(20px);
    }

    .checkBoxLabel {
      @extend .description;

      &.selected {
        color: $recharge-purple;
      }
    }
  }
}
