@import 'CaW/assets/theme';

.container {
  @include loginBackground;

  .headerWrapper {
    display: flex;
    padding: 0 16px 24px;
  }

  .contentWrapper {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    position: relative;
    z-index: 3;
  }
}
