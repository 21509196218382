// CaW main color
$caw-dark-red: #ce4851;
$caw-light-red: #e27078;
$caw-blue: #002d40;
$caw-blue2: #06206d;
$caw-light-blue: #bad9e8;
$caw-lightest-blue: #ecf5f9;
$caw-light-green: #dcf5e7;

$text-disable-grey: #aaaaaa;

$badge-background-color: rgba(44, 52, 96, 0.2);

$black: #000000;
$white: #ffffff;

$darkblue50: #eaedfa;
$darkblue300: #314bcb;
$helmDarkblue400: #22348c;
$darkblue500: #2a4670;
$darkblue400: #426eb1;

$lightpink: #fcf7f7;
$blue50: #f5f6ff;

$red500: #eb4545;
$red400: #ef6a6a;
$orange500: #ff7d45;
$green500: #4bc27d;

$colorGrey50: #f9f9fa;
$colorGrey100: #ecedef;
$colorGrey500: #94969d;
$colorGrey600: #61666e;
$colorGrey700: #414449;
$colorGrey800: #202225;

// grey
$grey700: #414449;
$grey600: #61666e;
$grey500: #949aa2;
$grey100: #ecedef;
$green50: #edf9f2;
$calendarPill: #cfebdb;
