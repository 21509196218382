@import 'assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.container {
  height: 100vh;
  position: relative;
  z-index: 1;

  .body {
    height: 100%;

    @media (max-width: $medium-screen-min-width) {
      padding: 0;
    }
  }

  .headerWrapper {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $medium-screen-min-width) and (max-height: $medium-small-screen-min-width) {
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
    padding: 16px 0;

    .logoWrapper {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      border: 1px solid #6575b1;

      @media (max-width: $medium-screen-min-width) {
        height: 60px;
        width: 60px;
      }

      @media (max-height: $medium-screen-min-height) {
        height: 60px;
        width: 60px;
      }
    }

    .practiceName {
      font-weight: bold;
      font-size: 22px;
    }
  }

  .workingWithWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 0 24px;

    @media (max-height: $medium-screen-min-height) {
      padding: 8px 0 12px;
    }

    .label {
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #2a4670;

      @media (max-height: $medium-screen-min-height) {
        font-size: 12px;
      }
    }

    .tacklitLogo {
      width: 113px;

      @media (max-height: 740px) {
        width: 80px;
      }
    }
  }

  .policyContainer {
    background: #f8f9fd;
    border-radius: 10px;
    padding: 16px;
    max-height: 600px;
    overflow: auto;
    margin-bottom: 16px;

    @media (min-height: 1150px) {
      max-height: 1000px;
      margin: 0 8px;
    }

    @media (max-height: $medium-large_screen-min-width) {
      max-height: 530px;
      margin: 0 8px;
    }

    @media (max-height: $medium-screen-min-height) {
      max-height: 400px;
      margin: 0 8px;
    }

    @media only screen and (max-height: $medium-small-screen-min-height) {
      max-height: 300px;
    }
  }

  .buttonContainer {
    left: 50%;
    margin-left: -100px;
    position: fixed;
    bottom: 40px;
    text-align: center;
    z-index: 1;

    @media (max-width: $medium-screen-min-width) {
      bottom: 20px;
    }

    .agreeBtn {
      height: 55px;
      width: 200px;
    }
  }
}
