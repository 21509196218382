@import 'helm/assets/theme';

.container {
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: $tablet-screen-max-content) {
    margin-top: $standard-padding * 10;
  }

  @media (max-width: $mobile-screen-max-content) {
    margin-top: 0;
  }

  .customSelect {
    margin-left: -16px;
  }

  .customSelectLabel {
    padding-left: 16px;
    color: #818e9b;
    padding-top: 8px;
    margin-bottom: 0;
    font-size: 12px;
  }

  .customSelectError {
    margin-left: 16px;
  }

  .customRadioLabel {
    color: #818e9b;
    padding-top: 8px;
    margin-bottom: 0;
    font-size: 12px;
  }

  .customRadioWrapper {
    gap: 40px;
  }

  .customInput {
    transform: translateX(-16px);
    padding: 0 $standard-padding * 4;
    color: #343434;
    margin-bottom: $standard-padding !important;
  }

  .fixCustomInput {
    margin-left: 16px;
    margin-right: -16px;
  }

  .customLabel {
    padding-left: 16px;
    color: #202225;
  }

  .voucherCode {
    margin-top: 8px;
    padding-right: 16px;

    &:focus-within {
      .label {
        color: $helm-primary;
      }
    }

    &.valid {
      .twoFAInput {
        border-color: #4abd03;
      }
    }

    &.error {
      .twoFAInput {
        border-color: #ffb4b4;
      }
    }

    .label {
      margin-bottom: 8px;
      font-size: 12px;
      color: #818e9b;
    }

    .twoFAInputContainer {
      margin-bottom: 0;
      justify-content: space-between;

      > div {
        margin: unset;
      }
    }

    .twoFAInputWrapper {
      height: 60px;
      width: 60px;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }

    .twoFAInput {
      height: 100%;
      margin-top: 0;
      padding: 8px;
      border: 1px solid #dadbde;
      border-radius: 4px;
    }

    .voucherCodeError {
      display: flex;
      gap: 8px;
      width: 100%;
      margin-top: 8px;
      padding: 12px 8px;
      font-size: 14px;
      font-weight: 600;
      color: $grey700;
      border-radius: 4px;
      background-color: $blue50;

      .icon {
        font-size: 24px;
        color: $helm-blue;
      }
    }
  }

  .header {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 16px;

    .highlight {
      color: $helm-primary;
    }
    display: block;
  }

  .subHeader {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 6px;
  }

  .loginText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    @media (max-width: $mobile-screen-max-content) {
      text-align: left;
    }
    .loginLink {
      color: $helm-primary;
      text-decoration: underline;
      margin-left: 8px;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .passwordHint {
    padding: 0 $standard-padding * 4;
    // margin-top: -20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    .hints {
      padding-top: $standard-padding * 3;

      ul {
        padding: 0;

        li {
          list-style: none;
          padding: $standard-padding * 1 0;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: $grey700;

          &::before {
            content: '';
            display: inline-block;
            vertical-align: text-top;
            height: 20px;
            margin-right: $standard-padding * 4;
            width: 20px;
            border: solid 3px $grey700;
            border-radius: 10px;
          }
        }
      }
    }
  }

  .passwordHintWithError {
    margin-top: 0;
  }

  .collectionNotice {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;

    .title {
      font-weight: bold;
      font-size: 18px;
    }

    a {
      text-decoration: underline;
      color: $helm-blue;
    }
  }

  .ageConfirm,
  .hasVoucherCode,
  .communicationPreferences .checkBoxes {
    padding: 0 $standard-padding * 4;

    .checkBox {
      vertical-align: top;

      input {
        outline: none;
        transform: scale(0.75);
        &:checked::after {
          display: inline-flex;
        }
      }
      margin-right: $standard-padding * 3;
    }

    .checkBoxText {
      height: fit-content;
      display: inline-block;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-top: $standard-padding;
      margin-bottom: $standard-padding * 3;

      a {
        text-decoration: underline;
        color: $helm-blue;
      }
    }
  }

  .hasVoucherCode {
    margin-top: 8px;
    padding: 0;

    .checkBoxText {
      margin: 0;
    }
  }

  .communicationPreferences {
    padding-top: $standard-padding * 5;
    font-weight: 400;
    font-size: 14px;

    .title {
      font-weight: 600;
      font-size: 18px;
    }

    .checkBoxes {
      margin-top: $standard-padding * 2;
    }
  }

  .submitButton {
    width: 100%;
    margin-top: $standard-padding * 7;
    margin-bottom: $standard-padding * 15;

    @media (max-width: $mobile-screen-max-content) {
      margin-bottom: $standard-padding * 20;
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}
