@import 'helm/assets/theme';

.headerWrapper {
  position: relative;

  .headerCard {
    position: absolute;
    top: 0;
    z-index: 100;
    width: 100%;
  }
}

.container {
  display: flex;
  flex-direction: row;
  font-weight: 600;

  .leftContent {
    min-height: 100vh;
    flex: 1;
    padding: $standard-padding * 49 0;
    color: white;
    background-image: url(~helm/assets/images/programPage/alcoholBackground.png);
    background-position: center;
    background-size: cover;

    .leftContentWrapper {
      max-width: 720px;
      margin-left: auto;
      padding: 0 $standard-padding * 14;

      .title {
        font-size: 60px;
      }

      .titleUnderline {
        width: 94px;
        height: 4px;
        margin: $standard-padding * 3 0;
        background-color: $helm-light-yellow;
      }

      .subTitle {
        font-size: 18px;
        max-width: 80%;
        min-width: 270px;

        .readMore {
          display: none;
        }
      }
    }
  }

  .rightContent {
    min-height: 100vh;
    flex: 1;
    padding-top: $standard-padding * 35;
    font-weight: 600;
    color: white;
    background-image: url(~helm/assets/images/programPage/whiteSpotInDarkBlue.jpg);
    background-position: center;
    background-size: cover;

    .rightContentWrapper {
      max-width: 720px;
      margin-right: auto;
      padding: 0 $standard-padding * 10;

      .title {
        font-size: 28px;
      }

      .subTitle {
        font-size: 16px;
      }

      .cards {
        padding: $standard-padding * 5 $standard-padding * 4;
        display: flex;
        .leftCol {
          flex: 1;
          margin-right: $standard-padding * 3;
        }

        .rightCol {
          flex: 1;
          margin-left: $standard-padding * 3;
        }

        .card {
          color: $grey700;
          background-color: white;
          border-radius: 8px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          margin-bottom: $standard-padding * 6;
          padding: $standard-padding * 3 $standard-padding * 5;

          img {
            margin-top: $standard-padding * 3;
            min-height: 100px;
            height: 100px;
            background-color: aliceblue;
            min-width: 100%;
            width: 100%;
            box-sizing: border-box;
            object-fit: cover;
          }

          .cardTitle {
            font-size: 16px;
            margin-right: -20px;
            margin-top: $standard-padding * 4;
            font-weight: 700;
          }

          .cardSubTitle {
            font-size: 14px;
            margin-top: $standard-padding;
          }

          .cardBtn {
            display: block;
            font-size: 16px;
            padding: $standard-padding * 3 $standard-padding * 5;
            color: white;
            background: $darkblue500;
            border-radius: 4px;
            box-shadow: none;
            margin: auto;
            margin-top: $standard-padding * 2;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }

      .mobileViewCards {
        display: none;
      }
    }
  }

  @media (max-width: $small-desktop-screen-max-content) {
    .leftContent {
      flex: 1 40%;
    }
    .rightContent {
      flex: 1 60%;
    }
  }

  @media (max-width: $tablet-screen-max-content) {
    flex-direction: column;
    .leftContent {
      min-height: unset;
      width: 100%;
      padding: $standard-padding * 19 $standard-padding * 4 $standard-padding * 8 $standard-padding * 4;

      .leftContentWrapper {
        margin-right: auto;
        max-width: 500px;
        padding: 0;

        .title {
          font-size: 32px;
        }

        .titleUnderline {
          width: 94px;
          height: 4px;
          margin: $standard-padding * 2 0;
          background-color: $helm-light-yellow;
        }

        .subTitle {
          font-size: 16px;
          max-width: 100%;
          line-height: 24px;

          .showOnTablet {
            display: inline;
          }

          .showOnDesktop {
            display: none;
          }

          .readMore {
            display: inline;
            cursor: pointer;
            margin-left: 0.5rem;
            text-decoration: underline;
          }

          &.showWhenReadMore {
            .showOnTablet {
              display: inline;
            }

            .showOnDesktop {
              display: inline;
            }

            .mobileSubTitle {
              display: none;
            }
          }
        }
      }
    }

    .rightContent {
      min-height: 80vh;
      width: 100%;
      padding: 0 $standard-padding * 4;

      .rightContentWrapper {
        margin-left: auto;
        max-width: 500px;
        padding: 0;

        .title {
          font-size: 24px;
          padding-top: $standard-padding * 8;
        }

        .subTitle {
          font-size: 14px;
          margin-top: $standard-padding * 2;
        }

        .cards {
          display: none;
        }

        .mobileViewCards {
          display: block;
          margin-top: $standard-padding * 4;

          .card {
            color: $grey700;
            background-color: white;
            border-radius: 8px;
            box-shadow: none;
            margin-bottom: $standard-padding * 5;
            padding: $standard-padding * 4;
            display: flex;

            img {
              margin-right: $standard-padding * 4;
              min-height: 100%;
              height: 100%;
              background-color: aliceblue;
              min-width: 80px;
              width: 80px;
              box-sizing: border-box;
              object-fit: cover;
            }

            .cardTitle {
              font-size: 14px;

              font-weight: 700;
            }

            .cardSubTitle {
              font-size: 12px;
              margin-top: $standard-padding;
            }

            .cardBtn {
              font-size: 14px;
              padding: $standard-padding * 2 $standard-padding * 4;
              background: $darkblue500;
              border-radius: 4px;
              margin-top: $standard-padding * 4;
              height: 33px;
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: $mobile-screen-max-content) {
    .leftContent {
      .leftContentWrapper {
        .subTitle {
          .showOnTablet {
            display: none;
          }
        }
      }
    }
  }
}
