@import 'assets/theme/index';

.container {
  display: flex;
  flex-direction: column;
  padding: 16px 0 32px;
  row-gap: 16px;

  @media (max-height: $medium-screen-min-height) {
    padding: 8px 0 24px;
    row-gap: 12px;
  }

  .suggestion {
    display: flex;
    align-items: flex-start;
    transition: 0.2s ease all;

    .checkIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      min-width: 24px;
      height: 24px;
      border: 1px solid #818e9b;
      color: #818e9b;
      padding: 2px;
      border-radius: 50%;
      margin-right: 12px;
      font-size: 14px;
      font-weight: bold;
      transition: 0.2s ease all;

      @media (max-height: $medium-screen-min-height) {
        width: 22px;
        min-width: 22px;
        height: 22px;
        font-size: 12px;
        margin-right: 10px;
      }
    }

    .label {
      line-height: 24px;
    }

    .passwordStrengthWrapper {
      display: flex;

      .passwordStrengthBox {
        padding: 4px 0 0 16px;
      }
    }
  }

  .suggestionSuccess {
    @extend .suggestion;
    color: #4abd03;

    .checkIcon {
      color: #4abd03;
      border: 2px solid #4abd03;
      background-color: #dbf2cd;
    }
  }

  .passwordStrengthIndicatorContainer {
    align-self: flex-end;
    display: flex;

    &.valid .passwordStrengthIndicator {
      background-color: #63c364 !important;
    }

    .passwordStrengthIndicator {
      height: 16px;
      width: 16px;
      border-radius: 100%;
      background-color: #e1e1e1;

      &:not(:last-child) {
        margin-right: 4px;
      }

      &.active {
        background-color: #f6ca30;
      }
    }
  }

  .passwordStrengthBar {
    div {
      display: none;
    }
  }
}
