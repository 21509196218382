@import 'assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.contentWrapper {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 0 16px;
  background-color: #2a4670;

  .content {
    display: flex;
    flex-direction: column;

    .imageWrapper {
      padding: 16px 0 8px;

      .image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }

    .name {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: #fff;
      padding-bottom: 16px;
    }

    .desc {
      font-size: 18px;
      line-height: 28px;
      color: #fff;
    }

    .specialistWrapper {
      display: flex;
      flex-direction: column;
      padding: 24px 0;

      .specialistTitle {
        font-weight: 600;
        font-size: 10px;
        line-height: 24px;
        color: #fff;
      }

      .specialist {
        display: flex;
        flex-wrap: wrap;
        padding-top: 8px;

        .specialistItem {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          background-color: #0085bd;
          border-radius: 20px;
          padding: 10px 20px;
          margin-bottom: 16px;

          &:not(:last-child) {
            margin-right: 16px;
          }
        }
      }
    }
  }
}

.text {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #3f52ff;
  padding-bottom: 16px;
  text-align: center;
}

.middleText {
  @extend .text;
  padding: 8px 0;
}

.button {
  display: flex;
  justify-content: center;
  color: #fff;
  padding: 16px 20px;
  min-width: 150px;
  background: #3f52ff;
  border-radius: 16px;
  cursor: pointer;
  user-select: none;
}

.noClickButton {
  @extend .button;
  opacity: 0.6;
  pointer-events: none;
}

.wrapper {
  cursor: not-allowed;
}
