@import 'Portobello/assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25%;
}

.container {
  display: flex;
  flex-direction: column;
  z-index: 1;
  overflow: auto;
  flex: 1 0 auto;
  min-height: calc(100vh - 82px);
  background-size: cover;
  background-image: url('~Portobello/assets/images/background/background.png');
  background-repeat: no-repeat;
  font-family: $Playfair-Display;
}

.content {
  padding-top: 24px;
  width: 100%;
}
