@import 'Ease/assets/theme';

.container {
  display: flex;

  .borderFrame {
    border-radius: 50%;
  }

  .image {
    width: 100%;
    border-radius: 50%;
  }
}
