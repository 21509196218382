@import 'MumsMatter/assets/theme';

.pageContainer {
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 82px);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('~MumsMatter/assets/images/background/background.png');
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.8;
    z-index: -1;
  }
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 100px;
}
