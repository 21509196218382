@import 'assets/theme';

.container {
  background: rgba(255, 242, 236, 1);
  padding: $standard-padding * 4;

  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding * 2);

  * {
    font-family: 'Open Sans' !important;
    color: #3f52ff;
  }

  .title {
    margin-bottom: $standard-padding * 5;
  }
}

.title {
  font-family: 'Monoton' !important;
  color: #3f52ff;
  font-size: 24px;
}
