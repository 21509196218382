@import 'MumsMatter/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 82px);
  background-size: cover;
  background-image: url('~MumsMatter/assets/images/background/background.png');
  background-repeat: no-repeat;
}
