@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 32px 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #344054;

  @media screen and (max-width: $medium-small-screen-min-width) {
    padding: 32px 10px;
  }
}

.inputContainerClassName {
  margin-bottom: unset;
}

.inputWrapperClassName {
  max-width: 64px !important;
  margin-right: 8px !important;
}

.verifyingClassName {
  border: unset;
}

.verifiedClassName {
  input {
    border: 2px solid $someone-health-light-red;
    color: $someone-health-light-red;
  }
}

.otpError {
  background: #fff5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 14px 30px 15px 23px;
  gap: 10px;

  i {
    font-size: 18px;
    color: $someone-health-light-red;
    margin-bottom: 3px;
  }
}

.resendButton {
  background: none;
  border: none;
  width: fit-content;
  padding: unset;
  text-decoration-line: underline;
  color: $someone-health-pink;

  &:disabled {
    cursor: not-allowed;
  }
}

.resendTimer {
  margin-top: $standard-padding;
  font-size: 14px;
  line-height: 21px;
}

.verifyButton {
  border-radius: 8px;
}

.inputClassName {
  max-width: 64px;
  height: 64px;
  background: $white;
  border: 2px solid $colorGrey600;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 400;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: $colorGrey600;
  margin-top: unset;

  @media screen and (max-width: $medium-small-screen-min-width) {
    font-size: 32px;
  }
}

.link,
link:hover {
  color: $someone-health-pink !important;
  text-decoration: underline !important;
}

.loading {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #ffffffc0;
  position: absolute;
  z-index: 2;
}
