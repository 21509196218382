@import 'Recharge/assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  color: $recharge-black;
  background-color: $recharge-light-purple;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  min-width: 120px;
  transition: all 0.1s ease-in;
}

.darkYellowContainer {
  @extend .container;
  background-color: $recharge-light-blue;
}
