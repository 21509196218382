@import 'helm/assets/theme';

.chartWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 210px;

  @media (max-width: $tablet-screen-max-content) {
    margin-top: 165px;
  }

  .circle {
    z-index: 0;
    border: 1px solid lightgray;
    position: absolute;
    border-radius: 50%;

    &:nth-of-type(1) {
      z-index: 2;
      border: 4px solid black;
      height: 250px;
      width: 250px;

      @media (max-width: $tablet-screen-max-content) {
        height: 200px;
        width: 200px;
      }
    }

    &:nth-of-type(2) {
      height: 225px;
      width: 225px;

      @media (max-width: $tablet-screen-max-content) {
        height: 180px;
        width: 180px;
      }
    }

    &:nth-of-type(3) {
      height: 200px;
      width: 200px;

      @media (max-width: $tablet-screen-max-content) {
        height: 160px;
        width: 160px;
      }
    }

    &:nth-of-type(4) {
      height: 175px;
      width: 175px;

      @media (max-width: $tablet-screen-max-content) {
        height: 140px;
        width: 140px;
      }
    }

    &:nth-of-type(5) {
      height: 150px;
      width: 150px;

      @media (max-width: $tablet-screen-max-content) {
        height: 120px;
        width: 120px;
      }
    }

    &:nth-of-type(6) {
      height: 125px;
      width: 125px;

      @media (max-width: $tablet-screen-max-content) {
        height: 100px;
        width: 100px;
      }
    }

    &:nth-of-type(7) {
      height: 100px;
      width: 100px;

      @media (max-width: $tablet-screen-max-content) {
        height: 80px;
        width: 80px;
      }
    }

    &:nth-of-type(8) {
      height: 75px;
      width: 75px;

      @media (max-width: $tablet-screen-max-content) {
        height: 60px;
        width: 60px;
      }
    }

    &:nth-of-type(9) {
      height: 50px;
      width: 50px;

      @media (max-width: $tablet-screen-max-content) {
        height: 40px;
        width: 40px;
      }
    }

    &:nth-of-type(10) {
      height: 25px;
      width: 25px;

      @media (max-width: $tablet-screen-max-content) {
        height: 20px;
        width: 20px;
      }
    }
  }

  .line {
    z-index: 2;
    position: absolute;
    height: 4px;
    width: 250px;
    border: 0;
    background-color: black;

    @media (max-width: $tablet-screen-max-content) {
      width: 200px;
    }

    &:nth-of-type(1) {
      transform: rotate(90deg);
    }

    &:nth-of-type(2) {
      transform: rotate(150deg);
    }

    &:nth-of-type(3) {
      transform: rotate(210deg);
    }
  }

  .sectorWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .sectorContainer {
      position: absolute;
      overflow: hidden;
      border-radius: 50%;

      .sector {
        z-index: 1;
        position: absolute;
        transform-origin: 0% 100%;
        background-color: #7d73b1;
        opacity: 0.8;
      }
    }

    .labelWrapper {
      position: absolute;
      width: 380px;
      height: 380px;
      font-size: 12px;

      @media (max-width: $tablet-screen-max-content) {
        width: 300px;
        height: 290px;
        font-size: 9px;
      }

      .labelStraightener {
        display: flex;
        justify-content: center;

        .label {
          max-width: 90px;
          word-wrap: break-word;
          text-align: center;
          font-weight: 600;

          @media (max-width: $tablet-screen-max-content) {
            max-width: 72px;
          }

          .labelNumber {
            padding: 1px 5.5px;
            background-color: lightgrey;
            border-radius: 50%;
            width: fit-content;
            margin: 0 auto;
            font-weight: 700;

            @media (max-width: $tablet-screen-max-content) {
              padding: 0.5px 4px;
            }
          }

          .label10 {
            padding: 1px 2px;
          }
        }
      }
    }
  }
}
