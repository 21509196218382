@import 'helm/assets/theme';

.container {
  .header {
    .headerTitle {
      font-style: normal;
      font-size: 15px;
      line-height: 23px;
    }

    .headerFilter {
      display: flex;
      font-size: 12px;
      line-height: 18px;

      .filter {
        padding-right: 8px;
      }

      .headerFilterDropdown {
        font-size: 12px;
        color: #3f52ff;
        cursor: pointer;
      }
    }
  }

  .content {
    margin-top: 6px;
    background: #fff;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 12px 16px 32px;

    .masonryContainer {
      display: flex;
    }
  }

  .noContent {
    padding: 20px 0 0;
    margin: -10px 0;
    color: #90a0b7;
  }
}
