@import 'SomeoneHealth/assets/theme';

:global {
  #image-renderer {
    background-image: none;
  }
}

.modalContainer {
  @media screen and (min-width: ($tablet-screen-max-content + 1)) {
    min-width: 800px;
  }

  .modalHeader {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 18px;
      margin: 0;
      overflow: hidden;
    }
  }
}
