@import 'Ease/assets/theme';

.container {
  color: $colorGrey700;
  font-size: 14px;
  line-height: 18px;

  a {
    color: $blue500;
    text-decoration: underline;
  }
}
