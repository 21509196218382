.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.cardContainer {
  height: 100%;
}
.fullHeightFooter {
  display: flex;
  flex-direction: column;
  height: calc(100% - 82px);
  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 720px;
    height: 100%;
    margin: 0 auto;
  }
}
