@import 'SomeoneHealth/assets/theme';

$slotChangeViewTablet: $small-desktop-screen-max-content + 220px;
$scrollingSlotView: $mobile-screen-max-content + 100px;
$mobileScreen: 1056px;

.container {
  display: flex;
  background: #f1fff7;
  margin-left: -34px;
  margin-right: -34px;
  padding: 40px 20px 40px 40px;
  min-height: 243px;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  @media (max-width: $mobileScreen) {
    display: block;
    padding: 16px;
    margin-left: -34px;
    margin-right: -34px;
  }

  @media (max-width: $small-screen-max-width) {
    margin-left: -16px;
    margin-right: -16px;
  }

  @media (max-width: $scrollingSlotView) {
    display: block;
  }

  .slotWrapper {
    display: flex;
    max-width: 100%;
    align-items: center;
    row-gap: 16px;
    @include columnGap(16px);

    @media (max-width: $mobileScreen) {
      position: absolute;
    }
  }

  .middleContent {
    display: flex;
    width: 100%;
    padding: 0 30px;

    @media (max-width: $mobileScreen) {
      flex-direction: column-reverse;
      gap: 30px;
      padding: 0;
    }
  }

  .bookingDesc {
    display: flex;
    flex-direction: column;
    color: $someone-health-maroon;

    .bookingTitle {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }

    .bookingDescSummary {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      margin-top: 10px;
    }

    .bookingOnLabel {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      font-weight: 700;
    }

    .importantInfoContainer {
      margin-top: 20px;
      font-weight: 500;

      .link {
        font-weight: $font-weight-bolder;
        color: $someone-health-maroon;
        text-decoration: underline;
      }
      .importantInfoTitle {
        font-size: 14px;
        line-height: 18px;
      }

      .importantInfoContent {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 8px;
      }

      .closeIcon {
        font-size: 18px;
        color: $someone-health-light-red;
      }
    }
  }

  .continueBtnWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .continueBtn {
      min-width: 270px;
    }

    @media (max-width: $mobileScreen) {
      align-items: center;
      margin-top: 30px;
    }

    .continueLabel {
      color: $someone-health-maroon;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding-top: 4px;
      text-align: center;
    }

    :global() {
      &:disabled {
        background-color: #e8d8e2 !important;
      }
    }
  }
}

.consultPreferenceWrapper {
  margin: auto;
  padding-left: 10px;
  min-width: 180px;

  @media (max-width: $mobileScreen) {
    display: flex;
    justify-content: flex-end;
    margin: unset;
  }
}

.consultPreference {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $someone-health-maroon;
  justify-content: center;
  text-align: center;

  .consultPreferenceLabel {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  .consultPreferenceButton {
    width: fit-content;
    padding: 0 5px;
    background: $white;
    border: 1.1383px solid #d9d9d9;
    margin-top: 10px;

    .indicator {
      box-shadow: unset;
      border: 1.87719px solid $someone-health-maroon;
      background-color: $someone-health-maroon;
    }

    .label {
      padding: 0 !important;
      color: $someone-health-maroon !important;
    }

    .labelActive {
      padding: 0 !important;
      color: $white !important;
    }
  }

  .optionButton1 {
    min-width: 50px;
  }

  .optionButton2 {
    min-width: 100px;
  }

  .optionButton3 {
    min-width: 150px;
  }

  .selectedPreference {
    margin-top: 15px;
  }
}
