@import 'Select/assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  color: $select-black;
  background-color: $select-green;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  min-width: 120px;
  transition: all 0.1s ease-in;
  user-select: none;
}
