@import 'assets/theme';
@import 'Select/assets/theme/palette/colours.scss';

.container {
  background-color: #ffe4e5;
  border-radius: 16px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: $select-black;
  padding: 16px;

  .titleContainer {
    align-items: center;
    color: $red500;
    display: flex;
    font-size: 20px;
    gap: 8px;

    @media (max-width: $medium-screen-min-width) {
      font-size: 14px;
    }
  }

  .desc {
    font-size: 16px;
    padding-bottom: 1rem;
  }

  .action {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .retryFinished {
      div,
      i {
        animation: none !important;
      }
    }
  }
}
