@import 'SomeoneHealth/assets/theme';

.headerContainer {
  display: flex;
  flex-direction: column;
  background-image: url(~SomeoneHealth/assets/images/background/practitioner-list-header.png);
  background-size: cover;
  background-position: center;
}

.searchBar {
  margin-top: 68px;
  margin-bottom: 52px;
  max-width: 1100px;

  @media (max-width: $tablet-screen-max-content) {
    margin-top: 51px;
    margin-bottom: 41px;
  }
}

.alphabetFilterBarContainer {
  background: $someone-health-blue;

  .alphabetFilterBar {
    max-width: 1100px;
  }
}

.result {
  padding-top: 36px;
  padding-bottom: 176px;
  max-width: 1100px;

  @media (max-width: $tablet-screen-max-content) {
    padding-top: 30px;
    padding-bottom: 100px;
  }
}

.loadingWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loadingItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include rowGap(8px);
  padding: 16px 0;

  .loadingContent {
    width: 100%;
    border-radius: 4px;
    height: 20px;
    padding: 0 20px;
  }
}
