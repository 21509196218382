@import 'assets/theme';

.inputContainer {
  display: flex;
  margin-bottom: 16px;

  div {
    width: 100%;
    max-width: 60px;
    margin-right: 4px;

    @media (max-width: $medium-screen-min-width) {
      max-width: 50px;
    }

    input {
      padding-left: 8px;
      padding-right: 8px;
      text-align: center;
    }
  }
}

.verifying {
  div {
    input {
      border: 1px solid #ffffff;
      animation: verifying 0.7s ease-in infinite;
    }

    &:nth-of-type(2) {
      input {
        animation-delay: 0.1s;
      }
    }

    &:nth-of-type(3) {
      input {
        animation-delay: 0.2s;
      }
    }

    &:nth-of-type(4) {
      input {
        animation-delay: 0.3s;
      }
    }

    &:nth-of-type(5) {
      input {
        animation-delay: 0.4s;
      }
    }

    &:nth-of-type(6) {
      input {
        animation-delay: 0.5s;
      }
    }
  }
}

@keyframes verifying {
  0% {
    background-color: #f8f9fd;
  }
  50% {
    background-color: #efffd7;
  }
}

.verified {
  div {
    input {
      border: 1px solid #4abd03;
    }
  }
}

.errored {
  div {
    input {
      border: none;
      border-bottom: 1px solid #ffb4b4;
      animation: shake 0.15s;
    }
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
