@import 'SomeoneHealth/assets/theme';

.container {
  color: $someone-health-maroon;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 46px;

  @media (max-width: $small-screen-max-width) {
    padding-left: 0;
  }

  .title {
    font-weight: $font-weight-bolder;
    font-size: 20px;
    line-height: 26px;
    color: $someone-health-maroon;

    @media (max-width: $small-screen-max-width) {
      padding-bottom: 16px;
    }
  }

  .gpProfileContent {
    display: flex;
    font-weight: 500;

    @media (max-width: $medium-large_screen-min-width) {
      flex-direction: column;
      gap: 25px;
    }
  }

  .item {
    flex: 1 1;
    @media (min-width: ($medium-large_screen-min-width + 1)) {
      width: 330px;
    }
  }

  .avatarContainer {
    display: flex;
    gap: 18px;

    @media (min-width: ($medium-large_screen-min-width + 1)) {
      padding-top: 30px;
    }

    .image {
      width: 90px;
      height: 90px;
      border-radius: 50%;
    }

    .nameContainer {
      display: flex;
      flex-direction: column;
      font-weight: 700;
      justify-content: center;

      .salutation {
        font-size: 24px;
        line-height: 32px;
      }

      .name {
        font-size: 32px;
        line-height: 42px;
      }
    }
  }

  .label {
    font-size: 12px;
    line-height: 16px;
  }

  .bonaFideDetails {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    @include rowGap($standard-padding * 2);

    .details {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 23px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 14px;
        line-height: 18px;
      }

      .icon {
        font-size: 20px;
        padding: $standard-padding;
        background-color: $badge-background-color;
        border-radius: 50%;
        margin-right: 8px;

        @media (max-width: $tablet-screen-max-content) {
          font-size: 18px;
        }
      }
    }
  }

  .credentials {
    p {
      margin-top: 10px;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
