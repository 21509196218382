@import 'MumsMatter/assets/theme';

.container {
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 82px);
  background-color: $white;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('~MumsMatter/assets/images/background/background.png');
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.8;
    z-index: -1;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 120px;

    .contentCardShadow {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border: none;
    }

    .titleContainer {
      display: flex;
      justify-content: space-between;

      .title {
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        margin-bottom: $standard-padding * 3;
        margin-top: $standard-padding * 4;
        color: $mums-matter-black;
      }

      .timeZone {
        display: flex;
        align-items: flex-end;
        margin-bottom: 8px;
        font-size: 16px;
      }
    }
  }
}
