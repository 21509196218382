@import 'Recharge/assets/theme';

$base-duration: 1s;

// Colors
$color-user-contained: $recharge-purple;
$color-user-contained-active: $recharge-purple;
$color-user-contained-disabled: #d8dae8;

$color-user-outlined: $recharge-purple;
$color-user-outlined-hover: $recharge-purple;

$color-user-contained-fab-hover: $recharge-purple;
$color-user-contained-fab-active: $recharge-purple;

$color-error: $recharge-purple;
$color-error-hover: $recharge-purple;
$color-error-active: $recharge-purple;

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

.hide {
  display: none;
}

.noTransition {
  transition: none;
}

.container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  font-weight: 600;
  overflow: hidden;
  transition: 0.5s;
  background-size: 200% auto;

  &:active {
    transition: none;
  }

  .submit {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .icon {
    vertical-align: middle;
  }

  .iconLeft {
    margin-left: -4px;
  }

  .iconRight {
    margin-right: -4px;
  }

  span {
    &:nth-of-type(2) {
      display: none;
    }

    &:nth-of-type(3) {
      display: none;
    }
  }
}

.active {
  display: flex;
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  background-color: #fff;
  background-repeat: no-repeat;
  animation: moveAcross 3s linear forwards infinite;

  &:before {
    width: 100%;
  }

  .submit {
    height: 0;
    overflow: hidden;
  }

  .loading {
    @include columnGap(8px);
  }

  span {
    &:nth-of-type(2) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &:nth-of-type(3) {
      display: none;
    }
  }
}

@keyframes moveAcross {
  0% {
    background-position: 200% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.finished {
  display: flex;

  &:before {
    width: 100%;
  }

  .submit {
    height: 0;
    overflow: hidden;
  }

  .loading {
    display: none;
  }

  .check {
    @include columnGap(8px);
  }

  span {
    &:nth-of-type(2) {
      display: none;
    }

    &:nth-of-type(3) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.small {
  height: 34px;
  font-size: 12px;
  padding: 10px 12px;
  line-height: 18px;

  .submit {
    @include columnGap(10px);
  }

  .icon {
    font-size: 18px;
  }
}

.medium {
  height: 40px;
  font-size: 14px;
  padding: 12px 14px;
  line-height: 21px;

  .submit {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include columnGap(10px);
  }

  .icon {
    font-size: 20px;
  }
}

.large {
  height: 48px;
  font-size: 16px;
  padding: 14px 16px;
  line-height: 24px;

  .submit {
    @include columnGap(11px);
  }

  .icon {
    font-size: 22px;
  }
}

.contained,
.contained-fab {
  .container {
    background-color: $color-user-contained;
    color: $white;
    border: none;

    &:disabled {
      cursor: not-allowed !important;
    }
  }
}

.round {
  .container {
    border-radius: 50%;
  }

  .small {
    height: 28px !important;
    width: 28px !important;
  }

  .medium {
    height: 36px !important;
    width: 36px !important;
  }

  .large {
    height: 56px !important;
    width: 56px !important;
  }
}

.errorTheme {
  .contained {
    .container {
      background-color: $color-error;
      color: $white;

      &:disabled {
        background-color: #d9caca;
        color: $white;
      }
    }
  }

  .outlined,
  .text {
    .container {
      background-color: transparent;
      color: $color-error;

      &:disabled {
        background-color: transparent;
        color: $colorGrey500;
      }
    }
  }

  .outlined {
    .container {
      border: 1px solid $color-error;

      &:disabled {
        border: 1px solid #d9caca;
      }
    }
  }

  .text {
    .container {
      border: none;
    }
  }

  .contained,
  .outlined,
  .text {
    .container {
      &:hover {
        background-color: $color-error-hover;
        color: $color-error;
      }

      &:active {
        background-color: $color-error-active;
        color: $white;
      }

      &:disabled {
        background-color: transparent;
        color: $colorGrey500;
        cursor: not-allowed !important;
      }
    }

    .active,
    .finished {
      background-color: $color-error-active;
      color: $white;

      &:hover {
        background-color: $color-error-active;
        color: $white;
      }
    }
  }
}

.userTheme {
  .contained {
    .container {
      background-image: $color-user-contained;

      &:hover {
        background-position: right top;
      }

      &:active {
        background-image: none;
        background-position: center;
        background-color: $color-user-contained-active;
      }

      &:disabled {
        background-image: none;
        background-color: $color-user-contained-disabled;
      }
    }

    .active,
    .finished {
      background-image: linear-gradient(to left, rgba(255, 255, 255, 0.3), rgba(255, 0, 0, 0));
      background-color: $color-user-contained-active;
    }
  }

  .contained-fab {
    .container {
      background-color: $color-user-outlined;

      &:hover {
        background-color: $color-user-contained-fab-hover;
      }

      &:active {
        background-color: $color-user-contained-fab-active;
      }

      &:disabled {
        background-color: $color-user-contained-disabled;
      }
    }

    .active,
    .finished {
      background-color: $color-user-contained-fab-active;

      &:hover {
        background-color: $color-user-contained-fab-active;
      }
    }
  }

  .outlined,
  .text {
    .container {
      background-color: transparent;
      color: $color-user-outlined;

      &:hover {
        background-color: $color-user-outlined-hover;
        color: $white;
      }

      &:active {
        background-color: $color-user-outlined;
        color: $white;
      }

      &:disabled {
        background-color: transparent;
        color: $colorGrey500;
        cursor: not-allowed !important;
      }
    }

    .active,
    .finished {
      background-color: $color-user-outlined;
      color: $white;

      &:disabled {
        background-color: transparent;
        color: $colorGrey500;
        cursor: not-allowed !important;
      }

      &:hover {
        background-color: $color-user-outlined;
      }
    }
  }

  .outlined {
    .container {
      border: 1px solid $color-user-outlined;

      &:disabled {
        border: 1px solid $color-user-contained-disabled;
      }
    }
  }

  .text {
    .container {
      border: none;
    }
  }

  .fab {
    .container {
      border-radius: 28px;
    }

    .small {
      height: 30px;
      padding: 6px 12px;
      line-height: 18px;

      .submit {
        @include columnGap(6px);
      }
    }

    .medium {
      height: 36px;
      padding: 8px 16px;
      line-height: 17px;

      .submit {
        @include columnGap(6px);
      }
    }

    .large {
      height: 56px;
      padding: 16px 20px;
      line-height: 19px;

      .submit {
        @include columnGap(10px);
      }
    }
  }
}

.fullWidth {
  width: 100%;
}
