@import 'Select/assets/theme';

.container {
  background-color: transparent;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $select-black;
  padding: 32px 16px 120px;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contentBox {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    max-width: 449px;

    .taskListContainer {
      display: flex;
      flex-direction: column;

      .taskListTitle {
        font-size: 18px;
        line-height: 27px;
        font-weight: 600;
        color: $select-black;
        padding-bottom: 16px;
        display: flex;
        flex-direction: column;
        @include rowGap(20px);

        @media (max-height: $tablet-screen-max-content) {
          font-size: 16px;
        }
      }

      .title {
        font-size: 28px;
        font-weight: 700;
        line-height: 42px;
      }

      .taskListBox {
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        @media (max-height: $tablet-screen-max-content) {
          row-gap: 16px;
        }
      }
    }
  }
}
