@import 'assets/theme';

$distanceLefRight: 28px;

.flexStart {
  display: flex;
  align-items: flex-start;
}

.flexCenter {
  display: flex;
  align-items: center;
}

.textLable {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: $colorGrey500;
}

@mixin textInfoValue($fz: 16px, $lineHeight: 24px, $fw: 400) {
  font-size: $fz;
  font-weight: $fw;
  line-height: $lineHeight;
  color: $colorGrey700;
}

.contentRow {
  @extend .flexStart;
  gap: 27px;
  .customInfo {
    div:last-child {
      @include textInfoValue(14px, 21px);
    }
  }
}

.itemInfoBlock {
  @extend .flexStart;
  flex-direction: column;
  gap: 4px;
  min-width: 400px;
  max-width: 400px;
}
