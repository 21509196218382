@import 'MumsMatter/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 8px;
  padding: 24px 34px 0;
  color: $mums-matter-dark-blue;

  @media (max-width: $small-screen-max-width) {
    padding: 20px 16px 0;
  }

  &.paddingBottom {
    padding-bottom: 28px;
  }
}

.header {
  padding-left: 46px;
  margin-bottom: 24px;

  @media (max-width: $small-screen-max-width) {
    padding-left: 0;
    margin-bottom: 32px;
  }

  .title {
    font-size: 24px;
    line-height: 33px;
    padding-bottom: 16px;
  }

  .description {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media (max-width: $small-screen-max-width) {
    gap: 32px;
  }
}

.completeActions {
  display: flex;
  flex-direction: column;
  padding: 38px 43px 43px 46px;
  background: $mums-matter-light-green;
  gap: 25px;
  border-radius: 8px;

  @media (max-width: $small-screen-max-width) {
    padding: 24px 12px;
  }

  .description {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
  }
}

.gpSlotDetailsWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
