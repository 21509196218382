@import 'Recharge/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 30px 0 12px;
  gap: 24px;

  @media (max-width: $tablet-screen-max-content) {
    padding: 30px 0 12px;
  }

  .filterWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 68px;
    color: $white;

    @media (max-width: $tablet-screen-max-content) {
      flex-direction: column;
      margin: 0 24px;
    }

    .label {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      margin-right: 64px;

      @media (max-width: $tablet-screen-max-content) {
        margin-right: 0;
        margin-bottom: 32px;
        width: 100%;
        text-align: center;
      }
    }

    .filter {
      width: auto;
      flex: 1;
      max-width: 40%;

      @media (max-width: $tablet-screen-max-content) {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .timezone {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .selectDropDown {
      display: flex;
      flex-direction: row;
    }
  }
}
