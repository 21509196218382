@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  background-color: #2a4670;
  flex: 1 0 auto;

  .headerContent {
    display: flex;
    width: 100%;
    flex: initial;
  }

  .headerWrapper {
    display: flex;
  }
}

.content {
  padding-top: 24px;
  width: 100%;
  position: relative;

  @media (max-width: $tablet-screen-max-content) {
    padding: 0;
  }
}
