@import 'assets/theme';
@import 'Ease/assets/theme';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fcf7f7;
  box-shadow: 0px 4px 8px 0px #0000001a;
  border-radius: 8px;
  padding: 20px;
  color: $colorGrey700;
  cursor: pointer;

  .infoWrapper {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: bold;
      font-size: 24px;
      padding-bottom: 2px;

      @media (max-width: $medium-screen-min-width) {
        font-size: 20px;
      }
    }

    .desc {
      font-size: 18px;
      font-weight: 600;
      padding-bottom: 2px;

      @media (max-width: $medium-screen-min-width) {
        font-size: 16px;
      }
    }

    .time {
      font-size: 14px;
    }
  }

  .arrowIcon {
    padding: 16px;
    cursor: pointer;
    font-size: 24px;
    color: $ease-dark-grey;
    border: 1px solid $ease-green;
  }
}

.noLinkContainer {
  @extend .container;
  background: #f3f3f3;
  color: #afadad;
  cursor: not-allowed;

  .arrowIcon {
    border: 1px solid #afadad;
    color: #afadad;
  }
}

.completeContainer {
  @extend .container;
  background: #edfcf4;
  cursor: auto;

  .infoWrapper {
    .title {
      font-size: 16px;
    }

    .desc {
      font-weight: bold;
      color: $blue500;
    }

    .time {
      font-size: 14px;
    }
  }

  .successIcon {
    border: 3px solid #4abd03;
    border-radius: 50%;
    font-size: 22px;
    color: #4abd03;
    font-weight: bold;
    padding: 4px;
    background: #fff;
  }
}
