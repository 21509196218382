@import 'Portobello/assets/theme';

.container {
  z-index: 1;
  min-height: calc(100vh - 82px);
  background-size: cover;
  background-image: url('~Portobello/assets/images/background/background.png');
  background-repeat: no-repeat;
  font-family: $Playfair-Display;

  .content {
    display: flex;
    flex-direction: column;
    color: $portobello-black;
    padding: 16px 16px 120px;

    .contentCardShadow {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border: none;
    }

    .titleContainer {
      display: flex;
      justify-content: space-between;

      .title {
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        margin-bottom: $standard-padding * 3;
        margin-top: $standard-padding * 4;
        color: $white;
      }

      .timeZone {
        display: flex;
        align-items: flex-end;
        margin-bottom: 8px;
        font-size: 16px;
      }
    }
  }
}
