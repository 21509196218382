@import 'Recharge/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 40px;
  color: $recharge-black;
  background-color: $recharge-light-blue;
  border-radius: 12px;
  border: 1px solid $recharge-light-blue;
  margin-bottom: 12px;

  @media (max-width: $tablet-screen-max-content) {
    padding: 20px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: $standard-padding;

    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
    }
  }

  .desc {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 16px;
  }

  .detailWrapper {
    display: flex;
    flex-direction: column;
    @include rowGap(10px);

    .detailCard {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;

      .icon {
        display: flex;
        align-items: center;
        font-size: 22px;
        margin-right: 8px;
      }
    }
  }
}
