@import 'SomeoneHealth/assets/theme';

.container {
  padding-top: 46px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  color: $someone-health-blue;
  text-align: center;

  .message {
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: -0.02em;
  }

  .suggestion {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;

    .link {
      color: $someone-health-pink;
      text-decoration: underline;
    }
  }
}
