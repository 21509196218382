@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .rangeTitle {
    margin-bottom: $standard-padding * 2;
    font-size: 13px;
    font-weight: bold;
  }

  .pizzaChartWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .pieWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(240deg);
      height: 200px;

      .pie {
        transition: all 1s;
        position: absolute;
        width: 200px;
        height: 200px;
        border-radius: 100%;
        clip: rect(0px, 100px, 200px, 0px);
      }

      .pieOuter {
        position: absolute;
        width: 200px;
        height: 200px;
        border-radius: 100%;
        clip: rect(0px, 200px, 200px, 100px);
        transform: rotate(0deg);
      }
    }

    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      background-color: #2a4670;
      border: 1px solid #ffffff;
      border-radius: 50%;
      width: 140px;
      height: 140px;

      .label {
        fill: #ffffff;
      }

      .ofValue {
        font-size: 14px;
        text-align: center;
        color: #ffffff;
      }
    }

    .needle {
      display: flex;
      position: absolute;

      .needleInner {
        position: relative;
        display: flex;
        right: 55px;
        top: 0;
        width: 110px;
        height: 10px;
        border-radius: 20px;
        background-color: #2a4670;
        transform: rotate(0deg);
        transition: all 1s ease-out;
        transform-origin: right;
      }
    }
  }

  .desc {
    position: relative;
    text-align: center;
    top: -20px;
    max-width: 240px;
    font-size: 16px;
    font-weight: 600;

    .bold {
      font-weight: bold;
    }
  }
}
