@import 'CORDS/assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: $desktop-screen-max-content;
  margin: 0 auto;
  width: 100%;
  padding: 0 16px;

  @media (max-width: $tablet-screen-max-content) {
    padding: 0 16px;
  }

  @media (max-width: $mobile-screen-max-content) {
    padding: 0 16px;
  }

  &.fullwidth {
    padding: 0;
    max-width: unset;
  }
}

.content {
  margin: 16px auto;
  height: calc(100vh - 90px);

  &.fullwidth {
    margin: 0;
  }
}
