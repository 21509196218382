@import 'helm/assets/theme';

.container {
  height: max-content;
  min-height: 100vh;
  position: relative;
  z-index: 1;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 3;
  }

  .content {
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 16px;
    padding-bottom: 120px;

    .titleContainer {
      display: flex;
      justify-content: space-between;

      .title {
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        margin-bottom: $standard-padding * 3;
        margin-top: $standard-padding * 4;
      }
      .timeZone {
        display: flex;
        align-items: flex-end;
        margin-bottom: 8px;
        font-size: 16px;
      }
    }
  }
}
