@import 'CaW/assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  color: $caw-blue;
  background-color: $calendarPill;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  min-width: 120px;
  border: 0;

  &.disabled {
    opacity: 0.5;
  }
}

.darkYellowContainer {
  background-color: $caw-light-blue;
}
