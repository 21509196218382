@import 'assets/theme/index';
@import 'SomeoneHealth/assets/theme';
@import 'helm/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .filterTitleWrapper {
    display: flex;
    position: relative;

    @media (max-width: $medium-desktop-screen-max-content) {
      display: none;
    }

    .filterTitle {
      position: absolute;
      top: 6px;
      padding: 0 16px;
      transition: all 0.3s;
      z-index: -1;
    }

    .showTitle {
      top: -24px;
      z-index: 0;
    }
  }

  .selectButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: $white;
    border: 1px solid $white;
    border-radius: 24px;
    color: #414141;
    padding: 8px 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    user-select: none;
    transition: all 0.3s;
    min-height: 48px;

    &.disabled {
      cursor: not-allowed;
    }

    .arrow {
      font-size: 22px;
      margin-left: 8px;
    }

    .selectedPillWrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;

      .selectedPill {
        display: flex;
        align-items: center;
        position: relative;
        background: $someone-health-pink;
        border-radius: 16px;
        padding: 8px 12px;
        color: $white;
        font-size: 12px;
        line-height: 16px;

        .closeBtn {
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: -8px;
          top: -8px;
          width: 20px;
          height: 20px;
          background-color: $someone-health-blue;
          border-radius: 50%;

          &:disabled {
            cursor: not-allowed;
          }

          .closeIcon {
            font-size: 14px;
          }
        }

        .label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100px;
        }
      }

      .plusLabel {
        display: flex;
        align-items: center;
        text-decoration-line: underline;
        color: $someone-health-pink;
      }
    }
  }

  .openMenu {
    border: 1px solid #7b61ff;
  }

  .menuWrapper {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    z-index: 1;

    .menuBoxHide {
      position: absolute;
      width: 100%;
      left: 0;
      background: #fff;
      box-shadow: 0 48px 88px -4px rgba(23, 9, 54, 0.06);
      margin-top: 4px;
      border-radius: 8px;
      visibility: hidden;
      opacity: 0;
      padding: 8px;
      transition: all 0.3s;
      transform: scaleY(0);
      transform-origin: top;

      .listBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
        color: $someone-health-grey800;
        transition: all 0.2s ease;
        cursor: pointer;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;

        &:hover {
          color: #160042;
          background-color: #f3f5fb;
        }

        .rightArrow {
          font-size: 16px;
          line-height: 24px;
          color: $someone-health-pink;
        }
      }

      .emptyList {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
        color: $someone-health-grey800;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }

      .label {
        user-select: none;
      }

      .hideMenu {
        display: none;
        flex-direction: column;

        .backButton {
          display: flex;
          align-items: center;
          padding: 12px;
          color: $someone-health-pink;
          cursor: pointer;

          .leftArrow {
            font-size: 16px;
            line-height: 24px;
            margin-right: 4px;
          }
        }

        .otherList {
          max-height: 384px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 10px;
          }

          &::-webkit-scrollbar-track {
            background-color: #f0eeee;
            border: 3px solid transparent;
            border-radius: 5px;
            background-clip: content-box;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: #fe9bb4;
          }
        }
      }

      .displayMenu {
        @extend .hideMenu;
        display: flex;
        max-height: 384px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background-color: #f0eeee;
          border: 3px solid transparent;
          border-radius: 5px;
          background-clip: content-box;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background-color: #fe9bb4;
        }
      }
    }

    .menuBoxShow {
      @extend .menuBoxHide;
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    }

    .searchWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .searchIcon {
        position: absolute;
        left: 18px;
        color: $someone-health-pink;
        font-size: 24px;
      }

      .searchInput {
        width: 100%;
        border: 1px solid #e4e6f1;
        border-radius: 4px;
        display: flex;
        flex: 1;
        height: 48px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        padding: 16px 16px 16px 40px;
        color: #160042;
      }
    }
  }
}

:global(.recharge-theme) {
  .container {
    .selectButton .selectedPillWrapper {
      font-weight: 600;

      .selectedPill {
        background: $recharge-purple;

        .closeBtn {
          background-color: $recharge-purple;
        }
      }

      .plusLabel {
        color: $recharge-purple;
      }
    }

    .menuWrapper {
      .menuBoxHide {
        .hideMenu .backButton {
          color: $recharge-purple;
        }

        .listBox {
          color: $recharge-black;

          .rightArrow {
            color: $recharge-purple;
          }
        }
      }

      .searchWrapper .searchIcon {
        color: $recharge-purple;
      }

      .emptyList {
        color: $recharge-black;
      }
    }
  }
}

:global(.select-theme) {
  .container {
    .filterTitleWrapper {
      .filterTitle {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        text-shadow: 0 0 8px $black;
      }

      .showTitle {
        top: -32px;
      }
    }

    .selectButton {
      border-radius: 0;

      .selectedPillWrapper {
        font-weight: 600;

        .selectedPill {
          background: $select-red;
          color: $select-black;

          .closeBtn {
            background-color: $select-red;
            color: $white;
          }
        }

        .plusLabel {
          color: $select-red;
        }
      }
    }

    .menuWrapper {
      .menuBoxHide {
        border-radius: 0;
        max-height: 600px;
        overflow: auto;

        .hideMenu .backButton {
          color: $select-red;
        }

        .listBox {
          border-radius: 0;
          color: $select-black;

          .rightArrow {
            color: $select-red;
          }
        }
      }

      .searchWrapper .searchIcon {
        color: $select-red;
      }

      .emptyList {
        color: $select-black;
      }
    }
  }
}
