@import 'assets/theme';
@import 'helm/assets/theme';

.container {
  width: $a4-print-width;
  color: white;
  height: $a4-print-height;
  display: flex;
  font-size: 1.5rem;
  padding: 22 * $smp-standard-padding;
  font-family: $smp-font-family;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-color: $smp-light-red;
  background-position: center;

  .date {
    text-transform: uppercase;
  }

  .title {
    font-size: 5rem;
    max-width: 524px;
    margin-top: 16 * $smp-standard-padding;
    line-height: 1.1;
  }

  .bottomSection {
    position: relative;

    .subtitle {
      max-width: 320px;
    }

    .createdByContainer {
      margin-top: 14 * $smp-standard-padding;
    }

    .value {
      color: $smp-yellow;
      font-weight: bold;
    }

    .logo {
      right: -40px;
      bottom: 0;
      position: absolute;
    }
  }
}

:global(.helm-theme) {
  .container {
    background-color: $helm-red;
  }

  .date,
  .bottomSection {
    font-family: $primary-font-stack-medium;
  }

  .title {
    font-family: $title-font-stack;
    font-weight: normal;
  }

  .bottomSection .value {
    color: $helm-yellow;
  }
}
