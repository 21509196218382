.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 8px 12px;
  border: 0.5px solid transparent;
  border-radius: 4px;
  height: 100%;
  overflow: hidden;

  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #5666a6;
  }
}
