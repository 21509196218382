@import 'CORDS/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  background: $cords-lightest-yellow-20;

  input,
  textarea,
  button {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, sans-serif;
  }
}
