@import 'SomeoneHealth/assets/theme';

.container {
  @include s1hLoginBackground;
}

.contentWrapper {
  margin-top: 60px;
  padding-bottom: 110px;
  max-width: 1180px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $someone-health-maroon;
  padding: 32px 16px 120px;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contentBox {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    max-width: 530px;

    .taskListContainer {
      display: flex;
      flex-direction: column;

      .taskListTitle {
        font-size: 18px;
        line-height: 27px;
        font-weight: $font-weight-bold;
        color: $someone-health-maroon;
        padding-bottom: 16px;
        display: flex;
        flex-direction: column;
        @include rowGap(20px);

        @media (max-height: $tablet-screen-max-content) {
          font-size: 16px;
        }
      }

      .title {
        font-size: 36px;
        font-weight: $font-weight-bolder;
        line-height: 42px;
        letter-spacing: -0.02em;
      }

      .desc {
        font-size: 24px;
        line-height: 42px;
        letter-spacing: -0.02em;
        font-weight: 500;
      }

      .taskListBox {
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        @media (max-height: $tablet-screen-max-content) {
          row-gap: 16px;
        }
      }
    }
  }
}
