@import 'Recharge/assets/theme';

$slotChangeViewTablet: $small-desktop-screen-max-content + 220px;
$scrollingSlotView: $mobile-screen-max-content + 100px;

.bookingContainer {
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #fcbf49 0%, $recharge-light-blue 0.01%, $recharge-purple 106.32%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 24px 0;

  .blocked {
    background: $white;
    border: 2px solid $recharge-purple;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: $recharge-purple;
    padding: $standard-padding;
    border-radius: 4px;
    max-width: max-content;
    margin-bottom: 16px;
  }

  .bookingContent {
    display: flex;
    gap: 48px;
    align-self: normal;

    @media (max-width: $small-desktop-screen-max-content) {
      display: block;
    }

    @media (max-width: $scrollingSlotView) {
      display: block;
    }

    .leftContent {
      display: flex;
      width: 100%;

      @media (max-width: $small-desktop-screen-max-content) {
        flex-direction: column;
        gap: 16px;
        padding: 0;
      }
    }

    .importantInfoContainer {
      margin-top: 20px;
      font-weight: 600;

      @media (max-width: $tablet-screen-max-content) {
        margin-top: 12px;
        font-size: 12px;
      }

      .link {
        font-weight: 500;
        color: $recharge-black;
        text-decoration: underline;
      }
    }

    .bookingDesc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 20px;
      color: $recharge-black;

      .bookingTitle {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;

        @media (max-width: $small-desktop-screen-max-content) {
          font-size: 20px;
          line-height: 24px;
        }
      }

      .bookingDescSummary {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        margin-top: 10px;

        @media (max-width: $small-desktop-screen-max-content) {
          font-size: 16px;
          line-height: 20px;
          margin-top: 4px;
        }
      }
    }

    .slotContainer {
      display: flex;
      flex-direction: column;
      flex: 1;

      @media (max-width: $scrollingSlotView) {
        width: auto;
      }

      .middleContent {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        @media (max-width: $tablet-screen-max-content) {
          flex-direction: column;
          gap: 16px;
          justify-content: flex-start;
          align-items: center;
        }
      }

      .slotWrapper {
        display: flex;
        max-width: 100%;
        align-items: center;
        row-gap: 16px;
        @include columnGap(16px);
      }

      .slotLabelMobile {
        color: $white;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        padding-left: 40px;
        padding-top: 4px;
        display: none;

        @media (max-width: $slotChangeViewTablet) {
          display: block;
          padding-left: 0;
          font-size: 10px;
          line-height: 13px;
        }
      }
    }

    .continueBtnWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .continueBtn {
        min-width: 250px;

        @media (max-width: $tablet-screen-max-content) {
          min-width: 90vw;
        }
      }

      @media (max-width: $small-desktop-screen-max-content) {
        margin-top: 16px;
      }

      .continueLabel {
        width: 80%;
        padding-top: 4px;
        color: $recharge-black;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;

        @media (max-width: $tablet-screen-max-content) {
          min-width: 70%;
        }
      }
    }
  }

  .consultPreferenceWrapper {
    margin-left: 10px;
    min-width: 160px;

    @media (max-width: $small-desktop-screen-max-content) {
      display: flex;
      margin-left: 140px;

      @media (max-width: $tablet-screen-max-content) {
        margin-left: 0;
      }
    }
  }

  .consultPreference {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $recharge-black;
    justify-content: center;
    text-align: center;
    gap: 4px;

    .consultPreferenceLabel {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
    }

    .consultPreferenceButton {
      width: fit-content;
      padding: 0 5px;
      background: $white;
      border: 1.1383px solid $recharge-lighter-blue;

      .indicator {
        box-shadow: unset;
        border: 1.87719px solid $recharge-purple;
        background-color: $recharge-purple;
      }

      .label {
        padding: 0 !important;
        color: $recharge-light-purple !important;
      }

      .labelActive {
        padding: 0 !important;
        color: $white !important;
      }
    }

    .optionButton1 {
      min-width: 50px;
    }

    .optionButton2 {
      min-width: 100px;
    }

    .optionButton3 {
      min-width: 150px;
    }

    .selectedPreference {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 16px;
      }
    }
  }
}
