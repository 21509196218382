@import 'helm/assets/theme';
@import 'assets/theme';

$maxContentWidth: 1120px;

.container {
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
  color: $grey700;
  font-family: 'Nunito Sans';

  .inputLabel {
    color: $colorGrey800;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }
  .inputLabelError {
    color: red !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }

  .customInput {
    transform: translateX(-$standard-padding);
    padding: 0 $standard-padding;
    color: #343434;
    margin-bottom: $standard-padding !important;
  }

  .inputError {
    transform: translateX(-$standard-padding);
    padding: 0 $standard-padding;
    border-color: red;
    margin-bottom: $standard-padding !important;
  }

  .showPasswordButtonClass {
    &:hover {
      color: $colorBlue1 !important;
      background-color: transparent !important;
    }
  }

  .customSelect {
    padding: $standard-padding * 2 0;
  }

  .selectInputLabel {
    @extend .inputLabel;
    margin-bottom: 0;
    font-size: 12px;

    &:focus {
      color: $colorBlue1 !important;
    }
  }

  .customLabel {
    padding-left: 16px;
    color: #202225;
  }

  .header {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 16px;
    display: block;
    @include rowGap(16px);

    .highlight {
      color: $helm-blue;
    }
  }

  .subHeader {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 6px;
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: $grey700;
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: $grey600;
    font-family: 'Nunito', normal;
  }

  .backgroundSection {
    display: flex;
    flex-direction: column;
    padding: 20px 0;

    .label {
      padding: 12px 0 4px 12px;
      font-family: 'Roboto', sans-serif;
    }

    .textAreaField {
      width: 100%;
      height: 100px;
      border-radius: 4px;
      padding: 12px;
      border: 1px solid #dadbde;
      color: #000000;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .fundingSection {
    .label {
      padding: 9px 0 17px;
    }
  }

  .communicationPreferences .checkBoxes {
    padding: 0 $standard-padding * 4;

    .checkBox {
      vertical-align: top;

      input {
        outline: none;
        transform: scale(0.75);
        &:checked::after {
          display: inline-flex;
        }
      }
      margin-right: $standard-padding * 3;
    }

    .checkBoxText {
      height: fit-content;
      display: inline-block;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-top: $standard-padding;
      margin-bottom: $standard-padding * 3;

      a {
        text-decoration: underline;
        color: $colorBlue1;
      }
    }
  }

  .communicationPreferences {
    padding-top: $standard-padding * 5;
    font-weight: 400;
    font-size: 14px;
    .label {
      line-height: 21px;
    }

    .checkBoxes {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }
  }

  .submitButton {
    width: 100%;
    margin-top: $standard-padding;
    margin-bottom: $standard-padding * 3;

    @media (max-width: $mobile-screen-max-content) {
      margin-bottom: $standard-padding * 2;
    }
  }
  .noTreatmentPlan {
    padding: 24px 0;
    background-color: $helm-light-yellow;

    .noGPDesc {
      max-width: $maxContentWidth;
      margin: 0 auto;
    }
  }
  input {
    &:focus {
      + {
        label {
          color: $colorBlue1 !important;
        }
      }
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}
