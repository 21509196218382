@import 'SomeoneHealth/assets/theme';
@import '../../assets/theme/index';

.container {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    height: 30px;
    width: 100%;
    background-color: $colorBlue3;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 60px);

    .loadingWrapper {
      height: 100vh;
    }
  }

  .footer {
    height: 30px;
    width: 100%;
    background-color: $colorBlue3;
    align-items: center;
    display: flex;
    padding-left: 8px;

    .logo {
      width: 46px;
    }

    span {
      font-size: 10px;
      color: white;
      margin-left: 8px;

      a {
        color: white;
        text-decoration: underline;
      }
    }
  }
}

:global(.s1h-client-portal-theme) {
  .header {
    display: none;
  }
  .content {
    min-height: calc(100vh - 42px);
  }
}
