@import 'assets/theme/index';
@import 'SomeoneHealth/assets/theme';
@import 'helm/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';

.label {
  user-select: none;

  .matchedValue {
    text-decoration: underline;
  }
}

.listBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  color: $someone-health-grey800;
  transition: all 0.2s ease;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  &:hover:not(.disabled) {
    color: #160042;
    background-color: #f3f5fb;
  }

  &.disabled {
    cursor: not-allowed;
  }

  .rightArrow {
    font-size: 16px;
    line-height: 24px;
    color: $someone-health-pink;
  }
}

.selectedListBox {
  @extend .listBox;
  color: #160042;
  background-color: #f3f5fb;
}

:global(.select-theme) {
  .listBox {
    color: $select-black;
    border-radius: 0;

    &:hover:not(.disabled) {
      color: $select-black;
      background-color: $select-light-red;
    }
  }

  .selectedListBox {
    color: $select-black;
    background-color: $select-red;
  }
}
