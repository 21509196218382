@import 'assets/theme/index';

.container {
  display: flex;
  flex-direction: column;
  background-color: #e6ebef;
  height: 100%;
}

.summaryDetailWrapper {
  padding: 16px 8px 4px;
}

.btnWrapper {
  position: relative;
  display: flex;
  justify-content: center;

  .btnBox {
    margin: 4px;
    min-width: 150px;

    button {
      min-height: 52px;
      
      &:disabled {
        background-color: #3f52fb55;
        border: none;
      }
    }
  }

  .loadingWrapper {
    padding-top: $standard-padding * 0.9;
    display: flex;
    justify-content: center;

    div > div {
      background-color: white;
    }
  }
}
