@import 'assets/theme';

.container {
  width: 816px;
  height: 1153px;
  position: relative;
  font-family: $smp-font-family;
  background-color: white;

  @media only screen {
    margin-top: $smp-standard-padding * 4;
  }
}
