$Poppins: 'Poppins';

// Font size
$font-extra-tiny: 10px;
$font-tiny: 12px;
$font-small: 14px;
$font-standard: 16px;
$font-medium-large: 18px;
$font-large: 24px;
$font-extra-large: 30px;
$font-extreme-large: 60px;

// Font weight
$font-weight-light: 200;
$font-weight-regular: 300;
$font-weight-bold: 400;
$font-weight-bolder: 600;
$font-weight-boldest: 800;

// Font family
$font: 'Roboto';

@font-face {
  font-family: $Poppins;
  src: url(~SomeoneHealth/assets/theme/typography/Poppins-Light.woff2) format('woff2'),
    url(~SomeoneHealth/assets/theme/typography/Poppins-Light.woff) format('woff');
  font-weight: 300;
}

@font-face {
  font-family: $Poppins;
  src: url(~SomeoneHealth/assets/theme/typography/Poppins-Regular.woff2) format('woff2'),
    url(~SomeoneHealth/assets/theme/typography/Poppins-Regular.woff) format('woff');
  font-weight: 400;
}

@font-face {
  font-family: $Poppins;
  src: url(~SomeoneHealth/assets/theme/typography/Poppins-Medium.woff2) format('woff2'),
    url(~SomeoneHealth/assets/theme/typography/Poppins-Medium.woff) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: $Poppins;
  src: url(~SomeoneHealth/assets/theme/typography/Poppins-SemiBold.woff2) format('woff2'),
    url(~SomeoneHealth/assets/theme/typography/Poppins-SemiBold.woff) format('woff');
  font-weight: 600;
}

@font-face {
  font-family: $Poppins;
  src: url(~SomeoneHealth/assets/theme/typography/Poppins-Bold.woff2) format('woff2'),
    url(~SomeoneHealth/assets/theme/typography/Poppins-Bold.woff) format('woff');
  font-weight: 700;
}

@font-face {
  font-family: $Poppins;
  src: url(~SomeoneHealth/assets/theme/typography/Poppins-ExtraBold.woff2) format('woff2'),
    url(~SomeoneHealth/assets/theme/typography/Poppins-ExtraBold.woff) format('woff');
  font-weight: 800;
}

@font-face {
  font-family: $Poppins;
  src: url(~SomeoneHealth/assets/theme/typography/Poppins-Black.woff2) format('woff2'),
    url(~SomeoneHealth/assets/theme/typography/Poppins-Black.woff) format('woff');
  font-weight: 900;
}
