@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;

  .borderFrame {
    background: linear-gradient(180deg, #db3c98 30.48%, #30208b 99.77%);
    padding: 12px;
    border-radius: 50%;
  }

  .image {
    width: 100%;
    border-radius: 50%;
  }
}

:global(.s1h-client-portal-theme) {
  .container .borderFrame {
    background: none;
  }
}
