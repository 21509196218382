@import 'assets/theme';

.WelcomeMessage {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 8px;

  @media (max-height: $medium-screen-min-height) {
    font-size: 18px;
  }
}
