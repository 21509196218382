@import 'assets/theme';

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .header {
    display: flex;
  }

  .customLogoImage {
    max-height: 80px;
  }

  .logoImage {
    width: 150px;

    @media (max-width: $medium-screen-min-width) {
      width: 115px;
    }
  }
}
