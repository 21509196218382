@import 'MumsMatter/assets/theme';

.container {
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 82px);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('~MumsMatter/assets/images/background/background.png');
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.8;
    z-index: -1;
  }
}

.contentLayoutWrapper {
  padding-top: 24px;
  padding-bottom: 24px;
  max-width: 1180px;
  gap: 20px;
  color: $mums-matter-dark-blue;
}

.welcomeMessage {
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: $mums-matter-dark-blue;

  @media (max-width: $small-screen-max-width) {
    font-size: 24px;
    line-height: 36px;
  }
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 99;
}
