@import 'assets/theme';

.contentWrapper {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 0 16px;
  background-color: #2a4670;

  .content {
    display: flex;
    flex-direction: column;
    color: #ffffff;

    .heading {
      font-size: 24px;
      line-height: 28px;
      padding: 24px 0;
    }

    .text {
      font-size: 18px;
      line-height: 28px;
    }

    .date {
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      margin: 24px 0;
    }

    .deliveryTypeInformation {
      font-weight: bold;
      font-size: 16px;
      line-height: 28px;
      margin-top: -12px;
      margin-bottom: 24px;
    }

    .suggestionList {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 16px;
      cursor: pointer;

      &:first-child {
        margin-top: 16px;
      }
    }

    .suggestionIcon {
      border: 1px solid #fff;
      border-radius: 50%;
      padding: 4px;
      margin-left: 16px;
    }

    .btnBox {
      width: 240px;
    }

    .atcButtonWrapper {
      margin: 16px 0;
    }

    .atcClosed {
      display: flex;
      align-items: center;
      width: max-content;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      color: #ffffff;
      outline: none;
    }

    .atcOpened {
      filter: opacity(0.7);
    }

    .atcDropdown {
      ul {
        margin-top: $standard-padding;
        list-style: none;

        li {
          a {
            font-size: 16px;
            color: #ffffff !important;
          }
          margin-top: $standard-padding * 2;
          font-weight: 700;
          font-style: italic;
          text-decoration: underline;
        }
      }
    }
  }
}

.floatCard {
  padding: 16px !important;
  min-height: 80px;

  .confirmMessage {
    display: flex;
    justify-content: center;
    align-items: center;

    .tickIcon {
      border: 1px solid #4abd03;
      color: #4abd03;
      padding: 4px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
}
