@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex: 1;
  position: relative;
  padding-top: 16px;
}

.noLabel {
  padding-top: 0 !important;
}

.textArea:focus,
.textArea:not(:focus):valid {
  ~ .label {
    top: 0;
    font-size: 11px;
    color: #818e9b;
    opacity: 1;
    font-weight: 600;
  }
}

.textArea:focus {
  color: #192a3e;
  border-color: #818e9b;

  ~ .label {
    color: #3f52ff;
  }
}

.textArea {
  display: flex;
  width: 100%;
  color: #000;
  background-color: transparent;
  font-size: 16px;
  line-height: 24px;
  overflow-wrap: break-word;
  resize: none;
  padding: 0 0 4px;
  border-style: none;
  border-bottom: 1px solid #d3d8dd;
  outline: none;
  overflow: hidden;
  transition: box-shadow 0.1s ease-out 0s;
  -webkit-appearance: none;
  border-radius: 0;

  &.error {
    border-bottom: 1px solid #ff1900;
    margin-bottom: 0;

    ~ .label {
      color: #ff1900;
    }
  }
}

.label {
  position: absolute;
  pointer-events: none;
  font-size: 16px;
  top: 18px;
  color: #818e9b;
  font-weight: 600;
  transition: 0.2s ease all;
}

:global(.ease-theme) {
  .textArea:focus ~ .label {
    color: $ease-dark-grey;
  }
}

:global(.recharge-theme) {
  .textArea:focus ~ .label {
    color: $recharge-black;
  }
}

:global(.select-theme) {
  .textArea:focus ~ .label {
    color: $select-black;
  }
}

:global(.s1h-client-portal-theme) {
  .textArea,
  .textArea:focus ~ .label {
    color: $someone-health-maroon;
  }
}
