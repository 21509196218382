@import 'assets/theme';

.container {
  height: max-content;
  min-height: 100vh;
  position: relative;
  z-index: 1;

  .contentWrapper {
    padding-bottom: 40px;
  }
}
