@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  max-width: 420px;
  min-width: 420px;
  background: $helm-yellow;
  border-radius: 20px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (max-width: $tablet-screen-max-content) {
    max-width: 330px;
    min-width: 330px;
  }

  .image {
    padding: 40px;
    height: 300px;
    background-color: $backgroundYellow;

    @media (min-width: $medium-small-screen-min-width) {
      height: 420px;
    }
  }

  .programWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
    padding: 0 20px 12px;
  }

  .title {
    font-family: $title-font-stack;
    font-size: 12px;
    color: $helm-purple;
  }

  .sessionDesc {
    font-family: $primary-font-stack-medium;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $helm-blue;
  }

  .chapterTitle {
    font-family: $title-font-stack;
    font-size: 28px;
    color: $helm-blue;

    @media (min-width: $medium-small-screen-min-width) {
      font-size: 32px;
    }
  }

  .chapterDesc {
    font-family: $primary-font-stack-medium;
    font-weight: 600;
    font-size: 14px;
    color: $helm-blue;

    @media (min-width: $medium-small-screen-min-width) {
      font-size: 16px;
    }
  }

  .connectWrapper {
    margin-top: 16px;
    padding: 0 20px 20px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 16px 16px;

    .moduleButtonWrapper {
      padding-top: 20px;

      .moduleButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 18px;
        font-family: $title-font-stack;
        font-size: 16px;
        font-weight: normal;
        line-height: 19px;
        border-radius: 20px;
        box-shadow: none;
      }
    }
  }
}

.active {
  border: 4px solid $helm-purple;
}

.completed {
  background: #66cb91;
}

.container[data-chapter='3'],
.container[data-chapter='6'] {
  .image {
    background: $helm-red;
  }
}
