@import 'Recharge/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  color: $recharge-black;

  .label {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }

  .bonaFideDetails {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    @include rowGap($standard-padding * 2);

    .details {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 23px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 14px;
        line-height: 18px;
      }

      .icon {
        font-size: 20px;
        padding: $standard-padding;
        background-color: $badge-background-color;
        border-radius: 50%;
        margin-right: 8px;

        @media (max-width: $tablet-screen-max-content) {
          font-size: 18px;
        }
      }
    }
  }
}
