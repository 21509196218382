@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'SomeoneHealth/assets/theme';
@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: $standard-padding * 2;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 1;

  @media (max-width: $medium-screen-min-width) {
    padding: 2px 0;
  }
}

.top {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  .button {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-style: normal;
    font-weight: bold;
    color: #ffffff;
    background-color: transparent;

    &:active {
      opacity: 0.8;
    }
  }

  .icon {
    font-size: 18px;
    margin-right: $standard-padding * 0.5;
  }

  .profilePic {
    margin-left: $standard-padding * 0.5;
    height: 48px;
    width: 48px;
    border-radius: 50%;
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  margin-top: $standard-padding * 6;
  padding: 0 $standard-padding * 4;

  .progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    font-size: 11px;
    color: $cta;
  }

  .button {
    display: flex;
    align-items: center;
    padding: 0;
    font-size: 11px;
    font-style: normal;
    font-weight: bold;
    color: #34343480;
    background-color: transparent;

    &:active {
      opacity: 0.8;
    }
  }

  .icon {
    font-size: 18px;
    margin-left: $standard-padding * 0.5;
  }

  @media (max-width: $medium-screen-min-width) {
    padding: 0 $standard-padding * 2;
    margin-top: 12px;
  }
}

:global(.ease-theme) {
  .top .button {
    color: $blue500;
  }

  .bottom .progress {
    color: $ease-dark-grey;
  }
}

:global(.recharge-theme) {
  .bottom .progress {
    color: $recharge-purple;
  }
}

:global(.select-theme) {
  .top .button,
  .bottom .progress {
    color: $select-black;
  }
}

:global(.s1h-client-portal-theme) {
  .container {
    @media (max-width: $medium-screen-min-width) {
      padding: $standard-padding * 2;
    }
  }

  .top .button,
  .bottom .progress {
    color: $someone-health-maroon;
  }
}
