@import 'assets/theme';
@import 'CaW/assets/theme';

.container {
  background-color: $caw-lightest-blue;
  position: fixed;
  height: 100vh;
  width: 100vw;
  margin-bottom: 0;
  overflow: auto;

  .content {
    padding: 50px 0 30px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 118px);
    max-width: 760px;
    margin: 0 auto;

    .title {
      @include playfairDisplayFont;
      display: flex;
      align-items: flex-start;
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -0.02em;
      color: $colorGrey700;
      padding-left: 36px;

      @media (max-width: $medium-large_screen-min-width) {
        padding-left: 0;
      }
    }

    .welcome {
      display: flex;
      margin-top: 10px;
      background: #ffffff;
      border-radius: 8px;
      padding: 24px 36px;
      position: relative;
      color: $colorGrey700;

      @media (max-width: $medium-large_screen-min-width) {
        padding: 20px 16px;
      }

      .welcomeTitle {
        @include playfairDisplayFont;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02em;
      }

      .message {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02em;
        padding: 12px 0 24px;

        @media (max-width: $medium-large_screen-min-width) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .messageEnding {
        @include playfairDisplayFont;
        margin-top: $standard-padding * 6;
        font-size: 24px;
        line-height: 36px;

        @media (max-width: $tablet-screen-max-content) {
          font-size: 18px;
        }

        .clinicianName {
          @include playfairDisplayFont;
          font-weight: bold;
        }
      }

      .avatar {
        position: absolute;
        top: -140px;
        right: -110px;

        @media (max-width: $medium-large_screen-min-width) {
          display: none;
        }

        img {
          height: 220px;
          min-width: 220px;
          border-radius: 50%;
        }
      }
    }

    .whatNext {
      padding: 24px 36px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 60px;
      display: flex;
      flex-direction: column;

      @media (max-width: $medium-large_screen-min-width) {
        margin-top: 28px;
        padding: 20px 16px;
      }

      .label {
        @include playfairDisplayFont;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02em;
        margin-bottom: 30px;
      }

      .radioLabel {
        font-size: 18px;
        line-height: 23px;
        color: $colorGrey700;
        margin-bottom: 24px;
      }

      .tasks {
        margin-top: 12px;
        margin-bottom: 24px;

        ul {
          padding: 0;

          li {
            font-weight: 400;
            font-size: 14px;
            display: flex;
            align-items: flex-start;
            list-style: none;
            list-style-position: inside;
            margin: $standard-padding * 2 0;

            .circle {
              vertical-align: top;
              display: inline-block;
              width: 20px;
              height: 20px;
              border: solid 2px;
              border-radius: 10px;
              margin-top: 1px;
              margin-right: $standard-padding * 3;
            }

            .liText {
              flex: 1;
              font-weight: 700;
              font-size: 18px;
              line-height: 23px;
              color: #22348c;
            }
          }
        }
      }

      .goToMyAccountBtn {
        width: 358px;

        @media (max-width: ($medium-large_screen-min-width - 1)) {
          width: 100%;
          max-width: 358px;
        }
      }
    }
  }
}
