@import 'assets/theme';
@import 'SomeoneHealth/assets/theme';
@import 'CaW/assets/theme';
@import 'Recharge/assets/theme';
@import 'MumsMatter/assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #2a4670;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.container {
  height: 100vh;
  overflow-y: scroll;
  background-color: #2a4670;
}

.someone_health {
  @include s1hLoginBackground;
}

.caw {
  background-color: $caw-blue;
}

.ease {
  background-color: transparent;
}

.recharge {
  background-color: transparent;
}

.select {
  background-color: transparent;
}

.cords {
  &.loading {
    background-color: transparent;
  }

  &.container {
    background-color: transparent;
    height: unset;
    max-height: unset;
    overflow-y: auto;
  }
}

.portobello {
  background-color: transparent;
}

.mums_matter {
  background-color: $mums-matter-light-green;
}

.gatewayContainer {
  height: 100%;
  min-height: 100vh;
  background-color: #2a4670;
  position: relative;
  z-index: 1;

  &::after {
    position: absolute;
    content: ' ';
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    height: 100%;
    min-height: 100vh;
    opacity: 0.8;
    background-color: #2a4670;
  }
}

.shGatewayContainer {
  @include s1hLoginBackground;
}

.content {
  position: relative;

  @media (max-width: $medium-screen-min-width) {
    padding: 0;
  }
}

.cawGatewayContainer {
  @extend .gatewayContainer;
  @include loginBackground;

  &::after {
    background-color: transparent;
  }
}

.easeGatewayContainer {
  @extend .gatewayContainer;
  background-color: transparent;

  &::after {
    background-color: transparent;
  }
}

.rechargeContainer {
  @extend .gatewayContainer;
  background-color: transparent;

  &::after {
    background-color: transparent;
  }
}

.selectContainer {
  @extend .gatewayContainer;
  background-color: transparent;

  &::after {
    background-color: transparent;
  }
}

.portobelloContainer {
  @extend .gatewayContainer;
  background-color: transparent;
  min-height: 100vh;
  background-position: right 82px;
  background-size: cover;
  background-image: url('~Portobello/assets/images/background/background.png');
  background-repeat: no-repeat;
  font-family: $Playfair-Display;

  &::after {
    background-color: transparent;
  }
}

.mumsMatterContainer {
  @extend .gatewayContainer;
  background-color: transparent;
  min-height: 100vh;
  background-position: right 82px;
  background-size: cover;
  background-image: url('~MumsMatter/assets/images/background/background.png');
  background-repeat: no-repeat;

  &::after {
    background-color: transparent;
  }
}

:global(.mums-matter-theme).container {
  background-image: url('~MumsMatter/assets/images/background/background.png');
}
