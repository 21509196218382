@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  .appointmentTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #00109f;
  }

  .connectWrapper {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    padding-top: 8px;

    .appointmentLink {
      color: #3f52ff;
      word-break: break-all;
    }
  }
}
