@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  padding: 16px;
  @include rowGap(24px);
}

.title {
  font-weight: 600;
  font-size: 28px;
  color: $white;
}

.content {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 8px;
  padding: 32px 24px 80px 24px;
  @include rowGap(24px);

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: 600;
      font-size: 24px;
      color: #2a4670;
    }
  }
  .graphs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 32px;
    column-gap: 24px;

    @media screen and (max-width: $tablet-screen-max-content) {
      flex-direction: column;
      flex-wrap: nowrap;
    }

    .graphContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      background: $white;
      box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 24px;
      min-width: 420px;

      @media screen and (max-width: $tablet-screen-max-content) {
        min-width: 300px;
        flex-wrap: inherit;
      }
    }
  }

  .divider {
    margin: 32px 0px;
    border-top: 1px solid #d9d9d9;
  }
}
