@import 'helm/assets/theme';

.container {
  color: $helm-blue;

  .label {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    padding-bottom: $standard-padding * 2;
  }

  .calendarListWrapper {
    display: flex;
    flex-wrap: wrap;
    @include columnGap(8px);

    .calendarContent {
      display: flex;
      flex-wrap: wrap;

      @media (max-width: $tablet-screen-max-content) {
        flex-wrap: nowrap;
        margin: 4px -16px 0 0;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      @media (max-width: $mobile-screen-max-content) {
        margin: 4px -8px 0 0;
      }

      .noAvailableWrapperEven {
        display: flex;
        flex-direction: column;
        background: $white;
        border-radius: 12px;
        margin-right: 8px;
        padding: 24px;

        .noAvailableCard {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          row-gap: 20px;
          justify-content: space-between;

          .messageWrapper {
            display: flex;
            max-width: 500px;

            .redDotWrapper {
              padding-top: 10px;
            }
          }

          .redDot {
            border-radius: 50%;
            background-color: $helm-red;
            width: 13px;
            height: 13px;
            margin-right: 16px;
          }

          .message {
            display: flex;
            flex: 1;
            flex-direction: column;

            .desc {
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;
              color: $helm-blue;
            }

            .noAvailDesc {
              display: flex;
              flex-wrap: wrap;
              font-weight: 500;
              font-size: 16px;
              line-height: 21px;
              color: $helm-blue;
              margin-right: 16px;
              white-space: break-spaces;
            }
          }

          .buttonWrapper {
            white-space: break-spaces;
            @media (max-width: $mobile-screen-max-content) {
              display: flex;
              width: 100%;
              justify-content: center;
            }
          }
        }
      }

      .noAvailableWrapperOdd {
        @extend .noAvailableWrapperEven;
        background: $white;
      }
    }

    .calendarCardWrapper {
      display: flex;

      @media (min-width: $tablet-screen-max-content) {
        flex-wrap: wrap;
      }
    }

    .calendarCard {
      display: flex;
      flex: 1;
      justify-content: center;
      min-width: 230px;
      background: $white;
      border-radius: 12px;
      padding: $standard-padding * 4 $standard-padding * 5;
      margin-right: 8px;
      margin-bottom: 8px;

      .cardContent {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      .timeslotWrapper {
        display: flex;
        flex-direction: column;
        @include rowGap($standard-padding * 3);
        padding-top: $standard-padding * 6;

        .timeslot {
          line-height: 12px;
        }

        .seeMoreSlot {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          line-height: 18px;
          text-decoration-line: underline;
          cursor: pointer;
          color: $helm-blue;
        }
      }
    }
  }

  .noAvailableWrapper {
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: 12px;
    margin-right: 8px;
    padding: 24px;

    .noAvailableCard {
      display: flex;
      align-items: center;

      .calendarBadge {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-image: url(~helm/assets/images/calendar/calendarShape.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 58px;
        padding: 21px 0 6px 0;

        .closeIcon {
          color: $helm-red;
          font-size: 30px;
        }
      }

      .desc {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: $helm-blue;
        padding-left: $standard-padding * 3;
      }
    }

    .noAvailDesc {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: $helm-blue;
      padding-top: $standard-padding * 6;
    }
  }

  .nextAvailabilityCard {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    min-width: 230px;
    background-color: #{$white}20;
    padding: $standard-padding * 8;

    @media (max-width: $tablet-screen-max-content) {
      display: none;
    }

    .nextAvailabilityLabel {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: 16px;
      line-height: 21px;
      color: $helm-red;
    }

    .nextIcon {
      border: 1px solid;
      border-radius: 50%;
      padding: $standard-padding;
      margin-left: 8px;
      color: $helm-blue;
    }
  }

  .mobileNextAvailabilityCard {
    @extend .nextAvailabilityCard;
    display: none;

    @media (max-width: $tablet-screen-max-content) {
      display: flex;
      flex: 1;
      justify-content: center;

      .nextAvailabilityLabel {
        flex-direction: row;
      }
    }
  }
}
