@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $white;
  border-radius: 16px;
  padding: 16px;
  max-width: 420px;
  min-width: 420px;
  margin-bottom: 16px;

  @media (max-width: $tablet-screen-max-content) {
    max-width: 330px;
    min-width: 330px;
  }

  .programWrapper {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #00109f;

    @media (max-width: $tablet-screen-max-content) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .sessionDesc {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $grey700;

    @media (max-width: $tablet-screen-max-content) {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .imageWrapper {
    margin: 8px 0;

    .image {
      width: 100%;
    }
  }

  .chaptersBox {
    display: flex;
    flex-direction: column;

    .chapterTitle {
      display: flex;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .chapterDesc {
      display: flex;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  .connectWrapper {
    margin: 16px -16px 0;
    background: $blue50;
    padding: 0 16px 16px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 16px 16px;

    .moduleButtonWrapper {
      padding-top: 20px;

      .moduleButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 18px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        box-shadow: none;
      }
    }
  }

  .transparentWrapper {
    @extend .connectWrapper;
    background: transparent;
  }
}

.containerComplete {
  @extend .container;
  background-color: $green50;
}

.noPaddingBottom {
  padding-bottom: 0;
}

.activeBorder {
  border: 4px solid #3f52ff;
  box-sizing: border-box;
  box-shadow: -8px 0px 16px rgba(0, 0, 0, 0.1), 8px 8px 16px rgba(0, 0, 0, 0.1);
}
