@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  font-family: $Poppins, 'Arial', 'Roboto', sans-serif;
  min-height: calc(100vh - 42px);
  color: $someone-health-maroon;

  input,
  textarea,
  button {
    font-family: $Poppins, 'Arial', 'Roboto', -apple-system, BlinkMacSystemFont, sans-serif;
  }
}
