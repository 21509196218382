.container {
  display: flex;
  flex-direction: column;
  padding: 8px 16px 16px;

  .practiceInfoWrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;

    .practiceCard {
      display: flex;
      max-width: 50%;

      .practiceImgWrapper {
        padding-right: 10px;

        .profile {
          border-radius: 50%;
          height: 100px;
          width: 100px;
          border: 4px solid #0086be;
          padding: 2px;
        }

        .practiceImg {
          border-radius: 16px;
          max-width: 100px;
          height: 100px;
          width: 100px;
        }
      }

      .practiceInfo {
        display: flex;
        flex-direction: column;
        padding-top: 2px;

        .name {
          font-weight: bold;
          font-size: 20px;
          line-height: 30px;
          display: flex;
          align-items: center;
          color: #343434;
        }

        .detailInfo {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #343434;
          opacity: 0.5;
          padding: 1px 0;
        }
      }
    }

    .practitionerCard {
      @extend .practiceCard;
      padding-right: 8px;
    }
  }

  .patientInfoWrapper {
    display: flex;
    justify-content: space-between;

    .infoWrap {
      display: flex;

      .infoTitle {
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        color: #343434;
        padding-right: 4px;
      }
    }
  }
}
