@import 'Ease/assets/theme';

.container {
  display: flex;
  border: 1px dashed #f15944;
  border-radius: 8px;
  min-height: 100px;
  min-width: 106px;
  background-color: $white;
}

.bookContainer {
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 8px;
  padding: 4px 8px 8px;
  min-height: 100px;
  min-width: 106px;

  .closeBtn {
    position: relative;
    left: 90px;
    top: -14px;
    height: 0;

    .closeIcon {
      background: $white;
      border-radius: 50%;
      font-size: 18px;
      padding: 2px;
      color: $ease-dark-grey;
      cursor: pointer;
      user-select: none;
    }
  }

  .timeSlot {
    font-size: 10px;
    line-height: 13px;
    background: $ease-dark-grey;
    color: $white;
    margin-top: 8px;
    padding: 4px 12px;
    text-align: center;
  }
}

.blockContainer {
  @extend .bookContainer;
  background-color: $colorGrey100;
  border: 2px solid $ease-green;
}
