@import 'helm/assets/theme';

.container {
  display: flex;
  align-items: center;

  svg {
    fill: $helm-blue;
    min-width: 185px;
    max-width: min(22.32vw, 305px);
    width: 100%;
    opacity: 1;
  }
}

.containerWhite {
  @extend .container;

  svg {
    fill: $white;
  }
}
