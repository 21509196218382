@import '../../assets/theme/index';

.container {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #ffffff;
  background-size: 100%;
  background-repeat: no-repeat;
  bottom: 0;
  flex-shrink: 0;
  width: 100%;

  .card {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 16px;
  }
}
