@import 'assets/theme';

.logoLoading {
  display: flex;

  .logoLoadingImg {
    width: 150px;
    height: 50px !important;
    border-radius: 8px;
  }
}
