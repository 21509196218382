@mixin loginBackground() {
  height: max-content;
  position: relative;
  z-index: 1;
  background-size: cover;
  min-height: 100vh;
  max-height: 100vh;
  background-image: url('~CaW/assets/images/background/profile-background.png');
  background-position: top;
  background-repeat: no-repeat;
  background-color: transparent !important;
}
