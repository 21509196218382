@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'assets/theme';
@import 'SomeoneHealth/assets/theme';

.container {
  flex: 1 0 20%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 6px 2px;
  touch-action: manipulation;
}

.emojiButton {
  display: flex;
  cursor: pointer !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  user-select: none;
  transition: all 0.15s ease-in-out;
  touch-action: manipulation;
  height: 48px !important;
  width: 48px !important;
  box-shadow: none !important;
  border-width: 2px !important;
  background-color: #cfe8f2;
  font-size: 32px;
}

.active {
  @extend .emojiButton;
  background-color: #5ab6e2;
  border: 2px solid #3f52ff;
  font-weight: 800;
  color: #ffffff;
}

:global(.ease-theme) {
  .active {
    background-color: $ease-green;
    border-color: $ease-green-orange;
  }
}

:global(.recharge-theme) {
  .active {
    background-color: $recharge-light-purple;
    border-color: $recharge-purple;
  }
}

:global(.select-theme) {
  .active {
    background-color: $select-light-red;
    border-color: $select-red;
  }
}

:global(.s1h-client-portal-theme) {
  .emojiButton {
    background-color: $someone-health-light-maroon2;
  }
  .active {
    background-color: $someone-health-light-maroon;
    border-color: $someone-health-maroon;
  }
}
