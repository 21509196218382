@import 'assets/theme';
@import 'CaW/assets/theme';
@import 'Ease/assets/theme';
@import 'Select/assets/theme';
@import 'Portobello/assets/theme';
@import 'MumsMatter/assets/theme';
@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 16px 0;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }

  .contentBox {
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    .backBtnWrapper {
      display: flex;
      align-items: center;
      font-size: 16px;

      .backBtn {
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
          font-size: 28px;
          margin-left: -8px;
          line-height: 21px;
        }
      }
    }

    .taskListContainer {
      display: flex;
      flex-direction: column;

      .taskListTitle {
        font-size: 18px;
        line-height: 28px;
        color: #fff;
        padding-bottom: 24px;

        @media (max-height: $medium-screen-min-height) {
          font-size: 16px;
        }

        .heading {
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          padding-bottom: 24px;
        }
      }

      .taskListBox {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        max-width: 600px;

        @media (max-height: $medium-screen-min-height) {
          row-gap: 16px;
        }
      }
    }
  }
}

:global(.caw-theme).container {
  color: $caw-blue !important;

  .backBtnWrapper {
    color: $caw-blue !important;
  }

  .taskListTitle {
    color: $caw-blue !important;
  }
}

:global(.ease-theme) {
  .container {
    color: $ease-dark-grey !important;

    .backBtnWrapper {
      color: $blue500 !important;
    }

    .taskListTitle {
      color: $ease-dark-grey !important;

      .heading {
        color: $ease-dark-purple !important;
      }
    }
  }
}

:global(.select-theme) {
  .container {
    color: $select-black !important;

    .backBtnWrapper {
      color: $select-black !important;
    }

    .taskListTitle {
      color: $select-black !important;

      .heading {
        color: $select-black !important;
      }
    }
  }
}

:global(.portobello-theme) {
  .container {
    color: $white !important;

    .backBtnWrapper {
      color: $white !important;
    }

    .taskListTitle {
      color: $white !important;

      .heading {
        color: $white !important;
      }
    }
  }
}

:global(.mums-matter-theme) {
  .container {
    color: $mums-matter-black !important;

    .backBtnWrapper {
      color: $mums-matter-black !important;
    }

    .taskListTitle {
      color: $mums-matter-black !important;

      .heading {
        color: $mums-matter-black !important;
      }
    }
  }
}

:global(.s1h-client-portal-theme) {
  .container {
    padding: 0;

    .backBtnWrapper {
      color: $someone-health-maroon;
    }

    .taskListContainer .taskListTitle {
      color: $someone-health-maroon;

      .heading {
        color: $someone-health-maroon;
      }
    }
  }
}
