.container {
  width: 100%;
  font-size: 16px;
}

.button {
  border: 1px solid #3f52ff;
  color: #3f52ff;
  background: none;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 32px;

  &:not(:disabled):hover {
    background-color: #3f52ff;
    color: white;
  }

  &:disabled {
    border-color: #949aa2 !important;
    color: #949aa2 !important;
  }
}
