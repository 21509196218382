@import 'Recharge/assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: $standard-padding * 6 $standard-padding * 4;
  color: $recharge-black;
  background-color: $white;
  border-radius: 8px;
  border: 2px solid $recharge-black;
  margin: auto;
  max-height: 526px;
  overflow: auto;

  &::-webkit-scrollbar {
    visibility: hidden;
  }

  @media (max-width: $mobile-screen-max-content) {
    padding: 8px 12px;
  }

  .timeslotWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    gap: 12px;
    row-gap: 24px;

    @media (max-width: $mobile-screen-max-content) {
      margin-top: 16px;
      row-gap: 16px;
    }

    .slot {
      line-height: 16px;
      background-color: $recharge-purple;
      color: $white;

      &.active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), $recharge-purple;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

.loadingContainer {
  @extend .container;
  padding: 20px;
  max-width: 420px;
  width: 100%;

  @media (max-width: $mobile-screen-max-content) {
    max-width: 100%;
  }

  .loadingHeaderWrapper {
    display: flex;

    .loadingAvatar {
      height: 60px;
      width: 60px;
      border-radius: 8px;

      @media (max-width: $mobile-screen-max-content) {
        height: 50px;
        width: 50px;
      }
    }

    .loadingDescWrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: $standard-padding * 3;

      .loadingTitle {
        height: 36px;
        width: 100%;
        border-radius: 30px;
        max-width: 158px;
        margin-bottom: $standard-padding;

        @media (max-width: $mobile-screen-max-content) {
          height: 30px;
        }
      }

      .loadingDesc {
        height: 18px;
        width: 100%;
        border-radius: 21px;
        max-width: 219px;
      }
    }
  }

  .loadingSlotWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    row-gap: 24px;
    column-gap: 10px;

    @media (max-width: $mobile-screen-max-content) {
      row-gap: 16px;
      margin-top: 16px;
    }

    .loadingSlot {
      height: 30px;
      width: 100%;
      border-radius: 30px;
      max-width: 110px;

      @media (max-width: $mobile-screen-max-content) {
        height: 24px;
      }
    }
  }
}
