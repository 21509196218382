@import 'assets/theme';

.container {
  color: white;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 8 * $smp-standard-padding 11 * $smp-standard-padding;
  position: absolute;
  font-size: 1.5rem;
  line-height: 1.25;
  background-color: $smp-light-red;

  .createdByContainer {
    margin-right: 16 * $smp-standard-padding;
  }

  .value {
    font-weight: bold;
    color: $smp-yellow;
  }
}
