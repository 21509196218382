@import 'assets/theme';

$color1: #fff;
$color2: #3f52ff;

.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .typeWrapper {
    padding-bottom: 16px;
  }

  .radioBtn {
    display: flex;
    align-items: center;
    input[type='radio'] {
      position: absolute;
      opacity: 0;
      + .label {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 18px;
        color: #192a3e;
        margin: 8px 0;
        cursor: pointer;

        &:before {
          content: '';
          background: $color1;
          border-radius: 100%;
          border: 1px solid #828282;
          display: flex;
          width: 20px;
          height: 20px;
          position: relative;
          margin-right: 16px;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .label {
          &:before {
            background-color: $color2;
            box-shadow: inset 0 0 0 4px $color1;
            border: 1px solid $color2;
          }
        }
      }
      &:focus {
        + .label {
          &:before {
            outline: none;
            border-color: $color2;
          }
        }
      }
      &:disabled {
        + .label {
          &:before {
            box-shadow: inset 0 0 0 4px $color1;
            border-color: darken($color1, 25%);
            background: darken($color1, 25%);
          }
        }
      }
      + .label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }

  .infoWrapper {
    display: none;
    margin-left: 38px;

    &.visible {
      display: block;
    }

    .info {
      font-size: 13px;
      line-height: 20px;
      color: #323c47;
      font-weight: bold;
    }
  }

  .locationLabel,
  .instructionsLabel {
    font-size: 11px;
    line-height: 16px;
    color: #818e9b;
  }

  .locationWrapper,
  .instructionsWrapper {
    display: flex;

    .location,
    .instructions {
      font-size: 13px;
      line-height: 20px;
      color: #323c47;
      padding: 4px 24px 4px 0;
      border-bottom: 1px solid #d3d8dd;
    }
  }

  .mapWrapper {
    padding-top: 8px;
    max-width: 500px;

    @media (max-width: $medium-screen-min-width) {
      height: 144px;
    }

    .map {
      border-radius: 16px;
    }
  }

  .link {
    color: #3f52fb;
  }
}
