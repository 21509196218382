@import 'SomeoneHealth/assets/theme';

.headerContainer {
  display: flex;
  flex-direction: column;
  background-image: url(~SomeoneHealth/assets/images/background/home.png);
  background-size: cover;
  background-position: center;
  min-height: 924px;

  @media (max-width: $tablet-screen-max-content) {
    min-height: auto;
    background-position: top;
    background-size: 150%;
    background-repeat: no-repeat;
  }

  .header {
    flex: unset;
  }

  .heroTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: $white;
    margin: 34px 0;

    @media (max-width: $tablet-screen-max-content) {
      margin: 24px 0 34px;
    }

    .heroText {
      display: flex;
      text-align: right;
      max-width: 500px;
      font-weight: 800;
      font-size: 60px;
      line-height: 70px;
      text-shadow: 2px 0 0 $someone-health-blue, -2px 0 0 $someone-health-blue, 0 2px 0 $someone-health-blue,
        0 -2px 0 $someone-health-blue, 1px 1px 0 $someone-health-blue, -1px -1px 0 $someone-health-blue,
        1px -1px 0 $someone-health-blue, -1px 1px 0 $someone-health-blue, 1px 1px 5px $someone-health-blue;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 32px;
        line-height: 42px;
      }
    }
  }
}

.landingContent {
  display: flex;
  align-items: center;
  position: relative;
  bottom: 250px;

  .heading {
    font-weight: 700;
    font-size: 48px;
    margin-top: 108px;
    color: #333333;
    line-height: 70px;
    letter-spacing: -0.01em;
    font-feature-settings: 'kern' off;
  }

  @media (max-width: $medium-desktop-screen-max-content) {
    bottom: 160px;
  }

  @media (max-width: $tablet-screen-max-content) {
    position: unset;
    bottom: 0;
    padding-top: 80px;
  }
}

.homePageContainer {
  max-width: 1280px !important;
}
