@import 'Recharge/assets/theme';

.container {
  display: flex;
  align-items: center;

  .numberBubble {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $recharge-black;
    background-color: $recharge-light-blue;
    border-radius: 50%;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    padding: 4px;
    min-width: 30px;
    margin-right: 8px;

    @media (max-width: $tablet-screen-max-content) {
      font-size: 14px;
      min-width: 26px;
      height: 26px;
      line-height: 20px;
    }
  }

  .stepTitle {
    color: $recharge-black;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;

    @media (max-width: $tablet-screen-max-content) {
      font-size: 14px;
    }
  }
}
