@import 'helm/assets/theme';

.container {
  color: $helm-blue;

  .label {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  .specialisationBadgeWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;

    .specialisationBadge {
      display: flex;
      align-items: center;
      font-family: $primary-font-stack-bold !important;
      font-size: 10px;
      line-height: 13px;
      text-align: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      padding: $standard-padding * 2 $standard-padding * 3;
      border-radius: 25px;
      background-color: $badge-background-color;
      margin: 0 8px 8px 0;
    }
  }

  .specialisationBadgeVerticalWrapper {
    @extend .specialisationBadgeWrapper;
    flex-direction: column;

    .specialisationBadge {
      max-width: fit-content;
    }
  }
}
