@import 'helm/assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.container {
  background-color: $helm-lighter-yellow;
  padding: 24px 0;
  z-index: 2;

  .detailsContentWrapper {
    display: flex;
    padding: $standard-padding * 12 0;
    @include columnGap(40px);

    @media (max-width: $small-desktop-screen-max-content + 100px) {
      padding: $standard-padding * 4 0;
    }

    @media (max-width: $tablet-screen-max-content) {
      flex-direction: column;
      @include columnGap(0);
      @include rowGap(40px);
      padding: 0;
    }

    .leftContent {
      display: flex;
      flex-direction: column;
      flex: 1 70%;
    }

    .rightContent {
      display: flex;
      flex-direction: column;
      flex: 1 30%;

      .slideShowWrapper {
        display: flex;
        height: 160px;
        position: relative;

        @media (max-width: $small-desktop-screen-max-content + 100px) {
          display: none;
        }

        .slideShowCard {
          position: absolute;
          top: -300px;
          left: -30%;
          height: 420px;
          width: 480px;

          @media (max-width: $small-desktop-screen-max-content + 200px) {
            top: -260px;
            left: -30%;
            height: 380px;
            width: 440px;
          }
        }
      }

      .detailsWrapper {
        display: flex;
        flex-direction: column;
        @include rowGap(40px);

        .appointmentBtnWrapper {
          display: flex;

          .appointmentBtn {
            width: 100%;
            padding: $standard-padding * 3;
          }
        }
      }
    }
  }
}
