// CORDS main color
$cords-lightest-yellow-20: #fdfbf8;
$cords-light-yellow: #fee7b3;
$cords-yellow: #f9af05;
$cords-black: #1d1d1f;
$cords-dark-blue: #1b365c;

$text-disable-grey: #aaaaaa;

$black: #000000;
$white: #ffffff;
