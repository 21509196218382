@import 'assets/theme';
@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
    row-gap: 16px;

    @media screen and (max-width: $medium-screen-min-width), (max-height: $medium-screen-min-height) {
      row-gap: 8px;
    }

    .title {
      font-weight: bold;
      font-size: 18px;
      line-height: 26px;

      @media screen and (max-width: $medium-screen-min-width), (max-height: $medium-screen-min-height) {
        font-size: 16px;
        line-height: 22px;
      }
    }

    .desc {
      font-size: 13px;
      line-height: 22px;

      @media screen and (max-width: $medium-screen-min-width), (max-height: $medium-screen-min-height) {
        font-size: 12px;
        line-height: 22px;
      }
    }
  }

  .formContainer {
    display: flex;
    flex-direction: column;

    .fieldContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-bottom: 40px;
      max-width: 500px;

      @media (max-width: $medium-screen-min-width) {
        margin-bottom: 32px;
      }

      &.fieldError,
      .fieldError {
        font-size: 12px;
        color: #ff4d4f;

        input {
          border-bottom: 1px solid #ff4d4f !important;
        }
      }
    }
  }
}

:global(.s1h-client-portal-theme) {
  .container .formContainer .inputLabel {
    color: $someone-health-maroon !important;
  }

  .container {
    font-family: $Poppins;

    .header {
      color: $someone-health-maroon;
    }

    .content {
      .title {
        color: $someone-health-maroon;
        font-weight: 500;
      }

      .detail {
        .avatar {
          border-color: $someone-health-maroon;
        }
      }

      .contact {
        .description {
          color: $someone-health-maroon;

          .highlight {
            font-weight: $font-weight-bolder;
          }
        }
      }
    }
  }

  .avatarTitle {
    color: $someone-health-maroon;
    font-family: $Poppins;
  }
}
