@import 'Ease/assets/theme';

.contentWrapper {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.container {
  height: 100%;
  overflow: auto;
  position: relative;
  z-index: 1;
}

.title {
  font-size: 24px;
  padding: 0 $standard-padding * 4;
  margin-bottom: $standard-padding * 3;
  margin-top: $standard-padding * 5;
  font-weight: 600;
  color: $black;
}

.description {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: $standard-padding * 10;
  color: $ease-dark-purple;
}

.content {
  background-color: $white;
  border-radius: 8px 8px 0 0;
  margin-bottom: $standard-padding * 16;
  padding: $standard-padding * 4 $standard-padding * 8;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (min-width: 560px) {
    border-radius: 8px;
  }
}
