@import 'CaW/assets/theme';

.container {
  @include loginBackground;

  .headerContent {
    display: flex;
    width: 100%;
    flex: initial;
  }

  .headerWrapper {
    display: flex;
  }
}
