@import 'helm/assets/theme';

.contentWrapper {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.container {
  height: max-content;
  min-height: 100vh;
}

.title {
  font-size: 24px;
  padding: 0 $standard-padding * 4;
  margin-bottom: $standard-padding * 3;
  margin-top: $standard-padding * 5;
  color: $white;
}

.description {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: $standard-padding * 10;
  color: $darkblue500;
}

.content {
  background-color: $white;
  border-radius: 8px 8px 0 0;
  margin-bottom: $standard-padding * 16;
  padding: $standard-padding * 4 $standard-padding * 8;

  @media screen and (min-width: 560px) {
    border-radius: 8px;
  }
}
