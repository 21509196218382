@import 'helm/assets/theme';

.header {
  flex: 0;
  padding-bottom: 32px;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.container {
  margin-top: 22px;
  display: flex;
  width: 100%;
  padding-bottom: 30px;
  justify-content: space-around;

  .leftContent {
    flex: 1;
    @media (min-width: ($tablet-screen-max-content + 1)) {
      padding-right: 20px;
    }
  }

  .rightContent {
    flex: 1;
    text-align: center;
  }
}

.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: $grey700;
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: $grey600;
    font-family: 'Nunito', normal;
  }

  .fundingSection {
    .label {
      padding: 8px 0 16px;
    }
  }

  .subFieldForm {
    padding: 16px 0 32px;
  }

  .submitButton {
    max-width: 540px;
    width: 100%;
    margin: 8px 0 32px;
  }
}

.timer {
  display: none;
}
