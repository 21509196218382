@import 'assets/theme';

.contentWrapper {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 0 16px;
  background-color: #2a4670;
}

.fullHeightFooter {
  height: calc(100% - 82px);
}

.cardContainer {
  align-items: flex-start !important;
  padding: 32px 16px 24px !important;

  .cardWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: #343434;
  }

  .aPointCard {
    background: #fff;
    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.18);
    border-radius: 16px;
    padding: 16px 24px;
    margin: 16px 0;
    max-width: 330px;

    @media (max-width: $medium-screen-min-width) {
      max-width: 100%;
      width: 100%;
    }

    .dateInfo {
      display: flex;
      align-items: center;
      padding-bottom: 16px;

      .date {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #404040;
        padding-right: 12px;
      }

      .monthDay {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #000000;
        padding-right: 8px;
      }

      .bookingTime {
        display: flex;
        justify-content: center;
        background: #f6e9dd;
        border-radius: 8px;
        padding: 8px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #fc8468;
        margin-left: 16px;

        .to {
          padding: 0 2px;
        }
      }
    }

    .sessionType {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #2a4670;
      text-transform: uppercase;
    }
  }

  .modeWrapper {
    display: flex;
    flex-direction: column;
    padding-top: 16px;

    .label {
      font-size: 16px;
      color: #192a3e;
    }
  }

  .divider {
    border: 1px solid #c4c4c4;
    margin: 16px 0;
  }

  .footer {
    display: flex;
    flex-direction: column;

    .policy {
      font-size: 13px;
      line-height: 20px;
      color: #343434;
      padding-bottom: 24px;
    }

    .button {
      display: flex;
      justify-content: center;
      background: #3f52fb;
      color: #fff;
      border-radius: 16px;
      padding: 16px;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      cursor: pointer;

      &.loading {
        background-color: #3f52fb80;
      }

      .loadingContainer {
        margin-right: 8px;
      }
    }
  }
}

.error {
  color: #fc8468;
}
