@import 'assets/theme';
@import 'helm/assets/theme';

.container {
  /* use padding-top instead of margin-top
   * ( so that it will reflect while printing 
   */
  padding-top: $smp-standard-padding * 17;
  font-family: $smp-font-family;
  page-break-inside: avoid;

  .title {
    @include titleFontSize;
    margin-bottom: $smp-standard-padding * 8;
  }

  .tableSection {
    page-break-inside: avoid;
    padding: 0 32px;

    @media screen and (max-width: $smp-mobile-screen-max-content) {
      padding: 0 8px;
    }

    .content {
      display: grid;
      font-size: 1.25rem;
      text-align: center;
      background-color: $smp-vanilla;
      grid-template-columns: 350px auto;
      grid-template-areas:
        'header header'
        'main input'
        'main input';

      @media screen and (max-width: $smp-mobile-screen-max-content) {
        grid-template-columns: auto;
      }

      > div {
        padding: $smp-standard-padding * 4;
      }

      .tableTitle {
        color: white;
        padding: $smp-standard-padding * 4;
        text-align: center;
        font-weight: bold;
        background-color: $smp-light-red;
      }

      .tableTitleIP6 {
        grid-area: header;
      }
    }
  }

  .gridSection {
    gap: $smp-standard-padding * 6;
    margin: 0 -32px;
    display: grid;
    font-size: 20px;
    padding-top: $smp-standard-padding * 12;
    page-break-inside: avoid;
    grid-template-columns: auto auto;

    @media screen and (max-width: $smp-mobile-screen-max-content) {
      grid-template-columns: auto;
    }

    .unhelpfulThought {
      color: white;
      position: relative;
      background-color: $smp-light-green;
    }

    .helpfulThought,
    .emotionManagement {
      background-color: $smp-vanilla;
    }

    .difficultEmotion {
      color: white !important;
      position: relative;
      background-color: $smp-yellow;
    }

    .rightArrow {
      position: absolute;
      right: -60px;
      height: 35px;

      @media screen and (max-width: $smp-mobile-screen-max-content) {
        display: none;
      }
    }

    .input {
      font-size: 1.4rem;
    }

    .white {
      color: white;
    }
  }
}

:global(.helm-theme) {
  .title,
  .tableTitle,
  .rowTitle,
  .label {
    font-family: $title-font-stack;
    font-weight: normal;
  }

  .content,
  .textArea,
  .unhelpfulThought,
  .helpfulThought,
  .emotionManagement,
  .difficultEmotion,
  .input {
    font-family: $primary-font-stack-medium;
  }

  .tableSection .content .tableTitle {
    background-color: $helm-red;
  }

  .gridSection {
    .unhelpfulThought,
    .difficultEmotion {
      background-color: $helm-purple;
    }
  }
}
