@import 'Select/assets/theme';

.container {
  display: flex;
  flex-direction: column;
}

.greeting {
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: $tablet-screen-max-content) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}

.greet {
  color: $select-black;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;

  @media (max-width: $tablet-screen-max-content) {
    font-size: 40px;
    line-height: 44px;
  }
}

.subGreet {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
}

.descWrapper {
  display: flex;
  flex-direction: column;
  margin-top: $standard-padding * 5;
  @include rowGap(16px);

  .desc {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 16px;
    }
  }
}

.content {
  white-space: pre-line;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: $select-black;

  @media (max-width: $small-desktop-screen-max-content) {
    font-size: 16px;
    line-height: 21px;
  }
}

.mainAreaBadge {
  background-color: $select-green !important;
}

.subAreaBadge {
  background-color: $select-red !important;
}

.bioPhoto {
  width: 60%;
  margin-top: 48px;
  border-radius: 20px;

  @media (max-width: $tablet-screen-max-content) {
    align-self: center;
    width: 80%;
    margin-top: 16px;
  }
}
