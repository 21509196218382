@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  margin: 66px 0;

  .title {
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    letter-spacing: -0.02em;
  }

  .graphContent {
    display: flex;
    align-items: center;
    padding: 24px 0;
  }
}
