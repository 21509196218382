@import 'SomeoneHealth/assets/theme';
@import 'Ease/assets/theme';
@import 'Select/assets/theme';

.contact {
  margin-top: $standard-padding * 5;

  .title {
    font-size: 24px;
    color: $darkblue500;
  }

  .description {
    color: $darkblue500;
    .highlight {
      font-weight: 600;
    }
  }

  .addKeyBtnWrapper {
    margin-top: $standard-padding * 5;
    margin-bottom: 44px;
  }
}

:global(.ease-theme) {
  .contact {
    .title {
      color: $ease-dark-grey;
      font-weight: 600;
    }

    .description {
      color: $ease-dark-grey;
    }
  }
}

:global(.select-theme) {
  .contact {
    .title {
      color: $select-black;
      font-weight: 600;
    }

    .description {
      color: $select-black;
    }
  }
}

:global(.s1h-client-portal-theme) {
  .contact {
    .title {
      color: $someone-health-maroon;
      font-weight: 600;
    }

    .description {
      color: $someone-health-maroon;
    }
  }
}
