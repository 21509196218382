@import 'assets/theme';

.textAreaContainer {
  overflow: hidden;
}

.textarea {
  transition: all 0.5s ease-in;
  height: max-content;

  &.hidden {
    margin-top: -20%;
  }

  @media (max-width: $medium-screen-min-width) {
    padding-top: 32px;
  }
}
