@import 'helm/assets/theme';

.containerWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .background {
    height: 100%;
    width: 50%;
    background: url('~helm/assets/images/partnerReferral/tokenImage.jpg') no-repeat;
    background-size: cover;
    background-position: 30%;
    overflow: hidden;
    position: absolute;
    right: 0;
    z-index: 0;

    @media (max-width: $medium-large_screen-min-width) {
      display: none;
    }
  }

  .header {
    flex: none;
  }

  .content {
    display: flex;
    height: 100%;
    padding-top: 80px;

    .leftContent {
      display: flex;
      flex-direction: column;
      flex: 1 2 50%;

      .formContent {
        @include rowGap(16px);
        align-self: center;
        max-width: 470px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .formWrapper {
        @include rowGap(24px);
        max-width: 400px;
        padding-bottom: 16px;
        margin: 0 auto;
        width: 100%;
      }

      .welcomeText {
        font-size: 30px;
        font-weight: 600;
        line-height: 38px;
      }

      .text {
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
      }

      .smallText {
        @extend .text;
        font-size: 14px;
        text-align: center;
      }

      .wiseLogoContainer {
        display: flex;

        .wiseLogo {
          width: 100%;
        }
      }

      .link {
        font-size: 14px;
        color: rgba(34, 52, 140, 1);
        font-weight: 600;
        cursor: pointer;
        width: max-content;
      }

      .signinButton {
        margin: 8px 0;
      }

      .inputContainerClassName {
        margin-bottom: unset;
      }

      .inputClassNameBox {
        max-width: 100%;
      }

      .inputClassName {
        max-width: 50px;
        height: 64px;
        background: $white;
        border: 1px solid $colorGrey600;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 4px;
        font-weight: 400;
        font-size: 28px;
        line-height: 60px;
        letter-spacing: -0.02em;
        color: $colorGrey600;
        margin-top: unset;

        @media screen and (max-width: $medium-small-screen-min-width) {
          font-size: 32px;
        }
      }

      .inputError {
        border: 1px solid $helm-red !important;
      }
    }

    .rightContent {
      flex: 1 2 50%;
      height: 70%;
      z-index: 99;

      @media screen and (max-width: $medium-large_screen-min-width) {
        display: none;
      }

      .infoBox {
        background-color: $helm-lighter-yellow;
        border: 1px solid $helm-lighter-yellow;
        padding: 12px;
        font-size: 20px;
        position: relative;
        margin-left: 20px;
        margin-bottom: 20px;
        bottom: -90%;
        display: flex;
        flex-direction: column;
        color: $helm-blue;

        .welcomeText {
          font-weight: 600;
          line-height: 38px;
        }
      }
    }
  }
}
