@import 'SomeoneHealth/assets/theme';

.container {
  margin-top: 60px;

  .cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .psychologistCardContainer {
    --grid-layout-gap: 32px;
    --grid-column-count: 3;
    --grid-item--min-width: 250px;

    --gap-count: calc(var(--grid-column-count) - 1);
    --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
    --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
    );
    grid-gap: var(--grid-layout-gap);
  }

  .psychologistSkeletonContainer {
    @extend .psychologistCardContainer;
  }

  .psychologistSkeletonContainerLoader {
    @extend .psychologistCardContainer;
    margin-top: 32px;
  }

  .noPsychologistWrapper {
    background-color: $someone-health-light-blue;
    padding-bottom: 20px;
  }
}

.gridSkeletonWrapper {
  margin-top: 60px;

  .gridSkeletonContent {
    --grid-layout-gap: 32px;
    --grid-column-count: 3;
    --grid-item--min-width: 250px;

    --gap-count: calc(var(--grid-column-count) - 1);
    --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
    --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
    );
    grid-gap: var(--grid-layout-gap);
  }

  .psychologistSkeletonContainer {
    @extend .gridSkeletonContent;
  }

  .psychologistSkeletonContainerLoader {
    @extend .gridSkeletonContent;
    margin-top: 32px;
  }
}
