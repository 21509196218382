$standard-padding: 4px;

@mixin columnGap($size) {
  & > *:not(:last-child) {
    margin-right: $size;
  }
}

@mixin rowGap($size) {
  & > *:not(:last-child) {
    margin-bottom: $size;
  }
}
