@import 'SomeoneHealth/assets/theme';

.headingContainer {
  text-align: center;

  .heading {
    font-weight: 700;
    font-size: 48px;
    margin: 108px auto 48px auto;
    color: #333333;
    line-height: 70px;
    letter-spacing: -0.01em;
    font-feature-settings: 'kern' off;

    @media (max-width: $small-screen-max-width) {
      margin: 60px auto 18px auto;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.02em;
    }
  }
}

.container {
  display: flex;
  align-items: flex-start;
  margin: 0;
  flex: 1;
  max-width: 100%;

  @media screen and (max-width: $small-desktop-screen-max-content) {
    flex-direction: column;
  }

  .menuWrapper {
    flex: 4;
    z-index: 1;
    margin-top: 4px;

    @media screen and (max-width: $small-desktop-screen-max-content) {
      display: none;
    }
  }

  .scrollWrapper {
    display: none;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: $small-desktop-screen-max-content) {
      display: flex;
    }

    .mobileMenuWrapper {
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
