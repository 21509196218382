.header {
  display: flex;
  flex-direction: column;
  padding: 0 16px 32px;
}
.ClinicianInfor {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.fullHeightFooter {
  height: calc(100% - 80px);
}

.cardContainer {
  align-items: flex-start !important;
  padding: 32px 16px 24px !important;
}
.divider {
  position: sticky;
  z-index: 8;
  border-bottom: 1px solid #ececec;
  margin: 0 10px;
}
