@import 'CaW/assets/theme';

$slotChangeViewTablet: $small-desktop-screen-max-content + 220px;
$scrollingSlotView: $mobile-screen-max-content + 100px;

.contentLayout {
  background: $caw-lightest-blue;
  padding-top: 27px;
  padding-bottom: 22px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  @media (max-width: $small-desktop-screen-max-content) {
    padding: 18px 19px 24px 19px;
  }
}

.bookingContainer {
  padding: 24px 16px;
  background: $caw-light-blue;

  @media (min-width: ($small-desktop-screen-max-content + 1px)) {
    padding: 0 16px 100px 16px;
  }

  &.isAdvisorySession {
    padding: 24px;
  }
}

.bookingContent {
  display: flex;

  @media (max-width: $small-desktop-screen-max-content) {
    flex-direction: column;
    gap: 15px;
  }
}

.bookingDesc {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: $caw-blue;
  padding-top: 23px;

  @media (max-width: $small-desktop-screen-max-content) {
    padding-top: unset;
  }

  .bookingTitle {
    @include playfairDisplayFont;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  .bookingDescSummary {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    margin-top: 8px;
  }
}

.middleContent {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: $small-desktop-screen-max-content) {
    gap: 24px;
  }

  .topContainer {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: $small-screen-max-width) {
      flex-direction: column;
      align-items: center;
    }

    @media (max-width: $small-desktop-screen-max-content - 1px) {
      justify-content: unset;
    }

    .slotContainer {
      display: flex;
      flex-direction: column;

      @media (max-width: $medium-small-screen-min-width) {
        width: 100%;
      }

      .blocked {
        background: $caw-light-red;
        border: 2px solid $caw-blue;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: $caw-blue;
        padding: $standard-padding;
        border-radius: 4px;
        max-width: max-content;
        margin-bottom: 16px;
      }

      .slotWrapper {
        display: flex;
        max-width: 100%;
        align-items: center;
        gap: 16px;
        justify-content: center;

        @media (max-width: $medium-small-screen-min-width) {
          gap: 8px;
        }
      }
    }

    .consultPreferenceWrapper {
      min-width: 232px;
    }

    .consultPreference {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $caw-blue;
      justify-content: center;
      text-align: center;

      .consultPreferenceLabel {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }

      .consultPreferenceButton {
        width: fit-content;
        padding: 0 5px;
        background: $white;
        border: 1.1383px solid #d9d9d9;
        margin-top: 10px;

        .indicator {
          box-shadow: unset;
          border: 1.87719px solid $caw-dark-red;
          background-color: $caw-dark-red;
        }

        .label {
          padding: 0 !important;
          color: $caw-light-red !important;
        }

        .labelActive {
          padding: 0 !important;
          color: $white !important;
        }
      }

      .selectedPreference {
        display: flex;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.04em;
        margin-top: 15px;
      }
    }
  }

  .cancellation {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.04em;

    .link {
      color: $colorGrey700;
      text-decoration: underline;
    }
  }
}

.continueBtnWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: $small-desktop-screen-max-content) {
    align-items: center;
    margin-top: 8px;
  }

  .continueBtn {
    min-width: 276px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .continueLabel {
    color: $caw-blue;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding-top: 8px;
  }
}
