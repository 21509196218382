@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(20px);
  padding: 0 8px 16px 0;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $grey100;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 2px;
    background-color: $grey100;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey500;
  }

  .activityCard {
    display: flex;
    flex-direction: column;

    .card {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 24px 20px;

      .activityTitle {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;

        .highlight {
          color: $grey700;
        }
      }

      .name {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: $grey700;
      }

      .buttonWrapper {
        display: flex;
        margin-top: 8px;

        .button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px 18px;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          box-shadow: none;

          .markIcon {
            margin-right: 8px;
          }
        }
      }
    }

    .completeCard {
      @extend .card;
      background-color: $green50;

      .activityTitle {
        color: $green500;
      }
    }

    .checkBoxCard {
      @extend .card;
      display: flex;
      flex-direction: column;
      background-color: $darkblue50;

      .activityTitle {
        color: $darkblue300;
      }

      .name {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        margin-top: 4px;
      }

      .desc {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: $grey700;
      }
    }
  }
}
