@import 'Recharge/assets/theme';

.container {
  display: flex;

  .image {
    width: 100%;
    border-radius: 50%;
  }
}
