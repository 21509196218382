@import 'Recharge/assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.container {
  padding: 24px 0;
  z-index: 2;
  color: $recharge-black;
  background-color: $white;

  .detailsContentWrapper {
    display: flex;
    padding: $standard-padding * 4;
    @include columnGap(40px);

    @media (max-width: $tablet-screen-max-content) {
      flex-direction: column;
      @include columnGap(0);
      @include rowGap(40px);
      padding: 0;
    }

    .leftContent {
      display: flex;
      flex-direction: column;
      flex: 1 70%;
      row-gap: 20px;

      .appointmentBtnWrapper {
        display: none;

        @media (max-width: $tablet-screen-max-content) {
          display: block;
          padding-bottom: $standard-padding * 5;
        }

        .appointmentBtn {
          width: 100%;
          padding: $standard-padding * 3;
        }
      }
    }

    .rightContent {
      display: flex;
      flex-direction: column;
      flex: 1 30%;

      .additionalPhoto {
        display: flex;
        height: 160px;
        position: relative;

        @media (max-width: $medium-desktop-screen-max-content) {
          display: none;
        }

        .additionalPhotoCard {
          position: absolute;
          top: -195px;
          height: 305px;
          width: 305px;

          .slideShow {
            mask-image: none;
            border-radius: 50%;
          }
        }
      }

      .detailsWrapper {
        display: flex;
        flex-direction: column;
        @include rowGap(40px);

        .appointmentBtnWrapper {
          @media (max-width: $tablet-screen-max-content) {
            display: none;
          }

          .appointmentBtn {
            width: 100%;
            padding: $standard-padding * 3;
          }
        }

        .deliveryModeContainer {
          display: flex;
          flex-direction: column;
          color: $recharge-black;
          gap: 12px;

          .deliveryModeLabel {
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
          }

          .deliveryModeItem {
            display: flex;
            align-items: center;
            font-size: 18px;
            line-height: 23px;
            font-weight: 600;
            text-align: center;

            @media (max-width: $tablet-screen-max-content) {
              font-size: 14px;
            }

            .callIcon {
              margin-right: 12px;
              padding: 4px;
              font-size: 24px;
              border-radius: 50%;
              background-color: $recharge-light-blue;

              @media (max-width: $tablet-screen-max-content) {
                margin-right: 8px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
