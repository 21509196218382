@import 'Select/assets/theme';

.header {
  @media (max-width: $small-screen-max-width) {
    padding: 16px 0 !important;
  }
}

.container {
  margin-top: 16px;
  display: flex;
  width: 100%;
  padding-bottom: 30px;
  justify-content: space-around;

  @media (max-width: $medium-desktop-screen-max-content) {
    padding-left: 100px;
  }

  @media (max-width: $small-desktop-screen-max-content) {
    padding-left: 0;
    padding-right: 0;
    justify-content: space-evenly;
    padding-bottom: unset;
  }
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.leftContent {
  @media (min-width: ($tablet-screen-max-content + 1)) {
    padding-right: 20px;
  }
}

.rightContent {
  max-width: 410px;
  width: 100%;

  @media (max-width: $small-desktop-screen-max-content) {
    max-width: 350px;
  }

  @media (max-width: $tablet-screen-max-content) {
    display: none;
  }

  .notice {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    flex-direction: column;

    @media screen and (max-width: $medium-large_screen-min-width) {
      flex-direction: row;
    }

    .noticeMsg {
      max-width: 260px;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;

      > div {
        text-align: center;
      }
    }
  }

  .holdingSlotCard {
    margin-top: 64px;
    padding: 24px;
    border: solid 1px #5fb0a1;
    border-radius: 4px;
    background-color: white;

    .grayText {
      opacity: 0.5;
      color: $select-black;
      vertical-align: top;
      font-size: 14px;
    }

    .practitionerAvatarRow,
    .appointmentsRow {
      display: flex;
      gap: 16px;

      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 100%;
      }

      .leftCol {
        width: 30%;
        display: inline-block;
        text-align: right;
      }

      .rightCol {
        width: 70%;
        display: inline-block;
        text-align: left;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
      }
    }

    .practitionerAvatarRow {
      margin-bottom: $standard-padding * 7;
      align-items: center;
    }
  }
}

.appointmentTypeContainer {
  margin-top: 16px;
  padding: 16px;
  color: $select-black;
  background: $select-light-green;
  border-radius: 12px;

  .title {
    font-size: 18px;
    line-height: 23px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .description {
    font-weight: 500;
  }
}
