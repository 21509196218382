@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-image: url(~SomeoneHealth/assets/images/background/practitioner-profile-header.png);
  background-size: 100% 416px;

  @media (max-width: $medium-large_screen-min-width) {
    background-size: unset;
    background-position: center;
  }

  .wrapper {
    width: 100%;
    position: relative;
  }

  .overlay {
    width: 56%;
    z-index: 1;
    position: absolute;
    right: 0;
    display: flex;
    height: calc(100% + 76px);
    top: -76px;
    min-width: 279px;

    @media (max-width: $medium-large_screen-min-width) {
      background: rgba(86, 65, 151, 0.5);
    }

    @media (max-width: $tablet-screen-max-content) {
      width: 60%;
    }
  }

  .detailsWrapper {
    display: flex;
    flex: 1;
    align-items: flex-end;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 5%;
    padding-right: 5%;

    @media (max-width: $medium-small-screen-min-width) {
      padding-right: 2%;
    }

    .name {
      font-family: $font;
      color: #fff;
      font-weight: 800;
      font-size: 60px;
      line-height: 70px;
      max-width: 480px;
      text-align: right;

      @media (max-width: $medium-desktop-screen-max-content) {
        font-weight: 700;
        font-size: 48px;
        line-height: 50px;
        letter-spacing: -0.02em;
      }

      @media (max-width: $medium-large_screen-min-width) {
        font-weight: 700;
        font-size: 36px;
        line-height: 47px;
        letter-spacing: -0.02em;
      }
      @media (max-width: $medium-small-screen-min-width) {
        max-width: 240px;
      }
    }

    .actionWrapper {
      display: flex;
      margin-top: 16px;

      @media (min-width: $medium-desktop-screen-max-content - 1px) {
        margin-bottom: 16px;
      }

      .otherPractitioner {
        display: flex;
        align-items: center;
        font-family: $font;
        color: #fff;

        .label {
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;
          text-decoration: underline;

          @media (max-width: $medium-large_screen-min-width) {
            font-size: 14px;
            line-height: 15px;
          }
        }

        .icon {
          font-size: 20px;
          margin-right: 4px;
        }
      }

      .bookAppointmentBtn {
        margin-left: 40px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .middleWrapper {
    display: flex;
    min-height: 340px;

    @media (max-width: $medium-large_screen-min-width) {
      min-height: 242px;
    }

    .headerImageContainer {
      display: flex;
      position: absolute;
      top: -79px;
      margin-left: 12%;
      width: 575px;
      height: 406px;
      transform: rotate(-0.87deg);

      @media (max-width: $desktop-screen-max-content) {
        margin-left: 10%;
      }

      @media (max-width: $medium-large_screen-min-width) {
        width: 350px;
        height: 350px;
        transform: unset;
        margin-left: 50px;
        top: -20px;
      }

      @media (max-width: $mobile-screen-max-content) {
        width: 300px;
        height: 300px;
        transform: unset;
        margin-left: -50px;
        top: -10px;
      }
    }

    .headerImgWrapper {
      display: flex;
      position: relative;
      width: 100%;

      @media (max-width: $desktop-screen-max-content) {
        width: 85%;
      }

      .headerImageBgWrapper {
        position: absolute;
        width: 106%;
        height: 106%;
        left: -42px;
        top: -21px;

        .headerImgBackground {
          height: 100%;
          mask-size: 100% 100%;
          background-size: cover;
          mask-position: center;
          mask-repeat: no-repeat;
          transform: rotate(85deg);
        }
      }

      .headerImgMask {
        width: 100%;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-image: url(~SomeoneHealth/assets/images/shape/1.png);

        .headerImage {
          width: 100%;

          @media (max-width: $medium-large_screen-min-width) {
            border-radius: 50%;
          }
        }
      }
    }
  }
}
