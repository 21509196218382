@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  color: $white;
  margin: 50px 0 90px;

  @media (max-width: $mobile-screen-max-content) {
    margin: 20px 0 50px;
  }

  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 62px;
    letter-spacing: -0.04em;

    @media (max-width: $mobile-screen-max-content) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  .desc {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-top: 4px;

    @media (max-width: $mobile-screen-max-content) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .buttonWrapper {
    display: flex;
    margin-top: 32px;

    .button {
      box-shadow: none;
    }
  }
}
