@import 'Portobello/assets/theme';

$headerHeight: 82px;

.backgroundContainer {
  background: linear-gradient(90deg, #fff 50%, $portobello-light-green 0%);

  @media (max-width: $medium-large_screen-min-width) {
    background: transparent;
  }

  .headerWrapper {
    position: relative;

    .header {
      position: absolute;
      padding: 12px 0 !important;

      @media (max-width: $medium-large_screen-min-width) {
        position: unset;
        padding: 0 !important;
      }
    }
  }
}

.container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  min-height: calc(100vh - 42px);

  @media (max-width: $small-desktop-screen-max-content) {
    padding-left: 0;
    padding-right: 0;
    justify-content: space-evenly;
    padding-bottom: unset;
  }
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.leftContent {
  width: 100%;
  padding-top: $headerHeight;
  padding-bottom: 24px;
  padding-right: 20px;
  background-color: $white;

  @media (max-width: $medium-large_screen-min-width) {
    padding-top: 0;
    padding-right: 0;
  }
}

.rightContent {
  max-width: 320px;
  width: 100%;
  padding: $headerHeight 16px 16px 40px;
  background-color: $portobello-light-green;

  @media (max-width: $medium-large_screen-min-width) {
    display: none;
    background-color: transparent;
    padding: 0 16px 16px;
  }

  .achWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .achLabel {
      color: #595c60;
      font-size: 14px;
      font-weight: 600;
    }

    .achDesc {
      color: #003a5d;
      font-family: $Playfair-Display, Georgia, sans-serif;
      font-size: 54px;
      line-height: 56px;
    }
  }

  .logoWrapper {
    padding-top: 28px;

    .logo {
      max-height: 60px;
      max-width: 250px;
    }
  }
}
