@import 'Select/assets/theme';

.footerContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: $white;
}

.container {
  max-width: 1200px;
  padding: 48px;

  @media (max-width: $mobile-screen-max-content) {
    padding: 24px;
  }

  .content {
    display: flex;
    flex-direction: row;
    gap: 120px;
    color: $select-black;

    @media (max-width: $tablet-screen-max-content) {
      gap: 80px;
    }

    @media (max-width: $mobile-screen-max-content) {
      flex-direction: column;
      gap: 40px;
    }
  }

  .socialMedia {
    display: flex;
    align-items: center;
    gap: 40px;

    a {
      color: $select-red;

      svg {
        width: auto;
        height: 40px;
        fill: currentColor;
      }
    }
  }

  .leftContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (max-width: $mobile-screen-max-content) {
      align-items: center;
    }

    .logo {
      width: 250px;
    }

    .text {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
    }

    .socialMedia {
      @media (max-width: $mobile-screen-max-content) {
        display: none;
      }
    }
  }

  .rightContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;

    .links {
      display: flex;
      flex-direction: column;
      gap: 4px;

      a {
        color: $select-black;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        text-decoration-line: underline;
      }
    }

    .contactUs {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .heading {
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
      }

      a {
        display: flex;
        align-items: center;
        color: $select-black;
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;

        .text {
          &:hover {
            text-decoration: underline;
          }
        }

        @media (max-width: $mobile-screen-max-content) {
          font-size: 16px;
        }

        .icon {
          margin-right: 16px;
          color: $select-red;
        }
      }
    }

    .socialMedia {
      display: none;

      @media (max-width: $mobile-screen-max-content) {
        display: flex;
      }
    }
  }
}
