@import 'assets/theme';
@import 'CORDS/assets/theme';

.button {
  align-self: center;
  margin-top: $standard-padding * 2;
  width: 284px;
  background-color: #3f52fbc9;
  border: 1px solid #3f52fbc0;
  color: #ffffff;

  &:active {
    opacity: 0.75;
  }
}

.floatingBtn {
  @extend .button;
  position: fixed;
  bottom: 16px;
  z-index: 999;
}

.cords {
  background-color: $cords-yellow;
  border: none;
  color: $cords-dark-blue;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: $standard-padding * 3 $standard-padding * 5;
  align-self: flex-start;
  min-width: 284px;

  i {
    font-weight: 600;
    font-size: 18px;
    margin-left: $standard-padding * 2;
  }
}
