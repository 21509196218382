@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  color: $white;
  background: #7628d5;
  position: relative;
  overflow: hidden;

  &.afterFirstSession {
    background-image: linear-gradient(180deg, #f25945 0%, #9442f7 100%);

    @media (min-width: $tablet-screen-max-content) {
      &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background-image: url('./MiddleBackground.png');
        background-position: 90% center;
        background-repeat: no-repeat;
        background-size: 120% 130%;

        @media (min-width: $medium-desktop-screen-max-content) {
          background-position: center;
          background-size: 100% 130%;
          transform: translateX(-5%);
        }
      }
    }
  }

  .wrapper {
    padding: 0;
    padding-top: 100px;
  }

  .firstLeftBackground {
    position: absolute;
    display: none;
    max-height: 750px;

    @media (min-width: $tablet-screen-max-content) {
      display: block;
      transform: translate(-60%, -10%);
      height: 120%;
    }

    @media (min-width: $medium-desktop-screen-max-content) {
      transform: translate(-55%, -10%);
    }
  }

  .nextSession {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 40%;
    padding-right: 40px;
    font-family: $title-font-stack;
    font-size: 80px;
    color: $backgroundYellow;
    background: url('./LeftBackground.svg') no-repeat;
    background-size: 167%;
    background-position: 100%;

    @media (min-width: $tablet-screen-max-content) {
      display: flex;
    }

    @media (min-width: $medium-desktop-screen-max-content) {
      padding-right: 80px;
    }
  }

  .loadingWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;

    .loading {
      &::after {
        border-color: $white transparent $white transparent;
      }
    }
  }

  .content {
    gap: 12px;
    width: auto;
    margin: 16px 20px 20px;
    padding: 20px 12px;
    line-height: normal;
    color: $helm-blue;
    border-radius: 8px;
    background-color: $backgroundYellow;
    z-index: 1;
    position: relative;

    @media (min-width: $medium-small-screen-min-width) {
      margin: 18px 60px 60px;
      padding: 20px;
    }

    @media (min-width: $tablet-screen-max-content) {
      align-self: flex-end;
      min-height: 500px;
      width: 40%;
      max-width: 600px;
      padding: 0;
      margin: 20px 19% 40px 0;
      background-color: unset;

      .nextSessionTitle {
        display: none;
      }
    }

    .background {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 0.2;
      z-index: -1;
    }
  }

  .beforeFirstSessionContent {
    @extend .content;
    gap: 16px;
    min-height: unset;
    margin: 0 auto;
    padding: 20px 20px 40px 20px;
    background-color: unset;

    @media (min-width: $medium-small-screen-min-width) {
      margin: 0 auto;
      padding: 60px 60px 48px;
    }

    @media (min-width: $tablet-screen-max-content) {
      align-self: flex-end;
      width: 70%;
      max-width: 764px;
      padding: 0;
      margin: 20px 80px 20px 180px;
    }

    @media (min-width: $medium-desktop-screen-max-content) {
      width: 55%;
      max-width: unset;
      margin: 20px 120px 40px;
    }

    .title {
      font-size: 32px;
      font-weight: 600;
      color: $backgroundYellow;

      @media (min-width: $medium-small-screen-min-width) {
        font-size: 44px;
      }

      @media (min-width: $tablet-screen-max-content) {
        font-size: 52px;
      }
    }

    .desc {
      font-size: 14px;
      font-weight: normal;
      color: $backgroundYellow;

      @media (min-width: $medium-small-screen-min-width) {
        font-size: 20px;
      }

      @media (min-width: $tablet-screen-max-content) {
        font-size: 24px;
      }
    }
  }

  .nextSessionTitle {
    font-family: $title-font-stack;
    color: $backgroundYellow;
    font-size: 32px;
    text-align: center;

    @media (min-width: $medium-small-screen-min-width) {
      font-size: 44px;
    }
  }

  .heading {
    margin-bottom: 12px;
    font-family: $title-font-stack;
    font-size: 14px;
    color: $helm-purple;

    &.invertMargin {
      margin-top: 12px;
      margin-bottom: 0;
    }

    @media (min-width: $tablet-screen-max-content) {
      font-size: 20px;
    }
  }

  .title {
    font-family: $title-font-stack;
    font-size: 32px;

    @media (min-width: $medium-desktop-screen-max-content) {
      font-size: 36px;
    }
  }

  .desc {
    font-family: $primary-font-stack-medium;
    font-size: 14px;
    font-weight: 500;

    @media (min-width: $tablet-screen-max-content) {
      font-size: 16px;
    }

    @media (min-width: $medium-desktop-screen-max-content) {
      font-size: 20px;
    }
  }

  .button {
    font-family: $title-font-stack;
    font-weight: normal;
    border-radius: 20px;
  }
}
