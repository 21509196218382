@import 'Select/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  overflow: auto; // for infinity load to works correctly

  .loadMoreWrapper {
    padding: 60px 0 24px;
  }
}
