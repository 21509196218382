@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  height: 92px;
  background: $someone-health-blue;
  gap: 16px;
  align-items: center;
  user-select: none;

  @media (max-width: $tablet-screen-max-content) {
    height: 120px;
    flex-direction: column;
    align-items: flex-start;
    gap: 23px;
    padding-top: 14px;
    padding-bottom: 17px;
  }

  .labelContainer {
    color: $white;
    width: 253.5px;

    .label {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
    }

    .subLabel {
      font-style: italic;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .content {
    display: flex;
    flex: 1;

    @media (max-width: $tablet-screen-max-content) {
      overflow-x: auto;
      overflow-y: hidden;
      max-width: 100%;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .filterContainer {
    display: flex;
    gap: 34px;
    flex: 1;
  }

  .optionList {
    display: flex;
    justify-content: space-between;
    max-width: 664px;
    flex: 1;

    .button {
      border: none;
      background: none;
      color: #fff;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
    }

    .selected {
      color: $someone-health-pink;
    }

    .unSelected {
      @media (max-width: $tablet-screen-max-content) {
        display: none;
      }
    }

    .disabled {
      color: $someone-health-grey500;
      cursor: not-allowed;
    }
  }

  .clearButton {
    color: $white;
    border: none;
    background: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    user-select: none;
  }
}
