@import 'assets/theme';
@import 'SomeoneHealth/assets/theme';

.container {
  margin: $standard-padding * 3 $standard-padding * 2.5;
  @include rowGap(24px);

  .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    color: #5666a6;
  }

  .row {
    display: flex;

    .field {
      display: flex;
      flex: 1;
      @include columnGap(8px);

      .label {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 130px;
        font-weight: bold;
        font-size: 14px;
        color: #949aa2;
        text-align: right;
      }

      .value {
        flex: 1;
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 14px;
        color: #414449;
      }
    }
  }
}

:global(.s1h-client-portal-theme) {
  .container {
    margin: $standard-padding * 3 $standard-padding * 5;
    .title {
      font-weight: 600;
    }

    .row .field {
      .label {
        font-weight: 500;
        color: $colorGrey700;
      }

      .value {
        font-weight: 400;
        color: $colorGrey700;
      }
    }
  }
}
