$large-screen-min-width: 1280px;
$medium-large_screen-min-width: 992px;
$medium-screen-min-width: 768px;
$medium-small-screen-min-width: 500px;
$small-screen-min-width: 320px;
$small-screen-max-width: 767px;

$medium-screen-min-height: 768px;
$medium-small-screen-min-height: 600px;

$smp-desktop-screen-max-content: 1440px;
$smp-tablet-screen-max-content: 820px;
$smp-mobile-screen-max-content: 700px;

$a4-print-width: 816px;
$a4-print-height: 1154px;
