@import 'SomeoneHealth/assets/theme';

.header {
  @media (max-width: $small-screen-max-width) {
    padding: 16px 0 !important;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 16px auto 0;
  padding-bottom: 110px;

  @media (min-width: 573px) and (max-width: 1102px) {
    padding-left: calc((100% - 572px) / 2);
  }

  .contentHeader {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .title {
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.02em;
      color: $colorGrey700;
    }

    .subHeader {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: $colorGrey600;
    }
  }

  .medicareCardContent {
    @media (max-width: 1102px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
  }

  .introClassName {
    max-width: 500px;
  }

  .verificationStatusClassName {
    width: 100%;
  }

  .submitButton {
    width: 100%;
    max-width: 540px;
  }
}
