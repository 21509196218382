@use 'sass:math';
@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  @media (max-width: $medium-small-screen-min-width) {
    padding: 16px;
  }
}

.scaleBarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.labelContainer {
  position: relative;

  .labelBox {
    position: absolute;
    right: 35px;
    bottom: -15px;
    height: 380px;
    display: grid;
    align-items: center;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.1px;
    text-align: right;
    text-transform: uppercase;
    color: #2a4670;
  }

  .labelBoxSmaller {
    position: absolute;
    right: 35px;
    bottom: -15px;
    height: 180px;
    width: 120px;
    display: grid;
    align-items: center;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.1px;
    text-align: right;
    text-transform: uppercase;
    color: #2a4670;
  }
}

.sliderContainer {
  display: flex;
  justify-content: center;
  transform: rotate(-90deg);
  position: relative;
  z-index: 99;

  .slider {
    position: absolute;
    bottom: -100px;
    left: 6px;
    z-index: 2;

    @media (max-width: $medium-screen-min-width) {
      bottom: -94px;
    }

    @supports (-moz-appearance: none) {
      bottom: -100px;
      left: 6px;
    }

    .hexSliderRange {
      touch-action: pan-x;
      transform: rotate(0deg);
    }
  }

  .pointerContainer {
    position: relative;
    z-index: 1;

    @for $i from 0 through 11 {
      &[data-value='#{$i}'] .circleContainer {
        left: math.percentage(math.div($i, 11));
      }
    }

    .label {
      position: absolute;
      left: -8px;
      top: 38px;

      @media (max-width: $medium-screen-min-width) {
        top: 26px;
      }

      .circleContainer {
        position: relative;
        width: 375px;

        .triangle {
          width: 0;
          height: 0;
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-bottom: 16px solid #56a756;
          position: relative;
          left: -26px;
        }

        .circle {
          width: 58px;
          height: 58px;
          position: relative;
          left: -46px;
          bottom: 7px;

          .profileWrapper {
            transform: rotate(90deg);
            display: flex;
            justify-content: center;
            align-items: center;
            height: 58px;
            width: 58px;
            border-radius: 50%;
            background-color: #56a756;

            .profile {
              height: 52px;
              width: 52px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}

.hexSliderRange {
  -webkit-appearance: none;
  margin: 0 0;
  width: 386px;
  height: 45px;
  background-color: transparent;

  @media (max-width: $medium-screen-min-width) {
    height: 110px;
  }

  @supports (-moz-appearance: none) {
    margin: 6px 0;
  }
}

.hexSliderRange:focus {
  outline: none;
}

.hexSliderRange::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  height: 110px;
  border: 0 solid #000101;
}

.hexSliderRange::-webkit-slider-thumb {
  border: 0 solid #000000;
  height: 110px;
  width: 40px;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.hexSliderRange:focus::-webkit-slider-runnable-track {
  background: transparent;
  outline: none;
}

.hexSliderRange::-moz-range-track {
  width: 100%;
  height: 110px;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  border: 0 solid #000101;
}

.hexSliderRange::-moz-range-thumb {
  border: 0 solid #000000;
  height: 40px;
  width: 40px;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;
}

.hexSliderRange::-ms-track {
  width: 100%;
  height: 110px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 39px 0;
  color: transparent;
}

.hexSliderRange::-ms-fill-lower {
  background: transparent;
  border: 0 solid #000101;
  border-radius: 50px;
  box-shadow: none;
}

.hexSliderRange::-ms-fill-upper {
  background: transparent;
  border: 0 solid #000101;
  border-radius: 50px;
  box-shadow: none;
}

.hexSliderRange::-ms-thumb {
  box-shadow: none;
  border: 0 solid #000000;
  height: 50px;
  width: 50px;
  border-radius: 7px;
  background: #57a756;
  box-sizing: content-box;
  border-radius: 80% 0 55% 50% / 55% 0 80% 50%;
  cursor: pointer;
  margin-top: -3.6px;
  position: relative;
  top: 50px;
  -webkit-transform: rotateZ(315deg);
  transform: rotateZ(315deg);
}

.hexSliderRange:focus::-ms-fill-lower {
  background: transparent;
}

.hexSliderRange:focus::-ms-fill-upper {
  background: transparent;
}
