@import 'assets/theme';

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  padding-bottom: 16px;

  @media (max-width: $medium-screen-min-width) {
    font-size: 18px;
  }
}

.subTitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  padding-bottom: 12px;

  @media (max-width: $medium-screen-min-width) {
    font-size: 18px;
  }
}
