@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;

  .title {
    font-weight: 600;
    font-size: 13px;
    line-height: 23px;
    color: #192a3e;
  }
}
