@import 'assets/theme';

.container {
  height: max-content;
  position: relative;
  overflow: auto;
  z-index: 1;

  .headerWrapper {
    display: flex;
    padding: 0 16px 24px;
  }

  .contentWrapper {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    position: relative;
    z-index: 3;
  }
}
