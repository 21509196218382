@import 'Portobello/assets/theme';

.container {
  display: flex;
  flex-direction: column;

  &:focus-within {
    .label {
      color: #363d78;
    }
  }
}

.label {
  margin-bottom: 8px;
  font-size: 12px !important;
  user-select: none;

  @media (min-width: $tablet-screen-max-content) {
    font-weight: 500;
  }
}

.errorMessage {
  margin-bottom: 0 !important;
}

.inputRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  .input {
    padding: 4px 12px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #b5b8bd;
    box-sizing: border-box;

    &.day,
    &.month {
      width: 56px;
    }

    &.year {
      width: 76px;
    }

    &.error {
      border-color: #d62828;
    }

    &:focus,
    &:active {
      border-color: var(--color-primary);
    }
  }

  .separator {
    font-size: 16px;
    font-weight: 600;
    color: #b5b8bd;
  }
}

.error {
  .label {
    color: #d62828;
  }

  .inputContainer .input {
    border-color: #d62828;
  }
}
