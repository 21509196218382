@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 8px 12px;
  border-radius: 4px;
  line-height: 22px;
  border: 0.5px solid transparent;

  .label {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #818e9b;
  }

  .value {
    font-size: 14px;
    line-height: 22px;
  }
}
