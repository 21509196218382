@import 'Ease/assets/theme';

.container {
  height: 100%;
  overflow: auto;
  position: relative;
  z-index: 1;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 3;
  }

  .content {
    display: flex;
    flex-direction: column;
    color: $ease-dark-grey;
    padding: 16px 16px 120px;

    .contentCardShadow {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border: none;
    }

    .titleContainer {
      display: flex;
      justify-content: space-between;

      .title {
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        margin-bottom: $standard-padding * 3;
        margin-top: $standard-padding * 4;
      }
      .timeZone {
        display: flex;
        align-items: flex-end;
        margin-bottom: 8px;
        font-size: 16px;
      }
    }
  }
}
