@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-width: 300px;
  padding: $standard-padding * 4;
  color: $helm-blue;
  background-color: $white;
  box-shadow: inset 0 4px 12px rgba(241, 223, 87, 0.2);
  border-radius: 12px;
  cursor: pointer;
  margin-bottom: 12px;

  @media (max-width: $tablet-screen-max-content) {
    max-width: 250px;
    min-width: 250px;
  }

  &.selected {
    background-color: $helm-blue;
    color: $white;
    cursor: auto;
  }

  &.disable {
    cursor: not-allowed;
    opacity: 0.9;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: $standard-padding;

    .title {
      font-family: $primary-font-stack-bold !important;
      font-size: 18px;
      line-height: 23px;
    }

    .advisorIcon {
      font-size: 24px;
    }
  }

  .desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
  }

  .detailWrapper {
    display: flex;
    flex-direction: column;
    @include rowGap(4px);

    .detailCard {
      display: flex;
      align-items: center;
      font-family: $primary-font-stack-bold !important;
      font-size: 16px;
      line-height: 27px;

      .icon {
        display: flex;
        align-items: center;
        font-size: 22px;
        margin-right: 8px;
      }
    }
  }
}

.containerSelect {
  @extend .container;
  color: $white;
  background-color: $helm-blue;
}
