@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;

  .signLabel {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    align-items: center;
  }

  .signatureContainer {
    display: flex;
    align-items: center;
    margin-bottom: $smp-standard-padding * 2;
    justify-content: flex-end;

    .signature {
      color: #000000;
      font-size: 44px;
      font-family: Kaushan Script;

      .signatureImg {
        margin: 8px 0;
        max-width: 200px;
        max-height: 80px;
      }
    }
  }

  .extraDetailsWrapper {
    color: #343434;
    font-size: 16px;
    margin-top: 12px;

    .extraDetails {
      &:first-of-type {
        font-weight: bold;
      }

      &:not(:first-of-type) {
        font-size: 12px;
      }
    }
  }
}
