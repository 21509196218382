@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 16px 0;

  .backBtnWrapper {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 11px;
    line-height: 18px;
    cursor: pointer;

    .icon {
      display: flex;
      width: 26px;
      font-size: 48px;
      position: relative;
      right: 14px;
    }
  }

  .infoWrapper {
    display: flex;

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      padding-right: 8px;

      .text {
        font-weight: 600;
        font-size: 11px;
        line-height: 20px;
        text-transform: uppercase;
        color: #fff;
      }
    }

    .profile {
      padding: 0;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
}
