@import 'helm/assets/theme/index';

.inputLabel {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.container {
  display: flex;
  flex-direction: column;

  .gpReferralContainer {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .gpTitle {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      margin-bottom: 8px;
    }

    .gpOption {
      margin: 8px 0 16px;
      max-width: 540px;
    }

    .gpForm {
      padding: 0;
    }
    .uploadAreaContainer {
      background-color: $darkLightBlue;
      label {
        > div {
          border-color: $helm-primary !important;
          color: $helm-primary !important;
        }
      }
    }
  }
}

.customSelect {
  padding-bottom: $standard-padding * 2;
}

.selectInputLabel {
  @extend .inputLabel;
  margin-bottom: 0;
  font-size: 12px;

  &:focus {
    color: #d3d8dd !important;
  }
}
