@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  margin: 66px 0;

  .titleWrapper {
    max-width: 100%;
  }

  .title {
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    letter-spacing: -0.02em;
    width: 100%;
    max-width: $desktop-screen-max-content;
    margin: 0 auto 16px;
  }

  .programContainer {
    display: flex;
    position: relative;

    &:hover {
      .leftScrollButton {
        transform: scale(1);
        transition-duration: 0.5s;
        opacity: 1;
      }
    }

    .programWrapper {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      .programCard {
        display: flex;
        padding-right: 24px;
        transform: translateX(calc(max(1440px, 100vw) / 2 - 730px));
        @include columnGap(24px);

        @media (max-width: 1460px) {
          &:first-child {
            margin-left: 24px;
          }
        }
      }
    }

    .arrowButton {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      color: $white;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 100px;
      width: 72px;
      height: 72px;
    }

    .leftScrollButton {
      position: absolute;
      text-align: center;
      top: 49%;
      left: 16px;
      user-select: none;
      cursor: pointer;
      transition: transform 0.5s ease-in, opacity 0.7s cubic-bezier(0.15, 0, 0.2, 1) 0.1s;
      transform: scale(0.8);
      opacity: 0;
    }

    .rightScrollButton {
      @extend .leftScrollButton;
      left: auto;
      right: 16px;
    }

    .hideButton {
      transform: scale(0.8) !important;
      opacity: 0 !important;
      cursor: default;
    }
  }

  .dotWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    @include columnGap(12px);

    .dot {
      height: 20px;
      width: 20px;
      background-color: $text-disable-grey;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s;
    }

    .dotActive {
      @extend .dot;
      background-color: $helm-blue;
    }
  }
}
