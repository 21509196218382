@import 'assets/theme';

.container {
  background-color: #fff;

  .completeTaskBox {
    display: flex;
    flex-direction: column;
    padding: 8px 0 48px;
    max-width: 530px;

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      padding-bottom: 16px;

      @media (max-width: $medium-screen-min-width) {
        font-size: 20px;
        padding-bottom: 8px;
      }
    }
  }

  .continueTaskBox {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    row-gap: 16px;
    max-width: 530px;

    .continueTaskHeader {
      display: flex;
      flex-direction: column;

      .continueTaskTitle {
        font-weight: bold;
        font-size: 24px;
        line-height: 22px;
        color: #3f52ff;
        padding-bottom: 8px;

        @media (max-width: $medium-screen-min-width) {
          font-size: 20px;
        }

        @media (max-height: $medium-small-screen-min-height) {
          font-size: 18px;
        }
      }

      .continueTaskDesc {
        font-size: 18px;
        line-height: 22px;
        color: #343434;

        @media (max-height: $medium-small-screen-min-height) {
          font-size: 14px;
        }
      }
    }
  }
}
