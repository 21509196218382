@import 'Select/assets/theme';

.container {
  background-size: cover;
  background-image: url('~Select/assets/images/background/filterBackground.png');
  background-repeat: no-repeat;

  .content {
    display: flex;
    flex-direction: column;
    padding: 48px 68px;
    gap: 24px;

    @media (max-width: $tablet-screen-max-content) {
      padding: 32px 0 20px;
    }

    .filterWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $white;

      @media (max-width: $tablet-screen-max-content) {
        flex-direction: column;
        align-items: center;
      }

      .label {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        margin-right: 64px;
        text-shadow: 0 0 8px $black;

        @media (max-width: $tablet-screen-max-content) {
          margin-right: 0;
          width: 100%;
          text-align: center;
        }
      }

      .filter {
        width: 96%;
        flex: 1;
        max-width: 96%;
      }
    }
  }
}
