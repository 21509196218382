@import 'MumsMatter/assets/theme';

.containerWrapper {
  flex: 1;

  .headerWrapper {
    .header {
      padding: 12px 0 !important;

      @media (max-width: $medium-large_screen-min-width) {
        position: unset;
        padding: 0 !important;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    padding: 8px 0 24px;

    .title {
      font-size: 40px;
      font-weight: 600;
      line-height: 62px;
      margin-bottom: 16px;

      @media (max-width: $medium-large_screen-min-width) {
        font-size: 24px;
        margin-bottom: 4px;
      }
    }

    .link {
      font-size: 20px;
      color: $mums-matter-green;
      text-decoration: underline;
      cursor: pointer;

      @media (max-width: $medium-large_screen-min-width) {
        font-size: 14px;
      }
    }

    .heading {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;

      @media (max-width: $medium-large_screen-min-width) {
        font-size: 16px;
        line-height: 22px;
      }
    }

    .infoContainer {
      display: flex;
      flex-direction: column;
      max-width: 860px;
      padding: 24px 32px;
      gap: 20px;
      border-radius: 8px;
      background-color: $white;
      box-shadow: 0 4px 8px 0 #0000001a;
      border: 1px solid #c0c0c0;

      @media (max-width: $medium-large_screen-min-width) {
        padding: 16px;
      }

      .desc {
        font-size: 20px;

        @media (max-width: $medium-large_screen-min-width) {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }

    .taskContainer {
      display: flex;
      flex-direction: column;
      max-width: 860px;
      padding: 24px 32px;
      gap: 20px;
      border-radius: 8px;
      background-color: $white;
      margin-top: 24px;
      box-shadow: 0 4px 8px 0 #0000001a;
      border: 1px solid #c0c0c0;

      @media (max-width: $medium-large_screen-min-width) {
        padding: 16px;
      }
    }

    .submitButton {
      @media (max-width: $medium-large_screen-min-width) {
        font-size: 14px;
        height: 40px;
      }
    }

    .tasks {
      ul {
        padding: 0;
        margin-bottom: 0;

        li {
          font-weight: 400;
          font-size: 14px;
          display: flex;
          align-items: center;
          list-style: none;
          list-style-position: inside;
          margin: $standard-padding * 2 0;

          .circle {
            vertical-align: top;
            display: inline-block;
            width: 4px;
            height: 4px;
            border: solid 2px;
            border-radius: 10px;
            margin-top: 1px;
            margin-right: $standard-padding * 3;
          }

          .liText {
            flex: 1;
            font-size: 18px;
            line-height: 23px;

            @media (max-width: $medium-large_screen-min-width) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
