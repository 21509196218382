@import 'assets/theme';

.finished {
  display: block;
  margin: 1px;
  border-radius: 50%;
  border: 2px solid #fff;
  animation: fadeinout 2s linear forwards;

  .icon {
    display: block;
    font-size: 14px;
    color: #fff;
    animation: float 2s;
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes float {
  0% {
    transform: translateY(20px);
  }

  20% {
    transform: translateY(0px);
  }

  40% {
    transform: translateY(0px);
  }

  60% {
    transform: translateY(0px);
  }

  80% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-20px);
  }
}
