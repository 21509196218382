@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;

  .profileWrapper {
    display: flex;
    align-items: center;

    .profileCard {
      display: flex;
      align-items: center;
    }

    .profile {
      height: 50px;
      width: 50px;

      @media (max-width: $tablet-screen-max-content) {
        height: 40px;
        width: 40px;
      }
    }

    .name {
      font-size: 20px;
      line-height: 26px;
      color: $white;
      margin-left: 16px;
      padding-top: 4px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 16px;
        line-height: 26px;
      }

      .menuIcon {
        font-size: 30px;
        user-select: none;
      }
    }

    .namePink {
      @extend .name;
      color: $someone-health-pink;
    }
  }

  .loginWrapper {
    display: flex;

    .login {
      font-size: 20px;
      line-height: 26px;
      text-decoration-line: underline;
      color: $someone-health-pink;
      cursor: pointer;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 16px;
      }
    }

    .loginWhite {
      @extend .login;
      color: $white;
    }
  }
}

:global(.s1h-client-portal-theme) {
  .profileWrapper {
    .name {
      color: $someone-health-maroon2;
      line-height: 0;
      padding: 8px;
      border-radius: 50%;
      margin-left: 4px;

      &:hover {
        color: $someone-health-maroon;
        background-color: #eafaeb;
      }
    }
  }

  .loginWrapper .login {
    color: $someone-health-maroon;
  }
}
