@import 'Recharge/assets/theme';

.container {
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;

  .inputLabel {
    color: $recharge-black;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }

  .selectInputLabel {
    @extend .inputLabel;
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;
  }

  .fixCustomInput {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .customInput {
    transform: translateX(-$standard-padding);
    padding: 0 $standard-padding;
    color: #343434;
    margin-bottom: $standard-padding !important;

    input {
      width: 100%;
    }
  }

  .header {
    display: flex;

    .headerText {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;
      display: block;
    }
  }

  .subHeader {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 6px;
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 21px;
  }

  .submitButton {
    width: 100%;
    margin-top: $standard-padding * 7;
    margin-bottom: $standard-padding * 15;

    @media (max-width: $mobile-screen-max-content) {
      margin-bottom: $standard-padding * 20;
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.selectContainer {
  z-index: 1;
}
