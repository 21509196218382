@import '../../assets/theme/index';

.container {
  display: flex;
  background-color: white;
  min-height: 100%;

  .contentLayout {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1280px;
    margin: 0 auto;
  }
}

.fullHeightContentLayout {
  @extend .contentLayout;
  height: 100vh;
}
