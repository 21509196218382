@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  overflow: auto;

  .contentWrapper {
    background-color: $white;

    &:nth-child(even) {
      background-color: $someone-health-light-blue;
    }

    .noResultWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $standard-padding * 10 0;

      .noResultTitle {
        font-weight: 700;
        font-size: 32px;
        line-height: 42px;
        letter-spacing: -0.02em;
        color: $someone-health-blue;
      }

      .noResultDesc {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: $someone-health-blue;
        padding-top: $standard-padding * 4;

        .clearAllFiltersButton {
          color: $someone-health-pink;
          cursor: pointer;
          user-select: none;
        }
      }
    }

    .recommendWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $standard-padding * 6 0 $standard-padding * 10;

      @media (max-width: $tablet-screen-max-content) {
        padding: $standard-padding * 2 0 $standard-padding * 2;
      }

      .trustLabel {
        text-align: center;
        width: 80%;
        font-weight: 700;
        font-size: 36px;
        letter-spacing: -0.02em;
        color: $someone-health-blue;
        border-bottom: 1px solid $someone-health-pink;
        line-height: 0.1em;
        margin: 20px 0;

        @media (max-width: $tablet-screen-max-content) {
          line-height: unset;
          font-size: 24px;
          border-bottom: none;
          width: 100%;
          margin: 16px 0;
        }

        .label {
          background: $someone-health-light-blue;
          padding: 0 10px;

          @media (max-width: $tablet-screen-max-content) {
            border-bottom: 1px solid $someone-health-pink;
            padding-bottom: 8px;
          }
        }
      }
    }
  }
}
