@import 'MumsMatter/assets/theme';

.container {
  display: flex;
  flex-direction: column;
}

.numberBubbleClassName {
  margin-right: 16px !important;
  background-color: $mums-matter-light-orange !important;
  color: $mums-matter-dark-blue !important;
}

.title {
  text-align: left !important;
  color: $mums-matter-dark-blue !important;
}

.content {
  padding-left: 46px;

  .listWrapper {
    overflow-x: auto;
    max-width: 100%;
    padding-bottom: 12px;

    .listOfAppointmentTypes {
      display: flex;
      gap: 70px;
    }
  }

  @media (max-width: $small-screen-max-width) {
    padding-left: 0;

    .listWrapper .listOfAppointmentTypes {
      gap: 10px;
    }
  }
}

.loadingWrapper {
  display: flex;
  @include columnGap(40px);

  .loading {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 420px;
    padding: $standard-padding * 4;
    color: $mums-matter-dark-blue;
    background-color: $mums-matter-light-orange;
    box-shadow: inset 0 4px 12px rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    cursor: pointer;
  }
}
