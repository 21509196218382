@import 'assets/theme';

.center {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: $standard-padding;
}
