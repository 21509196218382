@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
  border-radius: 8px;
  border: 1px solid #dadbde;
  background: $white;
  gap: 20px;

  .rounded {
    border-radius: 8px;
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;

    .name:not(span) {
      width: 90%;
    }

    .desc:not(span) {
      width: 50%;
    }

    .intro:not(span) {
      width: 100%;
    }
  }

  .specialisationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;

    .title:not(span) {
      width: 30%;
      align-self: flex-start;
    }

    .specialisationList {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;

      .smallSpecialisation:not(span) {
        width: 20%;
      }

      .mediumSpecialisation:not(span) {
        width: 35%;
      }
    }
  }

  .bottomContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;

    .nextAvailable:not(span) {
      width: 90%;
    }

    .seeAll:not(span) {
      width: 70%;
    }
  }
}

.listSkeletonContainer {
  display: flex;
  flex-direction: column;
  overflow: auto;

  .contentWrapper {
    background-color: $white;

    &:nth-child(even) {
      background-color: $someone-health-light-blue;
    }

    .content {
      display: flex;
      padding: $standard-padding * 6 0;

      .loadingWrapper {
        display: flex;
        flex: 1;

        @media (max-width: $tablet-screen-max-content) {
          flex-direction: column;
        }

        .profileWrapper {
          display: flex;
          flex: 1;
          min-width: 330px;
          flex-direction: column;
          @include rowGap($standard-padding * 6);

          @media (max-width: $small-desktop-screen-max-content) {
            min-width: 280px;
            flex: 1;
          }

          .bonaFidesLoadingWrapper {
            display: flex;
            flex-direction: column;
            @include rowGap($standard-padding * 2);

            .bonaFidesLoading {
              height: 18px;
              width: 90%;
            }
          }
        }

        .detailsContainer {
          display: flex;
          flex: 1 75%;
          flex-direction: column;
          padding-left: $standard-padding * 6;
          overflow-x: hidden;
          overflow-y: hidden;
          @include rowGap(24px);

          @media (max-width: $tablet-screen-max-content) {
            padding-left: 0;
            padding-top: $standard-padding * 6;
            overflow-x: unset;
            overflow-y: unset;
          }
        }
      }
    }
  }
}
