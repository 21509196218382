@import 'helm/assets/theme';

.modalHeader {
  .title {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }

  .subTitle {
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
}

.container {
  margin-right: -16px;
  max-height: calc(100vh - 260px);
  overflow-y: auto;

  .filterWrapper {
    padding-right: 16px;

    .filterItem:not(:last-child) {
      border-bottom: solid 1px #eee;
    }
  }

  .filter {
    padding: 10px 0 30px;
  }
}

.modalFooter {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modalClass {
  :global {
    .ant-modal-content {
      font-family: 'Roboto', sans-serif;
      height: calc(100vh - 40px);
      width: 100% !important;
      max-width: 100%;
      margin: 0;
      top: 0;
    }

    .ant-modal-content {
      margin: 40px 0 0;
    }

    .ant-modal-body {
      height: calc(100vh - 250px) !important;
    }
  }
}
