@import 'helm/assets/theme';

.container {
  background-position: 'center';
  background-repeat: 'no-repeat';
  background-size: 'cover';
  background: linear-gradient(0deg, #4b6184, #4b6184, #2a4670 40%) no-repeat;
  height: max-content;
  min-height: 100vh;
  padding-bottom: $standard-padding * 10;
  position: relative;
  z-index: 1;
}
