.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  overflow: auto;
  z-index: 1;
  flex: 1 0 auto;

  .headerContent {
    display: flex;
    width: 100%;
    flex: initial;
  }

  .headerWrapper {
    display: flex;
  }
}
