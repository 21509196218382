.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.practiceHeader {
  display: flex;
  align-items: center;
  position: relative;
  left: -120px;
  top: 40px;
  margin: auto;
  max-width: 600px;

  @media (max-width: 850px) {
    left: 10px;
  }

  .offsetLeft {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 5px;

    .practiceLogo {
      border-radius: 50%;
      width: 100px;
      @media (max-width: 850px) {
        width: 80px;
      }
    }

    .backBtnWrapper {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #343434;
      line-height: 18px;
      cursor: pointer;
      position: relative;
      top: 30px;
      @media (max-width: 850px) {
        top: 0px;
      }
      .icon {
        color: #bdbdbd;
        display: flex;
        width: 26px;
        font-size: 48px;
        position: relative;
        right: 14px;
      }
    }
  }
  .practiceName {
    margin-left: 36px;
    font-size: 28px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 36px;
  }
}

.cardContainer {
  align-items: flex-start !important;
  padding: 0px 16px !important;
  max-width: 600px;
  margin: auto;

  @media (max-width: 850px) {
    margin-top: 50px;
  }

  .title {
    font-size: 18px;
    line-height: 20px;
    color: #343434;
  }
}
.appointmentTypeWrapper {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  .appointmentName {
    font-weight: bold;
    font-size: 18px;
  }
  .filterTitle {
    padding-top: 33px;
    text-transform: uppercase;
    font-size: 11px;
    color: #818e9b;
  }
  .filterList {
    display: flex;
    flex-wrap: wrap;
  }
}
