@import 'assets/theme';
@import 'helm/assets/theme';

.headerContainer {
  padding: $standard-padding * 2 $standard-padding * 4;
  display: flex;
  justify-content: space-between;
  column-gap: $standard-padding;

  .header {
    color: $main-blue;
    display: flex;
    font-size: 24px;
    font-weight: 600;
    align-items: center;

    .totalNumber {
      color: white;
      display: inline-block;
      padding: 6px 12px;
      font-size: 16px;
      min-width: 36px;
      min-height: 36px;
      text-align: center;
      line-height: 24px;
      margin-left: $standard-padding * 2;
      border-radius: 50%;
      background-color: $main-blue;
    }
  }
}

.content {
  padding: $standard-padding * 4;

  &.disableContentPadding {
    padding: 0;
  }

  &.disableContentPaddingTop {
    padding-top: 0;
  }
}

:global(.helm-theme) {
  .headerContainer {
    font-family: $title-font-stack;
  }

  .content {
    font-family: $primary-font-stack-medium;
  }
}
