@import 'assets/theme';

.container {
  background-size: cover;
  background-position: center;

  .innerContainer {
    padding: 0 $smp-standard-padding * 20;

    .titleContainer {
      max-width: 314px;
      margin-bottom: $smp-standard-padding * 16;

      .title {
        font-size: 3rem;
        margin-bottom: $smp-standard-padding;
      }

      .subtitle {
        color: $smp-light-green;
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1.2;
      }
    }

    .list {
      font-size: 1.1rem;
      width: 390px;

      .listItem {
        margin-bottom: $smp-standard-padding * 8;
        padding-left: $smp-standard-padding * 6;

        .label {
          font-weight: bold;
        }
      }
    }
  }
}
