@import 'SomeoneHealth/assets/theme';

.header {
  @media (max-width: $small-screen-max-width) {
    padding: 16px 0 !important;
  }
}

.container {
  margin-top: 16px;
  display: flex;
  width: 100%;
  padding-bottom: 30px;
  justify-content: space-around;

  @media (max-width: $medium-desktop-screen-max-content) {
    padding-left: 100px;
  }

  @media (max-width: $small-desktop-screen-max-content) {
    padding-left: 0;
    padding-right: 0;
    justify-content: space-evenly;
    padding-bottom: unset;
  }
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.loginTextClass {
  color: $someone-health-pink !important;
}

.leftContent {
  @media (min-width: ($tablet-screen-max-content + 1)) {
    padding-right: 20px;
  }
}

.rightContent {
  max-width: 410px;

  @media (max-width: $small-desktop-screen-max-content) {
    max-width: 350px;
  }

  .clientTest {
    width: 100%;
  }

  .topNotch {
    display: flex;
    justify-content: center;
    margin-top: 54px;
    width: 100%;
    height: 435px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid #fa837f;
    flex-direction: column;
    padding: 0px 68px;
    gap: 20px;

    .topNotchTitle {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: $someone-health-blue;
    }

    .topNotchDesc {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: $colorGrey700;
    }
  }

  @media (max-width: $tablet-screen-max-content) {
    display: none;
  }
}
