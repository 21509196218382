@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: unset;
  margin: 0;
  padding: 64px 0;

  .title {
    padding: 0 20px;
    font-family: $title-font-stack;
    font-size: 32px;
    color: $helm-blue;

    @media (min-width: $medium-small-screen-min-width) {
      padding: 0 60px;
      font-size: 44px;
    }

    @media (min-width: $tablet-screen-max-content) {
      padding: 0 100px;
      font-size: 48px;
    }

    @media (min-width: $medium-desktop-screen-max-content) {
      padding: 0 120px;
      font-size: 52px;
    }
  }

  .programContainer {
    display: flex;
    position: relative;

    &:hover {
      .leftScrollButton {
        transform: scale(1);
        transition-duration: 0.5s;
        opacity: 1;
      }
    }

    .programWrapper {
      display: flex;
      gap: 24px;
      padding: 16px;
      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      .programCard {
        display: flex;
        gap: 24px;
      }
    }

    .arrowButton {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      color: $white;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 100px;
      width: 72px;
      height: 72px;
    }

    .leftScrollButton {
      position: absolute;
      text-align: center;
      top: 49%;
      left: 16px;
      user-select: none;
      cursor: pointer;
      transition: transform 0.5s ease-in, opacity 0.7s cubic-bezier(0.15, 0, 0.2, 1) 0.1s;
      transform: scale(0.8);
      opacity: 0;
    }

    .rightScrollButton {
      @extend .leftScrollButton;
      left: auto;
      right: 16px;
    }

    .hideButton {
      transform: scale(0.8) !important;
      opacity: 0 !important;
      cursor: default;
    }
  }

  .dotWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    @include columnGap(12px);

    .dot {
      height: 20px;
      width: 20px;
      background-color: $text-disable-grey;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s;
    }

    .dotActive {
      @extend .dot;
      background-color: $helm-blue;
    }
  }
}
