@import 'helm/assets/theme';

.modalContainer {
  max-width: 767px;
  top: 0 !important;
  margin: 0 auto;
  height: 100% !important;
  width: 100% !important;

  .content {
    padding: 0;
    width: 100%;
    height: calc(100vh - 47px);
  }
}
