@import 'SomeoneHealth/assets/theme';

.fieldContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 32px;
  max-width: 400px;

  &.fieldError,
  .fieldError {
    font-size: 12px;
    color: #ff4d4f;

    input {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }

  .radioLabelClass {
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: #343434;
  }
}

.phoneContainer {
  @extend .fieldContainer;
  margin: -10px 0 24px;
}

.phoneInput {
  margin-bottom: 0;
}

.selectContainer {
  @extend .fieldContainer;
  margin: 12px 0 32px;

  .selectContainerChild {
    z-index: 4;
  }
}

.fieldWithTooltip {
  display: flex;
  flex-direction: row;
  align-items: center;

  .leftContainer {
    flex: 1;
  }
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #343434;
  margin-bottom: 16px;
}

.toggleSwitchWrapper {
  margin: 32px 0 28px;

  .fieldLabel {
    font-size: 11px;
    color: #818e9b;
    font-weight: 600;
    padding-bottom: 8px;
  }
}

:global(.someone-health-theme) {
  .fieldContainer {
    max-width: 540px;
  }
}
