.contentWrapper {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 0 16px;
  background-color: #2a4670;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.fullHeightFooter {
  height: calc(100% - 82px);
}
.cardContainer {
  align-items: flex-start !important;
  padding: 26px 16px !important;
  .title {
    font-size: 18px;
    line-height: 20px;
    color: #343434;
  }
}
.appointmentTypeWrapper {
  display: flex;
  flex-direction: column;
  padding: 5px 0;

  .appointmentName {
    font-weight: bold;
    font-size: 18px;
  }

  .timezone {
    font-weight: 600;
    padding-top: 8px;
  }

  .filterTitle {
    padding-top: 33px;
    text-transform: uppercase;
    font-size: 11px;
    color: #818e9b;
  }

  .filterList {
    display: flex;
    flex-wrap: wrap;
  }
}
