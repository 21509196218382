@import 'helm/assets/theme';

.container {
  overflow: hidden;
  display: inline-block;
  width: 100%;
  height: 100%;
  mask-size: 100% 100%;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-image: url(~helm/assets/images/shape/4.png);

  .slideshowSlider {
    display: block;
    white-space: nowrap;
    transition: ease 1000ms;
    height: 100%;

    .slide {
      display: inline-flex;
      width: 100%;
      height: 100%;
    }

    .backgroundPhoto {
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }
}
