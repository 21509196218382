@import 'CaW/assets/theme';

.container {
  display: flex;
  align-items: center;
  height: 120px;

  @media (max-width: $tablet-screen-max-content) {
    height: 56px;
  }
}

.noWrapperContainer {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.padding {
  padding: $standard-padding * 2 $standard-padding * 4 !important;
}

.logoWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  z-index: 999;
  height: 80px;

  .logoBox {
    cursor: pointer;
  }

  .logo {
    max-width: 200px;

    @media (max-width: $tablet-screen-max-content) {
      max-width: 160px;
    }
  }
}
