@import 'Recharge/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  font-family: $font, sans-serif;
  min-height: 100vh;
  background-color: $recharge-purple;
  position: relative;
  // specially for Recharge's font, Montserrat
  font-variant: normal;

  input,
  textarea,
  button {
    font-family: $font, -apple-system, BlinkMacSystemFont, sans-serif;
  }

  .background1 {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 50%;
    width: 100%;
    max-width: 90vw;
    background-size: contain;
    background-image: url('~Recharge/assets/images/background/shape1.png');
    background-position: right bottom;
    background-repeat: no-repeat;
  }

  .background2 {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 35vh;
    width: 100%;
    max-width: 60vw;
    background-size: contain;
    background-image: url('~Recharge/assets/images/background/shape2.png');
    background-position: left bottom;
    background-repeat: no-repeat;
  }

  .content {
    height: 100vh;
    overflow: auto;
    position: relative;
    z-index: 1;
  }
}
