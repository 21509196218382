@import 'CaW/assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .filterTitleWrapper {
    display: flex;
    position: relative;

    .filterTitle {
      position: absolute;
      top: 6px;
      padding: 0 16px;
      transition: all 0.3s;
      z-index: -1;
    }

    .showTitle {
      top: -24px;
      z-index: 0;
    }
  }

  .selectButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: $white;
    border: 1px solid $white;
    border-radius: 24px;
    color: #414141;
    padding: 8px 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    user-select: none;
    transition: all 0.3s;
    min-height: 48px;

    .arrow {
      font-size: 22px;
      margin-left: 8px;
    }

    .label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 90%;
    }

    .clearButton {
      padding: 0;
      background: none;
      border-radius: 50%;
      height: 22px;
      width: 22px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: $caw-dark-red;

        .clearIcon {
          color: $white;
        }
      }

      .clearIcon {
        font-size: 16px;
      }
    }
  }

  .openMenu {
    border: 1px solid #7b61ff;
  }

  .badgeStyles {
    background: $caw-dark-red;
    color: $white;
    border: 1px solid #fff;
  }

  .menuWrapper {
    display: flex;
    z-index: 1;

    .menuBoxHide {
      position: absolute;
      min-width: 100px;
      width: max-content;
      background: #fff;
      box-shadow: 0 48px 88px -4px rgba(23, 9, 54, 0.06);
      margin-top: 4px;
      border-radius: 8px;
      visibility: hidden;
      opacity: 0;
      padding: 8px;
      transition: all 0.3s;
      transform: scaleY(0);
      transform-origin: top;

      .listBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
        color: $colorGrey800;
        transition: all 0.2s ease;
        cursor: pointer;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;

        &:hover {
          color: #160042;
          background-color: #f3f5fb;
        }

        .rightArrow {
          font-size: 16px;
          line-height: 24px;
          color: $caw-dark-red;
        }
      }

      .selectedListBox {
        @extend .listBox;
        color: #160042;
        background-color: #f3f5fb;
      }

      .emptyList {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
        color: $colorGrey800;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }

      .label {
        user-select: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .matchedValue {
          text-decoration: underline;
        }
      }

      .hideMenu {
        display: none;
        flex-direction: column;

        .backButton {
          display: flex;
          align-items: center;
          padding: 12px;
          color: $caw-dark-red;
          cursor: pointer;

          .leftArrow {
            font-size: 16px;
            line-height: 24px;
            margin-right: 4px;
          }
        }
      }

      .displayMenu {
        @extend .hideMenu;
        display: flex;
      }
    }

    .menuBoxShow {
      @extend .menuBoxHide;
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
      z-index: 2;
    }

    .searchWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .searchIcon {
        position: absolute;
        left: 18px;
        color: $caw-dark-red;
        font-size: 24px;
      }

      .searchInput {
        width: 100%;
        border: 1px solid #e4e6f1;
        border-radius: 4px;
        display: flex;
        flex: 1;
        height: 48px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        padding: 16px 16px 16px 40px;
        color: #160042;
      }
    }
  }
}
