@import 'assets/theme';

.container {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;

  @media print {
    display: none;
  }

  @media (max-width: $medium-screen-min-width) {
    padding: 8px;
  }

  .logoLoading {
    display: flex;

    .logoLoadingImg {
      width: 150px;
      height: 50px;
      border-radius: 8px;
    }
  }

  .customLogoImage {
    max-height: 80px;
    max-width: 290px;
  }

  .logoImage {
    width: 150px;

    @media (max-width: $medium-screen-min-width) {
      width: 115px;
    }
  }

  .profileWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;

    .profileBox {
      display: flex;
      align-items: center;

      .menuWrapper {
        padding-top: 10px;
        padding-left: 8px;

        .menuIcon {
          font-size: 30px;
          user-select: none;
        }
      }
    }

    .profile {
      height: 80px;
      width: 80px;

      @media (max-width: $medium-screen-min-width) {
        height: 60px;
        width: 60px;
      }
    }

    .logOutBtn {
      display: flex;
      justify-content: center;
      cursor: pointer;
      margin-top: $standard-padding * 2;

      .icon {
        padding-left: 4px;
      }
    }
  }
}

.noPadding {
  padding: 0 0 16px;
}
