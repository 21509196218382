.container {
  display: flex;
  cursor: pointer;
  margin: 2px 0;
}

.point {
  width: 40px;
  height: 6px;
  border-radius: 8px;
  box-shadow: 1px 1px 2px #c5c5c5;
  transition: all 0.1s ease-in-out;
}

.pointFocus {
  @extend .point;
  transform: scale(1.2);
}
