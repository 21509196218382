@import 'MumsMatter/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.rowWrapper {
  display: flex;
  gap: 16px;

  @media (max-width: $tablet-screen-max-content) {
    flex-direction: column;
    gap: 8px;
  }

  .fieldWrapper {
    width: 100%;
    max-width: 400px;
    color: #343434;

    .selectInputLabel {
      font-size: 12px;
      color: #343434;
    }
  }
}

.contactRowWrapper {
  @extend .rowWrapper;

  @media (max-width: $tablet-screen-max-content) {
    flex-direction: column;
    gap: 16px;
  }
}
