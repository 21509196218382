@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  padding: 22px 44px 22px 52px;
  border-radius: 0 27px 27px 27px;
  transition: all 0.2s ease;

  @media screen and (max-width: $small-desktop-screen-max-content) {
    flex-direction: column;
    min-width: 126px;
    padding: 16px 14px;
    margin-bottom: 24px;
    border-radius: 0 20px 20px 20px;
    transition: none;
    max-width: 174px;

    &:not(:last-child) {
      margin-right: 4px;
    }
  }

  .menuImage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 120px;
    min-width: 120px;
    opacity: 0.6;
    font-size: 50px;
    color: $white;
    border-radius: 50%;

    @media screen and (max-width: $small-desktop-screen-max-content) {
      height: 65px;
      width: 65px;
      min-width: 65px;
      font-size: 30px;
    }
  }

  .menuImageSelected {
    @extend .menuImage;
    opacity: 1;
  }

  .detailWrapper {
    display: flex;
    flex-direction: column;
    margin-left: 42px;
    white-space: normal;
    max-width: 172px;
    min-width: 148px;

    @media screen and (max-width: $small-desktop-screen-max-content) {
      padding: 6px 24px 0 24px;
      margin-left: 0;
    }

    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: $darkblue500;

      @media screen and (max-width: $small-desktop-screen-max-content) {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .titleSelected {
      @extend .title;
      line-height: 20px;
      font-weight: 600;
      font-size: 20px;
      color: $someone-health-blue;

      @media screen and (max-width: $small-desktop-screen-max-content) {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .desc {
      margin-top: 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.01em;
      font-feature-settings: 'kern' off;
      color: #c4c4c4;

      @media screen and (max-width: $small-desktop-screen-max-content) {
        margin-top: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        min-height: 32px;
        max-height: 32px;
        color: $someone-health-blue;
      }
    }

    .descSelected {
      @extend .desc;
      font-family: 'Nunito Sans';
      margin-top: 18px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $someone-health-blue;

      @media screen and (max-width: $small-desktop-screen-max-content) {
        font-family: 'Roboto';
        margin-top: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .arrowIcon {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    position: relative;
    left: 0px;
    transition: left 0.2s ease;
  }

  .hideArrowIcon {
    @extend .arrowIcon;
    opacity: 0;
    left: -12px;
  }
}

.containerSelected {
  @extend .container;
  z-index: 2;
  box-shadow: 3px 7px 14px 2px rgba(0, 0, 0, 0.1);
  width: 110%;
  background: #fff;
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: $small-desktop-screen-max-content) {
    border: 1px solid #e6e6e6;
    transition: none;
    padding: 16px 14px;
    min-width: 126px;
    max-width: 174px;
  }
}

.mobileArrowWrapper {
  position: relative;
  transform: rotate(90deg);

  .mobileArrow {
    position: absolute;
    top: -16px;
    left: 4px;
    background: #fff;
    padding: 4px 8px;
    font-size: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #eee;
  }
}

.hideMobileArrowIcon {
  @extend .mobileArrowWrapper;
  opacity: 0;
}
