@import 'SomeoneHealth/assets/theme';

.wrapper {
  display: flex;
  flex-direction: column;
  @include rowGap(24px);

  .title {
    font-size: 20px;
    line-height: 26px;
    color: $someone-health-maroon;
    font-weight: 500;
  }

  .contact {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: $someone-health-maroon;

    .highlight {
      @extend .contact;
      color: $someone-health-maroon;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  .contactBtn {
    border-radius: 50px;
    border: 1px solid $someone-health-maroon !important;
    color: $someone-health-maroon !important;
    &:hover {
      background-color: $someone-health-light-maroon2 !important;
    }
  }
}
