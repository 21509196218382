@import 'helm/assets/theme';
@import 'Select/assets/theme';
@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  margin: $standard-padding * 5 0;
  gap: $standard-padding;

  .removeBtn {
    min-width: unset;
    color: $red500;
    background-color: transparent;
    border: none !important;
    box-shadow: none;
    margin: 0 $standard-padding 0 $standard-padding;

    &:hover {
      opacity: 0.8;
    }
  }

  .detail {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    gap: $standard-padding;
  }

  .nameWrapper {
    display: flex;
    gap: $standard-padding * 5;

    @media (max-width: $mobile-screen-max-content) {
      flex-direction: column;
      gap: $standard-padding;
    }
  }

  .name {
    font-size: 18px;
    font-weight: 500;
  }

  .relationships {
    display: flex;
    flex-wrap: wrap;
    column-gap: $standard-padding * 5;
    row-gap: $standard-padding * 2;

    .relationship {
      font-weight: 400;
      font-size: 12px;
      background-color: #f3f3f3;
      border-radius: 14px;
      padding: $standard-padding $standard-padding * 3;
      height: fit-content;
    }
  }
}

:global(.s1h-client-portal-theme) {
  .container {
    .removeBtn {
      max-width: 48px;
      color: $someone-health-maroon;
    }
  }
}
