@import 'helm/assets/theme';

.modal {
  & > div {
    background-color: transparent;
    box-shadow: none;
  }
}

.avatarTitle {
  font-size: 20px;
  margin-top: $standard-padding * 5;
  text-align: center;
}

.avatarFooter {
  text-align: right;
  padding: $standard-padding * 2 $standard-padding * 6;
}

.avatarNicknameInput {
  display: none !important;
}

.container {
  height: max-content;
  min-height: 100vh;
  background: linear-gradient(0deg, #4b6184, #4b6184, #2a4670 40%) no-repeat;
  position: relative;
  z-index: 1;

  .content {
    max-width: 1280px;
    background-color: white;
    border-radius: 8px;
    padding: $standard-padding * 6 $standard-padding * 8;
    margin: $standard-padding * 6 auto;

    @media (max-width: $tablet-screen-max-content) {
      padding: $standard-padding * 8 $standard-padding * 6;
      margin: $standard-padding * 28 $standard-padding * 4;
    }

    .title {
      font-size: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $darkblue500;

      .saveButton {
        box-shadow: none;
        font-size: 16px;
        padding: $standard-padding * 4 $standard-padding * 5;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .detail {
      display: flex;
      align-items: flex-start;

      .avatar {
        width: 122px;
        height: 122px;
        border: dashed 1px $helm-primary;
        border-radius: 100%;
        margin-top: $standard-padding * 8;
        cursor: pointer;

        img {
          height: 100%;
        }
      }

      .form {
        max-width: 840px;
        margin-left: 5%;
        flex: 1;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-auto-rows: auto;

        @media (max-width: $tablet-screen-max-content) {
          display: block;
        }

        .formItem {
          margin: 0 10px;
          min-height: 68px;

          &.fieldError,
          .fieldError {
            font-size: 12px;
            color: #ff4d4f;

            input {
              border-bottom: 1px solid #ff4d4f !important;
            }
          }

          .passwordCell {
            display: flex;
            align-items: center;

            .password {
              flex: 1;
            }

            .linkBtn {
              text-align: right;
              margin-left: 0;
            }
          }
        }
      }
    }

    .input {
      padding-left: $standard-padding * 4;
      margin-left: -$standard-padding * 4 + 2;
      margin-top: 30px;
      background-color: transparent;
    }

    .datePicker {
      padding-left: $standard-padding * 4;
      margin-left: -$standard-padding * 4 + 2;
      background-color: transparent;
      border-bottom: 1px solid #c4c4c4 !important;
    }

    .datePickerLabel {
      color: #818e9b !important;
    }

    .error {
      text-align: left;
    }

    .linkBtn {
      color: $helm-primary;
      background-color: transparent;
      box-shadow: none;
      padding: 0 $standard-padding * 4;
      font-size: 16px;
      text-align: left;
      margin-left: -$standard-padding * 4;
      margin-right: $standard-padding * 4;
      margin-top: $standard-padding * 7;
      height: fit-content;

      i {
        font-size: 14px;
        margin-right: 10px;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    .contact {
      margin-top: $standard-padding * 5;

      .title {
        font-size: 24px;
        color: $darkblue500;
      }

      .description {
        color: $darkblue500;
        .highlight {
          font-weight: 600;
        }
      }

      .contactList {
        color: $black;
        .keyContact {
          display: flex;
          align-items: center;
          margin: $standard-padding * 5 0;

          &:last-of-type {
            margin-bottom: 0;
          }

          .removeBtn {
            min-width: unset;
            color: $red500;
            background-color: transparent;
            border: none;
            box-shadow: none;
            margin: 0 $standard-padding * 6 0 $standard-padding * 2;

            &:hover {
              opacity: 0.8;
            }
          }

          .detail {
            flex: 1;
            display: block;
            font-size: 12px;

            .name {
              display: flex;
              font-size: 18px;
              margin-bottom: $standard-padding;

              .relationships {
                display: flex;

                .relationship {
                  margin-left: $standard-padding * 5;
                  font-weight: 400;
                  font-size: 12px;
                  background-color: #f3f3f3;
                  border-radius: 14px;
                  padding: $standard-padding $standard-padding * 3;
                }
              }
            }
          }
        }
      }

      .addContactBtn {
        border: solid 1px $helm-primary;
        margin-top: $standard-padding * 5;
        padding: $standard-padding * 4;
        background-color: transparent;
        color: $helm-primary;
        box-shadow: none;

        i {
          font-size: 24px;
          margin-right: 10px;
          vertical-align: top;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .loading {
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
