@import 'CaW/assets/theme';

.container {
  color: $caw-blue;

  .label {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;

    .helpIcon {
      color: $caw-dark-red;
      margin-left: 8px;
      font-size: 20px;
      cursor: pointer;
    }

    .tooltip {
      max-width: 230px;
      border-radius: 8px;
      line-height: 16px;
      font-size: 14px;
      padding: 12px;
      opacity: 0.95 !important;
    }
  }

  .calendarListWrapper {
    display: flex;
    flex-wrap: wrap;

    .calendarContent {
      display: flex;
      width: 100%;
      flex-direction: column;

      @media (max-width: $tablet-screen-max-content) {
        margin: 0 -16px;
        padding: 0 16px;
        display: flex;
        flex: 1;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      @media (max-width: $mobile-screen-max-content) {
        margin: 4px -8px 0 0;
      }

      .moreAvailableBtn {
        white-space: break-spaces;
        margin-top: 12px;
      }
    }

    .calendarCardWrapper {
      display: flex;
      flex-direction: column;

      @media (max-width: $medium-desktop-screen-max-content) {
        flex-direction: row;
        @include columnGap(16px);
      }

      @media (min-width: $tablet-screen-max-content) {
        flex-wrap: wrap;
      }
    }

    .calendarCardOdd {
      display: flex;
      justify-content: center;
      min-width: 230px;
      max-width: 900px;
      background: $white;
      border-radius: 12px;
      padding: $standard-padding * 4 $standard-padding * 5;
      margin-bottom: 16px;

      @media (max-width: $medium-desktop-screen-max-content) {
        margin-bottom: 0;
      }

      .cardContent {
        display: flex;
        flex: 1;

        @media (max-width: $medium-desktop-screen-max-content) {
          flex-direction: column;
        }

        .calendarInfo {
          display: flex;
          width: 100%;
          max-width: 160px;
          align-items: center;
          @include columnGap(12px);

          .greenDot {
            border-radius: 50%;
            background-color: $caw-light-green;
            width: 13px;
            height: 13px;
            margin-right: 16px;
          }

          .calendarDateInfo {
            display: flex;
            flex-direction: column;
            color: #414141;

            .date {
              font-weight: 700;
              font-size: 20px;
              line-height: 26px;
            }

            .month {
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
            }

            .day {
              font-weight: 500;
              font-size: 16px;
              line-height: 21px;
            }

            .openAppointment {
              font-weight: 400;
              font-size: 10px;
              line-height: 13px;
              color: $caw-blue;
              opacity: 0.5;
            }
          }
        }
      }

      .timeslotWrapper {
        display: flex;
        align-items: center;
        margin: 0 30px;
        @include columnGap($standard-padding * 3);

        @media (max-width: $medium-desktop-screen-max-content) {
          flex-direction: column;
          margin: 16px 0 0 0;
          @include columnGap(0);
          @include rowGap($standard-padding * 3);

          a {
            width: 100%;
          }
        }

        .timeslot {
          line-height: 18px;
        }

        .seeMoreSlot {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          line-height: 18px;
          text-decoration-line: underline;
          cursor: pointer;
          color: $darkblue400;
        }
      }
    }

    .calendarCardEven {
      @extend .calendarCardOdd;
      background: $caw-light-blue;
    }
  }

  .noAvailableWrapperEven {
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: 12px;
    margin-right: 8px;
    padding: 24px;

    .noAvailableCard {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 20px;
      justify-content: space-between;

      .messageWrapper {
        display: flex;
        max-width: 500px;

        .redDotWrapper {
          padding-top: 10px;
        }
      }

      .redDot {
        border-radius: 50%;
        background-color: $red500;
        width: 13px;
        height: 13px;
        margin-right: 16px;
      }

      .message {
        display: flex;
        flex: 1;
        flex-direction: column;

        .desc {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: $caw-blue;
        }

        .noAvailDesc {
          display: flex;
          flex-wrap: wrap;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          color: $caw-blue;
          margin-right: 16px;
          white-space: break-spaces;
        }
      }

      .buttonWrapper {
        white-space: break-spaces;
        @media (max-width: $mobile-screen-max-content) {
          display: flex;
          width: 100%;
          justify-content: center;
        }
      }
    }
  }

  .noAvailableWrapperOdd {
    @extend .noAvailableWrapperEven;
    background: $caw-light-blue;
  }
}
