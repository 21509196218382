@import 'assets/theme';

.name {
  margin-top: $standard-padding * 3;
  margin-bottom: $standard-padding * 2;
  font-weight: bold;
  font-size: 16px;
  color: #343434;
}

.desc {
  margin-top: -16px;
  margin-bottom: $standard-padding * 2;
  color: #8b8b8b;
}
