@import 'assets/theme';
@import 'Ease/assets/theme';

.container {
  div,
  button {
    font-family: $font;
  }

  height: 100%;
  overflow: auto;
  position: relative;
  z-index: 1;

  .fixedLeftBtn {
    position: fixed;
    bottom: 40px;
    background: $ease-green;
    border-color: $ease-green;
    box-shadow: 0 4px 10px rgba(52, 52, 52, 0.35);
    border-radius: 55px !important;
    height: 55px;
    z-index: 4;
    font-weight: 600;
    font-size: 13px;
    color: $ease-dark-grey;
    min-height: 48px;
    min-width: 175px;

    @media (max-height: 740px) {
      bottom: 13px;
      left: 2%;
    }

    .newJournalBtn {
      display: flex;
      align-items: center;

      .icon {
        padding-right: 8px;
      }
    }

    .btnLoading {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn:after {
        border-color: #fff transparent #fff transparent;
      }
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 3;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 16px 120px;
    color: #fff;

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .contentBox {
      display: flex;
      flex-direction: column;
      row-gap: 32px;

      .taskListContainer {
        display: flex;
        flex-direction: column;
        max-width: 600px;

        .taskListHeader {
          margin-bottom: 30px;

          .taskListTitle {
            font-size: 28px;
            font-weight: 700;
            color: $ease-dark-grey;

            @media (max-height: $medium-screen-min-height) {
              font-size: 24px;
            }
          }

          .taskListDesc {
            font-size: 18px;
            color: $ease-dark-grey;

            @media (max-height: $medium-screen-min-height) {
              font-size: 16px;
            }
          }
        }

        .taskListBox {
          display: flex;
          flex-direction: column;
          row-gap: 24px;

          @media (max-height: $medium-screen-min-height) {
            row-gap: 16px;
          }
        }
      }
    }
  }

  .welcomeMessage {
    font-size: 36px;
    font-weight: 700;
    line-height: 47px;
    color: $ease-dark-grey;
  }

  .desc {
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    color: $colorGrey700;
  }
}
