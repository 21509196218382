@import 'assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.container {
  background-color: #fff;

  .formContainer {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 24px 16px 16px;
    max-width: 850px;
    width: 100%;

    @media (max-width: $medium-screen-min-width) {
      padding: 24px 4px 16px;
    }

    @media (max-height: $medium-small-screen-min-height) {
      padding: 16px 4px;
    }

    .submitButtonContainer {
      display: flex;
      justify-content: center;
      padding: 24px 0 8px;

      @media (max-height: $medium-screen-min-height) {
        padding: 16px 0 8px;
      }

      .submitBtn {
        min-width: 250px;
      }
    }
  }
}
