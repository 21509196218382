@import 'assets/theme';
@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 16px;
  max-width: 832px;

  @media (max-width: $medium-screen-min-width) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.invoiceWrapper {
  border: 1px solid #000000;
}

.padding16 {
  padding: 16px;
}

.padding20 {
  padding: 20px;
}

.wrapper {
  padding: 20px;
}

.flexWrap {
  flex-wrap: wrap;
}

.width280 {
  min-width: 280px;
  max-width: 280px;
}

.width350 {
  min-width: 350px;
  max-width: 350px;
}

.width360 {
  min-width: 360px;
  max-width: 360px;
}

.practiceHeading {
  color: $colorGrey700;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.invoiceHeading {
  color: #22348c;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.heading {
  color: $colorGrey700;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.label {
  color: $colorGrey500;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  min-width: 120px;
}

.value {
  color: $colorGrey700;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.textAreaContent {
  color: $colorGrey500;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.paymentMethodTitle {
  color: $colorGrey700;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}

.payOnlineContainer {
  width: 100%;
  background-color: #e9ebff;
  padding: 30px;

  .payOnlineContent {
    max-width: 900px;
    display: flex;
    justify-content: flex-end;
    margin: auto;
    flex-wrap: wrap-reverse;
    row-gap: 15px;

    .payOnlineDesc {
      width: 400px;
      font-size: 16px;
      color: #5666a6;
      font-weight: bold;
      text-align: right;
    }

    .buttonContainer {
      margin-left: 32px;

      .payOnlineButton {
        font-size: 14px;
        font-weight: bold;
        border-radius: 16px;
        width: 284px;
      }
    }
  }
}

.claimContainer {
  width: 100%;
  background-color: #e9ebff;
  padding: 30px;

  .claimInformation {
    max-width: 900px;
    font-size: 16px;
    color: #5666a6;
    font-weight: bold;
    text-align: right;
  }
}

.ctaContainer {
  width: 100%;
  padding: 30px;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ctaDesc {
    strong {
      text-decoration: underline;
    }
  }

  .ctaMarkButton {
    background-color: #25be69;
    color: white;
    border-radius: 10px;
    box-shadow: none;
    width: 200px;
  }

  .ctaUnmarkButton {
    margin-left: 32px;
    background-color: rgba(37, 190, 106, 0.2);
    color: black;
    border-radius: 10px;
    box-shadow: none;
  }
}

:global(.ease-theme) {
  .ctaContainer .ctaUnmarkButton {
    color: $white;
  }
}

:global(.s1h-client-portal-theme) {
  .container {
    .descriptionContainer {
      margin: 0 20px;

      @media (max-width: $medium-small-screen-min-width) {
        margin: 0 12px;
      }

      .label {
        font-weight: 600;
        color: $colorGrey700;
      }

      .description {
        color: $colorGrey700;
      }
    }
  }

  .payOnlineContainer {
    background-color: $someone-health-light-maroon2;

    .payOnlineContent {
      .payOnlineDesc {
        color: $someone-health-maroon;
      }

      .buttonContainer .payOnlineButton {
        border-radius: 50px;
      }
    }
  }

  .claimContainer {
    background-color: $someone-health-light-maroon2;

    .claimInformation {
      color: $someone-health-maroon;
    }
  }
}
