@import 'Select/assets/theme';

.actionWrapper {
  display: flex;
  flex-direction: column;
  @include rowGap(16px);
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: $select-black;
}

.menuItemWrapper {
  display: flex;
  flex-direction: column;
  gap: $standard-padding * 4;
}

.taskButton {
  background-color: $select-light-grey-100;
  border-radius: 0;
}
