@import 'CORDS/assets/theme';
@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: $black;

  .contentWrapper {
    display: flex;
    flex-direction: column;
  }

  .wrapper {
    background-color: transparent !important;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $standard-padding * 6;
    font-size: 18px;
    max-width: 1040px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    margin: 80px auto 0;
    background-color: $white;

    @media (max-width: $medium-large_screen-min-width) {
      padding: 20px 16px;
    }

    .title {
      font-size: 28px;
      font-weight: 600;
    }

    .description {
      font-weight: 400;
      font-size: 20px;
      margin: 8px 0;
    }

    .clinicianInfo {
      display: flex;
      align-items: center;

      .clinicianAvatar {
        max-width: 80px;
        margin-top: 16px;
        margin-right: 12px;
        margin-bottom: 16px;
        margin-left: 8px;
        border-radius: 50%;
      }

      .clinicianName {
        font-size: 28px;
        font-weight: 600;
      }
    }

    p {
      margin-top: $standard-padding * 2;
    }

    .signature {
      font-weight: 600;
    }

    .buttonWrapper {
      width: 100%;
    }

    .mainButton {
      background-color: $cords-yellow;
      border-radius: 100px;
      border: none;
      width: 100%;
      height: 52px;
      margin-top: 28px;
      color: $cords-dark-blue;
      display: flex;
      align-items: center;

      i {
        margin-left: 10px;
      }
    }
  }

  .thankYouContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    color: $cords-dark-blue;
    text-align: center;
    background: linear-gradient(180deg, #fdeabd, #fab40c);

    @media (max-width: $mobile-screen-max-content) {
      padding: 0 16px;
    }

    .thankYouBigText {
      font-weight: 700;
      font-size: 32px;
      margin-top: $standard-padding * 6;

      @media (max-width: $mobile-screen-max-content) {
        margin-top: $standard-padding * 3;
        font-size: 28px;
      }
    }

    .thankYouText {
      font-weight: 600;
      font-size: 24px;
      margin-top: $standard-padding * 3;

      @media (max-width: $mobile-screen-max-content) {
        margin-top: $standard-padding * 2;
        font-size: 16px;
      }
    }

    .checkIcon {
      align-self: center;
      width: 100%;
      max-width: 280px;

      @media (max-width: $mobile-screen-max-content) {
        max-width: 180px;
        height: 180px;
      }
    }
  }
}
