@import 'Select/assets/theme';

.contentWrapper {
  background-color: $white;

  &:nth-child(even) {
    background-color: $select-light-grey-10;
  }

  .content {
    display: flex;
    padding: $standard-padding * 6 0;

    @media (max-width: $tablet-screen-max-content) {
      flex-direction: column;
    }

    .mobileDetails {
      display: none;

      @media (max-width: $tablet-screen-max-content) {
        display: flex;
        margin-bottom: 24px;
      }
    }

    .profileWrapper {
      display: flex;
      flex: 1 25%;
      min-width: 330px;
      flex-direction: column;
      @include rowGap($standard-padding * 6);

      @media (max-width: $small-desktop-screen-max-content) {
        min-width: 280px;
        flex: 1;
      }

      .profile {
        display: flex;

        @media (max-width: $tablet-screen-max-content) {
          justify-content: center;
        }

        .profileImg {
          max-width: 280px;
        }
      }
    }

    .detailsContainer {
      display: flex;
      flex: 1 75%;
      flex-direction: column;
      padding-left: $standard-padding * 6;
      overflow-x: hidden;
      overflow-y: hidden;
      @include rowGap(24px);

      @media (max-width: $tablet-screen-max-content) {
        padding-left: 0;
        padding-top: $standard-padding * 6;
        overflow-x: unset;
        overflow-y: unset;
      }

      .details {
        @media (max-width: $tablet-screen-max-content) {
          display: none;
        }
      }

      .highlightSecondarySpecs {
        background-color: $select-light-green;
      }
    }
  }
}

.contentWrapperRs {
  @extend .contentWrapper;
  background-color: $select-light-grey-10;

  &:nth-child(even) {
    background-color: $white;
  }
}
