@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: row;
  padding: 0 $standard-padding * 7;
  padding-top: $standard-padding * 6;

  .content {
    margin: auto;
    flex: 1;
    width: 100%;
  }

  @media (max-width: $tablet-screen-max-content) {
    flex-direction: column-reverse;
    padding: 0;
  }
}
