@import 'SomeoneHealth/assets/theme';

.container {
  color: $someone-health-blue;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .filterCharacter {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }

  .matchedItems {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .item {
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -0.02em;
      color: $someone-health-blue;
      max-width: max-content;

      &:hover {
        text-decoration: underline;
        color: $someone-health-pink;
      }

      .matchedValue {
        text-decoration: underline;
      }
    }
  }
}
