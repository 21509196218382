@import 'CaW/assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: $standard-padding * 6 $standard-padding * 4;
  color: $caw-blue;
  background-color: $white;
  border-radius: 8px;
  border: 2px solid $caw-blue;
  margin: auto;
  max-height: 526px;
  overflow: auto;

  &::-webkit-scrollbar {
    visibility: hidden;
  }

  .timeslotWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    gap: 12px;
    row-gap: 24px;

    .slot {
      line-height: 16px;

      &.active {
        background: $caw-blue;
        color: $white;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

.loadingContainer {
  @extend .container;
  padding: 20px;
  width: 424px;

  .loadingHeaderWrapper {
    display: flex;

    .loadingAvatar {
      height: 60px;
      width: 60px;
      border-radius: 8px;
    }

    .loadingDescWrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: $standard-padding * 3;

      .loadingTitle {
        height: 36px;
        width: 100%;
        border-radius: 30px;
        max-width: 158px;
        margin-bottom: $standard-padding;
      }

      .loadingDesc {
        height: 18px;
        width: 100%;
        border-radius: 21px;
        max-width: 219px;
      }
    }
  }

  .loadingSlotWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    row-gap: 24px;
    column-gap: 10px;

    .loadingSlot {
      height: 30px;
      width: 100%;
      border-radius: 30px;
      max-width: 120px;
    }
  }
}
