@import 'Recharge/assets/theme';

.actionWrapper {
  display: flex;
  flex-direction: column;
  @include rowGap(16px);
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: $recharge-black;
}

.menuItemWrapper {
  display: flex;
  flex-direction: column;
  gap: $standard-padding * 4;
}
