@import 'Select/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  color: $select-black;

  .topContent {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;

    .name {
      font-weight: 700;
      font-size: 32px;
      line-height: 42px;
      letter-spacing: -0.02em;
      color: $select-black;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .tag {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      text-align: center;
      background: $select-light-green;
      border-radius: 40px;
      padding: 8px 10px;

      .callIcon {
        font-size: 20px;
        margin-right: 8px;

        @media (max-width: $tablet-screen-max-content) {
          margin-right: 0;
        }
      }
    }

    .selectedTag {
      @extend .tag;
      background: $select-green;
    }
  }

  .middleContent {
    display: flex;
    align-items: center;
    margin: 16px 0 24px;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  }

  .aboutMeSection {
    display: flex;
    flex-direction: column;

    .aboutMeLabel {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-bottom: 8px;
    }

    .aboutMe {
      white-space: pre-line;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .aboutMe :global {
    .readMore {
      color: $blue500;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-decoration: underline;
    }
  }
}
