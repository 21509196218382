@import 'Recharge/assets/theme';

.footerContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: $recharge-light-purple2;
}

.container {
  display: flex;
  padding: 48px 8px;

  @media (max-width: $mobile-screen-max-content) {
    padding: 24px;
  }

  .disclaimer {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 700;
    color: $white;
    text-align: center;

    @media (max-width: $mobile-screen-max-content) {
      font-size: 18px;
    }

    a {
      color: $recharge-black;
      text-decoration: underline;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    color: $white;

    @media (max-width: $mobile-screen-max-content) {
      flex-direction: column;
    }
  }

  .leftContent {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    @media (max-width: $mobile-screen-max-content) {
      display: none;
    }

    .heading {
      font-weight: 700;
      font-size: 32px;
      text-align: center;

      @media (max-width: $mobile-screen-max-content) {
        font-size: 26px;
      }
    }

    a,
    .location {
      display: flex;
      align-items: center;
      min-width: 400px;
      color: $white;
      font-weight: 600;
      font-size: 18px;

      @media (max-width: $mobile-screen-max-content) {
        min-width: 350px;
        font-size: 16px;
      }

      .icon {
        margin-right: 16px;
      }
    }
  }

  .rightContent {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    .links {
      display: flex;
      flex-direction: column;

      a {
        color: $white;
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;
        width: max-content;

        @media (max-width: $tablet-screen-max-content) {
          font-size: 18px;
        }
      }
    }

    .getStartedButton {
      width: max-content;
      padding: 12px 18px;
      font-size: 18px;
      font-weight: 600;
      color: $recharge-black;
      border-radius: 8px;
      background-color: $white;
      transition: all 0.2s;

      &:hover {
        background-color: $recharge-light-purple;
        color: $white;
      }
    }
  }

  .acknowledgement {
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    margin: 32px 0 0;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 0.75fr 0.25fr;
    grid-auto-columns: 1fr;
    display: grid;

    @media (max-width: $mobile-screen-max-content) {
      grid-template-columns: 1fr;
    }

    .text {
      color: $white;
      text-align: center;
      font-size: 0.8rem;

      @media (max-width: $mobile-screen-max-content) {
        font-size: 0.8rem;
      }
    }

    .flags {
      width: 100%;

      @media (max-width: $medium-small-screen-min-width) {
        width: 50%;
      }
    }
  }
}
