@import 'Select/assets/theme';

.container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .numberBubble {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $select-black;
    background-color: transparent;
    border-radius: 50%;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    padding: 4px;
    min-width: 30px;
    margin-right: 8px;
  }

  .stepTitle {
    color: $select-black;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
  }
}
