@import 'SomeoneHealth/assets/theme';

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.circle {
  width: 5px;
  height: 5px;
  background-color: #fff;
  border-radius: 50%;
  margin: 3px;
  animation: jump 0.7s ease-in infinite;
  background-color: #4abd03;

  &:nth-of-type(2) {
    animation-delay: 0.1s;
  }

  &:nth-of-type(3) {
    animation-delay: 0.2s;
  }

  &:nth-of-type(4) {
    animation-delay: 0.3s;
  }

  &:nth-of-type(5) {
    animation-delay: 0.4s;
  }
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

:global(.s1h-client-portal-theme) {
  .circle {
    background-color: $someone-health-maroon;
  }
}
