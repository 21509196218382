@import 'SomeoneHealth/assets/theme';
@import 'helm/assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'MumsMatter/assets/theme';

$spacing: 16px;

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.container {
  position: relative;
}

.input:focus,
.input:not(:focus):valid {
  ~ .label {
    top: 0;
    font-size: 11px;
    color: #818e9b;
    opacity: 1;
    font-weight: 600;
  }
}

.input:focus {
  color: #192a3e;
  border-color: #818e9b;

  ~ .label {
    color: #192a3e;
  }
}

.input {
  padding: 0;
  border: none;
  border-bottom: 1px solid #d3d8dd;
  outline: none;
  height: 38px;
  color: #343434;
  font-size: 13px;
  line-height: 18px;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 16px;
  box-sizing: border-box;
  border-radius: 0;

  &.error {
    border-bottom: 1px solid #ff1900;
    margin-bottom: 0 !important;

    ~ .label {
      color: #ff1900;
    }
  }

  &.noSpacing {
    margin-bottom: 0;
  }
}

.errorMessage {
  margin-bottom: 0 !important;
}

.inputBox {
  @extend .input;
  border: 1px solid #d3d8dd;
  padding: 8px;
  border-radius: 4px;
  height: auto !important;

  &.error {
    border: 1px solid #ff1900;
    margin-bottom: 0;

    ~ .label {
      color: #ff1900;
    }
  }
}

.label {
  position: absolute;
  pointer-events: none;
  font-size: 16px;
  top: 20px;
  color: #818e9b;
  font-weight: 500;
  transition: 0.2s ease all;
  &.error {
    color: #ff1900 !important;
  }
}

.staticLabel {
  @extend .label;
  top: 0;
  font-size: 11px;
  color: #818e9b;
  opacity: 1;
  font-weight: 600;
}

.boxStaticLabel {
  @extend .staticLabel;
  top: -4px;
}

.showPasswordButton {
  position: absolute;
  right: 12px;
  bottom: 0;
  padding: 0;
  border: none !important;
}

:global(.someone-health-theme) {
  .input:focus,
  .input:not(:focus):valid {
    ~ .label {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .input:focus {
    ~ .label {
      color: $someone-health-pink;
    }
  }
}

:global(.helm-theme) {
  .input:focus {
    ~ .label {
      color: $helm-blue;
    }
  }
}

:global(.ease-theme) {
  .input:focus {
    ~ .label {
      color: $ease-dark-grey;
    }
  }
}

:global(.recharge-theme) {
  .input {
    ~ .label {
      font-weight: 600;
    }

    &:focus {
      ~ .label {
        color: $recharge-black;
      }
    }
  }
}

:global(.select-theme) {
  .input {
    ~ .label {
      font-weight: 600;
    }

    &:focus {
      ~ .label {
        color: $select-black;
      }
    }
  }
}

:global(.mums-matter-theme) {
  .input {
    height: 30px;
    margin-bottom: 10px;

    ~ .label {
      font-weight: 400;
      font-size: 14px;
    }

    ~ .boxStaticLabel {
      font-size: 12px;
      top: -4px;
    }

    &:focus {
      ~ .label {
        font-size: 12px;
        color: $mums-matter-black;
      }

      ~ .boxStaticLabel {
        font-size: 12px;
        color: $mums-matter-black;
      }
    }

    &:not(:focus):valid {
      ~ .label {
        font-size: 12px;
        font-weight: 400;
        top: 0 !important;
      }
    }
  }
}

:global(.s1h-client-portal-theme) {
  .input {
    color: $someone-health-maroon;

    &:focus {
      ~ .label {
        color: $someone-health-maroon;
      }
    }
  }
}
