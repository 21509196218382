@import 'SomeoneHealth/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';

.select {
  width: 100%;
  border-radius: 8px;

  &.error {
    margin-bottom: 0;
  }

  &.noSpacing {
    margin-bottom: 0;
  }
}

.label {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #818e9b;
  margin-bottom: 4px;
}

.icon {
  line-height: 18px;
  color: #aeaeae;
  font-size: 20px;
}

.loadingWrapper {
  display: flex;
}

:global(.someone-health-theme) {
  .label {
    color: $grey600;
    font-size: 12px;
    font-weight: 400;
  }
  .focusLabelClass {
    color: $someone-health-pink;
  }
}

:global(.s1h-client-portal-theme) {
  .focusLabelClass {
    color: $someone-health-maroon;
  }
}

:global(.recharge-theme) {
  .label {
    color: $grey600;
    font-size: 12px;
    font-weight: 400;
  }
  .focusLabelClass {
    color: $recharge-black;
  }
}

:global(.select-theme) {
  .label {
    color: $grey600;
    font-size: 12px;
    font-weight: 400;
  }
  .focusLabelClass {
    color: $select-black;
  }
}
