@import 'SomeoneHealth/assets/theme';

.actionWrapper {
  display: flex;
  flex-direction: column;
  @include rowGap(16px);
}

.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  color: $someone-health-maroon;
}

.menuItemWrapper {
  display: flex;
  flex-direction: column;
  gap: $standard-padding * 4;
}
