@import 'assets/theme';
@import 'SomeoneHealth/assets/theme';

.container {
  @include s1hLoginBackground;

  .headerWrapper {
    display: flex;
    padding: 0 16px 24px;
  }

  .contentWrapper {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    position: relative;
    z-index: 3;
  }
}
