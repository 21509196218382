@import 'helm/assets/theme';

.container {
  padding-top: $standard-padding * 17;
  max-width: 410px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: $grey700;

  .mobileTimer {
    display: none;
  }

  .circularTimer {
    margin-top: $standard-padding * 7;
  }

  .notice {
    display: flex;
    align-items: center;
  }

  .timeLabel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;

    > div {
      div {
        font-family: $primary-font-stack-medium;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  .holdingSlotCard {
    margin-top: $standard-padding * 15;
    border: solid 2px $helm-light-yellow;
    border-radius: 8px;
    background-color: white;
    padding: $standard-padding * 6 $standard-padding * 7;

    .grayText {
      color: #949aa2;
      vertical-align: top;
      font-size: 14px;
    }

    .practitionerAvatarRow,
    .appointmentsRow {
      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 100%;
      }

      .leftCol {
        width: 30%;
        display: inline-block;
        line-height: 32px;
        text-align: right;
        padding-right: $standard-padding * 7;
      }

      .rightCol {
        width: 70%;
        display: inline-block;
        text-align: left;

        * {
          line-height: 32px;
        }
      }
    }

    .practitionerAvatarRow {
      margin-bottom: $standard-padding * 7;
      display: flex;
      align-items: center;

      .rightCol {
        * {
          line-height: 28px;
        }
      }
    }
  }

  @media (max-width: $tablet-screen-max-content) {
    padding-top: $standard-padding * 8;

    .clientTestimonialCard {
      display: none;
    }
  }

  @media (max-width: $mobile-screen-max-content) {
    padding-top: $standard-padding;
    font-size: 14px;

    .mobileTimer {
      display: inline-block;
      width: 50px;
    }

    .circularTimer {
      display: none;
    }

    .timeLabel {
      font-size: 20px;
      line-height: 26px;
      > div {
        div {
          font-family: $primary-font-stack-light;
          font-size: 10px;
          line-height: 13px;
        }
      }
    }

    .notice {
      font-size: 12px;
      margin: 0 -$standard-padding;
    }

    .holdingSlotCard {
      padding: $standard-padding * 5 $standard-padding * 4;
      margin-top: $standard-padding;
      margin-bottom: $standard-padding * 4;
      .graytext {
        font-size: 12px;
      }
      .practitionerAvatarRow,
      .appointmentsRow {
        img {
          width: 60px;
          height: 60px;
        }

        .leftCol {
          width: 27%;
          padding-right: $standard-padding * 4;
        }

        .rightCol {
          width: 73%;
        }

        .practitionerAvatarRow {
          margin-bottom: $standard-padding * 3;
        }
      }
    }
  }
}
