@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .bookAppointment {
    display: flex;
    box-shadow: none;
    margin-top: $standard-padding * 5;

    .bookAppointmentBtn {
      display: flex;
      align-items: center;

      .calendarIcon {
        font-size: 24px;
        padding-left: $standard-padding + 2;
      }
    }
  }
}
