@import 'helm/assets/theme';
@import 'SomeoneHealth/assets/theme';

.container {
  width: 100%;
  grid-column: 1 / 3;
  padding: $standard-padding * 6 0;
}

.title {
  font-weight: 600;
  color: $helm-primary;
  padding: 0 $standard-padding * 2;
  margin: 0;
}

.addressForm {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: auto;

  @media (max-width: $mobile-screen-max-content) {
    display: block;
    margin-left: 0;
    width: 100%;
  }

  .addressSuggestion {
    display: grid;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 100%;
  }

  .formItem {
    margin: 0 10px;
    min-height: 68px;

    &.fieldError,
    .fieldError {
      font-size: 12px;
      color: #ff4d4f;

      input {
        border-bottom: 1px solid #ff4d4f !important;
      }
    }
  }

  .input {
    padding-left: $standard-padding * 4;
    margin-left: -$standard-padding * 4 + 2;
    margin-top: 30px;
    background-color: transparent;
  }
}

:global(.s1h-client-portal-theme) {
  .title {
    color: $someone-health-maroon;
  }
}
