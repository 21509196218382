@import 'CaW/assets/theme';

.container {
  margin-top: 40px;
  width: 100%;
  padding-right: 75px;
  padding-left: 200px;
  padding-bottom: 30px;

  @media (max-width: $medium-desktop-screen-max-content) {
    padding-left: 100px;
  }

  @media (max-width: $small-desktop-screen-max-content) {
    padding-left: 0;
    padding-right: 0;
    justify-content: space-evenly;
    padding-bottom: unset;
  }
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}
.header {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 16px;
  display: block;

  .highlight {
    color: $caw-dark-red;
  }

  .primaryHeader {
    @include playfairDisplayFont;
  }
}

.subHeader {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 6px;
}
.content {
  display: flex;
  justify-content: space-between;
}

.leftContent {
  max-width: 600px;
  @media (min-width: ($tablet-screen-max-content + 1)) {
    padding-right: 40px;
  }
}

.rightContent {
  max-width: 410px;

  @media (max-width: $small-desktop-screen-max-content) {
    max-width: 350px;
  }

  .timerContainer {
    display: flex;
    flex-direction: column;
    text-align: center;

    .timerLabel {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding: 4px 0 24px;
    }
  }

  .clientTest {
    width: 100%;
  }

  @media (max-width: $tablet-screen-max-content) {
    display: none;
  }
}
