@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  color: $helm-blue;

  .topContent {
    display: flex;
    align-items: center;
    @include columnGap(16px);

    .name {
      font-family: $primary-font-stack-bold !important;
      font-size: 32px;
      line-height: 32px;
      letter-spacing: -0.02em;
      color: $helm-blue;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  .getToKnowBtn {
    display: flex;
    align-items: center;
    font-family: $primary-font-stack-bold !important;
    font-size: 16px;
    line-height: 23px;
    color: $helm-red;
    cursor: pointer;

    .arrowIcon {
      color: $helm-blue;
      border: 1px solid $helm-blue;
      border-radius: 50%;
      padding: 2px;
      font-size: 18px;
      margin-left: 8px;
    }
  }

  .role {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    padding: $standard-padding 0 $standard-padding * 3;

    @media (max-width: $tablet-screen-max-content) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  .aboutMe {
    white-space: pre-line;
    font-size: 16px;
    line-height: 16px;

    @media (max-width: $tablet-screen-max-content) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
