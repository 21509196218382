@import 'SomeoneHealth/assets/theme';

.wrapper {
  display: flex;
  flex-direction: column;

  .desc {
    color: $colorGrey700;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
  }

  .header {
    color: $colorGrey700;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
  }

  .customInput {
    transform: translateX(-$standard-padding);
    padding: 0 $standard-padding;
    color: #343434;
    margin-bottom: $standard-padding !important;
  }

  .inputLabel {
    color: $colorGrey800;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }

  .customSelect {
    padding: $standard-padding * 2 0;
  }

  .selectInputLabel {
    @extend .inputLabel;
    margin-bottom: 0;
    font-size: 12px;

    &:focus {
      color: $someone-health-pink !important;
    }
  }

  .parentOrGuardianDetails {
    padding-top: $standard-padding * 5;
    font-weight: 400;
    font-size: 14px;

    .header {
      color: $colorGrey700;
      font-size: 18px;
      font-weight: 500;
      line-height: 23px;
    }

    .checkBoxes {
      display: flex;
      flex-direction: column;
      margin-top: 8px;

      .checkBox {
        vertical-align: top;

        input {
          outline: none;
          transform: scale(0.75);
          &:checked::after {
            display: inline-flex;
          }
        }
        margin-right: $standard-padding * 3;
      }

      .checkBoxText {
        height: fit-content;
        display: inline-block;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-top: $standard-padding;
        margin-bottom: $standard-padding * 3;

        a {
          text-decoration: underline;
          color: $someone-health-pink;
        }
      }
    }
  }
}
