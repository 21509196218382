.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  .inforWrapper {
    display: flex;
    .info {
      display: flex;
      flex-direction: column;
      .text {
        margin-left: 20px;
        font-weight: 600;
        font-size: 11px;
        line-height: 50px;
        text-transform: uppercase;
        color: #3f52ff;
        margin-top: 7px;
        .description {
          margin-top: 10px;
          display: flex;
          .number {
            line-height: 24px;
            font-size: 24px;
            color: #3f52ff;
          }
          .content {
            font-size: 24px;
            line-height: 24px;
            color: black;
            margin-left: 4px;
          }
          text-transform: none;
          font-weight: bold;
        }
      }
    }

    .profile {
      width: 90px;
      height: 135px;
    }
  }
}
