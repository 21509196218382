@import 'SomeoneHealth/assets/theme';

.container {
  max-width: 955px;
  color: $someone-health-maroon;
  align-items: center;
  row-gap: 20px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-align: center;
}

.content {
  padding: 24px 36px;
  background: $white;
  max-width: 955px;
  border-radius: 8px;
  font-size: 24px;
  line-height: 36px;

  @media screen and (max-width: $small-screen-max-width) {
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
  }
}

.button {
  margin-top: 32px;
  min-width: 358px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: $medium-small-screen-min-width) {
    min-width: 100%;
  }
}
