@import 'assets/theme';

.container {
  background: #f5faff;
  box-shadow: 2px 8px 15px rgba(0, 0, 0, 0.1);
  padding: $standard-padding * 4;

  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding * 2);

  @media (min-width: $medium-screen-min-width) {
    background: #ffffff;
  }
}

.title {
  display: flex;
  align-items: center;
  @include columnGap($standard-padding);

  color: #3f52ff;
  font-size: 14px;
  font-weight: 700;
}

.form {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding * 6);
  margin-bottom: $standard-padding * 5;
}

.questionContainer {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding * 2);

  .question {
    font-weight: 500;
    font-size: 16px;
  }

  .questionDescription {
    font-weight: 500;
    font-size: 14px;
    font-style: italic;
  }

  .input {
    border: 1px solid #3f52ff;
    padding: 16px;
    width: 100%;
    resize: none;
    height: 100px;
    background: transparent;
  }
}

.button {
  border: 1px solid #3f52ff;
  color: #3f52ff;
  background: none;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 50px;

  &:not(:disabled):hover {
    background-color: #3f52ff;
    color: white;
  }

  &:disabled {
    border-color: #949aa2 !important;
    color: #949aa2 !important;
  }
}

.thankyou {
  color: #25be69;
}

.errorText {
  color: #ff2500;
}
