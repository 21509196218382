@import 'assets/theme';

.contentWrapper {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 0 16px;
  background-color: #2a4670;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.fullHeightFooter {
  height: calc(100% - 82px);
}

.cardContainer {
  align-items: flex-start !important;
  padding: 32px 16px 24px !important;

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: #343434;
  }

  .patientProfileImgWrapper {
    display: flex;
    font-size: 18px;
    line-height: 28px;
    padding-top: 16px;
    align-items: center;

    .image {
      width: 50px;
      height: 50px;
      padding-right: 8px;
    }

    .name {
      font-size: 18px;
      line-height: 28px;
      color: #000000;
    }
  }

  .otherOptionLabel {
    padding: 24px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .emergencyLabel {
    display: flex;
    flex-direction: column;

    .contact {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 14px;

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }

    .number {
      color: #fc0303;
      padding-left: 6px;
    }
  }

  .noteWrapper {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    width: 100%;
  }

  .footer {
    display: flex;
    flex-direction: column;
    padding-top: 64px;
    width: 100%;
    justify-content: center;
    align-items: center;

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 400px;
      min-width: 300px;
      padding: 16px;
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
      color: #fff;
      background: #3f52fb;
      border-radius: 16px;
      box-shadow: none;
      cursor: pointer;
    }
  }
}
