.referralNoteContainer {
  margin-top: 24px;
  max-width: 400px;

  .noteWrapper {
    padding-left: 0;
    margin-top: 16px;

    .noteAreaLabel {
      margin-bottom: 8px;
      font-size: 15px;
      line-height: 23px;
      color: #192a3e;
    }

    .textArea {
      display: flex;
      width: 100%;
      height: 180px;
      max-width: 500px;
      resize: none;
      background: #ffffff;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      padding: 10px;
    }
  }
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #343434;
}

:global(.someone-health-theme) {
  .referralNoteContainer {
    max-width: 540px;

    .textArea {
      max-width: 100%
    }
  }
}