@import 'assets/theme';

.container {
  background: #fcfdff;
  padding: $standard-padding * 4;

  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding * 2);
}

.title {
  display: flex;
  align-items: center;
  @include columnGap($standard-padding);

  color: #3f52ff;
  font-size: 14px;
  font-weight: 700;
}

.subTitle {
  color: #343434;
  font-size: 12px;
}

.body {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding * 2);
}
