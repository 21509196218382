@import 'assets/theme';
@import 'CaW/assets/theme';
@import 'Ease/assets/theme';
@import 'Select/assets/theme';
@import 'Portobello/assets/theme';
@import 'SomeoneHealth/assets/theme';

.introContainer {
  color: $white;

  .introNote {
    font-size: 18px;
    padding: 16px 0 0;
    line-height: 30px;
    word-break: break-word;

    .welcomeTitle {
      padding-bottom: 24px;
      font-size: 28px;
      font-weight: 600;
    }
  }

  .footerBtnCard {
    padding: 16px;
    border-radius: 30px 30px 0 0;
    background-color: $white;
    background-image: url(~assets/images/background-circles.png);
    background-size: cover;
    background-repeat: no-repeat;
    bottom: 0;
    width: 100%;
    max-width: 1280px;
    margin: 0 -16px;
    position: fixed;
    box-shadow: 1px -2px 8px rgba(0, 0, 0, 0.1);

    .returnContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #343434;

      .button {
        max-width: 400px;
      }

      .shButton {
        min-width: 350px;
        margin: 8px 0;
      }
    }
  }
}

:global(.caw-theme).introContainer {
  color: $caw-blue !important;

  .footerBtnCard {
    max-width: 1440px;
  }
}

:global(.ease-theme) {
  .introContainer {
    color: $ease-dark-grey !important;

    .welcomeTitle {
      color: $ease-dark-purple !important;
    }

    .footerBtnCard {
      max-width: 1440px;
      background-image: none;
    }
  }
}

:global(.recharge-theme) {
  .introContainer {
    .footerBtnCard {
      max-width: 1440px;
      background-image: none;
    }
  }
}

:global(.select-theme) {
  .introContainer {
    color: $select-black !important;

    .welcomeTitle {
      color: $select-black !important;
    }

    .footerBtnCard {
      max-width: 1440px;
      background-image: none;
    }
  }
}

:global(.portobello-theme) {
  .introContainer {
    color: $portobello-black !important;

    .welcomeTitle {
      color: $portobello-black !important;
    }

    .footerBtnCard {
      max-width: 1440px;
      background-image: unset;
    }
  }
}

:global(.s1h-client-portal-theme) {
  .introContainer {
    color: $someone-health-maroon;

    .welcomeTitle {
      color: $someone-health-maroon;
    }

    .footerBtnCard {
      max-width: 1440px;
      background-image: unset;
    }
  }
}
