@import 'CaW/assets/theme';

.loadingContainer {
  display: flex;
  justify-content: center;
  padding: 60px 0;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media screen and (min-width: ($medium-large_screen-min-width + 1)) {
    padding: 13px 23px;
  }

  .header {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: $colorGrey700;
  }

  .paymentContainer {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: $colorGrey700;
    }

    .paymentDetails {
      display: flex;
      gap: 33px;
    }

    .label {
      flex: 1 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .value {
      flex: 3 1;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.02em;
    }
  }

  .cancelPolicyWrapper {
    display: flex;
    flex-direction: column;

    .cancelTitle {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: $colorGrey700;
      padding-bottom: 8px;
    }
  }
}
