@import 'helm/assets/theme';

.container {
  display: flex;
  align-items: center;
  margin: 24px 0;
  flex: 1;
  max-width: 100%;

  @media screen and (max-width: $tablet-screen-max-content) {
    flex-direction: column;
  }

  .menuWrapper {
    flex: 4;
    z-index: 1;

    @media screen and (max-width: $tablet-screen-max-content) {
      display: none;
    }
  }

  .scrollWrapper {
    display: none;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: $tablet-screen-max-content) {
      display: flex;
    }

    .mobileMenuWrapper {
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
