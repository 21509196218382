@import 'assets/theme';
@import 'SomeoneHealth/assets/theme';

.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  margin-bottom: 0;
  overflow: auto;

  .loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    top: 0;
    background-color: #ffffffc0;
    position: fixed;
    z-index: 2;
  }

  .box {
    display: flex;
    flex-direction: column;
    margin: 0 60px;
    background-color: $someone-health-light-blue;
    padding: 16px;
    border-radius: 8px;

    @media (max-width: $medium-desktop-screen-max-content) {
      margin: 0;
    }

    .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px 0 32px;

      img {
        height: 150px;
        min-width: 150px;
        border-radius: 50%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      max-width: 1024px;
      margin: 0 auto;

      .badge {
        display: flex;
        align-items: center;
        border-radius: 4px;
        padding: 8px 16px;
        background-color: #219653;
        color: $white;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.14px;
        width: max-content;
      }

      .title {
        display: flex;
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: -0.02em;
        color: $colorGrey700;
        padding-top: 20px;

        @media (max-width: $tablet-screen-max-content) {
          font-size: 24px;
        }
      }

      .desc {
        font-size: 18px;
        line-height: 23px;
        padding-top: 28px;

        @media (max-width: $tablet-screen-max-content) {
          font-size: 16px;
        }

        .link {
          color: $someone-health-blue;
        }
      }
    }

    .closeButtonWrapper {
      display: flex;
      justify-content: center;
      padding: 48px 0 24px;

      .closeButton {
        min-width: 358px;
      }
    }
  }
}
