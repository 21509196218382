@import 'CaW/assets/theme';

.footerContainer {
  background-color: $caw-blue;
  color: $white;
  padding: 55px 0 40px;
  font-size: 0.7857em;
  line-height: 26px;
  font-family: $Gotham-Book;
}

.container {
  max-width: $desktop-screen-max-content;
  width: 100%;
  margin: 0 auto;

  .footerTop {
    display: flex;
    margin-bottom: 45px;
    max-width: 55%;
    @include columnGap(16px);

    @media (max-width: $tablet-screen-max-content) {
      max-width: 100%;
    }
  }

  .list {
    display: flex;
    flex: 1;

    ul {
      list-style: none;
      padding-left: 0;

      li {
        font-size: 11px;
        font-weight: 500;
        margin-bottom: 0;
        line-height: 28px;
        letter-spacing: 0;
        text-transform: uppercase;

        a {
          display: flex;
          color: $white;
          cursor: pointer;
        }
      }
    }
  }

  .footerMiddle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;

    .box {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $tablet-screen-max-content) {
        flex-direction: column;
      }

      .item {
        display: flex;
        align-items: center;
        padding: 0 20px;
        height: 14px;
        line-height: 20px;

        &:first-child {
          border-right: 2px solid #fff;

          @media (max-width: $tablet-screen-max-content) {
            border-right: none;
            margin-bottom: 8px;
          }
        }

        a {
          color: $white;
        }
      }
    }

    .infoWrapper {
      display: flex;
      flex-direction: column;
      font-family: $Gotham-Book;
      font-weight: 700;
      @include rowGap(10px);
    }

    .socialIcons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px 0 8px;
      color: $white;
      font-family: $Socialico;
      @include columnGap(4px);

      .icon {
        font-size: 32px;
        color: $white;
      }
    }
  }

  .footerBottom {
    text-align: center;
    font-size: 0.7857em;
    width: 100%;
  }
}
