@import 'SomeoneHealth/assets/theme';

.container {
  @include s1hLoginBackground;
}

.title {
  font-size: 24px;
  padding: 0 $standard-padding * 4;
  margin-bottom: $standard-padding * 3;
  margin-top: $standard-padding * 5;
}

.description {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: $standard-padding * 10;
}

.content {
  background-color: $white;
  border-radius: 8px 8px 0 0;
  margin-bottom: $standard-padding * 16;
  padding: $standard-padding * 4 $standard-padding * 8;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  @media screen and (max-width: 560px) {
    border-radius: 0;
    padding: $standard-padding * 8 $standard-padding * 4;
  }
}
