@import 'SomeoneHealth/assets/theme';

.container {
  @include s1hLoginBackground;

  .fixedLeftBtn {
    position: fixed;
    bottom: 40px;
    background: $someone-health-maroon;
    border-color: $someone-health-maroon;
    box-shadow: 0 4px 10px rgba(52, 52, 52, 0.35);
    border-radius: 55px !important;
    height: 55px;
    z-index: 4;
    font-weight: 600;
    font-size: 13px;
    color: $white;
    min-height: 48px;
    min-width: 175px;

    @media (max-height: 740px) {
      bottom: 13px;
      left: 2%;
    }

    .newJournalBtn {
      display: flex;
      align-items: center;

      .icon {
        padding-right: 8px;
      }
    }

    .btnLoading {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn:after {
        border-color: #fff transparent #fff transparent;
      }
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 3;
  }

  .content {
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 32px 16px;

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .contentBox {
      display: flex;
      flex-direction: column;
      row-gap: 32px;
    }
  }

  .main {
    background-color: $white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 35px 80px;
    color: #414449;
    @include rowGap(26px);

    @media (max-width: $small-screen-max-width) {
      padding: 20px;
    }

    .welcomeMessage {
      font-size: 36px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: -0.02em;
      color: $someone-health-maroon;
    }

    .mainContent {
      display: flex;
      justify-content: space-between;

      @media (max-width: $small-screen-max-width) {
        flex-direction: column;
        gap: 12px;
      }
    }

    .leftContent {
      display: flex;
      flex-basis: 45%;
      flex-direction: column;
      @include rowGap(24px);

      @media (max-width: $small-screen-max-width) {
        flex-basis: 100%;
      }
    }

    .rightContent {
      display: flex;
      flex-direction: column;
      flex-basis: 40%;
      @include rowGap(28px);

      @media (max-width: $small-screen-max-width) {
        flex-basis: 100%;
      }

      .desc {
        font-size: 20px;
        font-weight: 500;
        line-height: 21px;
        color: $someone-health-maroon;
      }
    }
  }
}
