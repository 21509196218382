@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: 24px 0;

  .content {
    display: flex;
    flex-direction: row;

    .imageWrapper {
      display: flex;

      .image {
        width: 130px;
        height: 130px;
      }
    }

    .descWrapper {
      display: flex;
      flex-direction: column;
      padding-left: 16px;

      .text {
        font-size: 13px;
        line-height: 20px;
        color: #343434;

        &:not(:last-child) {
          padding-bottom: 16px;
        }
      }
    }
  }

  .buttonWrapper {
    display: flex;
    padding-top: 32px;
    justify-content: center;

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #3f52ff;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 8px 16px;
      color: #3f52ff;
      font-weight: 600;
      font-size: 13px;
      cursor: pointer;

      .icon {
        font-size: 24px;
        padding-right: 4px;
      }
    }
  }
}
