@import 'helm/assets/theme';

.container {
  display: flex;
  align-items: center;

  @media (max-width: $mobile-screen-max-content + 100px) {
    max-width: 90%;
  }

  .logoWrapper {
    display: flex;
    width: 80px;
    margin-right: $standard-padding * 4;

    .logo {
      width: 100%;
      border-radius: 50%;
    }
  }

  .descWrapper {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 28px;
      line-height: 30px;
      font-weight: 600;
      color: $grey700;

      @media (max-width: $mobile-screen-max-content + 100px) {
        font-size: 20px;
        line-height: 24px;
        padding-bottom: $standard-padding;
      }
    }

    .desc {
      font-size: 18px;
      line-height: 23px;
      font-weight: 600;
      color: $grey700;

      @media (max-width: $mobile-screen-max-content + 100px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
