@import 'SomeoneHealth/assets/theme';
@import 'helm/assets/theme';
@import 'Recharge/assets/theme';

.container {
  margin-top: $standard-padding * 5;
  margin-bottom: $standard-padding * 10;

  .title {
    font-size: 24px;
    color: $darkblue500;
  }

  .description {
    color: $darkblue500;
    .highlight {
      font-weight: 600;
    }
    margin-bottom: 2rem;
  }

  .submitButton {
    padding: $standard-padding * 4;
    box-shadow: none;

    &:hover {
      opacity: 0.8;
    }
  }

  .infoRow {
    display: flex;
    align-items: center;
    margin-top: $standard-padding * 4;
    font-weight: 400;
    font-size: 16px;

    .label {
      color: $darkblue400;
      flex: 1 48%;
    }

    .value {
      color: $darkblue500;
      flex: 1 52%;
    }
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-top: $standard-padding * 5;

    .editButton,
    .cancelBtn {
      padding: $standard-padding * 4;
      box-shadow: none;
      background-color: transparent;
      color: $helm-primary;

      .icon {
        font-size: 24px;
        margin-right: 10px;
        vertical-align: top;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    .editButton {
      margin-top: $standard-padding * 4;
      border-radius: 4px;
      border: solid 1px $helm-primary;
    }
  }
}

:global(.recharge-theme) {
  .editCard {
    background-color: $blue50;
    padding: $standard-padding * 4;
    border-radius: 4px;
  }
}

:global(.s1h-client-portal-theme) {
  .title,
  .description {
    color: $someone-health-maroon;
    font-weight: 500;
  }

  .infoRow {
    .label {
      color: $someone-health-maroon2;
    }
    .value {
      color: $someone-health-maroon;
    }
  }
}
