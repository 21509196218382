// Base
$base-font-stack: 'Nunito';
$footer-font-stack: 'Nunito Sans';

// Font size
$font-extra-tiny: 10px;
$font-tiny: 12px;
$font-small: 14px;
$font-standard: 16px;
$font-medium-large: 18px;
$font-large: 24px;
$font-extra-large: 30px;
$font-extreme-large: 60px;

// Font weight
$font-weight-light: 200;
$font-weight-regular: 300;
$font-weight-bold: 400;
$font-weight-boldest: 800;

$smp-font-family: Arial, sans-serif;
$big-font-size: 48px;
$medium-font-size: 28px;
$standard-font-size: 16px;

@mixin titleFontSize() {
  font-size: $big-font-size;
  line-height: $big-font-size;
}

@mixin mediumFontSize() {
  font-size: $medium-font-size;
  line-height: $medium-font-size;
}

@mixin subtitle() {
  @include mediumFontSize();
  color: $smp-light-green;
  margin-top: 16px;
  font-weight: 700;
}
