@import 'Recharge/assets/theme';

.greet {
  color: $recharge-black;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;

  @media (max-width: $tablet-screen-max-content) {
    font-size: 28px;
    line-height: 36px;
  }

  @media (max-width: $mobile-screen-max-content) {
    font-size: 24px;
  }
}

.subGreet {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;

  @media (max-width: $tablet-screen-max-content) {
    font-size: 20px;
    line-height: 26px;
  }

  @media (max-width: $mobile-screen-max-content) {
    font-size: 16px;
    line-height: 20px;
  }
}

.descWrapper {
  display: flex;
  flex-direction: column;
  margin-top: $standard-padding * 5;
  @include rowGap(16px);

  .desc {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 16px;
    }
  }
}

.content {
  white-space: pre-line;
  //font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: $recharge-black;

  @media (max-width: $small-desktop-screen-max-content) {
    font-size: 16px;
    line-height: 21px;
  }

  @media (max-width: $mobile-screen-max-content) {
    font-size: 14px;
    line-height: 21px;
  }
}

.bioPhoto {
  width: 100%;
  margin-top: 48px;
  border-radius: 20px;

  @media (max-width: $tablet-screen-max-content) {
    margin-top: 16px;
  }
}
