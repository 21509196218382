@import 'CaW/assets/theme';

.container {
  max-width: 540px;

  .customInput {
    transform: translateX(-$standard-padding);
    padding: 0 $standard-padding;
    color: #343434;
    margin-bottom: $standard-padding !important;
    line-height: 44px;
    font-weight: 500;

    &:focus ~ .inputLabel {
      color: $caw-blue !important;
    }
  }

  .customSelect {
    padding: $standard-padding * 2 0;
  }

  .inputLabel {
    color: #818e9b;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }

  .selectInputLabel {
    @extend .inputLabel;
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;

    &:focus {
      color: $caw-blue !important;
    }
  }

  .subHeader {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 6px;
  }

  .submitButton {
    width: 100%;
    margin-top: $standard-padding * 7;
    margin-bottom: $standard-padding * 15;

    @media (max-width: $mobile-screen-max-content) {
      margin-bottom: $standard-padding * 20;
    }
  }
}
