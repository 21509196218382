@import 'helm/assets/theme';
@import 'SomeoneHealth/assets/theme';

.timeLabel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;

  > div {
    div {
      font-family: $primary-font-stack-medium;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .label {
    font-family: 'Roboto', sans-serif;
  }
}

:global(.s1h-client-portal-theme) {
  .timeLabel {
    font-weight: $font-weight-bolder;
    line-height: 43px;
    color: $someone-health-maroon;

    > div {
      div {
        line-height: 16px;
        font-weight: $font-weight-bold;
      }
    }

    .label {
      font-family: $Poppins;
    }
  }
}
