$Gotham-Book: 'Gotham Book';
$Playfair-Display: 'Playfair Display';
$Socialico: 'Socialico';

// Font size
$font-extra-tiny: 10px;
$font-tiny: 12px;
$font-small: 14px;
$font-standard: 16px;
$font-medium-large: 18px;
$font-large: 24px;
$font-extra-large: 30px;
$font-extreme-large: 60px;

// Font weight
$font-weight-light: 200;
$font-weight-regular: 300;
$font-weight-bold: 400;
$font-weight-boldest: 800;

@font-face {
  font-family: $Gotham-Book;
  src: url(~CaW/assets/theme/typography/GothamBold.woff2) format('woff2'),
    url(~CaW/assets/theme/typography/GothamBold.woff) format('woff');
  font-weight: 700;
}

@font-face {
  font-family: $Gotham-Book;
  src: url(~CaW/assets/theme/typography/GothamBook.woff2) format('woff2'),
    url(~CaW/assets/theme/typography/GothamBook.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  ascent-override: 90%;
}

@font-face {
  font-family: $Socialico;
  src: url(~CaW/assets/theme/typography/Socialico.woff) format('woff'),
    url(~CaW/assets/theme/typography/Socialico.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@mixin playfairDisplayFont() {
  font-family: $Playfair-Display;
  letter-spacing: 0;
}

// Font family
$font: $Gotham-Book;
