@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 40px 20px;
  line-height: normal;

  @media (min-width: $medium-small-screen-min-width) {
    padding: 64px 60px;
  }

  @media (min-width: $tablet-screen-max-content) {
    padding: 64px 100px;
  }

  @media (min-width: $medium-desktop-screen-max-content) {
    padding: 64px 120px;
  }

  .title {
    font-family: $title-font-stack;
    font-size: 32px;
    color: $helm-blue;

    @media (min-width: $medium-small-screen-min-width) {
      font-size: 44px;
    }

    @media (min-width: $tablet-screen-max-content) {
      font-size: 48px;
    }

    @media (min-width: $medium-desktop-screen-max-content) {
      font-size: 52px;
    }
  }

  .content {
    font-family: $primary-font-stack-light;
    font-size: 14px;
    color: $helm-blue;

    @media (min-width: $medium-small-screen-min-width) {
      font-size: 20px;
    }

    @media (min-width: $tablet-screen-max-content) {
      font-size: 24px;
    }

    @media (min-width: $medium-desktop-screen-max-content) {
      font-size: 28px;
    }
  }
}
