@import 'helm/assets/theme';

.modalContainer {
  width: 100% !important;
  max-width: 950px;

  .content {
    display: flex;
    flex-direction: column;

    .calendar {
      padding: 0 0 16px;
    }

    .bookingSession {
      margin: 10px -32px -32px -32px;
    }
  }
}
