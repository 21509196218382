@import 'Recharge/assets/theme';

.wrapper {
  display: flex;
  flex-direction: column;
  @include rowGap(24px);

  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: $recharge-black;
  }

  .contact {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    color: $recharge-black;

    .highlight {
      @extend .contact;
      color: $recharge-purple;
      font-weight: 700;
    }
  }
}
