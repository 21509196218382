@import 'assets/theme';
@import 'helm/assets/theme';

.container {
  font-family: $smp-font-family;
  page-break-inside: avoid;

  .atAGlanceContainer {
    display: grid;
    grid-gap: $smp-standard-padding * 8;
    font-size: 20px;
    padding-top: $smp-standard-padding * 16;
    margin-bottom: $smp-standard-padding * 8;
    grid-template-areas: 'title .' 'title .';

    @media screen and (max-width: $smp-mobile-screen-max-content) {
      grid-template-areas: 'title';
    }

    .titleContainer {
      grid-area: title;
      max-width: 315px;
      margin-bottom: 3rem;

      .title {
        @include titleFontSize;
        margin-bottom: $smp-standard-padding * 2;
      }

      .subtitle {
        @include subtitle;
      }
    }

    .cardContainer {
      display: grid;
      grid-gap: $smp-standard-padding * 8;

      .cravingManagement,
      .warningSign {
        position: relative;
        padding-left: $smp-standard-padding * 7;
        padding-right: $smp-standard-padding * 7;
        background-color: $smp-vanilla;
      }

      .top {
        order: -1;
      }
    }

    .label {
      margin-bottom: $smp-standard-padding * 8;
    }

    .handStopContainer {
      display: flex;
      margin-top: $smp-standard-padding * 4;
      justify-content: flex-end;
    }

    .handStop {
      right: $smp-standard-padding * 3;
      width: 80px;
      height: 80px;
      bottom: $smp-standard-padding * 3;
    }
  }

  .strategiesContainer {
    gap: $smp-standard-padding * 8;
    margin: 0 -32px;
    padding: $smp-standard-padding * 6 $smp-standard-padding * 10;
    display: flex;
    min-height: 200px;
    align-items: center;
    align-items: flex-start;
    background-color: $smp-yellow;

    @media screen and (max-width: $smp-mobile-screen-max-content) {
      flex-direction: column;
    }

    .label {
      font-size: 36px;
      flex-basis: 316px;
      line-height: 1.1;
      margin-bottom: $smp-standard-padding * 4;

      @media screen and (max-width: $smp-mobile-screen-max-content) {
        flex-basis: auto;
        margin-bottom: 0;
      }
    }

    .strategiesValue {
      flex: 1;
      font-size: 20px;
      margin-top: $smp-standard-padding * 4;
      white-space: pre-wrap;

      @media screen and (max-width: $smp-mobile-screen-max-content) {
        flex-shrink: 0;
        flex-grow: 1;
        width: 100%;
      }
    }
  }

  .signatureContainer {
    display: flex;
    page-break-inside: avoid;

    @media screen and (max-width: $smp-mobile-screen-max-content) {
      flex-direction: column;
    }

    .description {
      padding: $smp-standard-padding * 10;
      font-size: 1.6rem;
      max-width: 388px;
      padding-left: 0;

      @media screen and (max-width: $smp-mobile-screen-max-content) {
        max-width: none;
      }
    }

    .signature {
      flex: 1;
      display: flex;
      padding: $smp-standard-padding * 10 0;
      font-size: 1.1rem;
      margin-top: auto;
      text-align: right;
      flex-direction: column;

      .buttonContainer {
        margin-bottom: $smp-standard-padding * 6;
      }

      .signDetails {
        text-transform: uppercase;
      }

      .separator {
        margin: 0 $smp-standard-padding * 2;
      }

      .dayYear {
        font-size: 0.9rem;
        margin-left: 0.5rem;
      }
    }
  }
}

:global(.helm-theme) {
  .titleContainer,
  .cravingManagement .label,
  .warningSign .label,
  .strategiesContainer .label,
  .signatureContainer .description {
    font-weight: normal;
    font-family: $title-font-stack;
  }

  .cravingManagement,
  .warningSign,
  .strategiesContainer,
  .signatureContainer .signDetails,
  .textArea {
    font-family: $primary-font-stack-medium;
  }

  .signButton {
    border-radius: 20px;
    font-family: $title-font-stack;
    font-weight: normal !important;
  }

  .atAGlanceContainer .titleContainer .subtitle {
    color: $helm-blue;
  }

  .strategiesContainer {
    background-color: $helm-yellow;
  }
}
