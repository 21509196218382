@import 'helm/assets/theme';

.container {
  height: max-content;
  min-height: 100vh;
  background-color: $darkblue500;

  .headerWrapper {
    max-width: 100%;

    .header {
      padding: 8px 0;
      max-width: 100%;
    }
  }

  .loadingWrapper {
    width: 100%;
    max-width: $desktop-screen-max-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    color: $white;
  }

  .content {
    width: 100%;
    max-width: $desktop-screen-max-content;
    margin: 0 auto;
  }

  .headerContentWrapper {
    background: url(~helm/assets/images/background/IP6Header.png) center no-repeat;
    background-size: cover;
    max-width: 100%;
  }

  .progressWrapper {
    background-color: $helm-light-yellow;
    max-width: 100%;
  }

  .programWrapper {
    background-color: $helm-lighter-yellow;
    max-width: 100%;
    padding: 0;
  }

  .faqWrapper {
    background-color: $helm-light-yellow;
    max-width: 100%;

    .faqContent {
      background-color: transparent;
      padding: 120px 0 40px;
    }

    .contactUsWrapper {
      padding: 40px 0 100px;

      .contactUsTitle {
        font-family: 'Nunito', normal !important;
        font-weight: 800;
        font-size: 60px;
        line-height: 82px;

        @media (max-width: $tablet-screen-max-content) {
          font-size: 38px;
        }
      }

      .contactUsButton {
        font-family: 'Nunito', normal !important;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}
