@import 'Select/assets/theme';

.container {
  height: max-content;
  position: relative;
  z-index: 1;

  .content {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
}
