@import 'MumsMatter/assets/theme';

$headerHeight: 82px;

.backgroundContainer {
  background: linear-gradient(90deg, $mums-matter-light-blue 50%, transparent 50%),
    url('~MumsMatter/assets/images/background/background.png');
  background-size: cover; /* Adjust to fit the container */
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: $medium-large_screen-min-width) {
    background: transparent;
  }

  .headerWrapper {
    position: relative;

    .header {
      position: absolute;
      padding: 12px 0 !important;

      @media (max-width: $medium-large_screen-min-width) {
        position: unset;
        padding: 0 !important;
      }
    }
  }
}

.container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  min-height: calc(100vh - 42px);

  @media (max-width: $small-desktop-screen-max-content) {
    padding-left: 0;
    padding-right: 0;
    justify-content: space-evenly;
    padding-bottom: unset;
  }
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.leftContent {
  width: 100%;
  padding-top: $headerHeight;
  padding-bottom: 24px;
  padding-right: 40px;
  background-color: $mums-matter-light-blue;

  @media (max-width: $medium-large_screen-min-width) {
    padding-top: 0;
    padding-right: 0;
    background-color: $white;
  }
}

.rightContent {
  max-width: 320px;
  width: 100%;
  padding: 92px 16px 16px 16px;

  @media (max-width: $medium-large_screen-min-width) {
    display: none;
    background-color: transparent;
    padding: 0 16px 16px;
  }
}
