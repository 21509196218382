// select main color
$select-beige: #f8d7c4;
$select-black: #333333;
$select-green: #8be1b7;
$select-light-green: #d1f3e2;
$select-grey: #f2f2f2;
$select-light-grey-10: #f3fcf8;
$select-light-grey-100: #fdf5f0;
$select-red: #ee9c6c;
$select-light-red: #f3ba98;

$colorGrey50: #f9f9fa;
$colorGrey100: #ecedef;
$colorGrey500: #94969d;
$colorGrey600: #61666e;
$colorGrey700: #414449;
$colorGrey800: #202225;

$text-disable-grey: #aaaaaa;

$badge-background-color: rgba(44, 52, 96, 0.2);

$black: #000000;
$white: #ffffff;

$darkblue50: #eaedfa;
$darkblue200: #6e80dc;
$darkblue300: #314bcb;
$darkblue400: #22348c;
$darkblue500: #2a4670;
$darkblue400: #426eb1;

$lightpink: #fcf7f7;
$blue50: #f5f6ff;
$blue500: #3f52ff;

$red500: #eb4545;
$red400: #ef6a6a;
$orange500: #ff7d45;
$green500: #4bc27d;

// grey
$grey700: #414449;
$grey600: #61666e;
$grey500: #949aa2;
$grey100: #ecedef;
$green50: #edf9f2;
$calendarPill: #cfebdb;
