@import 'SomeoneHealth/assets/theme';

.container {
  @include s1hLoginBackground;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 3;
  }

  .content {
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 16px 16px 120px;

    .contentCardShadow {
      box-shadow: 0px 4px 8px 0px #0000001a;
      max-width: none;
    }

    .titleContainer {
      display: flex;
      justify-content: space-between;
      color: $someone-health-maroon;
      font-weight: 500;

      .title {
        font-size: 28px;
        line-height: 42px;
        margin-bottom: $standard-padding * 3;
        margin-top: $standard-padding * 4;
        padding-left: 38.5px;
      }

      .timeZone {
        display: flex;
        align-items: flex-end;
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
