@import 'Select/assets/theme';

.container {
  display: flex;
  width: 90%;
  align-items: center;
  gap: 64px;

  @media (max-width: $medium-desktop-screen-max-content) {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }

  .label {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-shadow: 0 0 8px $black;

    @media (max-width: $tablet-screen-max-content) {
      margin-right: 0;
      width: 100%;
      text-align: center;
    }
  }

  .mentalHealthConcern {
    max-width: 460px;
    width: 100%;

    @media (max-width: $medium-desktop-screen-max-content) {
      max-width: 100%;
    }
  }
  .secondLayerFilterWrapper {
    display: flex;
    width: 100%;
    align-items: center;

    .location {
      width: 100%;

      @media (max-width: $medium-desktop-screen-max-content) {
        max-width: 50%;
      }

      @media (max-width: $tablet-screen-max-content) {
        max-width: 100%;
      }
    }
  }
}
