@use 'sass:math';
@import 'assets/theme';

.container {
  background: rgba(255, 242, 236, 1);
  padding: $standard-padding * 4;

  display: flex;
  flex-direction: column;

  * {
    font-family: 'Open Sans';
    color: #3f52ff;
  }
}

.title {
  font-family: 'Monoton' !important;
  color: #3f52ff;
  font-size: 24px;
  margin-bottom: math.div($standard-padding, 2) * 3;
}
