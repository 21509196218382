@import 'assets/theme';
@import 'Select/assets/theme';

.label {
  display: inline-block;
  margin-bottom: $standard-padding * 4;
  font-size: 11px;
  color: #818e9b;
  opacity: 1;
  font-weight: 500;
}

.textarea {
  width: 100%;
  max-height: 420px;
  padding: $standard-padding * 2 $standard-padding * 3;
  border: 1px solid #dadbde;
  border-radius: 4px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  resize: none;
  // overflow: hidden;

  &::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
}

:global(.select-theme) {
  .label {
    font-size: 14px;
    font-weight: 600;
    color: $select-black;
  }
}
