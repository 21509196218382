@import 'helm/assets/theme';
@import 'Select/assets/theme';
@import 'SomeoneHealth/assets/theme';

.modal {
  & > div {
    background-color: transparent;
    box-shadow: none;
  }
}

.avatarTitle {
  font-size: 20px;
  margin-top: $standard-padding * 5;
  text-align: center;
}

.avatarFooter {
  text-align: right;
  padding: $standard-padding * 2 $standard-padding * 6;
}

.avatarNicknameInput {
  display: none !important;
}

.container {
  .content {
    max-width: 1280px;
    background-color: white;
    border-radius: 8px;
    padding: $standard-padding * 6 $standard-padding * 8;
    margin: $standard-padding * 6 $standard-padding * 4;

    @media (max-width: $tablet-screen-max-content) {
      padding: $standard-padding * 8 $standard-padding * 6;
      margin: $standard-padding * 6 $standard-padding * 4;
    }

    .title {
      font-size: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $darkblue500;

      .saveButton {
        box-shadow: none;
        font-size: 16px;
        padding: $standard-padding * 2 $standard-padding * 3;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .detail {
      display: flex;
      align-items: flex-start;

      @media (max-width: $mobile-screen-max-content) {
        flex-direction: column;
      }

      .avatar {
        width: 122px;
        height: 122px;
        border: dashed 1px $helm-primary;
        border-radius: 100%;
        margin-top: $standard-padding * 8;
        cursor: pointer;

        @media (max-width: $mobile-screen-max-content) {
          width: 80px;
          height: 80px;
        }

        img {
          height: 100%;
        }
      }
    }

    .linkBtn {
      color: $helm-primary;
      background-color: transparent;
      box-shadow: none;
      padding: 0 $standard-padding * 4;
      font-size: 16px;
      text-align: left;
      margin-left: -$standard-padding * 4;
      margin-right: $standard-padding * 4;
      margin-top: $standard-padding * 7;
      height: fit-content;

      i {
        font-size: 14px;
        margin-right: 10px;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    .contact {
      margin-top: $standard-padding * 5;

      .title {
        font-size: 24px;
        color: $darkblue500;
      }

      .description {
        color: $darkblue500;
        .highlight {
          font-weight: 600;
        }
        margin-bottom: 2rem;
      }

      .contactList {
        color: $black;
      }

      .addKeyBtnWrapper {
        margin-top: $standard-padding * 5;
        margin-bottom: 44px;
      }

      .addContactBtn {
        border: solid 1px $helm-primary;
        padding: $standard-padding * 4;
        background-color: transparent;
        color: $helm-primary;
        box-shadow: none;

        i {
          font-size: 24px;
          margin-right: 10px;
          vertical-align: top;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .loading {
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

:global(.select-theme) {
  .title {
    color: $select-black !important;
    font-weight: 600 !important;
  }

  .description {
    color: $select-black !important;

    .highlight {
      color: $select-red;
    }
  }
}

:global(.s1h-client-portal-theme) {
  .avatarTitle {
    font-size: 30px;
  }

  .container .content {
    .title {
      color: $someone-health-maroon;
      font-weight: 500;
    }

    .detail {
      color: $someone-health-maroon;

      .avatar {
        border-color: $someone-health-maroon;
      }
    }

    .description {
      color: $someone-health-maroon;

      .highlight {
        font-weight: $font-weight-bolder;
      }
    }

    .contact .contactList {
      color: $someone-health-maroon;
    }
  }
}
