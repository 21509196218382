@import 'assets/theme';

.container {
  display: flex;
  padding: 24px 32px;

  .icon {
    color: $colorBlue1;
    border: 1px solid $colorBlue1;
    max-height: 46px;
    margin-right: $standard-padding * 2.5;
    border-radius: 50%;
  }

  .contentContainer {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;

    .primaryText {
      color: $colorBlue1;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .secondaryText {
      color: #949aa2;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
