@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  align-items: center;
  height: 120px;

  @media (max-width: $tablet-screen-max-content) {
    height: 56px;
  }
}

.noWrapperContainer {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.padding {
  padding: $standard-padding * 2 $standard-padding * 4 !important;
}

.logoWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  z-index: 999;

  .logoBox {
    cursor: pointer;

    .maroonLogoImage {
      display: none;
    }
  }

  .logo {
    max-width: 270px;

    @media (max-width: $tablet-screen-max-content) {
      max-width: 160px;
    }
  }
}

.rightContent {
  display: flex;
  align-items: center;
  gap: 56px;
  min-height: 50px;

  @media (max-width: $tablet-screen-max-content) {
    flex-direction: column-reverse;
    gap: 51px;
    align-items: flex-end;
  }

  .switchViewLink {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #f6fafc;
  }
}

:global(.s1h-client-portal-theme) {
  .logoImage {
    display: none;
  }

  .maroonLogoImage {
    display: unset !important;
  }
}
