@import 'CaW/assets/theme';

.container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  @include playfairDisplayFont;

  .numberBubble {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    color: $caw-blue;
    border-radius: 50%;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    padding-bottom: 3px;
    min-width: 30px;
    height: 30px;
    margin-right: 8px;
  }

  .stepTitle {
    color: $grey700;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
  }
}
