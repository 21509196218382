$Playfair-Display: 'Playfair Display';
$Montserrat: 'Montserrat';

// Font size
$font-extra-tiny: 10px;
$font-tiny: 12px;
$font-small: 14px;
$font-standard: 16px;
$font-medium-large: 18px;
$font-large: 24px;
$font-extra-large: 30px;
$font-extreme-large: 60px;

// Font weight
$font-weight-light: 200;
$font-weight-regular: 300;
$font-weight-bold: 400;
$font-weight-boldest: 800;

// Font family
$font: $Montserrat;

body {
  font-family: $Montserrat, normal;
}
