@import 'CORDS/assets/theme';

.container {
  background-color: $cords-dark-blue;

  .content {
    max-width: $desktop-screen-max-content;
    margin: 0 auto;
    width: 100%;
  }
}
