@import 'helm/assets/theme';

$scrollingSlotView: $mobile-screen-max-content + 100px;

.headerWrapper {
  display: flex;
}

.errorContainer {
  display: flex;
  flex-direction: column;

  .noTimeSlotContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .noTimeSlotTitle {
      font-family: $title-font-stack !important;
      font-size: 60px;
      line-height: 66px;
      text-align: center;
      color: $helm-blue;

      @media (max-width: $scrollingSlotView) {
        font-size: 40px;
        line-height: 40px;
      }
    }

    .noTimeSlotDesc {
      display: flex;
      text-align: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: $helm-blue;
      max-width: 700px;
      padding-top: $standard-padding;

      @media (max-width: $scrollingSlotView) {
        font-size: 16px;
        line-height: 22px;
      }
    }

    .noTimeSlotButtonWrapper {
      padding-top: $standard-padding * 6;

      .noTimeSlotButton {
        font-size: 32px;
        line-height: 35px;

        @media (max-width: $scrollingSlotView) {
          font-size: 26px;
          line-height: 30px;
        }
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: row;
  padding: 0 $standard-padding * 7;

  .leftContent {
    flex: 1;
  }

  .rightContent {
    flex: 1;
    text-align: center;
  }

  .clientTestimonialCard {
    max-width: 410px;
    margin: auto;
    display: none;
  }

  @media (max-width: $tablet-screen-max-content) {
    flex-direction: column-reverse;
    padding: 0;
    .clientTestimonialCard {
      display: block;
      margin-bottom: $standard-padding * 20;
    }
  }

  @media (max-width: $mobile-screen-max-content) {
    .clientTestimonialCard {
      margin-bottom: $standard-padding * 10;
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}
