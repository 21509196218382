@import 'Recharge/assets/theme';

.container {
  display: flex;
  padding: 40px 0;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $recharge-black;

    .title {
      font-size: 36px;
      line-height: 47px;
      font-weight: 700;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 20px;
        line-height: 26px;
      }

      @media (max-width: $mobile-screen-max-content) {
        width: 100%;
        text-align: left;
      }
    }

    .desc {
      max-width: 650px;
      text-align: center;
      font-size: 20px;
      line-height: 26px;
      font-weight: 600;

      @media (max-width: $tablet-screen-max-content) {
        text-align: left;
        font-size: 14px;
        line-height: 18px;
        padding-top: $standard-padding;
      }

      @media (max-width: $mobile-screen-max-content) {
        width: 100%;
        text-align: left;
      }

      a {
        color: $recharge-purple;
        text-decoration: underline;
      }
    }

    .faqWrapper {
      padding: 64px 0 0;
      max-width: 900px;

      @media (max-width: $tablet-screen-max-content) {
        padding: 34px 0 0;
      }
    }
  }
}
