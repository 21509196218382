@import 'SomeoneHealth/assets/theme';

.backgroundSection {
  display: flex;
  flex-direction: column;

  .header {
    color: $colorGrey700;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
  }

  .noteAreaLabel {
    padding: 24px 0 4px 0;
  }

  .textAreaField {
    width: 100%;
    height: 100px;
    border-radius: 4px;
    padding: 12px;
    border: 1px solid #dadbde;
    color: #000000;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  }
}
