@import '../../InvoiceTemplatesPreviewCommon.scss';

.infoWrapper {
  @extend .flexStart;
  gap: 4px;
  .label {
    min-width: 120px;
    @extend .textLable;
  }
  .infoText {
    @include textInfoValue();
  }
}
