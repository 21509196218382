@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 16px;
  padding: 28px 20px;
  max-width: 420px;
  min-width: 420px;
  position: relative;

  @media (max-width: $tablet-screen-max-content) {
    max-width: 330px;
    min-width: 330px;
  }

  .title {
    margin-bottom: 24px;
    font-family: $title-font-stack;
    font-size: 14px;
    line-height: 17px;
    color: $helm-purple;
  }

  .content {
    max-height: 510px;
    overflow: auto;
  }
}
