@import 'Ease/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  font-family: $font, sans-serif;
  min-height: 100vh;

  input,
  textarea,
  button {
    font-family: $font, -apple-system, BlinkMacSystemFont, sans-serif;
  }
}
