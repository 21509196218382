@import 'helm/assets/theme';

$slotChangeViewTablet: $small-desktop-screen-max-content + 220px;
$scrollingSlotView: $mobile-screen-max-content + 100px;

.bookingContainer {
  background: linear-gradient(89.23deg, #f15944 -2.31%, #9342f7 103.36%);
  padding: 24px 0;

  &.isAdvisorySession {
    padding: 24px;
  }

  .bookingContent {
    display: flex;
    align-items: center;
    @include columnGap(16px);

    @media (max-width: $slotChangeViewTablet) {
      flex-direction: column;
      @include columnGap(0);
      @include rowGap(16px);
      margin: 0 auto;
      width: 100%;
      align-items: flex-start;
    }

    @media (max-width: $scrollingSlotView) {
      display: block;
    }

    .bookingDesc {
      display: flex;
      flex-direction: column;

      .bookingTitle {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: $white;
      }

      .bookingDescSummary {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: $helm-yellow;
      }

      .bookingOnLabel {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: $white;
      }
    }

    .slotContainer {
      display: flex;
      flex: 1;
      flex-direction: column;

      @media (max-width: $slotChangeViewTablet) {
        width: 100%;
        align-items: center;
      }

      @media (max-width: $scrollingSlotView) {
        width: auto;
        align-items: initial;
      }

      .blocked {
        background: $helm-light-yellow;
        border: 2px solid $helm-blue;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: $helm-blue;
        padding: $standard-padding;
        border-radius: 4px;
        max-width: max-content;
        margin-bottom: 16px;
      }

      .slotWrapper {
        display: flex;
        max-width: 100%;
        align-items: center;
        row-gap: 16px;
        @include columnGap(16px);

        @media (max-width: $scrollingSlotView) {
          overflow-x: auto;
          overflow-y: hidden;
          margin: -16px -16px 0 0;
          padding: 16px 0 4px;

          & > * {
            margin-right: 16px;
          }

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }

      .slotLabelMobile {
        color: $white;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        padding-left: 40px;
        padding-top: 4px;
        display: none;

        @media (max-width: $slotChangeViewTablet) {
          display: block;
          padding-left: 0;
          font-size: 10px;
          line-height: 13px;
        }
      }
    }

    .continueBtnWrapper {
      display: flex;
      flex-direction: column;
      max-width: 250px;

      @media (max-width: $slotChangeViewTablet) {
        align-items: center;
        width: 100%;
        max-width: 100%;
      }

      .continueBtn {
        display: flex;
        align-items: center;
        background-color: $helm-yellow;
        color: $helm-purple;
        font-size: 18px;
        line-height: 22px;

        @media (max-width: $slotChangeViewTablet) {
          padding: 8px 88px;
          font-size: 16px;
          line-height: 19px;
        }

        @media (max-width: $mobile-screen-max-content) {
          width: 100%;
        }

        .nextBtnIcon {
          display: flex;
          border: 1px solid $helm-purple;
          border-radius: 50%;
          margin-left: 8px;
          font-size: 20px;
        }
      }

      .continueLabel {
        text-align: center;
        color: $white;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        padding-top: 4px;
      }
    }
  }

  .slotLabel {
    color: $white;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding-left: 40px;
    padding-top: 4px;
    margin: 10px auto 0 auto;

    @media (max-width: $slotChangeViewTablet) {
      display: none;
      padding-left: 0;
      font-size: 10px;
      line-height: 13px;
    }
  }
}
