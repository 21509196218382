@import 'helm/assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: $helm-yellow;

  .headerWrapper {
    width: 100%;

    @media (max-width: $tablet-screen-max-content) {
      padding: 8px;
      background-color: $helm-light-yellow;
      z-index: 99;
    }
  }

  .wrapper {
    width: 100%;
    position: relative;
  }

  .overlay {
    background-color: $helm-yellow;
    opacity: 0.5;
    height: 100%;
    width: 68%;
    z-index: 1;
    position: absolute;
    right: 0;
    display: none;

    @media (max-width: $tablet-screen-max-content) {
      display: flex;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: $standard-padding 0 $standard-padding * 3;
  }

  .middleWrapper {
    display: flex;
    min-height: 460px;

    @media (max-width: $tablet-screen-max-content) {
      min-height: 300px;
    }

    .headerImageContainer {
      display: flex;
      position: absolute;
      top: -64px;
      margin-left: 4%;
      width: 100%;
      max-width: 650px;

      @media (max-width: $tablet-screen-max-content) {
        left: -125px;
        top: -44px;
        margin-left: 0;
        width: auto;
      }

      @media (max-width: $mobile-screen-max-content) {
        width: 100%;
      }
    }

    .headerImgWrapper {
      display: flex;
      position: relative;
      width: 100%;

      .headerImageBgWrapper {
        position: absolute;
        width: 106%;
        height: 106%;
        left: -42px;
        top: -21px;

        .headerImgBackground {
          height: 100%;
          mask-size: 100% 100%;
          background-size: cover;
          mask-position: center;
          mask-repeat: no-repeat;
          background-color: $helm-lighter-yellow;
          transform: rotate(85deg);
          mask-image: url(~helm/assets/images/shape/3.png);
        }
      }

      .headerImgMask {
        width: 100%;
        mask-size: 100% 100%;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-image: url(~helm/assets/images/shape/2.png);

        .headerImage {
          width: 100%;
        }
      }
    }

    .detailsWrapper {
      display: flex;
      flex: 1;
      align-items: flex-end;
      flex-direction: column;
      z-index: 2;

      @media (max-width: $tablet-screen-max-content) {
        justify-content: center;
      }

      .name {
        font-family: $title-font-stack;
        color: $helm-blue;
        font-weight: 800;
        font-size: 120px;
        line-height: 132px;

        @media (max-width: $small-desktop-screen-max-content) {
          font-size: 100px;
        }

        @media (max-width: $tablet-screen-max-content) {
          font-size: 40px;
          line-height: 44px;
        }
      }

      .actionWrapper {
        display: flex;
        margin-top: 16px;

        @media (max-width: $tablet-screen-max-content) {
          flex-direction: column-reverse;
        }

        .otherPractitioner {
          display: flex;
          align-items: center;
          font-family: $title-font-stack;
          color: $helm-blue;

          .label {
            font-size: 18px;
            line-height: 20px;
            letter-spacing: 0.02em;
            text-decoration: underline;

            @media (max-width: $tablet-screen-max-content) {
              font-size: 14px;
              line-height: 15px;
            }
          }

          .icon {
            font-size: 20px;
            margin-right: 4px;
          }
        }

        .bookAppointmentBtn {
          margin-left: 40px;

          @media (max-width: $tablet-screen-max-content) {
            margin-left: 0;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}
