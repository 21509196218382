@import 'Select/assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px;
  background-image: url(~Select/assets/images/background/detailsHeader.png);
  background-position: center right;
  background-repeat: no-repeat;

  @media (max-width: $medium-large_screen-min-width) {
    padding: 0;
  }

  @media (max-width: $mobile-screen-max-content) {
    background-size: 60%;
  }

  .wrapper {
    width: 100%;
    position: relative;
  }

  .overlay {
    width: 56%;
    z-index: 1;
    position: absolute;
    right: 0;
    display: flex;
    height: calc(100% + 76px);
    top: -106px;
    min-width: 279px;

    @media (max-width: $tablet-screen-max-content) {
      width: 60%;
    }
  }

  .detailsWrapper {
    display: flex;
    flex: 1;
    align-items: flex-end;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 5%;
    padding-right: 5%;

    @media (max-width: $medium-small-screen-min-width) {
      padding-right: 2%;
    }

    .name {
      color: $select-black;
      font-weight: 800;
      font-size: 60px;
      line-height: 70px;
      max-width: 100%;
      text-align: right;

      @media (max-width: $medium-desktop-screen-max-content) {
        font-weight: 700;
        font-size: 48px;
        line-height: 50px;
        letter-spacing: -0.02em;
      }

      @media (max-width: $medium-large_screen-min-width) {
        font-weight: 700;
        font-size: 36px;
        line-height: 47px;
        letter-spacing: -0.02em;
      }

      @media (max-width: $medium-small-screen-min-width) {
        max-width: 240px;
      }

      @media (max-width: $mobile-screen-max-content) {
        text-shadow: 0 0 1px $white;
      }
    }

    .actionWrapper {
      display: flex;
      margin-top: 16px;

      @media (min-width: ($medium-desktop-screen-max-content - 1px)) {
        margin-bottom: 16px;
      }

      .otherPractitioner {
        display: flex;
        align-items: center;
        color: $select-black;

        .label {
          font-weight: 600;
          font-size: 18px;
          line-height: 23px;

          @media (max-width: $medium-large_screen-min-width) {
            font-size: 14px;
            line-height: 15px;
          }

          @media (max-width: $mobile-screen-max-content) {
            text-shadow: 0 0 1px $white;
          }
        }

        .icon {
          font-size: 20px;
          margin-right: 4px;

          @media (max-width: $mobile-screen-max-content) {
            text-shadow: 0 0 1px $white;
          }
        }
      }

      .bookAppointmentBtn {
        margin-left: 40px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .middleWrapper {
    display: flex;
    min-height: 280px;

    @media (max-width: $medium-large_screen-min-width) {
      min-height: 242px;
    }

    .headerImageContainer {
      display: flex;
      position: absolute;
      top: -120px;
      margin-left: 10%;
      width: 400px;
      height: 400px;

      @media (max-width: $medium-large_screen-min-width) {
        width: 350px;
        height: 350px;
        transform: unset;
        margin-left: 72px;
        left: 38px;
        top: -103px;
      }

      @media (max-width: $mobile-screen-max-content) {
        width: 240px;
        height: 240px;
        transform: unset;
        margin-left: 2%;
        top: -10px;
        left: 0;
      }
    }

    .headerImage {
      width: 100%;
      border-radius: 50%;
    }
  }
}
