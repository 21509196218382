@import '../../../../assets/theme/index';
@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  align-items: center;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 13px;
    line-height: 18px;
  }
}

@media screen and (max-width: $medium-small-screen-min-width) {
  .container {
    padding: 16px 0;

    .content {
      width: 100%;
      font-size: 10px;

      .link {
        font-size: 10px;
      }
    }
  }
}

:global(.s1h-client-portal-theme) {
  .content {
    color: $someone-health-maroon;

    a {
      color: inherit;
      text-decoration: underline;
      font-weight: $font-weight-bolder;
    }
  }
}
