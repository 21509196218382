@import 'Select/assets/theme';

$slotChangeViewTablet: $small-desktop-screen-max-content + 220px;
$scrollingSlotView: $mobile-screen-max-content + 100px;

.appointmentSlotContainer {
  background-color: $select-light-grey-100;
  padding: 24px 0;

  @media (max-width: ($tablet-screen-max-content + 100px)) {
    padding: 12px 0 24px 0;
  }

  .content {
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: $standard-padding * 5;

      @media (max-width: $tablet-screen-max-content) {
        flex-direction: column-reverse;
        align-items: flex-start;
      }

      .leftContent {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;

        .titleWrapper {
          display: flex;
          flex-direction: column;

          @media (max-width: $tablet-screen-max-content) {
            flex: 1;
          }
        }

        .title {
          color: $select-black;
          font-weight: 700;
          font-size: 48px;
          line-height: 62px;

          @media (max-width: $tablet-screen-max-content) {
            text-align: center;
            font-size: 40px;
            line-height: 55px;
          }
        }

        .profile {
          border-radius: 50%;
          max-width: 135px;
          width: 100%;
          height: 135px;
          margin: 0 48px 0 58px;

          @media (max-width: ($small-desktop-screen-max-content + 100px)) {
            max-width: 100px;
            height: 100px;
          }

          @media (max-width: $tablet-screen-max-content) {
            display: none;
          }
        }

        .loginIfExistingClient {
          @extend .desc;

          position: absolute;
          right: 0;

          @media (max-width: $tablet-screen-max-content) {
            display: none !important;
          }
        }
      }

      .desc {
        display: flex;
        align-items: center;
        color: $select-black;
        cursor: pointer;
        max-width: max-content;
        padding-top: 4px;

        @media (max-width: $tablet-screen-max-content) {
          justify-content: center;
          width: 100%;
          max-width: 100%;
        }

        .text {
          font-weight: 600;
          font-size: 18px;
          line-height: 23px;

          @media (max-width: ($small-desktop-screen-max-content + 100px)) {
            font-size: 16px;
            line-height: 20px;
          }

          @media (max-width: ($tablet-screen-max-content + 100px)) {
            font-size: 14px;
            line-height: 15px;
            display: flex;
            text-align: right;
          }
        }

        .arrowIcon {
          font-size: 24px;
          margin-left: 8px;
        }
      }
    }

    .appointmentContentWrapper {
      display: flex;
      flex-direction: column;
      @include rowGap(40px);

      .appointmentType {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        min-height: 160px;
        @include columnGap(24px);

        @media (max-width: ($tablet-screen-max-content + 100px)) {
          overflow-x: auto;
          overflow-y: hidden;
          margin: 0 -16px 0 0;

          & > * {
            margin-right: 16px !important;
          }

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
}

.noAppointmentContainer {
  @extend .appointmentSlotContainer;
  padding: $standard-padding * 15 0;
}

.loadingWrapper {
  display: flex;
  width: 100%;
  @include columnGap(40px);

  .loading {
    display: flex;
    flex-direction: column;
    padding: $standard-padding * 4;
    background: $select-light-grey-100;
    border-radius: 12px;
    border: 1px solid $select-light-grey-100;

    :global {
      .ant-skeleton-content {
        .ant-skeleton-title,
        .ant-skeleton-paragraph > li {
          background: linear-gradient(
            90deg,
            rgba(200, 200, 200, 0.6) 25%,
            rgba(139, 139, 139, 0.7) 37%,
            rgba(200, 200, 200, 0.6) 63%
          );
          background-size: 400% 100%;
        }
      }
    }
  }
}
