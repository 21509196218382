@import 'SomeoneHealth/assets/theme';

.psychologistCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
  border-radius: 8px;
  border: 1px solid #dadbde;
  background: $white;
  gap: 20px;

  .infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 12px;

    .imageWrapper {
      width: 100px;
      height: 100px;
    }

    .borderFrame {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      width: 100px;
      height: 100px;
    }

    .avatar {
      object-fit: cover;
    }

    .nameContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .practitionerName {
        color: $someone-health-blue;
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: -0.56px;

        @media (max-width: $tablet-screen-max-content) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      .registeredPsychologist {
        color: $someone-health-blue;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        margin: 0;
      }
    }

    .shortDescription {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      color: $someone-health-blue;
      margin: 0;

      a {
        color: #3f52ff;
        text-decoration: underline;
      }
    }
  }

  .specialisation {
    display: flex;
    width: 100%;
  }

  .bottomContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: auto;

    .availableContainer {
      top: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 4px;

      .nextAvailable {
        color: $someone-health-blue;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }

      .availableCard {
        border-radius: 12px;
        background: $colorGrey50;
        padding: 16px 20px;
        gap: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .availableDate {
          color: $someone-health-blue;
          font-size: 18px;
          font-weight: 500;
          line-height: 23px;
        }
      }
    }

    .notAvailableCard {
      border-radius: 12px;
      background: $colorGrey50;
      padding: 12px 8px;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 12px;

      .text {
        color: $someone-health-black;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        display: flex;

        &::before {
          content: '';
          min-width: 13px;
          height: 13px;
          border-radius: 50%;
          margin-right: 8px;
          background-color: $someone-health-light-red;
          display: block;
          margin-top: 2px;
        }

        .bold {
          color: $someone-health-blue;
          font-weight: 700;
        }
      }

      .joinButton {
        width: 100%;
      }
    }

    .seeAll {
      margin-top: 20px;
    }
  }
}
