.container {
  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #343434;
    margin-bottom: 16px;
  }

  .fieldContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 32px;
    max-width: 380px;

    &.fieldError,
    .fieldError {
      font-size: 12px;
      color: #ff4d4f;

      input {
        border-bottom: 1px solid #ff4d4f !important;
      }
    }
  }

  .selectContainer {
    @extend .fieldContainer;
    margin: -12px 0 32px;

    .selectContainerChild {
      z-index: 4;
    }
  }
}

:global(.someone-health-theme) {
  .container {
    .fieldContainer {
      max-width: 540px;
    }
  }
}
