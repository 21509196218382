@import 'CORDS/assets/theme';

.container {
  background-color: $cords-yellow;

  .container {
    display: flex;
    flex: 1;
    background-color: $cords-yellow;
  }

  .content {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .loading {
    background-color: $cords-yellow;
  }
}
