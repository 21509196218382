@import 'Recharge/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  position: relative;

  .filterContainer {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
    justify-content: space-between;
    max-width: 940px;
    width: 66%;

    @media (max-width: ($small-desktop-screen-max-content + 100px)) {
      width: 100%;
      max-width: 100%;
    }

    @media (max-width: $mobile-screen-max-content) {
      align-items: flex-start;
      @include rowGap(8px);
      @include columnGap(0);
      flex-direction: column;
    }

    .timeFilterContainer {
      display: flex;
      align-items: center;
      width: 100%;
      @include columnGap(12px);

      .filterList {
        display: flex;
        align-items: center;
        @include columnGap(12px);
      }

      .timeRange {
        min-width: 210px;

        @media (max-width: $mobile-screen-max-content) {
          min-width: 180px;
        }
      }
    }

    .timezoneWrapper {
      display: flex;
      align-items: flex-end;
      width: 100%;
      margin: 0;

      .timezone {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .calendarWrapper {
    display: flex;
    z-index: 0;
    @include columnGap(16px);

    @media (max-width: ($small-desktop-screen-max-content + 100px)) {
      flex-direction: column;
      @include columnGap(0px);
      @include rowGap(16px);
    }

    .calendarCard {
      display: flex;
      flex-direction: column;
      flex: 1 68%;
      background-color: $white;
      border-radius: 8px;
      overflow: hidden;
      height: max-content;
    }

    .slotPickerCard {
      display: flex;
      flex: 1 32%;
    }
  }
}
