@import 'Ease/assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  color: $ease-dark-grey;
  background-color: $ease-green;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  min-width: 120px;
}
