@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding: 8px;
  color: $someone-health-blue;
  background-color: rgba(218, 60, 144, 0.22);
  border-radius: 30px;
}
