@import '../../assets/theme/index';

.container {
  display: flex;
  flex-direction: column;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}
