@import 'SomeoneHealth/assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.container {
  padding: 24px 0;
  z-index: 2;
  color: $someone-health-blue;
  background-color: $white;

  .detailsContentWrapper {
    display: flex;
    padding: $standard-padding * 12 0;
    @include columnGap(40px);

    @media (max-width: $small-desktop-screen-max-content + 100px) {
      padding: $standard-padding * 4 0;
    }

    @media (max-width: $tablet-screen-max-content) {
      flex-direction: column;
      @include columnGap(0);
      @include rowGap(40px);
      padding: 0;
    }

    .leftContent {
      display: flex;
      flex-direction: column;
      flex: 1 70%;
      row-gap: 20px;

      .mainAreaBadge {
        background-color: $someone-health-light-pink2;
      }

      .secondaryAreaBadge {
        background-color: $colorGrey100;
      }

      .techniqueBadge {
        background-color: #edf1ff;
      }

      .appointmentBtnWrapper {
        display: none;

        @media (max-width: $tablet-screen-max-content) {
          display: flex;
          padding-bottom: $standard-padding * 5;
        }

        .appointmentBtn {
          width: 100%;
          padding: $standard-padding * 3;
        }
      }
    }

    .rightContent {
      display: flex;
      flex-direction: column;
      flex: 1 30%;

      .additionalPhoto {
        display: flex;
        height: 160px;
        position: relative;

        @media (max-width: $medium-desktop-screen-max-content) {
          display: none;
        }

        .additionalPhotoCard {
          position: absolute;
          top: -195px;
          height: 305px;
          width: 305px;

          .slideShow {
            mask-image: none;
            border-radius: 50%;
          }
        }
      }

      .detailsWrapper {
        display: flex;
        flex-direction: column;
        @include rowGap(40px);

        .appointmentBtnWrapper {
          display: flex;

          @media (max-width: $tablet-screen-max-content) {
            display: none;
          }

          .appointmentBtn {
            width: 100%;
            padding: $standard-padding * 3;
          }
        }
      }
    }
  }
}
