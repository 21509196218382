@import 'assets/theme/index';

.container {
  .location {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 16px 0;

    .icon {
      font-size: 20px;
      color: #fc8468;
      margin-right: 16px;
    }

    .text {
      color: #8b8b8b;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
    }
  }

  .mapWrapper {
    border-radius: 16px;
    opacity: 0.7;
  }
}
