@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .imageWrapper {
    padding: 16px 0;

    .profile {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }

  .logOutBtn {
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    cursor: pointer;

    .icon {
      padding-left: 8px;
    }
  }
}
