@import 'Select/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-width: 300px;
  padding: $standard-padding * 4;
  color: $select-black;
  border: 1px solid $select-black;
  cursor: pointer;
  margin-bottom: 12px;

  @media (max-width: $tablet-screen-max-content) {
    max-width: 250px;
    min-width: 250px;
  }

  &.selected {
    background-color: $select-red;
    border-color: $select-red;
    cursor: auto;
  }

  &.disable {
    cursor: not-allowed;
    opacity: 0.9;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: $standard-padding;

    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
    }
  }

  .desc {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 16px;
  }

  .detailWrapper {
    display: flex;
    flex-direction: column;
    @include rowGap(10px);

    .detailCard {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;

      .icon {
        display: flex;
        align-items: center;
        font-size: 22px;
        margin-right: 8px;
      }
    }
  }
}
