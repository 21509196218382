@import 'assets/theme';

.container {
  background: #f6fafc;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 16px;
  color: #343434;
  @include rowGap(16px);

  .headerContainer {
    display: flex;
    justify-content: space-between;

    .titleContainer {
      .title {
        font-weight: bold;
        font-size: 16px;
        padding-bottom: 2px;

        @media (max-width: $medium-screen-min-width) {
          font-size: 14px;
        }
      }

      .status {
        color: #63c364;
        font-weight: bold;
      }
    }

    .iconContainer {
      display: flex;
      align-items: center;

      .successIcon {
        border: 1px solid #63c364;
        border-radius: 50%;
        font-size: 24px;
        color: #4abd03;
        padding: 8px;
      }
    }
  }

  .description {
    @include rowGap(8px);
  }

  .button {
    margin: 0 auto;
  }
}
