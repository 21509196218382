@import 'SomeoneHealth/assets/theme';

.pageContainer {
  @include s1hLoginBackground;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 100px;
}
