@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 28px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 24px;
    color: #ffffff;
  }

  @media (max-height: $medium-screen-min-height) {
    font-size: 14px;

    h2 {
      font-size: 18px;
    }
  }

  strong {
    font-weight: 600;
  }
}
