@import 'Select/assets/theme';

.container {
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;

  .fixCustomInput {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .radioButton {
    flex: 1;

    .radioLabel {
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
      background: $white;
      border-radius: 0;
      border: 1px solid $select-black;
    }
  }

  .selectInputLabel {
    margin: 8px 0;
    font-size: 14px;
    font-weight: 600;
    color: $select-black;
  }

  .header {
    display: flex;

    .headerText {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;
      display: block;
    }
  }

  .subHeader {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 6px;
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 21px;
  }

  .submitButton {
    width: 100%;
    margin-top: $standard-padding * 7;
    margin-bottom: $standard-padding * 15;

    @media (max-width: $mobile-screen-max-content) {
      margin-bottom: $standard-padding * 20;
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.selectContainer {
  z-index: 1;
}
