@import 'MumsMatter/assets/theme/index';

.container {
  display: flex;
  flex-direction: column;
  font-family: $font, sans-serif;
  min-height: 100vh;
  background-color: $mums-matter-light-green;
  position: relative;

  input,
  textarea,
  button {
    font-family: $font, -apple-system, BlinkMacSystemFont, sans-serif;
  }

  .content {
    height: 100vh;
    overflow: auto;
    position: relative;
    z-index: 1;
  }
}
