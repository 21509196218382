@import 'helm/assets/theme';
.container {
  width: 100%;
  background-color: white;
  position: sticky;
  top: 100vh;

  .signUpFooter {
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $helm-blue;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    .tacklitLogo {
      width: 70px;
    }

    .copyrightSymbol {
      display: inline-block;
      font-size: 13px;
      margin-left: $standard-padding * 4;
      margin-right: $standard-padding;
    }
  }

  @media (max-width: $tablet-screen-max-content) {
    .signUpFooter {
      height: 30px;

      .tacklitLogo {
        width: 75px;
      }
    }
  }
}
