@import 'SomeoneHealth/assets/theme';

$maxContentWidth: 1120px;

.contentLayout {
  padding: 0;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .medicareContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: $maxContentWidth;
    margin: 0 auto;

    .header {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 30px;

      @media (max-width: $medium-large_screen-min-width) {
        margin-bottom: 24px;
      }

      .highlight {
        color: $someone-health-pink;
      }

      .subHeader {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: $colorGrey600;
      }
    }
  }

  .treatmentPlanContainer {
    width: 100%;
    padding: 0 calc((100% - 1120px) / 2);
    margin-top: 16px;

    @media screen and (max-width: ($medium-large_screen-min-width + 1)) {
      display: flex;
    }

    .treatmentPlan {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 32px;
    }

    .gpDetailsLabel {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      margin-top: 10px;
    }
  }

  .noTreatmentPlan {
    padding: 24px 0;
    background-color: $someone-health-light-blue;

    .noGPDesc {
      max-width: $maxContentWidth;
      margin: 0 auto;
    }
  }

  .btnContainer {
    width: 100%;
    margin: 0 auto;
    max-width: $maxContentWidth;
    padding-bottom: 50px;

    .submitButton {
      max-width: 540px;
      width: 100%;
    }
  }
}

.timer {
  display: none;
}
