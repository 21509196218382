.button {
  padding: 8px 16px;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  transition: all 0.1s linear;
}

.clinician-primary {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 16px 32px;
  border: 1px solid #3f52ff;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  background: #3f52ff;
  box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
  border-radius: 4px;

  &:hover {
    opacity: 0.8;
  }
}

.primary {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  color: #fff;
  line-height: 18px;
  outline: none;
  align-self: center;
  margin: 16px 0;
  width: 100%;
  background-color: #3f52fb;
  border: 1px solid #3f52fb;
  font-size: 14px;

  &:active {
    background-color: #3f52fbcc;
  }

  &:disabled {
    background-color: #d3d2d3;
    cursor: not-allowed;
    border: none;
  }
}

.secondary {
  background-color: transparent;
  border: 1px solid #3f52ff;
  color: #3f52ff;
  font-weight: 400;

  &:active {
    background-color: #f8f9fd;
  }
}

.danger {
  background-color: #ff3434;
  color: #ffffff;

  &:active {
    background-color: #ff8080;
  }
}

.link {
  @extend .primary;
  background: transparent;
  border: none;
  color: #3f52fb;

  &:active {
    background: transparent;
  }

  &:disabled {
    background: transparent;
    color: #d3d2d3;
  }
}
