@import 'MumsMatter/assets/theme';

.containerWrapper {
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 82px);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('~MumsMatter/assets/images/background/background.png');
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.8;
    z-index: -1;
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 8px 0 24px;

    .title {
      font-size: 32px;
      font-weight: 600;
      line-height: 62px;
      margin-bottom: 16px;

      @media (max-width: $medium-large_screen-min-width) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 4px;
      }
    }

    .infoContainer {
      display: flex;
      flex-direction: column;
      max-width: 860px;
      padding: 24px 32px;
      gap: 20px;
      border-radius: 8px;
      background-color: $white;
      box-shadow: 0 4px 8px 0 #0000001a;
      border: 1px solid #c0c0c0;

      @media (max-width: $medium-large_screen-min-width) {
        padding: 16px;
      }

      .desc {
        font-size: 20px;

        @media (max-width: $medium-large_screen-min-width) {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }

    .btnWrapper {
      margin-top: 40px;
    }
  }
}
