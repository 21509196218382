@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .contentWrapper {
    background-color: $helm-light-yellow;

    &:nth-child(even) {
      background-color: $helm-lighter-yellow;
    }

    .content {
      display: flex;
      padding: $standard-padding * 6 0;

      @media (max-width: $tablet-screen-max-content) {
        flex-direction: column;
      }

      .mobileDetails {
        display: none;

        @media (max-width: $tablet-screen-max-content) {
          display: flex;
          margin-bottom: 24px;
        }
      }

      .profileWrapper {
        display: flex;
        flex: 1 25%;
        min-width: 330px;
        flex-direction: column;
        @include rowGap($standard-padding * 6);

        @media (max-width: $small-desktop-screen-max-content) {
          min-width: 280px;
          flex: 1;
        }

        .profile {
          display: flex;

          @media (max-width: $tablet-screen-max-content) {
            justify-content: center;
          }

          .profileImg {
            max-width: 300px;
          }
        }
      }

      .detailsContainer {
        display: flex;
        flex: 1 75%;
        flex-direction: column;
        padding-left: $standard-padding * 6;
        overflow-x: hidden;
        overflow-y: hidden;
        @include rowGap(24px);

        @media (max-width: $tablet-screen-max-content) {
          padding-left: 0;
          padding-top: $standard-padding * 6;
          overflow-x: unset;
          overflow-y: unset;
        }

        .details {
          @media (max-width: $tablet-screen-max-content) {
            display: none;
          }
        }
      }
    }

    .loadingWrapper {
      display: flex;
      flex: 1;

      @media (max-width: $tablet-screen-max-content) {
        flex-direction: column;
      }

      .bonaFidesLoadingWrapper {
        display: flex;
        flex-direction: column;
        @include rowGap($standard-padding * 2);

        .bonaFidesLoading {
          height: 18px;
          width: 90%;
        }
      }
    }
  }

  .joinWaitListMessage {
    text-align: center;
    padding: $standard-padding * 20 0;
    background-color: $helm-lighter-yellow;
  }
}
