@import 'helm/assets/theme';

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  padding: 32px 48px;
  border-radius: 0 27px 27px 27px;
  transition: all 0.2s ease;

  @media screen and (max-width: $tablet-screen-max-content) {
    flex-direction: column;
    min-width: 170px;
    padding: 14px 12px 30px;
    margin-bottom: 16px;
    border-radius: 0 20px 20px 20px;
    transition: none;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  .menuImage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    width: 110px;
    min-width: 110px;
    opacity: 0.6;
    border-radius: 50%;
    font-size: 50px;
    color: $white;

    @media screen and (max-width: $tablet-screen-max-content) {
      height: 60px;
      width: 60px;
      min-width: 60px;
      font-size: 30px;
    }
  }

  .menuImageSelected {
    @extend .menuImage;
    opacity: 1;
  }

  .detailWrapper {
    display: flex;
    flex-direction: column;
    margin-left: 16px;

    @media screen and (max-width: $tablet-screen-max-content) {
      padding: 16px 0 4px;
      margin-left: 0;
    }

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: $helmDarkblue400;
      white-space: normal;
      opacity: 0.7;

      @media screen and (max-width: $tablet-screen-max-content) {
        font-size: 14px;
      }
    }

    .titleSelected {
      @extend .title;
      opacity: 1;
    }

    .desc {
      margin-top: 10px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $grey700;
      opacity: 0.7;
      white-space: normal;

      @media screen and (max-width: $tablet-screen-max-content) {
        margin-top: 4px;
        font-size: 10px;
        display: none;
      }
    }

    .descSelected {
      @extend .desc;
      opacity: 1;
    }
  }

  .arrowIcon {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    color: $helm-primary;
  }

  .hideArrowIcon {
    @extend .arrowIcon;
    opacity: 0;
  }
}

.containerSelected {
  @extend .container;
  z-index: 2;
  box-shadow: 3px 7px 14px 2px rgba(0, 0, 0, 0.1);
  width: 110%;
  padding-right: 24px;
  background: #fff;
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: $tablet-screen-max-content) {
    border: 1px solid #e6e6e6;
    transition: none;
    padding: 14px 12px 30px;
  }
}

.mobileArrowWrapper {
  color: $helm-primary;
  position: relative;

  .mobileArrow {
    position: absolute;
    top: 14px;
    left: -16px;
    background: #fff;
    font-size: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #eee;
  }
}

.hideMobileArrowIcon {
  @extend .mobileArrowWrapper;
  opacity: 0;
}
