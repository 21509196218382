@import '../../assets/theme/index';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: $large-screen-min-width;
  margin: 0 auto;
  padding: 0 16px;
}
