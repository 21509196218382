@import 'helm/assets/theme';
@import 'SomeoneHealth/assets/theme';

.form {
  max-width: 840px;
  margin-left: 5%;
  flex: 1;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $mobile-screen-max-content) {
    display: block;
    margin-left: 0;
    width: 100%;
  }

  .timeZoneWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;

    .timeZoneSelect {
      max-width: 400px;
    }

    .timeZoneLabel {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 16px;

      .helpIcon {
        color: $red500;
        margin-left: 8px;
        font-size: 20px;
        cursor: pointer;
      }

      .tooltip {
        max-width: 230px;
        border-radius: 8px;
        line-height: 16px;
        font-size: 14px;
        padding: 12px;
        opacity: 0.95 !important;
      }
    }
  }

  .formItem {
    flex: 0 46%;
    min-height: 68px;
    margin: 0 2%;

    &.fieldError,
    .fieldError {
      font-size: 12px;
      color: #ff4d4f;

      input {
        border-bottom: 1px solid #ff4d4f !important;
      }
    }

    .passwordCell {
      display: flex;
      align-items: center;

      .password {
        flex: 1;
      }

      .linkBtn {
        text-align: right;
        margin-left: 0;
      }
    }
  }
}

.input {
  padding-left: $standard-padding * 4;
  margin-left: -$standard-padding * 4 + 2;
  margin-top: 30px;
  background-color: transparent;
}

.datePicker {
  padding-left: $standard-padding * 4;
  margin-left: -$standard-padding * 4 + 2;
  background-color: transparent;
  border-bottom: 1px solid #c4c4c4 !important;
}

.datePickerError {
  @extend .datePicker;
  border-bottom: 1px solid #ff4d4f !important;
}

.datePickerLabel {
  color: #818e9b !important;
}

.shDatePickerLabel {
  .input:focus {
    ~ .label {
      color: $someone-health-pink;
    }
  }
}

.error {
  text-align: left;
}
