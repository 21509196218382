.button {
  align-self: center;
  margin: 32px 0;
  width: 284px;
  background-color: #3f52fb;
  border: 1px solid #3f52fb;

  &:active {
    opacity: 0.75;
  }
}
