.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 8px 12px;
  border: 0.5px dashed transparent;
  border-radius: 4px;
  height: 100%;
  background: #f5f6ff;
  overflow: hidden;
  line-height: 22px;

  .title {
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #343434;
  }
}
