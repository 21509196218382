@import 'Select/assets/theme';

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.content {
  font-weight: 600;
  font-size: 16px;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;

  .error {
    display: none;
  }
}

.inputs {
  width: 100%;
  max-width: 376px;
  font-weight: 400;

  .row {
    height: 71px;
    display: flex;
    align-items: flex-end;

    margin-left: $standard-padding * 4;
    margin-right: -$standard-padding * 4;

    .input {
      margin-left: -$standard-padding * 4;
      padding: $standard-padding * 4;
      background: transparent;
    }

    .datePicker {
      width: 100%;
    }
  }

  .error {
    margin-left: $standard-padding * 4;
  }
}

.radioLabel {
  width: 180px;
  justify-content: center !important;
  border-radius: 0 !important;
}

.uploadedFiles {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: $standard-padding * 2 $standard-padding * 4;
}

.uploadedFile {
  width: 280px;
  display: flex;
  justify-content: space-between;
}

.removeFile {
  padding: 0;
  border: none;
  color: #fc8468;
  display: flex;
  align-items: center;
  background-color: transparent;
}

.uploadReferralContainer {
  margin-bottom: $standard-padding;

  .uploadContainerInput {
    display: none;
  }

  .uploadContainerBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: $standard-padding * 3 $standard-padding * 2;
    margin-top: $standard-padding * 6;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid $select-red;
    color: $select-black;
    font-weight: 400;

    .publishIcon {
      padding-right: 4px;
    }

    .publishLabel {
      font-size: 14px;
    }
  }
}

.submitButtonContainer {
  margin-top: $standard-padding * 8;

  .submitBtn {
    width: 100%;
    max-width: 352px;
    box-shadow: none;
  }
}
