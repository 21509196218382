@import 'SomeoneHealth/assets/theme';
@import 'Select/assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .thankYouText {
    align-self: center;
    font-size: 24px;
    font-weight: bold;
    line-height: 33px;
    text-align: center;
    color: #ffffff;
  }

  .thankYouImage {
    align-self: center;
    width: 100%;
    max-width: 420px;
    margin: 32px 0;
  }

  .shThankYouText {
    align-self: center;
    font-size: 24px;
    font-weight: bold;
    line-height: 33px;
    text-align: center;
    color: $someone-health-maroon;
    margin-bottom: 8px;
  }

  .shThankYouImage {
    align-self: center;
    width: 100%;
    max-width: 250px;
    margin: 28px 0 52px;
  }
}

:global(.ease-theme) {
  .container .thankYouText {
    color: $colorGrey700;
  }
}

:global(.select-theme) {
  .container .thankYouText {
    color: $select-black;
  }
}
