@import 'helm/assets/theme';

.container {
  display: flex;
  justify-content: center;
  flex: 6;
  z-index: 0;

  @media screen and (max-width: $tablet-screen-max-content) {
    display: flex;
    flex: 1;
    width: 100%;
  }

  .activityWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $white;
    font-weight: 600;
    font-size: 18px;
    min-height: 640px;
    width: 100%;
    box-sizing: border-box;

    @media screen and (max-width: $tablet-screen-max-content) {
      font-size: 16px;
      height: auto;
      min-height: 370px;
      max-width: 100%;
    }
  }

  .progressWrapper {
    @extend .activityWrapper;
    padding: 0;
    justify-content: flex-start;
    background-color: $helm-blue;
    border-radius: 16px;
  }

  .moodWrapper {
    @extend .activityWrapper;
    background-color: $helm-blue;
    border-radius: 16px;
  }

  .hiddenWrapper {
    display: none;
  }
}
