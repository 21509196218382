@import 'Recharge/assets/theme';

.headerContainer {
  display: flex;
  flex-direction: column;
  background-color: $recharge-light-purple2;
}

.container {
  display: flex;
  flex-direction: column;
  overflow: auto; // for infinity load to works correctly

  .loadMoreWrapper {
    padding: 60px 0 24px;
  }

  .contentWrapper {
    background-color: $white;

    &:nth-child(even) {
      background-color: $recharge-lighter-blue;
    }

    .content {
      display: flex;
      padding: $standard-padding * 6 0;

      @media (max-width: $tablet-screen-max-content) {
        flex-direction: column;
      }

      .mobileDetails {
        display: none;

        @media (max-width: $tablet-screen-max-content) {
          display: flex;
          margin-bottom: 24px;
        }
      }

      .profileWrapper {
        display: flex;
        flex: 1 25%;
        min-width: 330px;
        flex-direction: column;
        @include rowGap($standard-padding * 6);

        @media (max-width: $small-desktop-screen-max-content) {
          min-width: 280px;
          flex: 1;
        }

        .profile {
          display: flex;

          @media (max-width: $tablet-screen-max-content) {
            justify-content: center;
          }

          .profileImg {
            max-width: 280px;
          }
        }
      }

      .detailsContainer {
        display: flex;
        flex: 1 75%;
        flex-direction: column;
        padding-left: $standard-padding * 6;
        overflow-x: hidden;
        overflow-y: hidden;
        @include rowGap(24px);

        @media (max-width: $tablet-screen-max-content) {
          padding-left: 0;
          padding-top: $standard-padding * 6;
          overflow-x: unset;
          overflow-y: unset;
        }

        .details {
          @media (max-width: $tablet-screen-max-content) {
            display: none;
          }
        }

        .highlightSecondarySpecs {
          background-color: #cfebdb;
        }
      }
    }

    .loadingWrapper {
      display: flex;
      flex: 1;

      @media (max-width: $tablet-screen-max-content) {
        flex-direction: column;
      }

      .bonaFidesLoadingWrapper {
        display: flex;
        flex-direction: column;
        @include rowGap($standard-padding * 2);

        .bonaFidesLoading {
          height: 18px;
          width: 90%;
        }
      }
    }

    .noResultWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $standard-padding * 10 0;

      .noResultTitle {
        font-weight: 700;
        font-size: 32px;
        line-height: 42px;
        letter-spacing: -0.02em;
        color: $recharge-purple;
      }

      .noResultDesc {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: $recharge-black;
        padding-top: $standard-padding * 4;

        .clearAllFiltersButton {
          color: $blue500;
          cursor: pointer;
          user-select: none;
        }
      }
    }

    .recommendWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $standard-padding * 6 0 $standard-padding * 10;

      @media (max-width: $tablet-screen-max-content) {
        padding: $standard-padding * 2 0 $standard-padding * 2;
      }

      .trustLabel {
        text-align: center;
        width: 80%;
        font-weight: 700;
        font-size: 36px;
        letter-spacing: -0.02em;
        color: $recharge-purple;
        border-bottom: 1px solid $recharge-purple;
        line-height: 0.1em;
        margin: 20px 0;

        @media (max-width: $tablet-screen-max-content) {
          line-height: unset;
          font-size: 24px;
          border-bottom: none;
          width: 100%;
          margin: 16px 0;
        }

        .label {
          background: $recharge-lighter-blue;
          padding: 0 10px;

          @media (max-width: $tablet-screen-max-content) {
            border-bottom: 1px solid $recharge-purple;
            padding-bottom: 8px;
          }
        }
      }
    }
  }

  .contentWrapperRs {
    @extend .contentWrapper;
    background-color: $recharge-lighter-blue;

    &:nth-child(even) {
      background-color: $white;
    }
  }
}
