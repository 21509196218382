@import 'MumsMatter/assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25%;
}

.container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1 0 auto;
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 82px);
  background-color: $white;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('~MumsMatter/assets/images/background/background.png');
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.8;
    z-index: -1;
  }
}

.content {
  padding-top: 24px;
  width: 100%;
}
