@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: $standard-padding * 2;
  box-sizing: border-box;
  z-index: 1;

  @media (max-width: $medium-screen-min-width) {
    padding: 8px;
  }
}

.top {
  display: flex;
  justify-content: flex-end;

  .profilePic {
    margin-left: $standard-padding * 0.5;
    height: 48px;
    width: 48px;
    border-radius: 50%;

    .initial {
      font-size: 16px;
    }
  }
}
