@import 'Portobello/assets/theme';

$headerHeight: 82px;

.headerWrapper {
  .header {
    padding: 12px 0 !important;

    @media (max-width: $medium-large_screen-min-width) {
      position: unset;
      padding: 0 !important;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 24px 0;

  .infoBoxContainer {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: $white;
    max-width: 860px;
    padding: 24px 32px;
    box-shadow: 0 4px 8px 0 #0000001a;
    border: 1px solid #c0c0c0;

    @media (max-width: $medium-large_screen-min-width) {
      padding: 16px;
    }

    .title {
      font-family: $Playfair-Display;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
    }

    .desc {
      font-size: 16px;
      margin-top: 12px;
      margin-bottom: 8px;
    }
  }

  .formStyles {
    margin: 0 16px 0 0 !important;
  }

  .inputStyles {
    padding-left: 0;
    margin-left: 0;
  }

  .submitButton {
    margin: 8px 0 16px;
  }
}
