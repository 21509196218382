@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 8px 12px;
  border-radius: 4px;
  border: 0.5px solid transparent;
  background: transparent;
  line-height: 1.42;
  word-break: break-word;

  & > * {
    cursor: auto;
  }

  img {
    cursor: auto !important;
    max-width: 100%;
  }

  table {
    border: none;
    border-collapse: collapse;
    empty-cells: show;
    margin-bottom: 1em;
    margin-top: 1em;
    max-width: 100%;
  }

  table tr th,
  table tr td {
    border: 1px solid #dadada;
    min-width: 2em;
    padding: 0.4em;
    user-select: text;
    vertical-align: middle;
  }
}
