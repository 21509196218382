@import 'assets/theme';

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  justify-content: center;
  max-width: 700px;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 8px;
  margin: 0 auto;
  max-width: 700px;

  @media (max-width: $medium-screen-min-width) {
    padding: 24px 16px;
  }

  .practiceInfoWrapper {
    display: flex;
    align-items: center;

    .practiceLogo {
      width: 100px;
      height: 100px;
      border-radius: 50%;

      @media (max-width: $medium-screen-min-width) {
        width: 80px;
        height: 80px;
      }
    }

    .practiceInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 16px;

      .practiceName {
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
        color: #343434;
      }

      .practiceDesc {
        padding-top: 8px;
        font-size: 16px;
        line-height: 20px;
        color: #818e9b;
      }
    }
  }

  .tncCheckerWrapper {
    display: flex;
    flex-direction: column;
    max-width: 400px;

    .referralConfirm {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .captchaContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  .submitBtnWrapper {
    .submitBtn {
      // margin-left: 16px;
      margin-top: 24px;
      border-radius: 8px;
      max-width: 400px;
      min-width: 400px;
      font-size: 16px;

      @media (max-width: $medium-small-screen-min-width) {
        margin-left: 0;
      }
    }
  }

  .policyWrapper {
    .privacy {
      margin-top: 12px;
      // max-width: 400px;
      font-size: 13px;
      line-height: 16px;
      color: #343434;

      @media (max-width: $medium-small-screen-min-width) {
        margin-left: 0;
      }

      .policyURL {
        text-decoration: underline;
      }
    }
  }
}

.formWrapper {
  margin-left: 116px;

  @media (max-width: $medium-screen-min-width) {
    margin-top: 24px;
    margin-left: 0;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #343434;
    margin-bottom: 16px;
  }

  .informationFormWrapper {
    margin-top: 32px;
  }
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 32px;
  max-width: 380px;

  &.fieldError,
  .fieldError {
    font-size: 12px;
    color: #ff4d4f;

    input {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }
}

.textAreaContainer {
  @extend .fieldContainer;
  margin-bottom: 0;

  &.fieldError {
    color: #000;

    textarea {
      border: 1px solid #ff4d4f !important;
    }
  }
}
