@import 'SomeoneHealth/assets/theme';

.container {
  @include s1hLoginBackground;

  .contentWrapper {
    max-width: 1280px;
    margin: 0 auto;
  }

  .content {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
}
