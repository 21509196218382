@import 'CORDS/assets/theme';

.container {
  background-color: $cords-yellow;

  .content {
    padding: 16px 32px;
    max-width: $desktop-screen-max-content;
    margin: 0 auto;
    width: 100%;
  }
}
