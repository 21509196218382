@import 'Select/assets/theme';

.layoutClassName {
  min-height: calc(100vh - 118px);
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.container {
  padding: 24px 0;
  min-height: calc(100vh - 118px);

  .header {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 30px;

    .subHeader {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: $colorGrey600;
    }
  }

  .content {
    display: flex;
    justify-content: space-around;
    color: $select-black;

    .clientTestimonialCard {
      max-width: 410px;
      margin: auto;
      display: none;
    }

    @media screen and (max-width: $medium-large_screen-min-width) {
      align-items: center;
      flex-direction: column-reverse;
      gap: 10px;

      .clientTestimonialCard {
        display: block;
      }
    }
  }

  .noSupportTask {
    display: flex;
    flex-direction: column;
  }

  .leftContent {
    flex: 2 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 580px;
    margin-right: 40px;

    @media screen and (max-width: $medium-large_screen-min-width) {
      margin-right: 0;
    }

    .greet {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.02em;
      display: flex;
    }
    .summaryContainer {
      padding: unset;
      align-items: flex-start;

      .summaryContent {
        margin-left: -30px;

        @media screen and (max-width: $medium-large_screen-min-width) {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: $colorGrey700;
    }

    .content {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }

    .submitButton {
      width: 100%;
    }
  }

  .rightContent {
    max-width: 410px;

    .notice {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 25px;
      flex-direction: column;

      @media screen and (max-width: $medium-large_screen-min-width) {
        flex-direction: row;
      }
    }

    @media screen and (max-width: $medium-large_screen-min-width) {
      .timeLabel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          div {
            font-weight: 500;
            font-size: 10px;
            line-height: 13px;
          }
        }
      }
    }

    .noticeMsg {
      max-width: 260px;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;

      div {
        text-align: center;
      }

      @media screen and (max-width: $medium-large_screen-min-width) {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        max-width: unset;
      }
    }
  }
}
