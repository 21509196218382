@import 'helm/assets/theme';

.testimonialCard {
  margin-top: $standard-padding * 17;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  position: relative;
  width: 100%;
  padding: $standard-padding * 8 $standard-padding * 7;

  .shape2 {
    position: absolute;
    top: -217px;
    right: -236px;
    background-size: cover;
    width: 398px;
    height: 352px;
    transform: rotate(156.52deg);
    mask-image: url(~helm/assets/images/shape/2.png);
    mask-size: 100% 100%;
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: $helm-light-yellow;
  }

  .shape4 {
    position: absolute;
    left: -266px;
    bottom: -256px;
    background-size: cover;
    width: 488px;
    height: 392px;
    transform: rotate(-169.16deg);
    mask-image: url(~helm/assets/images/shape/4.png);
    mask-size: 100% 100%;
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: $helm-light-yellow;
  }

  .testimonialCardBody {
    border-radius: 8px;
    border: solid 2px $helm-yellow;
  }

  .testimonialCardTitle {
    padding: $standard-padding * 10 $standard-padding * 6;
    padding-bottom: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: left;

    &::after {
      content: '';
      display: block;
      margin-top: $standard-padding * 2;
      height: 4px;
      width: 40px;
      background-color: $helm-purple;
    }
  }

  .testimonialCardContent {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    margin: $standard-padding * 8 auto $standard-padding * 4 auto;
    padding: 0 $standard-padding * 13;
  }

  .testimonialClientAvatar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 16px;

    .avatarText {
      text-align: right;
      position: relative;
      font-family: $primary-font-stack-light;
      font-size: 16px;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: -10px;
        display: block;
        height: 4px;
        width: 40px;
        background-color: $helm-purple;
      }
    }

    img {
      margin: $standard-padding * 4;
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 100%;
    }
  }

  @media screen and (max-width: $mobile-screen-max-content) {
    margin-top: 0;
    padding: $standard-padding * 5 $standard-padding * 4;
    padding-bottom: $standard-padding * 7;
    .testimonialCardTitle {
      font-size: 16px;
      &::after {
        transform: scale(0.84);
      }
    }

    .testimonialCardContent {
      font-size: 14px;
      padding: 0 $standard-padding * 10;
    }

    .testimonialClientAvatar {
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}
