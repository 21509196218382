@import 'assets/theme';

.container {
  margin-top: $standard-padding;

  .stem {
    font-style: italic;
    font-size: 12px;
    color: #4b718e;
  }
}
