@import 'helm/assets/theme';

.container {
  height: max-content;
  min-height: 100vh;
  position: relative;
  z-index: 1;

  .fixedLeftBtn {
    position: fixed;
    bottom: 40px;
    background: $helm-primary;
    border-color: $helm-primary;
    box-shadow: 0 4px 10px rgba(52, 52, 52, 0.35);
    border-radius: 55px !important;
    height: 55px;
    z-index: 4;
    font-weight: 600;
    font-size: 13px;
    color: $white;
    min-height: 48px;
    min-width: 175px;

    @media (max-height: 740px) {
      bottom: 13px;
      left: 2%;
    }

    .newJournalBtn {
      display: flex;
      align-items: center;

      .icon {
        padding-right: 8px;
      }
    }

    .btnLoading {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn:after {
        border-color: #fff transparent #fff transparent;
      }
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 3;
  }

  .content {
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 16px;
    padding-bottom: 120px;

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .contentBox {
      display: flex;
      flex-direction: column;
      row-gap: 32px;

      .taskListContainer {
        display: flex;
        flex-direction: column;
        max-width: 600px;

        .taskListTitle {
          font-size: 18px;
          line-height: 28px;
          color: #fff;
          padding-bottom: 16px;

          @media (max-height: $tablet-screen-max-content) {
            font-size: 16px;
          }
        }

        .taskListBox {
          display: flex;
          flex-direction: column;
          row-gap: 24px;

          @media (max-height: $tablet-screen-max-content) {
            row-gap: 16px;
          }
        }
      }
    }
  }

  .footer {
    z-index: 3;
  }
}
