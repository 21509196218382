// Helm main color
$helm-lighter-yellow: #fef4c8;
$helm-light-yellow: #fcec9c;
$helm-yellow: #f9e048;
$helm-purple: #9342f7;
$helm-red: #f15944;
$helm-blue: #243062;
$helm-primary: #3f52ff;

$text-disable-grey: #aaaaaa;

$badge-background-color: rgba(44, 52, 96, 0.2);

$black: #000000;
$white: #ffffff;

$darkblue50: #eaedfa;
$darkblue300: #314bcb;
$helmDarkblue400: #22348c;
$darkblue500: #2a4670;
$darkblue400: #426eb1;
$darkLightBlue: #f6fafc;

$lightpink: #fcf7f7;
$blue50: #f5f6ff;

$red500: #eb4545;
$red400: #ef6a6a;
$orange500: #ff7d45;
$green500: #4bc27d;

// grey
$grey700: #414449;
$grey600: #61666e;
$grey500: #949aa2;
$grey100: #ecedef;
$green50: #edf9f2;
$calendarPill: #cfebdb;

$colorGrey50: #f9f9fa;
$colorGrey100: #ecedef;
$colorGrey500: #94969d;
$colorGrey600: #61666e;
$colorGrey700: #414449;
$colorGrey800: #202225;

$backgroundYellow: #fff4c6;
