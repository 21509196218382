@import 'helm/assets/theme';

.container {
  background-image: url(~helm/assets/images/background/HelmClientBg.jpg);
  background-size: cover;
  background-position: center;
  font-family: 'Nunito', normal;
  min-height: 100vh;

  .content {
    text-align: center;
    max-width: 1014px;
    background-color: white;
    border-radius: 8px;
    padding: $standard-padding * 14 $standard-padding * 16;
    margin: $standard-padding * 6 auto;

    @media (max-width: $tablet-screen-max-content) {
      padding: $standard-padding * 8 $standard-padding * 6;
      margin: $standard-padding * 28 $standard-padding * 4;
    }

    .input {
      padding-left: $standard-padding * 4 + 2;
      margin-left: -$standard-padding * 4;
      width: calc(100% + 32px);
    }

    .inputBox {
      max-width: 507px;
      margin: auto;
      width: 80%;
    }

    .submitBtn {
      margin: $standard-padding * 4 auto;
      width: 275px;
      box-shadow: none;

      @media only screen and (max-width: $tablet-screen-max-content) {
        width: 100%;
      }

      &:active {
        opacity: 0.8;
      }
    }
  }
}
