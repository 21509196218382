@import 'helm/assets/theme';

.container {
  position: relative;
  text-align: center;
  font-family: 'Nunito', normal;

  .menuTrigger {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .menuBox {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 101;
    right: 0;
    border-radius: 8px;
    margin-top: $standard-padding * 7;
    padding: $standard-padding * 4;
    background-color: $white;
    font-weight: 600;
    font-size: 18px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 350px;

    @media (max-width: $tablet-screen-max-content) {
      margin-top: 16px;
      font-size: 16px;
    }

    .greeting {
      font-weight: 700;
      font-size: 28px;
      line-height: 42px;
      color: $helm-blue;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .bookAppointment {
      display: flex;
      box-shadow: none;
      margin-top: $standard-padding * 5;

      .bookAppointmentBtn {
        display: flex;
        align-items: center;

        .calendarIcon {
          font-size: 24px;
          padding-left: $standard-padding + 2;
        }
      }
    }

    .menuItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $standard-padding * 4 $standard-padding * 5;
      margin-top: $standard-padding * 5;
      background-color: $lightpink;
      border-radius: 8px;
      color: $grey700;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      .arrowIcon {
        border-radius: 50%;
        border: solid 1px $helm-blue;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $helm-blue;
        font-size: 17px;
        padding: $standard-padding + 2;
      }
    }

    .signOut {
      margin-top: $standard-padding * 6;
      color: $grey700;
      cursor: pointer;
    }
  }
}
