@import 'Select/assets/theme';

.container {
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
  margin-top: $standard-padding * 10;
  font-family: $font;

  @media (max-width: $mobile-screen-max-content) {
    margin-top: $standard-padding * 4;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 20px;

    @media (max-width: $tablet-screen-max-content) {
      line-height: 28px;
    }

    .highlight {
      font-weight: 700;
    }
  }

  .profile {
    max-width: 80px;
  }

  .headerTitle {
    flex: 1;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }

  .subHeader {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 6px;
  }

  .loginText {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: right;

    @media (max-width: $mobile-screen-max-content) {
      text-align: left;
    }

    .loginLink {
      color: $blue500;
      text-decoration: underline;
      margin-left: 8px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .referral {
    margin-top: $standard-padding * 4;
    font-size: 12px;
    color: $grey600;
    user-select: none;
  }

  .introduce {
    margin-top: $standard-padding * 6;
    margin-bottom: $standard-padding * 2;
    font-size: 14px;
    color: $grey600;

    .label {
      padding-left: 16px;
    }
  }

  .ageConfirm {
    margin-top: $standard-padding * 2;
  }

  .ageConfirm,
  .communicationPreferences .checkBoxes {
    .checkBox {
      vertical-align: top;

      input {
        outline: none;
        transform: scale(0.75);
        &:checked::after {
          display: inline-flex;
        }
      }
      margin-right: $standard-padding * 3;
    }

    .checkBoxText {
      height: fit-content;
      display: inline-block;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      margin-top: $standard-padding;
      margin-bottom: $standard-padding * 3;

      a {
        text-decoration: underline;
        color: $blue500;
      }
    }
  }

  .communicationPreferences {
    padding-top: $standard-padding * 5;
    font-weight: 600;
    font-size: 14px;

    .title {
      font-size: 18px;
      line-height: 32px;
    }

    .checkBoxes {
      margin-top: $standard-padding * 2;
    }
  }

  .submitButton {
    width: 100%;
    margin-top: $standard-padding * 7;
    margin-bottom: $standard-padding * 15;

    @media (max-width: $mobile-screen-max-content) {
      margin-bottom: $standard-padding * 20;
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 99;
}
