@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;

  &.flexEnd {
    justify-content: flex-end;
  }

  .label {
    max-width: calc(100% - 24px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 10px;
    font-weight: 600;
  }
}
