@import 'assets/theme';
@import 'SomeoneHealth/assets/theme';
@import 'CaW/assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  color: #fff;
  position: relative;
  z-index: 3;

  .contentWrapper {
    display: flex;
    flex-direction: column;

    .wrapper {
      background-color: transparent !important;
    }

    .helmHeader {
      padding: 0 0 16px 0;

      @media (max-width: $medium-large_screen-min-width) {
        padding: 0 16px 16px;
      }
    }

    .shHeader {
      padding: 8px 0 !important;

      @media (max-width: $medium-large_screen-min-width) {
        padding: 8px 16px !important;
      }
    }

    .tacklitLogo {
      padding: 8px 0 !important;

      @media (max-width: $medium-large_screen-min-width) {
        padding: 8px 16px !important;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 200px;
    padding: 20px 0;
    font-size: 18px;

    @media (max-width: $medium-large_screen-min-width) {
      padding: 20px 16px;
    }

    .description {
      color: #ffffff;
      font-size: 18px;
      line-height: 28px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 24px;
        color: #ffffff;
      }

      strong {
        font-weight: 600;
      }
    }
  }

  .doneContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-size: 18px;

    .thankYouText {
      font-size: 24px;
      font-weight: bold;
      line-height: 33px;
      text-align: center;
      color: #ffffff;

      @media (max-height: $medium-screen-min-height) {
        font-size: 20px;
      }
    }

    .thankYouImage {
      width: 90%;
      max-width: 330px;
      margin: 32px 0;

      @media (max-height: $medium-screen-min-height) {
        max-width: 70%;
      }
    }

    .shThankYouText {
      align-self: center;
      font-size: 24px;
      font-weight: bold;
      line-height: 33px;
      text-align: center;
      color: $someone-health-maroon;
      margin-bottom: 8px;

      @media (max-height: $medium-screen-min-height) {
        font-size: 20px;
      }
    }

    .shThankYouImage {
      align-self: center;
      width: 100%;
      max-width: 250px;
      margin: 28px 0 52px;
    }

    .homePageBtn {
      display: flex;
      color: $someone-health-maroon;
      margin-top: 16px;
      justify-content: center;
      cursor: pointer;
    }
  }

  .card {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: $white;
    background-image: url(~assets/images/background-circles.png);
    background-size: cover;
    background-repeat: no-repeat;
    max-height: 240px;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;

    .returnContent {
      width: 100%;

      .title {
        margin: 32px 24px 0;
        font-size: 15px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #3f52ff;
      }

      .buttonWrapper {
        margin-bottom: 16px;
      }

      .button {
        width: 75%;
        margin: auto;
        margin-top: $standard-padding * 3;
      }
    }
  }
}

:global(.caw-theme).container {
  color: $caw-blue;

  .contentWrapper {
    padding: 0 16px;
  }

  .content {
    .description {
      color: $caw-blue;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $caw-blue;
      }
    }
  }

  .title {
    color: $caw-blue !important;
  }
}

:global(.ease-theme) {
  .container {
    color: $ease-dark-grey !important;

    .contentWrapper {
      padding: 0 16px;
    }

    .content {
      .description {
        color: $ease-dark-grey;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $ease-dark-grey;
        }
      }
    }

    .title {
      color: $ease-dark-grey !important;
    }

    .doneContainer .thankYouText {
      color: $ease-dark-grey;
    }
  }
}

:global(.recharge-theme) {
  .container {
    color: $recharge-black;

    .card {
      background-image: none;

      .returnContent .title {
        color: $recharge-black;
      }
    }

    .contentWrapper {
      padding: 0 16px;
    }
  }
}

:global(.select-theme) {
  .container {
    color: $select-black !important;

    .contentWrapper {
      padding: 0 16px;
    }

    .content {
      .description {
        color: $select-black;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $select-black;
        }
      }
    }

    .title {
      color: $select-black !important;
    }

    .doneContainer .thankYouText {
      color: $select-black;
    }

    .card {
      background-image: none;
    }
  }
}

:global(.s1h-client-portal-theme) {
  .container {
    color: $someone-health-maroon;

    .contentWrapper {
      padding: 0 16px;
    }

    .content {
      .description {
        color: $someone-health-maroon;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $someone-health-maroon;
        }
      }
    }

    .title {
      color: $someone-health-maroon !important;
    }

    .card {
      background-image: none;
    }
  }
}
