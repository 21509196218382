@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border-radius: 20px;
}

.filterContainer {
  position: sticky;
  top: 0;
  z-index: 8;
  background-color: white;

  .filterWrapper {
    margin: 0 -16px;
    padding: 8px 0 16px;
    display: flex;
    overflow-x: auto;
    background-color: #ffffff85;
    justify-content: center;

    @media (max-width: $medium-screen-min-width) {
      background-color: #fff;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .filterDate {
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #8b8b8b;
      min-width: 50px;
      padding: 8px 12px;
      cursor: pointer;
    }

    .filterDateActive {
      @extend .filterDate;
      border-bottom: 2px solid #3f52ff;
      color: #3f52ff;
    }
  }

  .divider {
    border-bottom: 1px solid #c4c4c4;
    margin: 0 10px;
  }
}

.availabilityList {
  margin-top: 16px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: $mid-grey;
  }
}

.calendarContainer {
  display: flex;
  background: #fff;
  border-radius: 16px;
  margin: 16px 8px;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.18);

  .dateWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 16px 12px;
    min-width: 60px;

    .dotWrapper {
      position: relative;

      .availDot {
        width: 11px;
        height: 11px;
        border-radius: 50px;
        background: #63c364;
        position: absolute;
        top: -6px;
        right: -13px;
      }

      .noAvailDot {
        @extend .availDot;
        background: #f44336;
      }
    }

    .date {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
    }

    .month {
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      padding: 8px 0;
    }

    .countDotContainer {
      display: flex;
      justify-content: center;
      max-width: 40px;
      text-align: center;

      .countDotWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .appCountDot {
          width: 8px;
          height: 8px;
          border-radius: 50px;
          background: #fc8468;
          margin: 2px;
        }
      }
    }
  }

  .calendarWrapper {
    display: flex;
    padding: 16px;
    flex-direction: column;

    .day {
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
    }

    .desc {
      font-weight: lighter;
      font-size: 13px;
      line-height: 16px;
      padding: 8px 0;
      font-style: italic;
    }

    .calendarBox {
      display: flex;
      flex-wrap: wrap;

      .calendar {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        padding: 8px 10px;
        color: #fc8468;
        background: #f6e9dd;
        border-radius: 8px;
        cursor: pointer;
        margin: 8px 8px 0 0;
      }

      .practicePill {
        color: #2a4670;
        background: #cfebdb;
      }
    }
  }
}

.practice {
  background-color: #fcf7f7;
}
