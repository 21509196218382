@import 'helm/assets/theme';

.container {
  background: $helm-blue;
  overflow: hidden;
  padding: clamp(4rem, 10vw, 8rem) $standard-padding * 4 0;

  .content {
    color: $helm-lighter-yellow;
    display: flex;
    gap: clamp(2.5rem, 1.4264rem + 4.7714vw, 5.5rem);
    flex-wrap: wrap;
    font-family: $primary-font-stack-medium;
    max-width: $desktop-screen-max-content;
    margin: auto;
    box-sizing: content-box;

    .leftContent {
      flex-basis: 40%;

      .row1 {
        padding: clamp(2.4rem, 5.8vw, 4.8rem) 0;
        padding-bottom: 4.5rem;
      }

      .row2 {
        padding: 1.75rem 0;
        padding-top: 0;
        align-items: flex-end;
      }
    }

    @media (max-width: $tablet-screen-max-content) {
      flex-direction: column;
      .leftContent {
        &.row1 {
          padding-bottom: 5rem;
        }

        &.row2 {
          flex-direction: column-reverse;
          align-items: flex-start;
        }
      }
    }

    a {
      color: $helm-lighter-yellow;
    }

    .contact {
      flex: 1;
      line-height: 1.4;

      .telAndEmail {
        font-size: clamp(1.26375rem, 1.0674rem + 0.8728vw, 1.8125rem);
        line-height: 1;

        .email {
          text-decoration: underline;
        }
      }

      .support {
        margin-block-start: clamp(2.5rem, 1.4264rem + 4.7714vw, 2.5rem);
        font-size: clamp(1rem, 0.5711rem + 0.7952vw, 1.25rem);
        letter-spacing: -0.02em;
        max-width: 495px;

        .link {
          text-decoration: underline;
        }
      }
    }

    .takeTheHelm {
      font-family: $title-font-stack;
      flex: 1;
      font-size: clamp(2rem, 1.3514rem + 2.8827vw, 3.8125rem);
      line-height: 1;
      letter-spacing: 0.0125em;
      margin-block-start: clamp(2.5rem, 1.4264rem + 4.7714vw, 2.5rem);
    }

    .rightContent {
      flex: 1;

      .programs {
        letter-spacing: -0.02em;
        flex: 1;
        font-weight: 700;

        ul {
          padding: 0;
          overflow: visible;

          @media (max-width: $tablet-screen-max-content) {
            max-width: 100%;
          }

          li {
            list-style: none;
            line-height: 1.4em;
            font-size: clamp(1.26375rem, 1.0674rem + 0.8728vw, 1.8125rem);

            &:hover {
              opacity: 0.8;
            }
            transition: all 0.1s;
          }
        }
      }

      .policy {
        margin-block-start: clamp(2.5rem, 1.4264rem + 4.7714vw, 2.5rem);
        margin-block-end: 0;
        font-size: clamp(1rem, 0.5711rem + 0.7952vw, 1.25rem);
        text-decoration: underline;
        letter-spacing: -0.02em;
      }

      .quotes {
        margin-block-start: clamp(2.5rem, 1.4264rem + 4.7714vw, 2.5rem);
        margin-block-end: 0;
        flex: 1;
        font-size: 0.75rem;
        letter-spacing: -0.02em;
        line-height: 1.4;
      }
    }

    .rightContentWithoutLink {
      @extend .rightContent;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .quotes {
        flex: 0;
      }
    }
  }

  .tacklitRow {
    max-width: $desktop-screen-max-content;
    margin: auto;
    font-family: 'Nunito', normal;
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $white;
    width: 100%;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    gap: 0;

    .tacklitLogo {
      width: 65px;
    }

    .copyrightSymbol {
      display: inline-block;
      font-size: 16px;
      margin-left: $standard-padding * 2;
      margin-right: $standard-padding;
    }

    .tacklitName {
      margin-left: $standard-padding;
      text-decoration: underline;
      color: $white;
    }
  }
}
