@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: row;
  padding: 0 $standard-padding * 7;

  .leftContent {
    flex: 1;
  }

  .rightContent {
    flex: 1;
    text-align: center;
  }

  .clientTestimonialCard {
    max-width: 410px;
    margin: auto;
    display: none;
  }

  @media (max-width: $tablet-screen-max-content) {
    flex-direction: column-reverse;
    padding: 0;
    .clientTestimonialCard {
      display: block;
      margin-bottom: $standard-padding * 20;
    }
  }

  @media (max-width: $mobile-screen-max-content) {
    .clientTestimonialCard {
      margin-bottom: $standard-padding * 10;
    }
  }
}
