@import 'helm/assets/theme';

$base-duration: 1s;

// Colors
$color-3: #2ecc71;

// Breakpoints
$sm: new-breakpoint(min-width 320px);
$med: new-breakpoint(min-width 768px);
$lg: new-breakpoint(min-width 1024px);

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

.hide {
  display: none;
}

.container {
  font-family: $title-font-stack !important;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $standard-padding * 4 $standard-padding * 5;
  background-color: $helm-blue;
  border: none;
  border-radius: 40px;
  color: $helm-lighter-yellow;
  font-size: 18px;
  line-height: 1em;
  letter-spacing: 0.02em;
  overflow: hidden;
  transition: all 0.3s;

  &:hover {
    color: $white;
  }

  @media (max-width: $tablet-screen-max-content) {
    font-size: 14px;
    line-height: 15px;
    padding: $standard-padding * 2 $standard-padding * 4;
  }

  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: lighten($color-3, 10%);
  }

  .submit {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include columnGap(8px);

    &.reverse {
      flex-direction: row-reverse;
    }
  }

  span {
    &:nth-of-type(2) {
      display: none;
    }

    &:nth-of-type(3) {
      display: none;
    }
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.active {
  display: flex;
  background-color: $color-3;
  max-height: 52px;
  height: 52px;

  &:before {
    width: 100%;
    transition: width 3s linear;
  }

  .submit {
    visibility: hidden;
    height: 52px;
  }

  span {
    &:nth-of-type(1) {
      top: -100%;
      transform: translateY(-50%);
    }

    &:nth-of-type(2) {
      top: 100%;
      transform: translateY(0%);
      font-size: 20px;
      display: block;

      svg {
        animation: loading $base-duration linear infinite;
      }
    }

    &:nth-of-type(3) {
      display: none;
    }
  }
}

.finished {
  display: flex;
  background-color: lighten($color-3, 10%);
  max-height: 52px;
  height: 52px;

  .submit {
    display: none;
  }

  .loading {
    display: none;
  }

  .check {
    display: block !important;
    font-size: 20px;
    animation: scale 0.5s linear;

    svg {
      transform-origin: center center;
    }
  }
}

@keyframes loading {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale {
  0% {
    transform: scale(10);
  }
  50% {
    transform: scale(0.2);
  }
  70% {
    transform: scale(1.2);
  }
  90% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

.secondary {
  box-shadow: none;
  background-color: transparent;
  border: 1px solid #3f52ff;
  color: #3f52ff;
  font-weight: 400;

  &:active {
    background-color: #f8f9fd;
  }
}
