@import 'MumsMatter/assets/theme';

.container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 28px;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  .fields {
    display: flex;
    flex-direction: column;
    width: 55%;

    @media (max-width: 800px) {
      width: 100%;
    }

    .fieldWrapper {
      width: 100%;
      max-width: 400px;

      .selectInputLabel {
        font-size: 12px;
      }
    }

    .dvaContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 24px;
    }

    .nameRowWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .dobInfo {
      display: flex;
      margin: 8px 0 4px;
    }

    .medicareInfo {
      display: flex;
      flex-direction: row;
      gap: 24px;
      justify-content: flex-start;
      margin: 16px 0 8px;

      .irn {
        width: 10%;
        min-width: 50px;
      }
    }
  }

  .medicareCheck {
    display: flex;
    margin-top: 20px;
  }

  .medicareImg {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-top: 4px;

    @media (max-width: 800px) {
      width: 100%;
    }

    .medicareBox {
      display: flex;
      padding: 10px 16px;
      border-radius: 4px;
      background-color: #e6e6f0;
    }

    .menuIcon {
      font-size: 20px;
      line-height: 20px;
      margin-right: 6px;
    }

    .medicareDescBox {
      display: flex;
      flex-direction: column;

      .desc {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
      }

      .medicareImg {
        width: 100%;
        margin-top: 12px;
      }
    }
  }
}
