@import 'Select/assets/theme';

.hidden {
  display: none;
}

.circularTimer {
  display: flex;
  justify-content: center;
}

.timeLabel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      font-weight: 500;
      font-size: 10px;
      line-height: 13px;
    }
  }
}
