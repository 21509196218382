@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  justify-content: center;
  z-index: 0;

  @media screen and (max-width: $small-desktop-screen-max-content) {
    display: flex;
    flex: 1;
    width: 100%;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 654px;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 124px 108px;
    overflow: hidden;
    position: relative;

    .backgroundImage {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      width: 100%;
      background-color: #ffffff50;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.2;
    }

    @media screen and (max-width: $small-desktop-screen-max-content) {
      min-width: 99%;
      font-size: 16px;
      border-radius: 10px;
      padding: 46px 52px;

      .backgroundImage {
        background-position: center top;
      }
    }

    .quoteMark {
      width: 18px;
      height: 28px;

      @media screen and (max-width: $small-desktop-screen-max-content) {
        width: 10px;
        height: 14px;
      }
    }

    .quote {
      margin: 22px 0;
      font-style: italic;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: $darkblue500;

      @media screen and (max-width: $small-desktop-screen-max-content) {
        margin: 14px 0;
        font-style: normal;
        font-size: 16px;
        line-height: 21px;
      }
    }

    .closeQuote {
      transform: rotate(180deg);
      display: flex;
      justify-content: flex-end;
    }

    .detailWrapper {
      margin-top: 48px;

      .detail {
        display: flex;
        align-items: center;
        margin: 8px 0;
        @include columnGap(12px);

        .detailIcon {
          width: 32px;
          height: 32px;

          @media screen and (max-width: $small-desktop-screen-max-content) {
            width: 28px;
            height: 28px;
          }
        }

        .icon {
          padding: $standard-padding;
          background-color: $badge-background-color;
          border-radius: 50%;
          margin-right: 8px;
          color: $someone-health-blue;
          font-size: 24px;

          @media (max-width: $tablet-screen-max-content) {
            font-size: 18px;
          }
        }

        .detailLabel {
          font-weight: 500;
          font-size: 18px;
          line-height: 23px;
          color: $someone-health-blue;

          @media screen and (max-width: $small-desktop-screen-max-content) {
            font-size: 16px;
            line-height: 21px;
          }
        }

        .verifiedIcon {
          color: $someone-health-pink;
          margin-left: 4px;
          font-size: 20px;
          cursor: pointer;
        }
      }
    }

    .navigateButton {
      margin-top: 56px;

      @media screen and (max-width: $small-desktop-screen-max-content) {
        margin-top: 36px;
      }
    }
  }

  .hiddenWrapper {
    display: none;
  }
}
