@import 'SomeoneHealth/assets/theme';

.section {
  max-width: 1280px !important;

  @media (max-width: $tablet-screen-max-content) {
    padding: 0;
  }

  .container {
    display: flex;
    position: relative;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    background-color: $someone-health-light-pink;
    padding: 24px;
    top: -340px;

    @media (max-width: $medium-desktop-screen-max-content) {
      top: -250px;
      bottom: auto;
    }

    @media (max-width: $tablet-screen-max-content) {
      position: unset;
      width: 100%;
      border-radius: 0 0 12px 12px;
    }

    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: $someone-health-blue;
      padding-bottom: 16px;
    }

    .contentWrapper {
      display: flex;
      justify-content: space-between;
      @include columnGap(16px);

      @media (max-width: $small-desktop-screen-max-content) {
        flex-direction: column;
        @include columnGap(0);
        @include rowGap(16px);
      }

      .shortCard {
        width: 25%;

        @media (max-width: $small-desktop-screen-max-content) {
          width: 100%;
        }
      }

      .longCard {
        width: 50%;

        @media (max-width: $small-desktop-screen-max-content) {
          width: 100%;
        }
      }

      .card {
        display: flex;
        flex-direction: column;
        background-color: $white;
        border-radius: 12px;
        padding: 16px;

        .cardTitle {
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;
          color: $someone-health-blue;
        }

        .desc {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #6b7094;
          padding: 4px 0 16px;
        }

        .nextAvailableListWrapper {
          overflow-y: auto;
          max-height: 280px;
        }

        .seeMore {
          display: flex;
          justify-content: flex-end;
          margin-top: 10px;
        }

        .nextAvailableLoading {
          display: flex;
          margin-top: 24px;
          @include columnGap(16px);
        }

        .nextAvailableList {
          display: flex;
          flex-direction: column;
          @include rowGap(24px);

          .nextAvailableCard {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: space-between;

            .infoWrapper {
              display: flex;
              align-items: center;

              .avatar {
                border-radius: 50%;
                width: 42px;
                height: 42px;
              }

              .name {
                margin: 0 16px;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #414141;
              }
            }

            .nextButton {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 36px;
              height: 36px;
              background: linear-gradient(86.21deg, #f9897d -13.88%, #fd5f87 118.3%);
              border-radius: 40px;
              color: $white;
              cursor: pointer;

              .arrow {
                font-size: 20px;
                width: 20px;
              }
            }
          }
        }

        .featuredCardWrapper {
          display: flex;
          flex-direction: column;
          padding-top: 16px;
          @include rowGap(16px);

          .featuredCard {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            &:not(:last-child) {
              padding-bottom: 16px;
              border-bottom: 1px solid #db3d98;
            }

            @media (max-width: $tablet-screen-max-content) {
              flex-direction: column;
              align-items: flex-start;
            }

            .upperInfo {
              display: flex;
              flex-direction: column;
              @include rowGap(16px);
            }

            .lowerInfo {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              @include rowGap(20px);

              @media (max-width: $tablet-screen-max-content) {
                width: 100%;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: space-between;
                @include rowGap(0);
                padding-top: 16px;
              }
            }

            .tagWrapper {
              display: flex;
              align-items: center;
              @include columnGap(12px);

              .seeMoreSlot {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 700;
                font-size: 10px;
                line-height: 13px;
                text-decoration-line: underline;
                cursor: pointer;
                color: $someone-health-blue500;
              }
            }

            .nextAppointment {
              display: flex;
              flex-direction: column;
              color: #49575a;

              .nextLabel {
                display: flex;
                align-items: center;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 10px;
                line-height: 13px;
              }

              .appointmentDate {
                font-weight: 700;
                font-size: 16px;
                line-height: 21px;
                display: flex;
                align-items: center;
              }
            }

            .featuredInfoWrapper {
              display: flex;
              align-items: center;

              .avatar {
                border-radius: 50%;
                width: 64px;
                height: 64px;
              }

              .clinicianInfo {
                display: flex;
                flex-direction: column;
                margin-left: 16px;

                .clinicianName {
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 23px;
                  display: flex;
                  align-items: center;
                  color: $someone-health-black;
                }

                .clinicianStyles {
                  font-size: 14px;
                  line-height: 18px;
                  display: flex;
                  align-items: center;
                  color: $someone-health-black;
                }
              }
            }

            .viewButton {
              display: flex;
              justify-content: flex-end;

              .viewBtn {
                @media (max-width: $tablet-screen-max-content) {
                  padding: 4px 12px;
                  height: 34px;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 18px;
                }
              }

              .btnIcon {
                @media (max-width: $tablet-screen-max-content) {
                  font-size: 14px;
                }
              }
            }
          }
        }

        .popularSearchWrapper {
          display: flex;
          flex-direction: column;
          padding-top: 16px;
          @include rowGap(16px);

          .popularSearch {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.02em;
            color: #5e606e;

            &:hover {
              color: $someone-health-pink_2;
            }
          }
        }
      }
    }
  }
}
