@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #f6fafc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 24px 20px;

  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;

    @media (max-width: $tablet-screen-max-content) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .desc {
    font-size: 18px;
    line-height: 27px;
    margin-top: 8px;

    @media (max-width: $tablet-screen-max-content) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .imageContainer {
    margin-top: 24px;

    .image {
      width: 100%;
      max-width: 350px;
      user-select: none;
    }
  }

  .buttonContainer {
    margin-top: 20px;

    .button {
      display: flex;
      margin: 0;
      max-width: fit-content;
    }
  }
}
