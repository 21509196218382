@import 'helm/assets/theme';

.container {
  display: flex;

  .mask {
    mask-size: 100% 100%;
    mask-position: center;
    mask-repeat: no-repeat;

    .image {
      width: 100%;
    }
  }

  .maskShape1 {
    mask-image: url(~helm/assets/images/shape/1.png);
  }

  .maskShape2 {
    mask-image: url(~helm/assets/images/shape/2.png);
  }

  .maskShape3 {
    mask-image: url(~helm/assets/images/shape/3.png);
  }

  .maskShape0 {
    mask-image: url(~helm/assets/images/shape/4.png);
  }
}
