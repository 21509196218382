@import 'assets/theme';

.backgroundContainer {
  display: flex;
  flex: 1;
  background: #fff;
  border-radius: 6px;
}

.shBackgroundContainer {
  @extend .backgroundContainer;
  box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.1);
}
