@import 'Ease/assets/theme';

.container {
  position: relative;
  padding-top: 8px;

  &.notTouched {
    .input {
      color: #818e9b;
    }
  }

  &.focus {
    .label {
      color: $blue500;
    }

    .input {
      color: #192a3e;
      border-color: #818e9b;
    }
  }

  &.haveText {
    .backInput {
      visibility: visible;
    }
  }

  .label {
    color: #818e9b;
    position: absolute;
    pointer-events: none;
    font-size: 12px;
    top: 8px;
    font-weight: 600;
  }

  &.error {
    .input {
      border-color: #fc8468;
    }
    .label {
      color: #fc8468 !important;
    }
  }
}

.input {
  padding: 0;
  border: none;
  border-bottom: 1px solid #d3d8dd;
  outline: none;
  height: 38px;
  color: #343434;
  font-size: 13px;
  line-height: 18px;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 16px;
  box-sizing: border-box;
  border-radius: 0;

  &.error {
    border-bottom: 1px solid #ff1900;
    margin-bottom: 0;
  }
}

.label {
  position: absolute;
  pointer-events: none;
  font-size: 11px;
  top: 0;
  color: #818e9b;
  font-weight: 600;
  transition: 0.2s ease all;
}

.backInput {
  position: absolute;
  top: 0;
  margin-top: 24px;
  width: 100%;
  height: 35px;
  pointer-events: none;
  visibility: hidden;
  z-index: 0;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.01rem;
  padding: 0;
  display: flex;
  align-items: center;
  color: #818e9b;
  :global {
    .PhoneInputCountry,
    .flag-dropdown {
      display: none;
    }
  }
}

.frontInput {
  input {
    background: none !important;
    border: none !important;
    padding: 0 !important;
  }
  :global {
    .PhoneInputCountry,
    .flag-dropdown {
      display: none;
    }
  }
}

.enableCountryFlagFrontInput {
  input {
    background: none !important;
    border: none !important;
  }
  :global {
    .PhoneInputCountry,
    .flag-dropdown {
      display: none;
    }
  }
}

.enableCountryFlag {
  display: flex;

  :global {
    .PhoneInputCountry,
    .flag-dropdown {
      display: flex !important;
    }
  }

  .countryCodeInput {
    margin-left: 48px;
  }

  .countryCodeDropdown {
    z-index: 3 !important;
    background-color: transparent !important;
    border: none !important;
    border-bottom: none !important;
    border-right: none !important;
    border-radius: 8px 0 0 0 !important;

    div,
    div:hover {
      background-color: transparent !important;
    }
  }

  .countryDropdown {
    max-height: 100px;
    max-width: 255px;

    li {
      margin-top: 0 !important;
    }
  }
}

.noVisibleChar {
  color: transparent;
}

:global(.ease-theme) {
  .container {
    &.focus {
      .label {
        color: $ease-dark-grey !important;
      }
    }

    .label {
      color: $grey600;
      font-size: 12px;
      font-weight: 400;
    }
  }
}
