.container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 874px;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    padding: 16px 0 0;
    border: 1px solid #000000;
  }

  .content {
    position: relative;
    padding: 16px;
    border-radius: 4px !important;
    min-height: 350px;
  }
}
