@import 'helm/assets/theme';

.container {
  height: max-content;
  min-height: 100vh;
  background-color: $backgroundYellow;
}

.headerContainer {
  position: relative;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
}

.desktopHeader {
  @extend .header;
  display: none;

  @media (min-width: $tablet-screen-max-content) {
    display: flex;
  }
}

.mobileHeader {
  @extend .header;

  @media (min-width: $tablet-screen-max-content) {
    display: none;
  }
}

.loadingWrapper {
  justify-content: center;
  align-items: center;
  min-height: 500px;
  color: $white;
}
