@import 'assets/theme/index';

.container {
  background-color: #f8f9fd;

  .progressBar {
    display: flex;
    flex: 1;
    justify-content: space-between;
    max-width: 850px;
    margin: 0 auto;
    width: 100%;
    padding: 16px 0;
    text-align: center;

    .pillItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 100px;
      cursor: pointer;

      .icon {
        background-color: #ffffff;
        height: 34px;
        width: 34px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #34343433;
        font-weight: bold;
        line-height: 24px;
        font-size: 18px;
        transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);

        &.isActive {
          background-color: #fc8468;
          color: #fff;
        }

        .checkmark {
          color: #4abd03;
        }
      }

      .iconComplete {
        @extend .icon;
        box-sizing: border-box;
        border: 2px solid #4abd02;
      }

      .label {
        display: flex;
        flex: 1;
        justify-content: center;
        font-size: 12px;
        line-height: 18px;
        color: #969698;
        transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
        max-width: 100px;
        margin-top: 8px;

        &.isActive {
          font-weight: 600;
          color: #343434;
        }
      }
    }
  }
}
