@import 'SomeoneHealth/assets/theme';
@import 'assets/theme';

.header {
  padding: 10px 0;
}

.container {
  margin-top: 22px;
  display: flex;
  width: 100%;
  padding-bottom: 30px;
  justify-content: space-around;

  @media (max-width: $medium-desktop-screen-max-content) {
    padding-left: 100px;
  }

  @media (max-width: $small-desktop-screen-max-content) {
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
    flex-direction: column;
    padding-bottom: unset;
  }
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.loginTextClass {
  color: $colorBlue1 !important;
}

.leftContent {
  @media (min-width: ($tablet-screen-max-content + 1)) {
    padding-right: 20px;
  }
}

.rightContent {
  flex: 1;
  text-align: center;
}
