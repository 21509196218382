@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'SomeoneHealth/assets/theme';
@import 'MumsMatter/assets/theme';
@import 'assets/theme';

.contentContainer {
  background-color: white;
  background-image: url(~assets/images/background-circles.png);
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 32px;
  border-radius: $standard-padding * 2;

  @media (max-width: $medium-screen-min-width) {
    padding: 16px;
  }

  .practiceInfoWrapper {
    display: flex;

    .image {
      height: 80px;
      width: 80px;
      margin-right: 8px;
      border-radius: 16px;
    }

    .practiceDetails {
      display: flex;
      flex-direction: column;

      .name {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        align-items: center;
        color: #343434;
      }

      .detail {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #343434;
        opacity: 0.5;
      }
    }
  }

  .clientInfoWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 24px;
    row-gap: 4px;

    @media (max-width: $medium-screen-min-width) {
      flex-direction: column;
    }

    .clientLabel {
      font-weight: bold;
      font-size: 14px;
    }
  }

  .contentBodyWrapper {
    margin: 48px 0 24px;
    max-height: 600px;
    overflow: auto;

    table {
      border: none;
      border-collapse: collapse;
      empty-cells: show;
      margin-bottom: 1em;
      margin-top: 1em;
      max-width: 100%;
    }

    table tr th,
    table tr td {
      border: 1px solid #dadada;
      min-width: 2em;
      padding: 0.4em;
      user-select: text;
      vertical-align: middle;
    }
  }

  .signatureContainer {
    display: flex;
    justify-content: space-between;
    padding: 24px 0;

    @media (max-width: $medium-screen-min-width) {
      flex-direction: column;
      row-gap: 24px;
    }

    .signatureWrapper {
      display: flex;
      flex-direction: column;
      min-width: 200px;

      .signatureBox {
        background: #eff2fc;
        border-radius: 8px;
        font-size: 14px;
        line-height: 19px;
        color: #7c7c7e;
        padding: 12px 16px;
        width: 200px;
        cursor: pointer;
      }

      .signLabel {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        padding-bottom: 4px;
      }

      .previewSignature {
        display: flex;
        flex-direction: column;

        .signature {
          display: flex;
          align-items: center;
          font-family: Kaushan Script;
          font-size: 36px;
          cursor: pointer;
          color: #000000;

          .signatureImg {
            max-width: 200px;
            max-height: 80px;
            margin: 8px 0;
          }
        }
      }
    }
  }

  .submitConsentFormWrapper {
    display: flex;
    padding-top: 60px;
    justify-content: flex-end;

    .submitButton {
      min-width: 200px;
    }
  }
}

.contentContainerWithNoBg {
  @extend .contentContainer;
  background-image: none;
}

:global(.ease-theme) {
  .contentContainer {
    background-image: none;

    .signatureContainer .signatureWrapper .signatureBox {
      background: $ease-light-yellow;
    }
  }
}

:global(.recharge-theme) {
  .contentContainer {
    background-image: none;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);

    .signatureContainer .signatureWrapper .signatureBox {
      background: $recharge-lighter-blue;
    }
  }
}

:global(.select-theme) {
  .contentContainer {
    background-image: none;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);

    .signatureContainer .signatureWrapper .signatureBox {
      color: $select-black;
      background: $select-light-green;
      border-radius: 0;
    }
  }
}

:global(.mums-matter-theme) {
  .contentContainer {
    background-image: none;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);

    .contentBodyWrapper {
      * {
        font-family: $font !important;
        color: $someone-health-maroon !important;
      }
    }
  }
}

:global(.s1h-client-portal-theme) {
  .contentContainer {
    background-image: unset;
    color: $someone-health-maroon;

    .practiceInfoWrapper {
      .practiceDetails {
        .name {
          color: $someone-health-maroon;
          font-weight: $font-weight-bolder;
        }

        .detail {
          color: $someone-health-maroon;
        }
      }
    }

    .contentBodyWrapper {
      * {
        font-family: inherit !important;
        color: $someone-health-maroon !important;
      }
    }

    .signatureContainer {
      .previewSignature .signature {
        color: $someone-health-maroon;
      }

      .signatureWrapper .signatureBox {
        background: $someone-health-light-maroon2;
      }
    }
  }
}
