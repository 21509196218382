@import 'assets/theme';
@import 'helm/assets/theme';
@import 'SomeoneHealth/assets/theme';
@import 'MumsMatter/assets/theme';

.wrapper {
  display: flex;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  user-select: none;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #ebeff2;

    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      color: #323c47;
    }

    .icon {
      color: #c2cfe0;
      cursor: pointer;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 590px;
  padding: 20px 16px;

  @media (min-width: $medium-screen-min-width) {
    width: 500px;
    padding: 20px 32px;
  }

  .signatureTypeWrapper {
    display: flex;
    padding-bottom: 16px;

    .radioWrapper {
      display: flex;

      @media (max-width: $medium-small-screen-min-width) {
        flex-direction: column;
      }
    }

    .radioBtn {
      &:not(:first-child) {
        margin-left: 16px;

        @media (max-width: $medium-small-screen-min-width) {
          margin-left: 0;
        }
      }
    }

    .radioLabel {
      color: #3f52ff;
      font-weight: 600;
      font-size: 13px;

      &::before {
        margin-right: 12px !important;
      }
    }
  }

  .box {
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 20px;

    .valedictionContainer {
      padding: 4px 0 8px;
      width: 45%;

      .valedictionField {
        height: 20px;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #595959;
      }

      .valedictionLabel {
        font-size: 11px;
        height: 16px;
      }
    }

    .previewSignature {
      display: flex;
      align-items: center;
      font-family: Kaushan Script;
      font-size: 36px;
      color: #000000;

      .signatureImg {
        max-width: 200px;
        max-height: 80px;
        margin: 8px 0;
      }
    }

    .signaturePad {
      width: 100%;
      height: 100%;
      border-bottom: 1px dashed #343434;
      background-color: #f8f9fd;
      min-height: 150px;

      @media (max-width: $medium-screen-min-width) {
        min-height: 200px;
      }
    }

    .signatureBtnWrapper {
      display: flex;
      justify-content: space-between;
      max-width: 170px;

      .clearBtn {
        display: flex;
        padding: 2px;
        height: 34px;
        width: 80px;
      }

      .saveBtn {
        align-items: center;
        padding: 2px;
        height: 34px;
        width: 80px;
        flex: inherit;
        margin: 0;
      }
    }

    .signatureInput {
      display: flex;
      width: 100%;
      border: none;
      border-bottom: 1px dashed #343434;
      font-family: Kaushan Script;
      font-size: 36px;
      line-height: 20px;
      color: #000000;
    }
  }

  .includeBelowSignature {
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      align-items: center;
      margin-top: 32px;
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      color: #323c47;

      @media (max-width: $medium-screen-min-width) {
        font-size: 16px;
      }

      .icon {
        margin-left: 8px;
        color: #fd8468;
        border-radius: 50%;
        cursor: pointer;
      }

      .tooltip {
        max-width: 230px;
        border-radius: 8px;
        line-height: 16px;
        font-size: 14px;
        padding: 12px;
        opacity: 0.95 !important;
      }
    }
  }

  .btnBox {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
    padding-bottom: 20px;

    .button {
      max-width: 200px;
    }
  }
}

:global(.s1h-client-portal-theme) {
  .container {
    .header {
      .title {
        color: $someone-health-maroon;
      }
    }
  }
  .content .box .signaturePad {
    background-color: $someone-health-light-maroon2;
  }
}

:global(.helm-theme) {
  .container {
    .header .title {
      font-family: $title-font-stack;
      font-weight: normal;
    }
  }

  .signatureTypeWrapper,
  .styles.error {
    font-family: $primary-font-stack-medium;
  }

  .clearBtn {
    font-family: $title-font-stack;
    border-color: $helm-blue;
    color: $helm-blue;
  }

  .button {
    background: $helm-blue;
    color: $helm-lighter-yellow;
    border-radius: 20px;
    font-family: $title-font-stack;
    font-weight: normal !important;
  }
}

:global(.mums-matter-theme) {
  .container {
    .header .title {
      font-family: $mums-matter-black;
      font-weight: normal;
    }
  }

  .clearBtn {
    font-family: $title-font-stack;
    border-color: $helm-blue;
    color: $helm-blue;
  }
}
