@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  color: #192a3e;
  padding: 24px 16px 40px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 16px;

  @media (max-height: 768px) {
    margin-top: 0;
  }

  .expandHeader {
    display: flex;
    align-items: center;
    padding-bottom: 16px;

    .logoWrapper {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      border: 1px solid #6575b1;
      margin-right: 16px;

      @media (max-width: $medium-screen-min-width) {
        height: 60px;
        width: 60px;
      }
    }

    .practiceInfoWrapper {
      line-height: 23px;

      .welcomeLabel {
        font-size: 14px;
        padding-bottom: 4px;
      }

      .practiceName {
        font-weight: bold;
        font-size: 22px;
      }
    }
  }

  .expandContent {
    max-height: 400px;
    overflow: hidden;

    @media (max-height: 768px) {
      max-height: 250px;
      margin: 0 8px;
    }

    @media only screen and (max-height: 600px) {
      max-height: 130px;
    }
  }
}

.noBoxContainer {
  @extend .container;
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  margin-top: 0;
  padding: 8px 0 32px;

  .expandContent {
    max-height: 400px;
    overflow: auto;
  }
}
