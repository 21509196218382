@import 'CaW/assets/theme';

.container {
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;

  .customInput {
    transform: translateX(-$standard-padding);
    padding: 0 $standard-padding;
    color: #343434;
    margin-bottom: $standard-padding !important;
    line-height: 44px;

    &:focus ~ .inputLabel {
      color: $caw-blue !important;
    }
  }

  .extraLongInputLabelContainer {
    .customInput {
      margin-top: 50px;

      @media (max-width: $medium-small-screen-min-width) {
        margin-top: 70px;
      }

      &:focus,
      &:not(:focus):valid {
        ~ .inputLabel {
          top: 15px;
        }
      }
    }
  }

  .customSelect {
    padding: $standard-padding * 2 0;
  }

  .inputLabel {
    color: #818e9b;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }

  .selectInputLabel {
    @extend .inputLabel;
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;

    &:focus {
      color: $caw-blue !important;
    }
  }

  .showPasswordButtonClass {
    &:hover {
      color: $caw-dark-red !important;
      background-color: transparent !important;
    }
  }

  .customLabel {
    padding-left: 16px;
    color: #202225;
  }

  .header {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 16px;
    display: block;

    .highlight {
      color: $caw-dark-red;
    }

    .primaryHeader {
      @include playfairDisplayFont;
    }
  }

  .subHeader {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 6px;
  }

  .loginText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: right;

    @media (max-width: $tablet-screen-max-content) {
      text-align: left;
    }

    .loginLink {
      color: $caw-dark-red;
      text-decoration: underline;
      margin-left: 8px;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .passwordHint {
    padding: 0 $standard-padding * 4;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    .hints {
      padding-top: $standard-padding * 3;

      ul {
        padding: 0;

        li {
          list-style: none;
          padding: $standard-padding * 1 0;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: $grey700;

          &::before {
            content: '';
            display: inline-block;
            vertical-align: text-top;
            height: 20px;
            margin-right: $standard-padding * 4;
            width: 20px;
            border: solid 3px $grey700;
            border-radius: 10px;
          }
        }
      }
    }
  }

  .passwordHintWithError {
    margin-top: 0;
  }

  .isTermsAndConditionsAccepted,
  .communicationPreferences .checkBoxes {
    padding: 0 $standard-padding * 4;

    .checkBox {
      vertical-align: top;

      input {
        outline: none;
        transform: scale(0.75);
        &:checked::after {
          display: inline-flex;
        }
      }
      margin-right: $standard-padding * 3;
    }

    .checkBoxText {
      height: fit-content;
      display: inline-block;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-top: $standard-padding;
      margin-bottom: $standard-padding * 3;

      a {
        text-decoration: underline;
        color: $caw-dark-red;
      }
    }
  }

  .collectionNotice {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;

    .title {
      font-weight: 600;
      font-size: 18px;
    }

    a {
      text-decoration: underline;
      color: $caw-dark-red;
    }
  }

  .communicationPreferences {
    padding-top: $standard-padding * 5;
    font-weight: 400;
    font-size: 14px;

    .title {
      font-weight: 600;
      font-size: 18px;
    }

    .checkBoxes {
      display: flex;
      flex-direction: column;
      margin-top: $standard-padding * 2;
    }
  }

  .submitButton {
    width: 100%;
    margin-top: $standard-padding * 7;
    margin-bottom: $standard-padding * 15;

    @media (max-width: $mobile-screen-max-content) {
      margin-bottom: $standard-padding * 20;
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}
