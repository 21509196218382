@import 'assets/theme';

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px 0 0 0;

  @media (max-width: $medium-small-screen-min-width) {
    padding: 16px 0 50px;
  }
}
