@import 'CaW/assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.header {
  padding: 8px 16px;
}

.content {
  background-color: $caw-light-blue;
  padding: 16px 0;
}
