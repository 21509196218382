@import 'SomeoneHealth/assets/theme';

.container {
  display: flex;
  width: 90%;
  align-items: center;
  column-gap: 16px;

  @media (max-width: $medium-desktop-screen-max-content) {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    @include rowGap(32px);
  }

  .mentalHealthConcern {
    max-width: 460px;
    width: 100%;

    @media (max-width: $medium-desktop-screen-max-content) {
      max-width: 100%;
    }
  }

  .secondLayerFilterWrapper {
    display: flex;
    width: 100%;
    align-items: center;

    .ageRange {
      max-width: 220px;
      width: 100%;
      margin-right: 16px;

      @media (max-width: $medium-desktop-screen-max-content) {
        max-width: 50%;
      }
    }

    .gender {
      max-width: 220px;
      width: 100%;

      @media (max-width: $medium-desktop-screen-max-content) {
        max-width: 50%;
      }
    }

    .reset {
      display: flex;
      color: $white;
      font-size: 14px;
      line-height: 18px;
      text-decoration-line: underline;
      margin-left: 12px;
      cursor: pointer;
      user-select: none;

      &.disabled {
        cursor: not-allowed;
      }

      @media (max-width: $medium-desktop-screen-max-content) {
        display: none;
      }
    }
  }
}
