@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .appointmentTitle {
    font-family: $title-font-stack;
    font-size: 16px;
    line-height: 24px;
    color: $helm-purple;
  }

  .connectWrapper {
    padding-top: 8px;
    font-family: $primary-font-stack-medium;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: $helm-blue;

    @media (min-width: $medium-small-screen-min-width) {
      font-size: 16px;
    }

    .appointmentLink {
      color: #3f52ff;
      text-decoration: underline;
      word-break: break-all;
    }
  }
}
