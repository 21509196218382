@import 'assets/theme';
@import 'Ease/assets/theme';
@import 'Recharge/assets/theme';
@import 'Select/assets/theme';
@import 'SomeoneHealth/assets/theme';
@import 'MumsMatter/assets/theme';

.card {
  background-color: #ffffff;
  border-radius: $standard-padding;
  border: 1px solid;

  .loaderContainer {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header {
    padding: $standard-padding * 2 $standard-padding * 4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .left {
      color: $main-blue;
      font-size: 24px;
      font-weight: 600;

      display: flex;
      align-items: center;

      .headerText {
        margin-right: $standard-padding * 2;
      }

      .numberCircle {
        line-height: 24px;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
        padding: 6px 12px;
        background-color: $main-blue;
        border-radius: 50%;
      }
    }

    .right {
      .button {
        margin: 0;
        display: none;

        @media (min-width: $medium-screen-min-width) {
          display: flex;
          align-items: center;
        }

        .buttonIcon {
          margin-right: $standard-padding * 1.5;
          font-size: 20px;
        }

        .buttonText {
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
        }
      }
    }
  }
}

:global(.ease-theme) {
  .header .left .numberCircle {
    color: $ease-dark-grey;
    background-color: $ease-green;
  }
}

:global(.recharge-theme) {
  .header .left {
    .headerText {
      color: $recharge-black;
    }

    .numberCircle {
      color: $white;
      background-color: $recharge-purple;
    }
  }
}

:global(.select-theme) {
  .header .left {
    .headerText {
      color: $select-black;
    }

    .numberCircle {
      color: $select-black;
      background-color: $select-red;
    }
  }
}

:global(.mums-matter-theme) {
  .header .left {
    .headerText {
      color: $mums-matter-black;
    }

    .numberCircle {
      color: $mums-matter-black;
      background-color: $mums-matter-orange;
    }
  }
}

:global(.s1h-client-portal-theme) {
  .header .left {
    .headerText {
      color: $someone-health-maroon;
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
    }

    .numberCircle {
      color: $white;
      background-color: $someone-health-maroon;
      font-weight: 400;
    }
  }
}
