@import 'CaW/assets/theme';

.container {
  color: $caw-blue;

  .label {
    font-size: 12px;
    line-height: 16px;
  }

  .specialisationBadgeWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;

    .specialisationBadge {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      padding: $standard-padding * 2 $standard-padding * 3;
      border-radius: 25px;
      background-color: $colorGrey100;
      margin: 0 8px 8px 0;
      color: $caw-blue;
    }

    .specialisationBadgeSelected {
      @extend .specialisationBadge;
      background-color: $caw-light-green;
    }
  }

  .specialisationBadgeVerticalWrapper {
    @extend .specialisationBadgeWrapper;
    flex-direction: column;

    .specialisationBadge {
      max-width: fit-content;
    }
  }

  .seeMoreSlot {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
    text-decoration-line: underline;
    cursor: pointer;
    margin: 0 8px 8px 0;
    color: $caw-light-blue;
  }
}
