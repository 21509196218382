@import 'helm/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(20px);

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $grey100;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 2px;
    background-color: $grey100;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey500;
  }

  .card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 24px 20px;

    .activityTitle {
      font-family: $title-font-stack;
      color: $helm-purple;
    }

    .name {
      font-family: $title-font-stack;
      font-size: 20px;
      line-height: 30px;
      margin: 4px 0;
      color: $helm-blue;
    }

    .desc {
      font-family: $primary-font-stack-light;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: $helm-blue;
    }

    .buttonWrapper {
      display: flex;
      margin-top: 8px;

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 18px;
        font-family: $title-font-stack;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        border-radius: 20px;
        box-shadow: none;

        .markIcon {
          margin-right: 8px;
        }
      }
    }
  }

  .completeCard {
    @extend .card;
    background-color: $green50;

    .activityTitle {
      color: $green500;
    }
  }

  .checkBoxCard {
    @extend .card;
    display: flex;
    flex-direction: column;
    background-color: $backgroundYellow;
  }
}
