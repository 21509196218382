@import 'helm/assets/theme';

.container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 60px 0 0 8px;

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
  }

  .bgImage {
    display: none;

    @media (max-width: $tablet-screen-max-content) {
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      background: url(~helm/assets/images/ip6/progressMountain.png) right bottom no-repeat;
      background-size: contain;
      opacity: 0.4;
    }
  }

  .dot {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $grey100;
    color: $helm-blue;
    cursor: pointer;
  }

  .completeDot {
    background-color: $green500 !important;
    color: $white !important;
  }

  .activeDot {
    border: 8px solid $helm-yellow;
  }

  .sessionContainer {
    display: flex;
    flex-direction: column;
    padding: 0 50px;
    @include rowGap(12px);
    z-index: 9;

    @media (max-width: $tablet-screen-max-content) {
      padding: 0 24px 60px;
    }

    .sessionCard {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;

      .sessionDot {
        @extend .dot;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        background-color: #94969d;
        color: $white;
      }

      .sessionActiveDot {
        background-color: $helm-yellow;
        color: $helm-blue;
      }
    }
  }

  .quoteContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    color: $helm-blue;
    padding: 24px;
    z-index: 9;

    @media (max-width: $tablet-screen-max-content + 150px) {
      padding: 12px;
    }

    @media (max-width: $tablet-screen-max-content) {
      display: none;
    }

    .quoteContent {
      display: flex;
      flex-direction: column;
      max-width: 50%;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;

      @media (max-width: $tablet-screen-max-content + 150px) {
        font-size: 14px;
        line-height: 22px;
      }

      .quote {
        display: flex;
        padding-bottom: 8px;
      }

      .quoteBy {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .bgContainer {
    position: absolute;
    right: 0;
    bottom: 0;

    @media (max-width: $tablet-screen-max-content) {
      display: none;
    }

    .image {
      width: 100%;
      z-index: -99;
      user-select: none;
      border-radius: 0px 0px 14px 0px;
    }

    .dotContainer {
      display: flex;
      flex-direction: column;

      .mountainDot {
        @extend .dot;
        width: 44px;
        height: 44px;
        position: absolute;
        z-index: 99;
      }

      .dot1 {
        @extend .mountainDot;
        bottom: 48px;
        left: -1px;
      }

      .dot2 {
        @extend .mountainDot;
        bottom: 26%;
        left: 16%;
      }

      .dot3 {
        @extend .mountainDot;
        bottom: 46%;
        left: 36%;
      }

      .dot4 {
        @extend .mountainDot;
        top: 30%;
        right: 41%;

        @media (max-width: $tablet-screen-max-content + 150px) {
          top: 27%;
          right: 38%;
        }
      }

      .dot5 {
        @extend .mountainDot;
        top: 8%;
        right: 26%;
      }

      .dot6 {
        @extend .mountainDot;
        top: -3%;
        right: 1%;

        @media (max-width: $tablet-screen-max-content + 150px) {
          top: -5%;
        }
      }
    }

    .supportIconWrapper {
      display: flex;

      .taskIcon {
        font-size: 14px;
        position: absolute;
        color: #00109f;
        z-index: 99;

        @media (max-width: $tablet-screen-max-content + 150px) {
          font-size: 10px;
        }
      }

      .taskIcon1 {
        @extend .taskIcon;
        bottom: 21%;
        left: 8%;

        @media (max-width: $tablet-screen-max-content + 150px) {
          bottom: 24%;
          left: 11%;
        }
      }

      .taskIcon2 {
        @extend .taskIcon;
        bottom: 36%;
        left: 30%;
      }

      .taskIcon3 {
        @extend .taskIcon;
        bottom: 58%;
        left: 46.5%;
      }

      .taskIcon4 {
        @extend .taskIcon;
        bottom: 75%;
        left: 63%;
      }

      .taskIcon5 {
        @extend .taskIcon;
        bottom: 95%;
        left: 81%;
      }
    }
  }
}
