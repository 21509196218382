@import 'assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.container {
  height: 100vh;
  background-color: #2a4670;
  position: relative;
  z-index: 1;

  .body {
    height: 100%;

    @media (max-width: $medium-screen-min-width) {
      padding: 0;
    }
  }

  .headerContainer {
    display: flex;
    padding: 8px 0;

    .logo {
      max-width: 140px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 8px 20px;
    color: #fff;
    justify-content: space-between;
    margin-bottom: 60px;

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      padding-bottom: 16px;

      @media (max-width: $medium-screen-min-width) {
        font-size: 20px;
        padding-bottom: 8px;
      }
    }

    .desc {
      font-size: 18px;
      line-height: 28px;
      padding-bottom: 24px;

      @media (max-width: $medium-screen-min-width) {
        font-size: 16px;
        padding-bottom: 16px;
      }
    }

    .taskBox {
      display: flex;
      flex-direction: column;
      padding-bottom: 24px;
      row-gap: 16px;
      max-width: 530px;

      .labelTitle {
        font-size: 18px;
        margin-bottom: -8px;
      }
    }
  }
}
