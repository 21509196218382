@import 'helm/assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .taskContentWrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: $helm-blue;
    border-radius: 16px;
  }

  .sessionContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
    position: relative;
    background: linear-gradient(0deg, rgba(250, 223, 74, 0.2), rgba(250, 223, 74, 0.2)), #ffffff;
    border-radius: 16px;

    .bgImage {
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      background-size: cover !important;
      border-radius: 16px;
    }

    .sessionBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      z-index: 99;
      padding: 40px 70px;
      color: $grey700;
      max-width: 680px;

      @media screen and (max-width: $tablet-screen-max-content) {
        padding: 24px;
      }

      .session {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #00109f;
      }

      .sessionTime {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: $grey700;
      }

      .chapterName {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: $grey700;
        margin-top: 16px;
      }

      .chapterDesc {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: $grey700;
      }

      .chapterAppointmentWrapper {
        display: flex;
        flex-direction: column;
        padding-top: 16px;

        .appointmentTitle {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #00109f;
        }

        .appointmentLink {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #3f52ff;
          padding-top: 8px;
        }
      }

      .moduleButtonWrapper {
        padding-top: 20px;

        .moduleButton {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px 18px;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          box-shadow: none;
        }
      }
    }
  }

  .activityContent {
    max-height: 600px;
    overflow: auto;
    padding: 0;

    .card {
      max-width: 400px;
      min-width: 400px;

      @media (max-width: $tablet-screen-max-content) {
        min-width: 100%;
        padding: 20px;
      }
    }
  }

  .doneActivityContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 16px 80px;
    border-radius: 16px;
    background: url(~helm/assets/images/ip6/hooray.png) center center no-repeat;
    background-size: cover;

    @media (max-width: $tablet-screen-max-content) {
      padding: 16px;
    }

    .doneTitle {
      font-weight: 600;
      font-size: 48px;
      line-height: 62px;
      letter-spacing: -0.04em;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 26px;
        line-height: 28px;
      }
    }

    .doneDesc {
      font-size: 18px;
      line-height: 27px;
      margin-top: 8px;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  .unlockContent {
    @extend .doneActivityContent;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: $grey700;
    padding: 80px;
    background: url(~helm/assets/images/ip6/unlockBg.png) no-repeat;
    background-size: cover;

    .buttonWrapper {
      display: flex;
      margin-top: 32px;

      .button {
        box-shadow: none;
      }
    }
  }
}
