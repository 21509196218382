@import 'Recharge/assets/theme';

.header {
  @media (max-width: $small-screen-max-width) {
    padding: 16px 0 !important;
  }
}

.container {
  margin-top: 16px;
  display: flex;
  width: 100%;
  padding-bottom: 30px;
  justify-content: space-around;

  @media (max-width: $medium-desktop-screen-max-content) {
    padding-left: 100px;
  }

  @media (max-width: $small-desktop-screen-max-content) {
    padding-left: 0;
    padding-right: 0;
    justify-content: space-evenly;
    padding-bottom: unset;
  }
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.leftContent {
  @media (min-width: ($tablet-screen-max-content + 1)) {
    padding-right: 20px;
  }
}

.rightContent {
  max-width: 410px;
  width: 100%;

  @media (max-width: $small-desktop-screen-max-content) {
    max-width: 350px;
  }

  @media (max-width: $tablet-screen-max-content) {
    display: none;
  }

  .notice {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    flex-direction: column;

    @media screen and (max-width: $medium-large_screen-min-width) {
      flex-direction: row;
    }

    .noticeMsg {
      max-width: 260px;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;

      > div {
        text-align: center;
      }
    }
  }
}
