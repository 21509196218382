@import 'helm/assets/theme';

:global(.helm-theme) {
  .newPlanButton {
    background: $helm-yellow !important;
    color: $helm-blue !important;
    border-radius: 20px;
    font-family: $title-font-stack;
    font-weight: normal !important;
  }
}
