@import 'Ease/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 16px;
}

.title {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: $colorGrey700;
}

.checkBoxLabel {
  font-weight: bold;
}
