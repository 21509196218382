@import 'Recharge/assets/theme';

.container {
  position: relative;
  text-align: center;
  font-family: $font, normal;

  .menuTrigger {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .menuBox {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 101;
    right: 0;
    border-radius: 8px;
    padding: $standard-padding * 4;
    background-color: $white;
    font-weight: 600;
    font-size: 18px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 380px;
    gap: $standard-padding * 5;

    @media (max-width: $tablet-screen-max-content) {
      font-size: 16px;
    }

    .greeting {
      font-weight: bold;
      font-size: 28px;
      line-height: 42px;
      color: $black;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .bookAppointment {
      display: flex;
      box-shadow: none;
      width: 100%;
    }

    .signOut {
      color: $recharge-black;
      cursor: pointer;
    }
  }
}
