@import 'helm/assets/theme';

.impactAppointmentModal .ant-modal-content {
  border-radius: 0;
  overflow: hidden;
}

.ant-modal-header {
  border-radius: 8px 8px 0 0;

  @media (max-width: $small-desktop-screen-max-content + 100px) {
    padding: 12px 16px !important;
  }
}

.impactAppointmentModal .ant-modal-body {
  @media (max-width: $small-desktop-screen-max-content + 100px) {
    padding: 0 !important;
  }
}
