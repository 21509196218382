@import 'Select/assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.container {
  .headerContent {
    display: flex;
    width: 100%;
    flex: initial;
  }

  .headerWrapper {
    display: flex;
  }
}

.content {
  padding-top: 24px;
  padding-bottom: 24px;
  width: 100%;
  position: relative;

  @media (max-width: $tablet-screen-max-content) {
    padding: 0;
  }

  .consentContent {
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  }
}
