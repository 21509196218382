@import 'helm/assets/theme';

.greet {
  color: $helm-blue;
  font-family: $title-font-stack;
  font-size: 60px;
  line-height: 66px;
  padding-bottom: $standard-padding * 4;

  @media (max-width: $small-desktop-screen-max-content) {
    font-size: 52px;
    line-height: 44px;
  }

  @media (max-width: $tablet-screen-max-content) {
    font-size: 40px;
    line-height: 44px;
  }
}

.greetDesc {
  white-space: pre-line;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: $helm-blue;

  @media (max-width: $small-desktop-screen-max-content) {
    font-size: 16px;
    line-height: 21px;
  }
}

.videoWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  .title {
    color: $helm-blue;
    font-family: $title-font-stack;
    font-weight: 800;
    font-size: 40px;
    line-height: 44px;
    padding-bottom: $standard-padding * 4;
  }

  .video {
    width: 100%;
    height: 460px;
    object-fit: cover;
    border-radius: 20px;
    cursor: pointer;

    @media (max-width: $tablet-screen-max-content) {
      height: auto;
    }
  }
}

.bioPhotoWrapper {
  display: flex;
  margin-top: 60px;

  @media (max-width: $small-desktop-screen-max-content) {
    margin-top: 24px;
  }

  .bioPhoto {
    width: 100%;
    border-radius: 20px;
  }
}
