@import 'Select/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.title {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  color: $select-black;
}

.description {
  margin-bottom: 24px;
  color: $select-black;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.checkBoxLabel {
  font-weight: 600;
}
