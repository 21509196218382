.container {
  display: flex;
  padding: 16px 16px 4px;
  flex-direction: column;

  .signLabel {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }

  .signature {
    display: flex;
    align-items: center;
    font-family: Kaushan Script;
    font-size: 36px;
    color: #000000;

    .signatureImg {
      max-width: 200px;
      max-height: 80px;
      margin: 8px 0;
    }
  }

  .extraDetailsWrapper {
    margin-top: 12px;
    font-size: 16px;
    color: #343434;

    .extraDetails {
      &:first-of-type {
        font-weight: bold;
      }

      &:not(:first-of-type) {
        font-size: 12px;
      }
    }
  }
}
