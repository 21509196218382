@import 'helm/assets/theme';

@font-face {
  font-family: $title-font-stack;
  src: url(~helm/assets/theme/typography/national2compressed-extrabold.woff2) format('woff2'),
    url(~helm/assets/theme/typography/national2compressed-extrabold.woff) format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $title-font-stack;
  src: url(~helm/assets/theme/typography/national2compressed-extrabold.woff2) format('woff2'),
    url(~helm/assets/theme/typography/national2compressed-extrabold.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $primary-font-stack-medium;
  src: url(~helm/assets/theme/typography/geometricslab703bt-medium-webfont.woff2) format('woff2'),
    url(~helm/assets/theme/typography/geometricslab703bt-medium-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $primary-font-stack-light;
  src: url(~helm/assets/theme/typography/geometricslab703bt-light-webfont.woff2) format('woff2'),
    url(~helm/assets/theme/typography/geometricslab703bt-light-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $primary-font-stack-bold;
  src: url(~helm/assets/theme/typography/geometricslab703bt-bold-webfont.woff2) format('woff2'),
    url(~helm/assets/theme/typography/geometricslab703bt-bold-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

.container {
  display: flex;
  flex-direction: column;
  font-family: $primary-font-stack-medium, normal;

  input,
  textarea,
  button {
    font-family: $primary-font-stack-medium, -apple-system, BlinkMacSystemFont, sans-serif;
  }
}
